import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';

@Component({
  selector: 'app-comment-delete-reason',
  templateUrl: './comment-delete-reason.component.html',
  styleUrls: ['./comment-delete-reason.component.scss'],
})
export class CommentDeleteReasonComponent implements OnInit {
  deleteCommentReasonForm: FormGroup;
  submitAttempt : boolean = false;
  comment ;
  postID ;
  constructor(public formBuilder: FormBuilder,
              public postService: PostService,
              public modalController: ModalController) { 
    this.deleteCommentReasonForm = this.formBuilder.group({ 
      reason: ['', Validators.required]
    });
  }

  ngOnInit() {}

  closemodal(){
    this.modalController.dismiss();
  }

  submitReason(){
    this.submitAttempt = true;
    console.log(this.deleteCommentReasonForm.status)
    if (this.deleteCommentReasonForm.status == 'VALID') {

      console.log(this.comment , this.deleteCommentReasonForm.value.reason)
      this.postService.deleteComment(this.comment, this.deleteCommentReasonForm.value.reason , this.postID);
    }
  }

}
