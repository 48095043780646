import { Injectable } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { newUserSignupObject, UserService } from '../user/user.service';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { EncryptionService } from '../encryption/encryption.service';
import { LoggingService } from '../logging/logging.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserLogin } from 'src/app/interfaces/user-login';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { PostService } from '../post/post.service';
import { UserAnalyticsService } from '../user-analytics/user-analytics.service';
// import { ChatService } from '../chat/chat.service';
import { AIChatService } from 'src/app/ai/public/service/aichat.service';

import { map } from 'rxjs/operators';
import { SocketService } from '../socket/socket.service';

declare const google: any; // Fix for Web Google Identity Services

@Injectable({
    providedIn: 'root'
})
export class OauthService {
    public userName: string = '';
    public userEmail: string = '';
    public platform: string = sessionStorage.getItem('platform'); // Default to Web
    public webClientId = '848966689874-stse4mlnpo7ohsueju3alf583dme1o6c.apps.googleusercontent.com';
    public androidClientId = '848966689874-stse4mlnpo7ohsueju3alf583dme1o6c.apps.googleusercontent.com';
    // public iOSClientId = '848966689874-stse4mlnpo7ohsueju3alf583dme1o6c.apps.googleusercontent.com';
    public iOSClientId = '848966689874-ckmsnk0vj4dgcsh4j83cf5jus7uv5bh4.apps.googleusercontent.com';
    public userFirstName: string = '';
    public userLastName: string = '';
    signUpLoader: any;
    signupMode: string = 'email';
    oauthType: string = 'login';
    // public iOSClientId = '848966689874-ec3asb1qs5hg3abpqmg4ad0eeffkprj4.apps.googleusercontent.com';
    selectedOccupation: string = '';
    userDefinedOccupation: string = '';
    selectedSpecialties: any = [];
    submitAttempt: boolean = false;
    public AppName: any;
    invalidAttempt: boolean = false;
    userDetails: any;
    navigating: boolean = false;
    currentURL: any = '';

    constructor(
        public loadingController: LoadingController,
        public encryptionservice: EncryptionService,
        public userservice: UserService,
        public loggingService: LoggingService,
        public authService: AuthenticationService,
        public platformType: Platform,
        public modalCtrl: ModalController,
        public router: Router,
        public socketService: SocketService,
        public storageService: StorageService,
        public postService: PostService,
        public userAnalyticsService: UserAnalyticsService,
        public aichatService: AIChatService,
        public userService: UserService
    ) {
        if (this.platform === 'web') {
            this.loadGoogleScript();
        }
    }



    async login() {
        try {
            if (!this.platform) this.platform = sessionStorage.getItem('platform');

            // alert(`platform -> ${this.platform}`)
            if (this.platform === 'ios') {
                await this.loginMobileIos();
            } else if (this.platform === 'android') {
                await this.loginMobileAndroid();
            } else {
                await this.loginWeb();
            }
        } catch (error) {
            console.error('Login Failed:', error);
        }
    }

    public async loginWeb() {
        await this.loadGoogleScript(); // Ensure GIS script is loaded before calling prompt

        setTimeout(() => {
            if (google && google.accounts && google.accounts.id) {
                google.accounts.id.prompt(); // Open Google Login Popup
            } else {
                console.error('Google Identity Services not initialized');
            }
        }, 500); // Short delay before login
    }

    public async loginMobileAndroid() {
        try {
            await GoogleAuth.initialize({
                clientId: this.androidClientId,
                scopes: ['profile', 'email'],
                grantOfflineAccess: true
            }).then(async (res) => {
                console.log("🚀 ~ file: oauth.service.ts:32 ~ OauthService ~ initializeGoogleAuth ~ res:", res)
                const user = await GoogleAuth.signIn();
                this.userName = user.name || '';
                this.userEmail = user.email || '';
                this.splitFullName(this.userName);
                localStorage.setItem('google_user', JSON.stringify({ name: this.userName, first: this.userFirstName, last: this.userLastName, email: this.userEmail }));
                console.log("Google Login Success on Mobile:", user);

                if (this.oauthType == 'signup') this.registerUser();
                else if (this.oauthType == 'login') this.loginHealable();
            });
        } catch (error) {
            console.error('Google Login Failed on Mobile:', error);
        }
    }

    public async loginMobileIos() {
        try {
            await GoogleAuth.initialize({
                clientId: this.iOSClientId,
                scopes: ['profile', 'email'],
                grantOfflineAccess: true
            }).then(async (res) => {
                console.log("🚀 ~ file: oauth.service.ts:32 ~ OauthService ~ initializeGoogleAuth ~ res:", res)
                const user = await GoogleAuth.signIn();
                this.userName = user.name || '';
                this.userEmail = user.email || '';
                this.splitFullName(this.userName);
                localStorage.setItem('google_user', JSON.stringify({ name: this.userName, first: this.userFirstName, last: this.userLastName, email: this.userEmail }));
                console.log("Google Login Success on Mobile:", user);

                if (this.oauthType == 'signup') this.registerUser();
                else if (this.oauthType == 'login') this.loginHealable();
            });
        } catch (error) {
            console.error('Google Login Failed on Mobile:', error);
        }
    }

    // public async loginMobile() {
    //     try {
    //         // Ensure GoogleAuth is initialized
    //         await GoogleAuth.initialize({
    //             clientId: this.platform === 'ios' ? this.iOSClientId : this.androidClientId,
    //             scopes: ['profile', 'email'],
    //             grantOfflineAccess: true
    //         });

    //         // Sign in
    //         const user = await GoogleAuth.signIn();
    //         this.userName = user.name || '';
    //         this.userEmail = user.email || '';

    //         localStorage.setItem('google_user', JSON.stringify({ name: this.userName, email: this.userEmail }));
    //         console.log("Google Login Success on Android:", user);
    //     } catch (error) {
    //         console.error('Google Login Failed on Android:', error);
    //     }
    // }



    public async loadGoogleScript(): Promise<void> {
        return new Promise((resolve) => {
            if (document.getElementById('google-auth')) {
                resolve();
                return;
            }

            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.id = 'google-auth';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (google && google.accounts && google.accounts.id) {
                    google.accounts.id.initialize({
                        client_id: this.webClientId,
                        callback: this.handleCredentialResponse.bind(this),
                        cancel_on_tap_outside: false,
                        ux_mode: 'popup'
                    });
                    resolve();
                } else {
                    console.error('Google Identity Services failed to initialize');
                }
            };
            document.head.appendChild(script);
        });
    }

    public handleCredentialResponse(response: any) {
        console.log('Google Token:', response.credential);
        localStorage.setItem('google_token', response.credential);
        this.extractUserData(response.credential);
    }

    public extractUserData(token: string) {
        const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT token
        this.userName = payload.name || '';
        this.userEmail = payload.email || '';
        this.splitFullName(this.userName);
        localStorage.setItem('google_user', JSON.stringify({ name: this.userName, first: this.userFirstName, last: this.userLastName, email: this.userEmail }));


        if (this.oauthType == 'signup') this.registerUser();
        else if (this.oauthType == 'login') this.loginHealable();
    }

    public splitFullName(fullName) {
        let parts = fullName.split(" ");
        [this.userFirstName, this.userLastName] = [parts[0], parts.slice(1).join(" ")]; // First word as first name, rest as last name
    }

    logout() {
        if (this.platform === 'web') {
            google.accounts.id.disableAutoSelect();
        } else {
            GoogleAuth.signOut();
        }

        localStorage.removeItem('google_user');
        this.userName = '';
        this.userFirstName = '';
        this.userLastName = '';
        this.userEmail = '';
    }

    get isAuthenticated(): boolean {
        return !!localStorage.getItem('google_user');
    }


    generateScreenName(firstName, lastName) {
        firstName = firstName.toLowerCase();
        lastName = lastName.toLowerCase();

        // Combine first and last name characters
        let charArray = (firstName + lastName).split("");

        // Shuffle characters randomly (Fisher-Yates Shuffle)
        for (let i = charArray.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [charArray[i], charArray[j]] = [charArray[j], charArray[i]]; // Swap
        }

        let shuffledName = charArray.join("");

        // Append current timestamp (last 6 digits for more uniqueness)
        let timestamp = Date.now().toString().slice(-6);
        shuffledName += timestamp;

        // Ensure the final name is at least 12 characters long
        while (shuffledName.length < 12) {
            shuffledName += Math.floor(Math.random() * 10); // Add random digits
        }

        // Trim the final username to a max of 15 characters
        shuffledName = shuffledName.substring(0, 15);

        return shuffledName;
    }

    async registerUser() {
        var userLoginObject: newUserSignupObject;
        this.signUpLoader = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Please wait...',
        });
        this.signUpLoader.present();


        userLoginObject = {
            loginId: this.userEmail.toLowerCase(),
            email: this.userEmail.toLowerCase(),
            password: '',
            ehx: this.encryptionservice.encryption(this.userEmail.toLowerCase()),
            signupProcessCompleted: "true",
            userType: '',
            occupation: this.selectedOccupation,
            otherOccupationSpecialization: this.userDefinedOccupation,
            isUserNew: true,
            phoneNumber: '',
            countryCode: '',
            countryName: '',
            signupMode: this.signupMode,
            isEmailVerified: false,
            professional: false,
            verified: false,
            verifiedOn: '',
            isInfluncer: false,
            influncerStartDate: '',
            healthCareProfessionalStartDate: '',
            activated: false,
            friends: [],
            group_ids: [],
            page_ids: [],
            healthCareProfessional: true,
            first_name: this.userFirstName,
            middle_name: '',
            last_name: this.userLastName,
            full_name: this.userName,
            screen_name: this.generateScreenName(this.userFirstName, this.userLastName),
            photo: '',
            mobile_phone: '',
            gender: '',
            address: '',
            addressCoordinates: '',
            work_phone: '',
            specialization: this.selectedSpecialties,
            subSpeciality: '',
            userDescription: '',
            about: '',
            age: '',
            healthInterests: [],
            prescription: '',
            treatment: '',
            videoConsultActivated: false,
            loginAction: 'resendVerifySignup',
            admin: false,
            adminApproval: '',
            logLevel: 0,
            request: [],
            respondFriendRequest: [],
            implicitFollow: [],
            inviteduser: false,
            userPreferences: '',
            channels: [],
            appointmentOnlyUser: false,
            selectedRadioButton: 'browseHealable',
            blockedBy: [],
            blockedUsers: [],
            blockedComments: [],
            blockedPosts: [],
            userVideoStoryCreatedAt: '',
            userVideoStoryName: '',
            isHealthCareProviderApproved: 'false',
            referral_code: '',
            verificationDocuments: []

        }

        let type = "";



        this.userservice.registerNewUser(userLoginObject, '', type).subscribe(res => {
            console.log("🚀 ~ file: OauthComponent.ts:403 ~ oauthComponent ~ this.user.registerNewUser ~ res:", res)
            if (!res || res == null || res == undefined || res.error == true) {
                this.signUpLoader.dismiss();
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'oauthComponent', 'registerUser() -> registerNewUser', '', 'Error in response');
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'oauthComponent', 'registerUser() -> registerNewUser', '');
                this.signUpLoader.dismiss();

                this.navigateToDefaultRoute()
            }

        }, error => {
            console.log("🚀 ~ file: OauthComponent.ts:427 ~ oauthComponent ~ this.user.registerNewUser ~ error:", error)
            this.signUpLoader.dismiss();
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'oauthComponent', 'registerUser() -> registerNewUser', '', error);

        })


    }

    navigateToDefaultRoute() {
        this.currentURL = (this.storageService.getStorage('activeURL') === false) ? "" : this.storageService.getStorage('activeURL')
        console.log("🚀 ~ navigateToDefaultRoute ~ this.currentURL:", this.currentURL)
        if (this.navigating) return;
        this.navigating = true;
        this.postService.refreshList();
        if ((this.currentURL).includes("chat-window")) {//for AI-Chat Module
            console.log("this.router.navigate(['/tabs/tab2'])");
            this.aichatService.callrefreshAIChatPage("dorefresh")
            this.router.navigate(['/tabs/tab2']);
        } else {
            console.log("this.router.navigate([''])");

            this.router.navigate(['']);
        }
    }

    async loginHealable() {
        // set platform variable when user login
        sessionStorage.setItem('platform', this.authService.checkPlatform());

        const loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Please wait...',
        });
        loading.present();

        this.submitAttempt = false;
        this.invalidAttempt = false;
        let uLogObj: UserLogin;

        uLogObj = {
            strategy: 'emailAuth',
            loginId: `${this.userEmail}`,
            password: '',
            deviceRegistrationId: !this.platformType.is('desktop') ? "123" : "ABC123",
            platform: !this.platformType.is('desktop') ? true : false,
            platformType: sessionStorage.getItem('platform'),
            loginType: 'OAuth'
        };

        console.log("🚀 ~ file: login.page.ts:263 ~ LoginPage ~ login ~ uLogObj", uLogObj)
        this.authService.userSignIn(uLogObj).subscribe((res: any) => {
            this.userDetails = res;
            console.log("🚀 ~ file: login.page.ts:166 ~ LoginPage ~ this.authService.userSignIn ~ res", res)
            if (res) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'loginPage', 'login()', '');
                // First check account activation status
                // If true, check for AppName
                // Else naviagte to forgotPassword (OTP) page for activating account

                if (res.activated == true) {
                    loading.dismiss();
                    this.socketService.setupSocketListeners('login component')
                    // Check for appName
                    if (this.AppName == 'Albatross') {
                        // If admin accepted the request
                        if (res.adminApproval == 'true') {
                            this.userService.getUserInfoForGlobalUserObject();
                            if (this.storageService.getStorage('activeURL')) this.getActiveURLFromLocalStorageAndNavigateToIt();
                            else this.router.navigate([''])
                        }
                        //If admin approval is pending
                        else if (res.adminApproval == 'pending') {
                            this.router.navigate(['/adminapproval']);
                        }
                        // If admin approval is false or empty
                        else {
                            let obj = { userDetails: res.userDetails };
                            this.router.navigateByUrl('/signup-physician', { state: obj });
                        }
                    }
                    else {
                        if (res.userDetails.signupProcessCompleted == 'false') {

                            let obj = { userDetails: res.userDetails };
                            this.modalCtrl.dismiss();
                            this.router.navigateByUrl('/continue-signup-patient', { state: obj });
                        }
                        else {
                            console.log("🚀 ~ file: login.component.ts:209 ~ LoginComponent ~ this.authService.userSignIn ~ res.userDetails:", res.userDetails)
                            console.log("🚀 ~ file: login.component.ts:199 ~ LoginComponent ~ this.authService.userSignIn ~ this.storageService.getStorage('activeURL'):", this.storageService.getStorage('activeURL'))
                            this.userService.getUserDataForUserProfile(this.storageService.getStorage('currentUserId'))
                                .pipe(
                                    map((data: any) => data.resObject.data[0])
                                )
                                .subscribe(userInfo => {

                                    if (userInfo.Disable == true) {
                                        this.router.navigate(['/request-activate-account']);
                                        loading.dismiss();
                                        return;
                                    }

                                    console.log("🚀 ~ file: user.service.ts ~ line 778 ~ UserService ~ getUserInfoForGlobalUserObject ~ userInfo", userInfo)
                                    this.postService.refreshList();
                                    this.userAnalyticsService.trackPagesHandler('UserLoggedIn');

                                    if (this.storageService.getStorage('activeURL')) {
                                        this.getActiveURLFromLocalStorageAndNavigateToIt();
                                    }
                                    else this.router.navigate([''])
                                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUserInfoForGlobalUserObject()', '');
                                }, (err) => {
                                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserInfoForGlobalUserObject()', '', err)
                                }
                                )


                            // this.userService.getUserInfoForGlobalUserObject();

                        }

                        // check for signupProcessCompleted
                        // If true, - user had completed signup - navigate to home page
                        // If pending/false - user didnot complete signup - navigate to physician/patient signup page
                        // if (res.userDetails.signupProcessCompleted == 'true') {
                        //     this.userService.getUserInfoForGlobalUserObject();
                        //     this.router.navigate([''])
                        // }
                        // else {
                        //     console.log("inside else");

                        //     if (res.userDetails.healthCareProfessional == true) {
                        //         console.log("inside else healthCareProfessional");
                        //         let obj = { userDetails: res.userDetails };
                        //         this.router.navigateByUrl('/signup-physician', { state: obj });
                        //         console.log("🚀 ~ file: login.page.ts:316 ~ LoginPage ~ this.authService.userSignIn ~ obj", obj)
                        //     }
                        //     else {
                        //         console.log("inside else of else", res, res.userDetails);

                        //       //  let obj = { userDetails: res.userDetails };
                        //     //this.router.navigate(['/continue-signup-patient'], { state: obj })
                        //        // this.router.navigateByUrl('/continue-signup-patient', { state: obj });
                        //     }
                        // }
                    }

                }
                else {
                    loading.dismiss();
                    // let value = {
                    //     email: this.email,
                    //     password: this.loginForm.value['password'],
                    //     comingFrom: 'signup',
                    //     phoneNumber: `${this.loginForm.value.phoneNumber}`,
                    //     mode: this.signupMode,
                    //     countryCode: `${this.countryCode}`
                    // } // add phone number and email from user input
                    // this.openForgotPasswordModal(value);
                    // this.router.navigate(['/forgot-password'], { state: value })
                    // this.router.navigate(['/forgot-password',
                    //     value.comingFrom,
                    //     value.mode,
                    //     {
                    //         email: value.email,
                    //         phoneNumber: value.phoneNumber,
                    //         countryCode: value.countryCode,
                    //         password: value.password
                    //     }
                    // ])
                }
            }
            else {
                loading.dismiss();
                this.invalidAttempt = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'loginPage', 'login()', '', 'Error in response');
            }

        }, (errorObj => {
            //this.myConfig.err.INVALID_LOGIN
            // this.loginErrorMessage = errorObj.error.message;
            // if (errorObj && errorObj.error && errorObj.error.data && errorObj.error.data.timeRemainingInSeconds) {
            //     this.timeoutClock(
            //         errorObj.error.data,
            //         () => {
            //             this.loginDisabled = true;
            //         },
            //         () => {
            //             this.loginDisabled = false;
            //         }
            //     );
            // }
            loading.dismiss();
            this.invalidAttempt = true;
            this.submitAttempt = true;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'loginPage', 'login()', '', errorObj);
        }))


    }

    getActiveURLFromLocalStorageAndNavigateToIt() {
        if (this.storageService.getStorage('activeURL')) {
            let url = this.storageService.getStorage('activeURL').split('/')
            console.log("🚀 ~ file: login.component.ts:132 ~ LoginComponent ~ getActiveURLFromLocalStorage ~ url:", url)

            // this.userProfilePage.getUserData(url[2]);

            this.modalCtrl.dismiss().then(() => {
                // this.router.navigate(['/user-profile',  url[2]]);
                // setTimeout(() => {
                console.log(this.storageService.getStorage('activeURLType'))
                if (this.storageService.getStorage('activeURLType') == "postShared") {
                    this.postService.callrefreshSharedPOSTTrigger("");
                    this.userService.callrefreshSharedprofileurlINfoTrigger("");
                }
                if (this.storageService.getStorage('activeURLType') == "profileShared") {
                    this.userService.callrefreshSharedprofileurlINfoTrigger("");
                }
                if (this.storageService.getStorage('activeURLType') == "aichatmodule") {//for AI-Chat Module
                    this.aichatService.callrefreshAIChatPage("dorefresh")
                    this.router.navigate(['/tabs/tab2']);
                }

                // }, 500); 

            })

        }
    }
}
