import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EncryptionService } from '../encryption/encryption.service';
import { LoggingService } from '../logging/logging.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserAnalyticsService {
  baseServerUrl: any = environment.API_URL;
  constructor(public storageService : StorageService, public loggingService: LoggingService, public encryptionProvider: EncryptionService, public http : HttpClient) {
    this.loggingService.fetchGlobalConstants();
   }

  trackPagesHandler(pageName) {    
    let chooseAction = {
      UserId: this.storageService.getStorage('currentUserId'),
      EventDateTime: '', //Server date time
      IPAddress: '',
      EventType : 'PageVisited',
      EventName: pageName
    };        

     this.http
    .post(this.baseServerUrl + '/analytics', JSON.stringify(chooseAction)).subscribe((res : any)=>{
      this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userAnalyticsService', 'trackPagesHandler()', '');
    },(err)=>{
      this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'userAnalyticsService','trackPagesHandler()', '', err);
    })

	}
}

