import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Camera, CameraResultType } from '@capacitor/camera'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Platform } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { EncryptionService } from '../encryption/encryption.service';
import { StorageService } from '../storage/storage.service';
import { LoggingService } from '../logging/logging.service';
import { BehaviorSubject, Subject } from 'rxjs';


export interface chatListObject {
    userInfo: {},
    lastMesageInfo: {},
    roomId: string,
    unreadMessageCount: number,
    userStatus: string,
    isTyping: boolean
}

export interface chatMessage {
    senderId: string
    receiverId: string
    Deleted: boolean
    isRead: boolean
    createdAt: string
    message: string
    messageType: string  //'media' / 'text'
    chatType: string
    Media: any
    chatRoomId: string
    messageStatus: string
}

@Injectable({
    providedIn: 'root'
})



export class ChatService {
    baseServerUrl: any = environment.API_URL
    baseAIServerUrl: any = environment.AI_URL
    activeUsersChatList: any = []
    activeWindowMessages: any = []
    chatTabNotificationCountDisplay: number = 0
    activeChatUser: any = ''
    activeChatRoomId: any = ''
    atBottom: boolean = false;
    newMessageCount: any = 0;

    chatpdfDownloadurl: any;

    constructor(public http: HttpClient, public router: Router, public actionSheetController: ActionSheetController, public storageService: StorageService, public encryptionService: EncryptionService, public loggingService: LoggingService) {
        this.loggingService.fetchGlobalConstants();
    }


    /**
       * Fetch user profile Information
       */
    public getActiveChatList(id) {
        this.activeUsersChatList = []
        return this.http.get(this.baseServerUrl + '/chatusers?loggedInUserId=' + id).subscribe((res: any) => {
            if (res == undefined || res == null || res.data == undefined || res.data == null) {
                throw new Error()
            }
            //  if(res.data == []){
            //    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getActiveChatList()', 'user doest not have any chat list, no activity');
            //  }

            for (const item of res.data) {
                let chatListObj = {
                    userInfo: item.chat_list_receipient_info[0],
                    lastMesageInfo: item.lastMessageInfo[0],
                    roomId: item._id,
                    unreadMessageCount: item.unreadMessageCount.length > 0 ? item.unreadMessageCount[0] : 0,
                    userStatus: item.userStatus,
                    isTyping: item.typingStatus
                }
                this.activeUsersChatList.push(chatListObj)
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getActiveChatList()', 'active chat list-->' + this.activeUsersChatList);
            }
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getActiveChatList()', '', err)
        })

    }

    public emitChatPageActiveAsSocketEvent() {

    }

    public emitChatPageInactiveAsSocketEvent() {

    }

    public sortActiveChatListArray() {
        this.activeUsersChatList.sort((a, b) => {
            let da: any = new Date(a.lastMesageInfo.createdAt)
            let db: any = new Date(b.lastMesageInfo.createdAt);
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'sortActiveChatListArray()', '');
            return db - da;
        })
    }

    getMessagesForActiveWindow(roomId, messageSenderId) {
        return this.http.get(this.baseServerUrl + '/chatmessages?roomId=' + roomId + '&messageSenderId=' + messageSenderId).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getMessagesForActiveWindow()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getMessagesForActiveWindow()', '', err)
        }))
    }

    getMoreMessagesForActiveWindow(roomId, messageSenderId, dateOfFirstMessage) {
        return this.http.get(this.baseServerUrl + '/chatmessages?roomId=' + roomId + '&messageSenderId=' + messageSenderId).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getMessagesForActiveWindow()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getMessagesForActiveWindow()', '', err)
        }))
    }

    sendMessage(chatMessage) {
        return this.http.post(this.baseServerUrl + '/chatmessages', JSON.stringify(chatMessage)).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'sendMessage()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'sendMessage()', '', err)
        }))
    }

    async scrolling(event) {
        const scrollElement = await event.target.getScrollElement();
        console.log("🚀 ~ file: chat.service.ts:135 ~ ChatService ~ scrolling ~ scrollElement:", scrollElement)
        const scrollBottom = scrollElement.scrollTop + scrollElement.clientHeight;
        const scrollHeight = scrollElement.scrollHeight - 2;

        if (scrollBottom > scrollHeight) { this.atBottom = true; }
        else {
            this.atBottom = false;
            this.newMessageCount = 0;
        }
    }


    ///start this is used for triggering chat room window
    private getchatUser = new BehaviorSubject<any>(0);
    getchatUser_trigge$ = this.getchatUser.asObservable();

    callgetchatUserTrigger(data) {
        this.getchatUser.next(data);
    }
    ///end this is used for triggering chat room window

}
