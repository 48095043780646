import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
    selector: 'app-file-loader-window',
    templateUrl: './file-loader-window.component.html',
    styleUrls: ['./file-loader-window.component.scss'],
})
export class FileLoaderWindowComponent implements OnInit {
    mediaArray: any;


    constructor(public mediaService: MediaService) {
        console.log("🚀 ~ file: file-loader-window.component.ts ~ line 16 ~ FileLoaderWindowComponent ~ constructor ~ this.mediaService.uploadEditMediaArray", this.mediaService.uploadEditMediaArray)
    }

    ngOnInit() { }

}
