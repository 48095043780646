import { Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject, from, of } from 'rxjs';
import { catchError, finalize, map, tap, zip } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../encryption/encryption.service';
import { StorageService } from '../storage/storage.service';
import { LoggingService } from '../logging/logging.service';
import { AlertController, ModalController } from '@ionic/angular';
import { AboutPage } from 'src/app/modules/welcome/about/about.page';


export interface newUserSignupObject {

    loginId: string, // can be phone number or email based on user selection
    email: string,
    ehx: string,
    password: string,
    userType: '',
    professional: boolean,
    verified: boolean,
    activated: boolean,
    signupProcessCompleted: string,
    friends: [],
    group_ids: [],
    page_ids: [],
    healthCareProfessional: boolean,
    phoneNumber: string,
    countryCode: string,
    countryName: string,
    signupMode: string,
    isEmailVerified: boolean,
    verifiedOn: string,
    isInfluncer: boolean,
    influncerStartDate: string,
    healthCareProfessionalStartDate: string,
    isUserNew: boolean,
    occupation: string,
    otherOccupationSpecialization: string,

    // FIRST PAGE SIGNUP DETAILS COMMON FOR PATIENT AND PHYSICIAN
    first_name: string,
    middle_name: string,
    last_name: string,
    full_name: string,

    // IF PATIENT THEN LOOK FOR SCREEN NAME
    screen_name: string,
    userDescription: string,
    photo: string,
    mobile_phone: string,
    gender: string, // ADDED FOR BOTH PHYSICIAN AND PATIENTS
    address: string,
    addressCoordinates: string,
    // ONLY FOR PHYSICIAN
    work_phone: string,

    // END OF FIRST PAGE SIGNUP DETAILS

    // SECOND PAGE SIGNUP DETAILS

    //ONLY FOR PHYSICIAN
    specialization: string,
    subSpeciality: string,
    // about = userDescription
    about?: string,

    //ONLY FOR PATIENT

    age: string,
    healthInterests: [],
    prescription: string,
    treatment: string,
    videoConsultActivated: boolean,

    // END OF SECOND PAGE SIGNUP DETAILS

    // THIRD PAGE SIGNUP DETAILS
    // licenseCountry: "",
    // licenseState: "",
    // licenseNumber: "",
    // END OF THIRD PAGE SIGNUP DETAILS

    loginAction: string,
    adminApproval: string,
    admin: boolean,
    logLevel: number,
    request: [],
    respondFriendRequest: [],
    implicitFollow: [],
    inviteduser: boolean,
    userPreferences: string,
    channels: [],
    appointmentOnlyUser: boolean,
    selectedRadioButton: string

    // Block Arrays
    blockedBy: [],
    blockedUsers: [],
    blockedComments: [],
    blockedPosts: [],

    userVideoStoryName: string,
    userVideoStoryCreatedAt: string

    isHealthCareProviderApproved: string
    verificationDocuments: []
    referral_code: string
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseServerUrl: any = environment.API_URL;
    loaderValue: any;
    physicianProfileweightage: number = 10;
    patientProfileFieldweightage: number = 20;
    userSubject: BehaviorSubject<any> = new BehaviorSubject(null)
    userdata: any
    test: any
    currentLoggedInUser: any = ""
    userProfileData: any = ""
    userProfilePicture: any = "";
    welcomePageModal: HTMLIonModalElement;
    activateAccountRequested: any = false;
    globalLoader: Subject<any> = new Subject<any>();
    constructor(
        private http: HttpClient,
        public modalCtrl: ModalController,
        public encryptionService: EncryptionService,
        public storageService: StorageService,
        public loggingService: LoggingService,
        private alertController: AlertController
    ) {
        this.loggingService.fetchGlobalConstants();
        this.getLoggedInUser();
    }

    updateCurrentUser(user: any) {
        if (user == null || user == undefined) {
            return
        }
        else {
            let userResponseObject
            if (user?.length > 0 && user[0]._id != null && user[0]._id != undefined && (user[0]._id == this.storageService.getStorage('currentUserId'))) {
                userResponseObject = user[0]
            }
            else if (user.data != undefined && user.data?.length > 0 && (user.data[0]._id == this.storageService.getStorage('currentUserId'))) {
                userResponseObject = user.data[0]
            }
            else {
                return false
            }

            this.userProfileData = {
                ...this.userProfileData,
                ...userResponseObject
            }
            console.log("🚀 ~ file: user.service.ts:137 ~ UserService ~ updateCurrentUser ~ this.userProfileData:", this.userProfileData)
            this.currentLoggedInUser = {
                ...this.currentLoggedInUser,
                ...userResponseObject
            }

            let currentuserSubject = this.userSubject.getValue()
            let newUserSubject = {
                ...currentuserSubject,
                ...userResponseObject
            }

            this.userSubject.next(userResponseObject)

            console.log("current logged in in user service", this.currentLoggedInUser, this.userProfileData);


        }
    }
    // console.log("called next method", user);
    // if(user == null || user == undefined || user.data == null || user.data == undefined || user.data.length < 1){
    //     console.log("inside if of update current user");
    //     return false
    // }




    getUserObservable() {

        return this.userSubject.asObservable();
    }

    checkIfUserPhoneNumberExist(phoneNumber) {
        console.log("🚀 ~ file: user.service.ts:176 ~ UserService ~ checkIfUserPhoneNumberExist ~ phoneNumber:", phoneNumber)
        let responseObject = {
            userExist: false,
            error: false,
            errorMessage: "",
            resObject: {}
        }
        return this.http.get(this.baseServerUrl + '/userdetails?phoneNumber=' + phoneNumber).pipe(map((res: any) => {
            console.log("🚀 ~ file: user.service.ts:115 ~ UserService ~ returnthis.http.get ~ res", phoneNumber, res)
            //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
            if (!res || res == undefined || res == null || !res.data || res.data == undefined) {
                responseObject.error = true
                responseObject.errorMessage = "no or bad response from the backend. "
            }
            responseObject.resObject = res.data
            if (res.data?.length > 0) {
                if (res.data.filter(e => e.phoneNumber == phoneNumber).length > 0) {
                    responseObject.userExist = true
                }
                else {
                    responseObject.userExist = false
                }
            }
            else {
                responseObject.userExist = false
            }
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkIfUserExist()', '');
            return responseObject
        }
        ), catchError((err) => {
            responseObject.error = true;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkIfUserExist()', '', err)
            throw responseObject;
        }))
    }



    checkIfUserEmailExist(userEmailId) {
        let responseObject = {
            userExist: false,
            error: false,
            errorMessage: "",
            resObject: {}
        }
        return this.http.get(this.baseServerUrl + '/userdetails?email=' + userEmailId).pipe(map((res: any) => {
            //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
            if (!res || res == undefined || res == null || !res.data || res.data == undefined) {
                responseObject.error = true
                responseObject.errorMessage = "no or bad response from the backend. "
            }
            responseObject.resObject = res.data
            if (res.data?.length > 0) {
                if (res.data.filter(e => e.email == userEmailId).length > 0) {
                    responseObject.userExist = true
                }
                else {
                    responseObject.userExist = false
                }
            }
            else {
                responseObject.userExist = false
            }
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkIfUserExist()', '');
            return responseObject
        }
        ), catchError((err) => {
            responseObject.error = true;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkIfUserExist()', '', err)
            throw responseObject;
        }))
    }


    registerNewUser(newUserObject: newUserSignupObject, invitationCode, type) {
        console.log("🚀 ~ file: user.service.ts:250 ~ UserService ~ registerNewUser ~ newUserObject:", newUserObject)
        let responseObject = {
            isUserRegistered: false,
            error: false,
            errorMessage: "",
            resObject: {}
        }

        return this.http.post(this.baseServerUrl + '/users' + `?invitationCode=${invitationCode}` + `&type=${type}`, newUserObject,).pipe(map((res: any) => {
            // return this.http.post(this.baseServerUrl + '/users', newUserObject, invitationCode).pipe(map((res: any) => {
            console.log("🚀 ~ file: user.service.ts:257 ~ UserService ~ returnthis.http.post ~ res:", res)
            //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
            if (!res || res == undefined || res == null) {
                responseObject.error = true
                responseObject.errorMessage = "no or bad response from the backend."
            }
            responseObject.isUserRegistered = true
            responseObject.resObject = res;
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'registerNewUser()', '');
            return responseObject
        }), catchError((err) => {
            console.log("🚀 ~ file: user.service.ts:269 ~ UserService ~ returnthis.http.post ~ err:", err)
            responseObject.error = true;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'registerNewUser()', '', err)
            throw responseObject;
        })
        )
    }


    public getLoggedInUser() {
        return this.http.get(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId')).pipe((tap((response: any) => {

            this.updateCurrentUser(response)

            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getLoggedInUser()', '');
        }),
            catchError(err => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getLoggedInUser()', '', err)
            })
        ));
    }


    public getUserInfo(uid) {
        return this.http.get(this.baseServerUrl + '/users?_id=' + uid).pipe(
            tap((response: any) => {

                this.updateCurrentUser(response)

            }),
            catchError(err => {
                of('this is error: ' + err);
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserInfo()', '', err)
            })
        );
    }

    /**
     * Add Physician location
     * @param location : User selected location
     * @returns location response
     */
    addPhysicianLocation(location) {
        return this.http.post(this.baseServerUrl + '/physicianlocation', JSON.stringify(location)).pipe(
            tap((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addPhysicianLocation()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addPhysicianLocation()', '', err)

            }))
    }

    addCertifications(details) {
        return this.http.post(this.baseServerUrl + '/certifications', JSON.stringify(details)).pipe(
            tap((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addCertifications()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addCertifications()', '', err)

            }))
    }

    updateCertifications(details, id) {
        return this.http
            .patch(this.baseServerUrl + '/certifications/' + id, JSON.stringify(details)).pipe(
                tap((response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updateCertifications()', '');
                    return response;
                }), catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updateCertifications()', '', err)

                }))
    }

    updateAwards(details, id) {
        return this.http
            .patch(this.baseServerUrl + '/awards/' + id, JSON.stringify(details)).pipe(((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updateAwards()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updateAwards()', '', err)

            }))
    }

    updatePublications(details, id) {
        return this.http
            .patch(this.baseServerUrl + '/publications/' + id, JSON.stringify(details)).pipe(tap((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updatePublications()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updatePublications()', '', err)

            }))
    }

    updateEducations(educationDetails, details) {
        return this.http
            .patch(this.baseServerUrl + '/education/' + educationDetails, JSON.stringify(details)).pipe(((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updateEducations()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updateEducations()', '', err)

            }))
    }

    updateWorks(workDetails, details) {
        return this.http
            .patch(this.baseServerUrl + '/work/' + workDetails, JSON.stringify(details)).pipe(((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updateWorks()', '');
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updateWorks()', '', err)

            }))
    }

    /**
     * Delete Physician location
     */
    removePhysicianLocation(id) {
        return this.http.delete(this.baseServerUrl + '/physicianlocation/' + id).pipe(((response: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'removePhysicanLocation()', '');
            return response;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'removePhysicanLocation()', '', err)

        })
        );

    }

    /**
     * When Physician updates his location
     * @returns : update response
     */
    updatePhysicianLocation(id, location) {
        return this.http
            .patch(this.baseServerUrl + '/physicianlocation/' + id, JSON.stringify(location)).pipe(((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updatePhysicianLocation()', '');
                return response.json();
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updatePhysicianLocation()', '', err)

            })
            );

    }


    /**
     * Get physician specialities
     * @returns response
     */
    getSpecialities() {
        return this.http.get(this.baseServerUrl + '/specializationlist?$sort[item]=1').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getSpecialities()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getSpecialities()', '', err)

        })))

    }


    /**
     * Get nurse specialities
     * @returns response
     */
    getNurseSpecialities() {
        return this.http.get(this.baseServerUrl + '/nurseSpecializationlist?$sort[item]=1').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getNurseSpecialities()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getNurseSpecialities()', '', err)
        })))
    }

    /**
     * Get nurse specialities
     * @returns response
     */
    getPsychologistSpecialities() {
        return this.http.get(this.baseServerUrl + '/psychologistSpecializationlist?$sort[item]=1').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getPsychologistSpecialities()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getPsychologistSpecialities()', '', err)
        })))
    }

    /**
     * Get nurse specialities
     * @returns response
     */
    getTherapistSpecialities() {
        return this.http.get(this.baseServerUrl + '/therapistSpecializationlist?$sort[item]=1').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getTherapistSpecialities()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getTherapistSpecialities()', '', err)
        })))
    }

    /**
     * Get nurse specialities
     * @returns response
     */
    getDentistSpecialities() {
        return this.http.get(this.baseServerUrl + '/dentistSpecializationlist?$sort[item]=1').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getDentistSpecialities()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getDentistSpecialities()', '', err)
        })))
    }

    /**
     * Add user details
     * @returns userDetials response
     */
    public addToProfile(details, type?: String) {
        console.log("inside add to profile");
        return this.http
            // .patch(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId') + `&invitationCode=${invitationCode}`, JSON.stringify(details)).pipe(
            // .patch(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId') + `&type=${type}`, JSON.stringify(details)).pipe(
            .patch(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId'), JSON.stringify(details)).pipe(
                map(
                    (response: any) => {
                        console.log("inside add to profile response before update current user", response);
                        if (response) {
                            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToProfile()', '');
                            return response;
                        }
                        else {
                            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToProfile()', '',)
                            return from([false]);
                        }
                    }, catchError((err) => {
                        throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToProfile()', '', err)

                    })
                ),
                (tap((response: any) => {

                    this.updateCurrentUser(response)
                    //     if(response.data[0]._id ==  this.storageService.getStorage('currentUserId')){
                    //         console.log("inside add to profile tap");   

                    //    }

                })))
    }

    /**
     * created new update function for only user guide variable update
     */
    public addToProfileUserGuideUpdate(details) {
        return this.http
            // .patch(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId') + `&invitationCode=${invitationCode}`, JSON.stringify(details)).pipe(
            .patch(this.baseServerUrl + '/users?_id=' + this.storageService.getStorage('currentUserId'), JSON.stringify(details)).pipe(
                map(
                    (response: any) => {
                        console.log("inside add to profile response before update current user", response);
                        if (response) {
                            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToProfile()', '');
                            return response;
                        }
                        else {
                            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToProfile()', '',)
                            return from([false]);
                        }
                    }, catchError((err) => {
                        throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToProfile()', '', err)

                    })
                ),
                (tap((response: any) => {
                    this.userProfileData.appUserGuideShow = "done";

                })))
    }

    public addToEducation(details) {
        return this.http
            .post(this.baseServerUrl + '/education', JSON.stringify(details)).pipe(map(
                (response: any) => {
                    if (response) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToEducation()', '');
                        return response;
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToEducation()', '',)
                        return from([false]);
                    }
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToEducation()', '', err)

                })
            ));
    }

    public addToAwards(details) {
        return this.http
            .post(this.baseServerUrl + '/awards', JSON.stringify(details)).pipe(map(
                (response: any) => {
                    if (response) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToAwards()', '');

                        return response;
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToAwards()', '',)

                        return from([false]);
                    }
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToAwards()', '', err)

                })
            ));
    }

    public addToPublications(details) {
        return this.http
            .post(this.baseServerUrl + '/publications', JSON.stringify(details)).pipe(map(
                (response: any) => {
                    if (response) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToPublications()', '');

                        return response;
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToPublications()', '',)

                        return from([false]);
                    }
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToPublications()', '', err)

                })
            ));
    }

    public addToWork(details) {
        return this.http
            .post(this.baseServerUrl + '/work', JSON.stringify(details)).pipe(map(
                (response: any) => {
                    if (response) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addToWork()', '');

                        return response;
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToWork()', '',)

                        return from([false]);
                    }
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addToWork()', '', err)

                })
            ));
    }

    public updateUserRecord(userId, dataToBeUpdated) {
        return this.http.patch(this.baseServerUrl + '/users?_id=' + userId, JSON.stringify(dataToBeUpdated)).pipe((tap((res: any) => {
            this.updateCurrentUser(res)

        }))
            , (map((res: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'updateUserRecord()', '');
                if (res == null || res == undefined || res.data == null || res.data == undefined || res.data?.length < 1) {
                    throw new Error("res undefied");
                }

                return res;
            })),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'updateUserRecord()', '', err)

            })
        )
    }

    /**
     * Get system languages
     * @returns languages array
     */
    public getSystemLanguages() {
        return this.http.get(this.baseServerUrl + '/system-languages').pipe(map((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getSystemLanguages()', '');

            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getSystemLanguages()', '', err)

        })))

    }

    /**
     * Get Patient Health Interests
     */
    public gethealthInterests() {
        return this.http
            .get(this.baseServerUrl + '/health-interests?$sort[item]=1')
            .pipe(map((res: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getHealthInterests()', '');

                return res;
            }, catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getHealthInterests()', '', err)

            })))
    }

    /**
 * Get Patient Health Interests
 */
    public getCommunities() {
        return this.http
            .get(this.baseServerUrl + '/community-list?$sort[item]=1')
            .pipe(map((res: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getCommunities()', '');

                return res;
            }, catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getCommunities()', '', err)

            })))
    }

    public checkIfReferralCodeValid(code) {
        return this.http
            .get(this.baseServerUrl + '/public?content=referral-code' + '&referral_code=' + code)
            .pipe(map(
                (response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkIfReferralCodeValid()', '');

                    return response;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkIfReferralCodeValid()', '', err)

                })
            ));
    }

    public checkDuplicateScreenname(screenname) {
        return this.http
            .get(this.baseServerUrl + '/public?content=screen-name' + '&screen_name=' + screenname)
            .pipe(map(
                (response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkDuplicateScreenname()', '');

                    return response;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkDuplicateScreenname()', '', err)

                })
            ));
    }

    // public checkDuplicateScreenname(screenname) {
    //     return this.http
    //         .get(this.baseServerUrl + '/signup?screen_name=' + screenname)
    //         .pipe(map(
    //             (response: any) => {
    //                 this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkDuplicateScreenname()', '');

    //                 return response;
    //             }, catchError((err) => {
    //                 throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkDuplicateScreenname()', '', err)

    //             })
    //         ));
    // }

    public checkReferralCode(code) {
        return this.http
            .get(this.baseServerUrl + '/signup?referral_code=' + code)
            .pipe(map(
                (response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkReferralCode()', '');

                    return response;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkReferralCode()', '', err)

                })
            ));
    }

    public getBlockedUsersList(currentUserId) {
        return this.http
            .get(this.baseServerUrl + '/block?uid=' + currentUserId)
            .pipe(map(
                (response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkDuplicateScreenname()', '');

                    return response;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'checkDuplicateScreenname()', '', err)

                })
            ));
    }



    // new forgot password service
    public forgotPasswordEmail(Action) {
        let forgotPasswordResponse = {
            error: false,
            errorMessage: "",
            resObject: {}
        }
        return this.http
            .post(this.baseServerUrl + '/forgotpassword', JSON.stringify(Action))
            .pipe(map((res: any) => {
                if (!res || res == undefined || res == null) {
                    forgotPasswordResponse.error = true
                    forgotPasswordResponse.errorMessage = "No response"
                }
                else {
                    forgotPasswordResponse.resObject = res;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'forgotPasswordEmail()', '');

                return forgotPasswordResponse;
            }, catchError((err) => {
                forgotPasswordResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'forgotPasswordEmail()', '', err)
                throw forgotPasswordResponse;
            })));
    }

    /**
     * Verifying code in ForgotPassword
     * @param email - user email
     * @param verifyObject - includes otp, loginAction and purpose
     * @returns 
     */
    public verifyCode(email, verifyObject) {

        return this.http
            .get(this.baseServerUrl + '/forgotpassword?email=' + email + '&typedInOtp=' + verifyObject.typedInOtp + '&loginAction=' + verifyObject.loginAction + '&purpose=' + verifyObject.purposeOfGettingOTP +
                '&invitedUser=' + verifyObject.invitedUser + '&inviteeUserId=' + verifyObject.inviteeUserId + '&rewardCode=' + verifyObject.rewardCode + `&loginId=${verifyObject.loginId}` + `&signupMode=${verifyObject.signupMode}`)
            .pipe(map(
                (res: any) => {
                    if (!res || res == undefined || res == null) {
                        return false
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'verifyCode()', '');

                    return res
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'verifyCode()', '', err)

                })
            ));
    }


    /**
 * Fetch user profile Information
 */
    public getUserDataForPublicUserProfile(id) {
        let getUserProfileInformationResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(this.baseServerUrl + '/public?content=user-profile' + '&action=getUserProfileInformation' + '&_id=' + id + '&myID=' + this.storageService.getStorage('currentUserId'))
            //   .get(this.baseServerUrl + '/users?action=getUserProfileInformation&_id=' + id + '&myID=' + this.storageService.getStorage('currentUserId'))
            .pipe(tap((response: any) => {

                this.updateCurrentUser(response)

            }),
                map(
                    (response: any) => {

                        if (!response || response == undefined || response == null) {
                            getUserProfileInformationResponse.error = true;
                        }
                        else {

                            getUserProfileInformationResponse.error = false;
                            getUserProfileInformationResponse.resObject = response;
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUserDataForUserProfile()', '');

                        return getUserProfileInformationResponse;
                    }
                    // 	,
                    // 	(error) => {
                    // 		getUserProfileInformationResponse.error = true;
                    // 		return getUserProfileInformationResponse;

                    // 	}
                )
                , catchError((err: any) => {
                    getUserProfileInformationResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserDataForUserProfile()', '', err);
                    throw getUserProfileInformationResponse;
                })
            )
            ;
    }


    /**
     * Fetch user profile Information
     */
    public getUserDataForUserProfile(id) {
        let getUserProfileInformationResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(this.baseServerUrl + '/public?content=user-profile' + '&action=getUserProfileInformation' + '&_id=' + id + '&myID=' + this.storageService.getStorage('currentUserId'))
            // .get(this.baseServerUrl + '/users?action=getUserProfileInformation&_id=' + id + '&myID=' + this.storageService.getStorage('currentUserId'))
            .pipe(tap((response: any) => {

                this.updateCurrentUser(response)

            }),
                map(
                    (response: any) => {

                        if (!response || response == undefined || response == null) {
                            getUserProfileInformationResponse.error = true;
                        }
                        else {

                            getUserProfileInformationResponse.error = false;
                            getUserProfileInformationResponse.resObject = response;
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUserDataForUserProfile()', '');

                        return getUserProfileInformationResponse;
                    }
                    // 	,
                    // 	(error) => {
                    // 		getUserProfileInformationResponse.error = true;
                    // 		return getUserProfileInformationResponse;

                    // 	}
                )
                , catchError((err: any) => {
                    getUserProfileInformationResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserDataForUserProfile()', '', err);
                    throw getUserProfileInformationResponse;
                })
            )
            ;
    }

    /**
     * Fetch user profile Information
     */
    public getUserGroupsAndConnections(id) {
        let getUserGroupsAndConnections = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(this.baseServerUrl + '/get-user-info/?_id=' + id)
            .pipe(map(
                (response: any) => {
                    if (!response || response == undefined || response == null) {
                        getUserGroupsAndConnections.error = true;
                    }
                    else {
                        getUserGroupsAndConnections.error = false;
                        getUserGroupsAndConnections.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUserGroupsAndConnections()', '');

                    return getUserGroupsAndConnections;
                }
                // ,
                // (error) => {
                // 	getUserGroupsAndConnections.error = true;
                // 	return getUserGroupsAndConnections;
                // }
                , catchError((err) => {
                    getUserGroupsAndConnections.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserGroupsAndConnections()', '', err)
                    throw getUserGroupsAndConnections;
                })
            ));
    }

    public checkProfileCompletedPercentage(user, onPage) {
        if (user != undefined) {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'checkProfileCompletedPercentage()', '');
            if (user.resObject.data[0].healthCareProfessional == true) {
                this.loaderValue = 0;
                this.loaderValue = 2 * this.physicianProfileweightage;
                let physicianFieldsArray = [
                    user.resObject.data[0].work_phone,
                    user.resObject.data[0].gender,
                    user.resObject.data[0].physicianlocation,
                    user.resObject.data[0].photo,
                    user.resObject.data[0].languages,
                    user.resObject.data[0].userDescription,
                    user.resObject.data[0].education,
                    user.resObject.data[0].work
                ];

                for (const element of physicianFieldsArray) {
                    if (element == undefined || element == null || element == "") {
                    } else {
                        this.loaderValue += this.physicianProfileweightage;
                    }
                }
            } else {
                this.loaderValue = 0;
                this.loaderValue = 3 * this.patientProfileFieldweightage;
                let patientFieldsArray = [
                    user.resObject.data[0].photo,
                    user.resObject.data[0].userDescription,
                ];

                for (const element of patientFieldsArray) {
                    if (element == undefined || element == null || element == "") {
                    } else {
                        this.loaderValue += this.patientProfileFieldweightage;
                    }
                }

            }
            this.assignPercentageValueToLoader(this.loaderValue, user, onPage)
        }

        return this.loaderValue;
    }

    public assignPercentageValueToLoader(caseValue, userInfo, onPage) {

        setTimeout(() => {
            let loader = <SVGElement>document.querySelector('.defaultLoader')
            let phoneLoader = <HTMLElement>document.querySelector('.loader-bar')
            let info = document.getElementsByClassName("infoBtn");
            if (loader != (undefined || null)) {
                loader.style.strokeDashoffset = `${(314 - (314 * caseValue) / 100)}`;
            }
            if (phoneLoader != (undefined || null)) {
                phoneLoader.style.setProperty("--loader-width", `${caseValue}%`);
            }
            if (info[0] != undefined && onPage == 'home') {
                switch (caseValue) {
                    case 80:
                        if (userInfo.resObject.data[0].photo != (null || "")) {
                            info[0].classList.remove('infoBtn-both', 'infoBtn-profile')
                            info[0].classList.add('infoBtn-description')
                        }
                        if (userInfo.resObject.data[0].userDescription != null && userInfo.resObject.data[0].userDescription != "") {
                            info[0].classList.remove('infoBtn-both', 'infoBtn-description')
                            info[0].classList.add('infoBtn-profile')
                        }
                        break;
                    case 60:
                        info[0].classList.remove('infoBtn-profile', 'infoBtn-description')
                        info[0].classList.add('infoBtn-both')
                        break;
                }
            }
        }, 500);
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'assignPercentageValueToLoader()', '');
    }

    public patchUserPassword(uid, details) {
        return this.http
            .patch(this.baseServerUrl + '/users?_id=' + uid, JSON.stringify(details))
            .pipe(tap(res => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'patchUserPassword()', '');
                return res;
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'patchUserPassword()', '', err)

                })
            );
    }

    public blockUser(details) {
        console.log(" show block user details here", details);

        return this.http
            .post(this.baseServerUrl + '/block', JSON.stringify(details))
            .pipe(tap(res => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'blockUser()', '');
                return res;
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'blockUser()', '', err)

                })
            );
    }

    public unblockUser(details) {
        console.log(" show block user details here", details);

        return this.http
            .post(this.baseServerUrl + '/block', JSON.stringify(details))
            .pipe(tap(res => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'unblockUser()', '');
                return res;
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'unblockUser()', '', err)

                })
            );
    }

    // when id sent in patch request need to be converted to ObjectId
    public joinUserToGroup(details) {
        return this.http
            .patch(this.baseServerUrl + '/signup?_id=' + this.storageService.getStorage('currentUserId'), JSON.stringify(details))
            .pipe(tap(res => {
                this.updateCurrentUser(res)
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'joinUserToGroup()', '');
                return res;
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'joinUserToGroup()', '', err)

                })
            );
    }

    // when id sent in patch request need to be converted to ObjectId
    public joinUserToGroupViaLink(details, id) {
        return this.http
            .patch(this.baseServerUrl + '/signup?_id=' + id, JSON.stringify(details))
            .pipe(tap(res => {
                this.updateCurrentUser(res)
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'joinUserToGroup()', '');
                return res;
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'joinUserToGroup()', '', err)

                })
            );
    }

    public getUsersForShowUsersPage(extension) {

        return this.http.get(this.baseServerUrl + '/signup' + extension).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUsersForShowUsersPage()', '');

            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUsersForShowUsersPage()', '', err)

            })
        );

    }

    public addLanguages(details) {
        return this.http.post(this.baseServerUrl + '/languages', JSON.stringify(details)).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'addLanguages()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'addLanguages()', '', err)

            }))
    }

    public removeUserData(extension) {
        return this.http.delete(this.baseServerUrl + extension).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'removeUserData()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'removeUserData()', '', err)

            })
        )
    }

    public changePassword(details) {
        return this.http
            .patch(this.baseServerUrl + '/signup?_id=' + this.storageService.getStorage('currentUserId'), JSON.stringify(details))
            .pipe((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'changePassword()', '');
                return response;
            }, catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'changePassword()', '', err)

            })
            )
    }


    promotionLink(userAction) {
        return this.http
            .get(this.baseServerUrl + '/promo?userAction=' + userAction + '&userId=' + this.storageService.getStorage('currentUserId'))
            .pipe(map(
                (response: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'promotionLink()', '');
                    return response;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'promotionLink()', '', err)

                })
            ));
    }


    getUserInfoForGlobalUserObject() {
        console.log("getUserInfoForGlobalUserObject called");

        this.getUserDataForUserProfile(this.storageService.getStorage('currentUserId'))
            .pipe(
                map((data: any) => data.resObject.data[0])
            )
            .subscribe(userInfo => {
                console.log("🚀 ~ file: user.service.ts ~ line 778 ~ UserService ~ getUserInfoForGlobalUserObject ~ userInfo", userInfo)
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getUserInfoForGlobalUserObject()', '');
            }, catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getUserInfoForGlobalUserObject()', '', err)
            })
            )
    }

    public showPendingUserstoAdmin() {
        return this.http
            .get(this.baseServerUrl + '/users?adminApproval=pending')
            .pipe(
                map((res: any) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'showPendingUserstoAdmin()', '');
                    return res;
                }, catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'showPendingUserstoAdmin()', '', err)
                }))
            )
    }

    public approveUserfromAdmin(details) {
        return this.http
            .patch(this.baseServerUrl + '/signup?_id=' + details._id, JSON.stringify(details))
            .pipe((response: any) => {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'approveUserfromAdmin()', '');
                return response;
            }, catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'approveUserfromAdmin()', '', err)
            }))
    }

    ///start this is used for triggering refresh shared profile inof page
    private refreshSharedprofileurlINfo = new Subject<any>();
    refreshSharedprofileurlINfo_trigge$ = this.refreshSharedprofileurlINfo.asObservable();

    callrefreshSharedprofileurlINfoTrigger(data) {
        this.refreshSharedprofileurlINfo.next(data);
    }
    ///end this is used for triggering refresh shared profile inof page


    async showInvitationCodeInfo() {
        const alert = await this.alertController.create({
            header: '',
            subHeader: 'Invitation Code',
            message: 'The invitation code enables users to attain Healthcare Professional (HCP) status directly, bypassing the standard verification process. Accessible within the settings tab under \'Invite Healthcare Provider,\' this code is generated uniquely for each profile. Users can effortlessly share it with colleagues to facilitate a streamlined registration process.',
            cssClass: "inviteCodeMsg",
            mode: 'ios',
            buttons: [
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        console.log('Alert confirmed');
                    },
                },
            ],
        });

        await alert.present();
    }


    maskEmail(email) {
        // mask email id in xxxxxxx and last 3 digit with domain
        var maskEmail;
        const [name, domain] = email.split('@');
        const maskedName = '********' + name.slice(-3);
        const maskedEmail = maskedName + '@' + domain;
        return maskedEmail;

    }

    async openAboutPrivacyInfo(comingFrom, pageFlag) {
        const SignUpModal = await this.modalCtrl.create({
            component: AboutPage,
            backdropDismiss: false,
            cssClass: 'about-policy-modal',
            componentProps: {
                comingFromPageName: comingFrom,
                pageFlag: pageFlag
            }
        });
        SignUpModal.present();

        const { data, role } = await SignUpModal.onWillDismiss();

    }


    /**
 * Get Deactivate Reasons
 * @returns response
 */
    getDeactivateReasons() {
        return this.http.get(this.baseServerUrl + '/deactivateAccountReasons').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getDeactivateReasons()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getDeactivateReasons()', '', err)
        })))
    }

    /**
 * Get Reactivate Reasons
 * @returns response
 */
    getReactivateReasons() {
        return this.http.get(this.baseServerUrl + '/reactivateAccountReasons').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getReactivateReasons()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getReactivateReasons()', '', err)
        })))
    }

    /**
* Get Delete Reasons
* @returns response
*/
    getDeleteReasons() {
        return this.http.get(this.baseServerUrl + '/deleteAccountReasons').pipe(map((res) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userService', 'getDeleteReasons()', '');
            return res;
        }, catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'getDeleteReasons()', '', err)
        })))
    }

    /**
     * @param details : User security setting details
     * @returns response
     */
    sendUserSecuritySettingRequest(details) {
        // if (this.activateAccountRequested) return;

        this.activateAccountRequested = true;
        return this.http.post(this.baseServerUrl + '/userSecuritySettingRequests', JSON.stringify(details)).pipe(
            tap((response: any) => {
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'sendUserSecuritySettingRequest()', '', err)

            }))
    }

    /**
     * @param details : User security setting details
     * @returns response
     */
    getUserSecuritySettingDetails() {
        return this.http.get(this.baseServerUrl + `/userSecuritySettingRequests?uid=${this.storageService.getStorage('currentUserId')}`).pipe(
            tap((response: any) => {
                return response;
            }), catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userService', 'sendUserSecuritySettingRequest()', '', err)
            }))
    }

}



