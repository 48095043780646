export const globalConstants = {
    "log_error": 0,
    "log_info": 1,
    "log_debug": 2,
    "file_size": 10000000,
    "duration": 3000,
    "logOut_time": 259200000,
    "img_size": 31457280,
    "pdf_size": 104857600,
    "video_size": 1048576000,
    "mediaFileLimit": 25,
    "patientMinimumAgeLimit": 14,
    "searchSkipValue": 20,
    "searchLimitValue": 20,
    "connectionsSkipValue": 20,
    "connectionsLimitValue": 20,
    "groupsSkipValue": 20,
    "groupsLimitValue": 20
}