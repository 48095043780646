import { Injectable } from '@angular/core';
import { EncryptionService } from '../encryption/encryption.service';
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    AWSSecretKey: string;
    CurrentUser: string;
    userEmail: string;
    CurrentLoglevel: string;
    refresherVariableForPhysicianSchedulePage: boolean;
    setupListenerOnce: boolean;
    loggingConstants: any = [];
    localStorageObj: any = {
        'CurrentUser': '',
        'userEmail': '',
        'currentUserId': '',
        'CurrentLoglevel': '',
        'notificationData': '',
        'session': '',
        'chatPageActive': '',
        'userObject': '',
        'resendCodeCount': '',
        'loginAttemptCount': '',
        'loginId': '',
        'signupProcessCompleted': '',
        'activeURL': '',
        'activeURLType': ''
    }
    constructor(public encryptionProvider: EncryptionService) { }

    /**
     * Set storage with hashed key and encrypted value
     * @param key : set storage 
     * @param value : value of respective key
     * @returns 
     */
    public setStorage(key, value) {

        if (this.localStorageObj.hasOwnProperty(key)) {
            let hashedKey = this.encryptionProvider.hashing(key);
            let encryptedValue = this.encryptionProvider.encryption(value);
            localStorage.setItem(hashedKey, encryptedValue);
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Get from storage using hashed key. 
     * @param key : get from storage using this key. 
     */
    public getStorage(key) {

        let hashedKey = this.encryptionProvider.hashing(key);
        let response = localStorage.getItem(hashedKey)

        if (response != null || response != undefined) {
            let decryptedValue;
            decryptedValue = this.encryptionProvider.decryption(response);
            return decryptedValue;
        }
        else {
            return false;
        }


    }


    /**
* Method : Promisified getStorage
* Description :  Get from storage using hashed key. 
* This method is identical to another method 'getStorage' 
* Any changes made to this method should be applied to other method also
* @param {any} indicates error occured
* @return {Observable<error>}
*/
    async promisifiedGetStorage(key) {
        let hashedKey = this.encryptionProvider.hashing(key);
        return await new Promise((resolve, reject) => {
            localStorage.get(hashedKey).then(
                (response) => {
                    if (response) {
                        let decryptedValue;
                        decryptedValue = this.encryptionProvider.decryption(response);
                        resolve(decryptedValue);
                    }
                    else {
                        resolve(false)
                    }

                }, (error) => {
                    resolve(false);
                })
        })
    }

}
