import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Raven from 'raven-js'
import { StorageService } from '../storage/storage.service';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { globalConstants } from 'src/assets/config/global-constants';
@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    baseServerUrl: any = environment.API_URL
    loggingConstraints: any = globalConstants;
    loglevel: any;
    username: any;
    email: any;
    id: any;
    systemLogLevelResponse: any;
    loginId: any;

    constructor(
        private http: HttpClient,
        public storageService: StorageService,
        public toastCtrl: ToastController
    ) {

    }


    /**
     * Serves as a fallback for logLevel. 
     * when the user is not logged in, in that case user's loglevel is not available. 
     * This method is called to get the default loglevel from systemoptions
     *  table in order to log messages to sentry
     * @returns system's default log level from backend
     */
    fetchSystemLogLevel() {
        return this.http.get(this.baseServerUrl + '/systemOptions').pipe(map((res: any) => {
            return res.json()
        }))
    }

    fetchGlobalConstants() {
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     this.loggingConstraints = res;
        // });
    }


    /** HU-46 Sarthak Bhatt 19th August 2021
     * Logs the message and type of message to sentry.
     * Gets called by its wrapper method logMessage()
     * Uses Raven to connect with sentry
     * @param MessageLevel : receives from logMessage() method : 0 or 1 or 2
     * @param methodDescription : receives from logMessage() method : string
     * @param message : receives from logMessage() method : string
     */

    logToSentry(MessageLevel: number, pageName: string, methodName: string, message: string) {

        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        // // 	//   res = res.json()
        // 	this.loggingConstraints = res;		
        if (this.loglevel == this.loggingConstraints.log_error) {
            switch (MessageLevel) {
                case 0:
                    Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'error' });
                    break;
            }
        } else if (this.loglevel == this.loggingConstraints.log_info) {
            // If user logLevel is 1, then log only errors and info messages to sentry
            switch (MessageLevel) {
                case 0:
                    Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'error' });
                    break;

                // case 1:
                //     Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'info' });
                //     break;
            }
        } else {
            // If user logLevel is 2, then log only errors, info and debug messages to sentry
            switch (MessageLevel) {
                case 0:
                    Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'error' });
                    break;

                // case 1:
                //     Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'info' });
                //     break;

                // case 2:
                //     Raven.captureMessage('PageName->' + pageName + '--methodName->' + methodName + '-> Message =' + JSON.stringify(message), { level: 'debug' });
                //     break;
            }
        }
        // })
    }



    /** HU-46 Sarthak Bhatt 19th Aug 2021 
     * @description : Logs message to sentry through raven. Takes four parameters.
     * Call this in all the providers and local-page level methods for error and debug logging.
     * Serves as a wrapper method of "logToSentry(param1, param2, param3, param4)"
     * @param MessageLevel : import global-constants => "log_error": 0,"log_info": 1,"log_debug": 2,
     * @param pageNameString :String = “Page Name : createCaseOrPostPage”
     * @param methodNameString :String = “Page : userProvider; Method: resgisterUser(); Location: “user defined”
     * @param message : user defined
     */
    logMessage(MessageLevel, pageNameString, methodNameString, message) {
        // If user logLevel is undefined, which means user has not logged in.
        if (this.loglevel == undefined || this.loglevel == null) {

            let val = this.storageService.getStorage('CurrentLoglevel')
            // If there is a value for 'CurrentLoglevel' in storage.
            // 'CurrentLoglevel' will be stored if authentication is success.
            // if (val != false) {
            //     const data = JSON.parse(val);
            //     this.loglevel = data.logLevel;
            //     this.username = data.username;
            //     this.email = data.email;
            //     this.id = data._id;

            //     Raven.setUserContext({
            //         username: this.username,
            //         email: this.email,
            //         loginId: this.loginId,
            //         id: this.id
            //     });
            //     this.logToSentry(MessageLevel, pageNameString, methodNameString, message)
            // }
            // If there is not value for 'CurrentLoglevel' in storage - which means authentication failed
            // Then fetch systemLogLevel from 'systemOptions' table.
            // else {
            //     this.fetchSystemLogLevel().subscribe((res) => {
            //         this.systemLogLevelResponse = res;
            //         this.loglevel = this.systemLogLevelResponse.data[0].defaultLogLevel;
            //         // // Get user's ip address and store it in user name
            //         this.logToSentry(MessageLevel, pageNameString, methodNameString, message)
            //     }, (err) => {
            //         // Fetch systemLogLevel returned an error
            //         // So set the defaultLogLevel to 2 a hardcoded value of debug
            //         this.loglevel = 2;
            //         this.logToSentry(MessageLevel, pageNameString, methodNameString, message)
            //     })
            // }
            // }, (err) => {
            // 	this.fetchSystemLogLevel().subscribe((res:any) => {
            // 		this.systemLogLevelResponse = res;
            // 		this.loglevel = this.systemLogLevelResponse.data[0].defaultLogLevel;
            // 		this.logToSentry(MessageLevel, pageNameString,methodNameString, message)
            // 	}, (err) => {
            // 		// Fetch systemLogLevel returned an error
            // 		// So set the defaultLogLevel to 2 a hardcoded value of debug
            // 		this.loglevel = 2;
            // 		this.logToSentry(MessageLevel, pageNameString,methodNameString, message)
            // 	})
            // });
        }
        // If logLevel is not undefined
        // directly proceed to save messages to sentry.
        else {
            this.logToSentry(MessageLevel, pageNameString, methodNameString, message)
        }
    }


    httpErrorHandler(level, pageName, methodName, methodParams?, errorObject?) {
        this.logMessage(level, pageName, methodName, errorObject);
        return from([false]);
    }


    toastMessage() {
        this.toastCtrl.create({
            message: 'There is some issue with your internet connection or the server is down'
        })
    }

    toastMessageSomethingWentWrong() {
        this.toastCtrl.create({
            message: 'Something went wrong. Please try later.'
        })
    }

}
