import { Photo } from './../../../services/media/media.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaService } from 'src/app/services/media/media.service';
import { ActionSheetController, AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { WallsService } from 'src/app/services/walls/walls.service';
import { UserService } from 'src/app/services/user/user.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ProfilePicturePage } from '../../media-utility/profile-picture/profile-picture.page';
import { ShowConnectionsPage } from '../show-connections/show-connections.page';
import { ShareGroupsPage } from '../../groups/share-groups/share-groups.page';
import { NetworkService } from 'src/app/services/network/network.service';
import { ConnectionGroupsPage } from '../../groups/connection-groups/connection-groups.page';
import { ConnectionFriendsPage } from '../../groups/connection-friends/connection-friends.page';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { ConnectionCommunitiesPage } from '../../groups/connection-communities/connection-communities.page';
import { PostService } from 'src/app/services/post/post.service';
import { ReportPage } from 'src/app/modules/home/report/report.page';
import { BlockUser } from 'src/app/interfaces/block-user';
import { WatchStoryComponent } from 'src/app/components/watch-story/watch-story.component';
import { VideoStoryService } from 'src/app/services/video-story/video-story.service';
import { RecordStoryComponent } from 'src/app/components/record-story/record-story.component';
import { UploadProfilePictureOptionsComponent } from 'src/app/components/upload-profile-picture-options/upload-profile-picture-options.component';
import { WelcomePage } from '../../welcome/welcome/welcome.page';
import { environment } from 'src/environments/environment';
import { Share } from '@capacitor/share';
import { TourGuideService } from 'src/app/services/tour-guide/tour-guide.service';
// import { MediaRecorder } from '../../../../../node_modules/media'


@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.page.html',
    styleUrls: ['./user-profile.page.scss', './user-profile-new.page.scss'],
})

@Injectable({
    providedIn: 'root'
})

export class UserProfilePage implements OnInit {
    @ViewChild('mainContent') mainContent: any;
    @ViewChild('video') video: ElementRef;
    clientUrl: any = environment.Client_URL;
    currentPopover: any = null;
    // photo: any = "non empty string";
    photo: any = "";
    userData: any;
    currentUserId: any;
    certificatesData: any;
    educationData: any;
    awardsData: any;
    publicationsData: any;
    workData: any;
    isCurrentUser: boolean;
    isUserBlocked: boolean = false
    canSeeAboutMe: boolean;
    canSeeAddress: boolean;
    canSeeAge: boolean;
    canSeeConnections: boolean;
    canSeeGender: boolean;
    canSeeHealthInterests: boolean;
    canSeeMobileNumber: boolean;
    canSeePatientDetails: boolean;
    canSeeRelationshipToPatient: boolean;
    canSeeTreatments: boolean;
    canSendRequest: boolean;
    canSendMessage: boolean;
    canShowAndroidProfileUploadOptions: boolean = false;
    canShowAndroidStoryUploadOptions: boolean = false;
    err: any;
    isAlreadyConnected: boolean;
    isRequestSentAlredy: boolean = false;
    platformType = sessionStorage.getItem('platform');
    userId: any;
    userProfilePicture: any = '';
    uploadURLToS3: any;
    imageName: string;
    mediaRecorder: MediaRecorder;
    videoPlayer: any;
    isRecording: boolean = false;
    videos = [];
    profileNameInitials = '';
    uid;

    @ViewChild('video') captureElement: ElementRef;
    @ViewChild('preview', { static: false }) preview: ElementRef;
    @ViewChild('descriptionWrapper') descriptionWrappeElem: Element;
    @ViewChild('descriptionDisplay') descriptionDisplayElem: Element;
    chunks: Blob[] = [];
    recordingTime: number = 0;
    stream: MediaStream;
    localStream: MediaStream;
    isSeeMoreDescriptionVisible: boolean;
    decriptionTextWrapperDomProp: any;
    decriptionTextDisplayDomProp: any;
    isPersonalInfoSectionVisible: boolean = false;
    // isAboutMeSectionVisible: boolean = false;
    // iscertificatesSectionVisible: boolean = false;
    // isAwardsSectionVisible: boolean = false;
    // isPublicationsSectionVisible: boolean = false;
    // isEducationSectionVisible: boolean = false;
    // isWorkExperienceSectionVisible: boolean = false;
    isLoginModalOpen: boolean = false;
    static this: any;
    isVideoStoryUploadSuccess: boolean = false;
    currentURL: string = '';
    isVideoStoryExist: boolean = false;
    pageName: string = 'UserProfile';

    showUserInfo: boolean = false;
    showUserInfoNotAvailable: boolean = false;
    showLoginLink: boolean = false;
    showUserInfoLoader: boolean = false;
    isRequestAlreadyReceived: boolean = false;

    constructor(
        public router: Router,
        public location: Location,
        public route: ActivatedRoute,
        public modalCtrl: ModalController,
        public alertCtrl: AlertController,
        public toastController: ToastController,
        private changeDetector: ChangeDetectorRef,
        public popoverController: PopoverController,
        public actionSheetController: ActionSheetController,
        public userService: UserService,
        public postService: PostService,
        public mediaService: MediaService,
        public wallsService: WallsService,
        public storageService: StorageService,
        public networkService: NetworkService,
        public loggingService: LoggingService,
        public videoStoryService: VideoStoryService,
        public notificationService: NotificationService,
        public userAnalyticsService: UserAnalyticsService,
        public TourGuideService: TourGuideService,
    ) {
        this.loggingService.fetchGlobalConstants();
    }

    ngOnInit() {
        console.log("ngOnInit called");
        this.userService.refreshSharedprofileurlINfo_trigge$.subscribe(data => {
            this.ionViewWillEnter();
        });

        // this.currentUserId = this.storageService.getStorage('currentUserId');

        // // if (this.uid) this.userId = this.uid;
        // // else 
        // this.userId = this.route.snapshot.params['id'];
        // console.log("🚀 ~ file: user-profile.page.ts:111 ~ UserProfilePage ~ ngOnInit ~ this.userId:", this.userId)

        // setTimeout(() => {
        //     this.getUserData(this.userId);
        // }, 1000);
        // this.userAnalyticsService.trackPagesHandler('UserProfilePage');

        // console.log("ng on init user profile");

        // this.saveURLToLocalStorage();
    }

    ngAfterContentInit() {
        this.currentURL = this.clientUrl + window.location.pathname;
        console.log("🚀 ~ file: user-profile.page.ts:148 ~ UserProfilePage ~ ngAfterContentInit ~ window.location:", window.location)
        console.log("🚀 ~ file: user-profile.page.ts:148 ~ UserProfilePage ~ ngAfterContentInit ~ this.location:", this.location)
        console.log("🚀 ~ file: user-profile.page.ts:149 ~ UserProfilePage ~ ngAfterContentInit ~ this.router:", this.router)
    }

    ngOnDestroy() {
        // Code to execute when the component is destroyed
        console.log('UserProfilePage has been destroyed');
    }

    saveURLToLocalStorage() {
        if (!this.storageService.getStorage('session')) {
            this.storageService.setStorage('activeURL', this.router.routerState.snapshot.url)
            this.storageService.setStorage('activeURLType', "profileShared");
            console.log("🚀 ~ file: user-profile.page.ts:137 ~ UserProfilePage ~ saveURLToLocalStorage ~ this.router.routerState.snapshot.url:", this.router.routerState.snapshot.url)
            console.log("🚀 ~ file: user-profile.page.ts:139 ~ UserProfilePage ~ saveURLToLocalStorage ~ this.storageService.getStorage('activeURL'):", this.storageService.getStorage('activeURL'))
            console.log("🚀 ~ file: user-profile.page.ts:139 ~ UserProfilePage ~ saveURLToLocalStorage ~ this.storageService.getStorage('activeURLType'):", this.storageService.getStorage('activeURLType'))
        }
    }

    async ngAfterViewInit() {
        this.videos = await this.videoStoryService.loadVides();
    }

    ionViewWillEnter() {
        console.log("check for user block situation in will enter");
        this.currentUserId = this.storageService.getStorage('currentUserId');

        // if (this.uid) this.userId = this.uid;
        // else 
        this.userId = this.route.snapshot.params['id'];
        console.log("🚀 ~ file: user-profile.page.ts:111 ~ UserProfilePage ~ ngOnInit ~ this.userId:", this.userId)

        setTimeout(() => {
            this.getUserData(this.userId);
        }, 500);
        this.userAnalyticsService.trackPagesHandler('UserProfilePage');

        this.saveURLToLocalStorage();
    }
    handleRefresh(event) {
        this.ionViewWillEnter();
        setTimeout(() => {
            // Any calls to load data go here
            event.target.complete();
        }, 2000);
    }

    checkCanShowProfileAndroidUploadOptions() {
        this.canShowAndroidProfileUploadOptions = !this.canShowAndroidProfileUploadOptions;
    }

    checkCanShowStoryAndroidUploadOptions() {
        this.canShowAndroidStoryUploadOptions = !this.canShowAndroidStoryUploadOptions;
    }

    async share() {
        if (this.platformType == 'web') {
            this.copyToClipboard();
        } else {
            await Share.share({
                title: 'Share Profile',
                text: `Explore ${this.userService.userProfileData?.screen_name ? this.userService.userProfileData?.screen_name : this.userService.userProfileData?.full_name}'s profile to discover their interests, achievements, and connections. From professional accomplishments to personal passions, their profile offers a glimpse into the unique blend of skills and experiences that define them.`,
                url: this.currentURL,
                dialogTitle: 'Share with others',
            });
        }
    }

    copyToClipboard() {
        const textArea = document.createElement('textarea');
        textArea.value = this.currentURL;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        this.presentCopyToClipboardToast()
    }

    async presentCopyToClipboardToast() {
        const copyToClipboardToast = await this.toastController.create({
            message: 'Profile Link Copied to clipboard successfully!!',
            position: 'top',
            duration: 2000,
        });
        copyToClipboardToast.present();
    }

    async presentUploadProfilePicturePopover(ev: any) {
        const uploadProfilePicturePopover = await this.popoverController.create({
            component: UploadProfilePictureOptionsComponent,
            cssClass: ['my-custom-class', 'upload-profile-comp'],
            event: ev,
            translucent: true,
        });
        await uploadProfilePicturePopover.present();

        const { role } = await uploadProfilePicturePopover.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    async blockUser() {
        let alert = this.alertCtrl.create({
            message: "Are you sure want to block this user ? You will not be able to see each other's content or profile anymore.",
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Block User',
                    role: 'confirm',
                    handler: () => {
                        let blockUserObject: BlockUser = {
                            blockedBy: this.currentUserId,
                            blockedUser: this.userId,
                            blockedComment: '',
                            blockedPost: '',
                            action: 'block'
                        }
                        this.userService.blockUser(blockUserObject).subscribe(async (res) => {
                            console.log("user block record created successfully", res);
                            let successalert = this.alertCtrl.create({
                                message: "User blocked. You can unblock this user from the 'Blocked Users' option in the settings tab. ",
                                buttons: ['Ok']
                            });
                            (await successalert).present()
                            this.isUserBlocked = true
                        })

                    },
                },
            ],
        });
        (await alert).present();

    }

    async alertBeforeRemovingProfilePicture() {
        let alert = this.alertCtrl.create({
            message: "Are you sure want to remove profile picture?",
            buttons: [
                {
                    text: 'No, Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Yes, remove',
                    role: 'confirm',
                    handler: () => { this.removeProfilePicture() },
                },
            ],
        });
        (await alert).present();

    }

    async reportUser() {
        console.log("user data on report", this.userService.userProfileData);

        const captionModal = await this.modalCtrl.create({
            component: ReportPage,
            cssClass: 'my-custom-class',
            componentProps: {
                userReport: this.userService.userProfileData,
                currentUser: this.currentUserId
            },
            backdropDismiss: true,
        });
        captionModal.present();

        captionModal.onDidDismiss().then((res) => {
            console.log("caption modal dismiss", res);

            if (res.data == undefined) {
                return
            }
            else {
                if (res.data.hasOwnProperty('userBlocked')) {
                    console.log("In user user block section");
                    if (res.data.userBlocked == true) {
                        this.isUserBlocked = true
                        return
                    }
                    else {
                        return
                    }
                }
            }
        })
    }

    hideBlockedUsersProfile() {
        this.mainContent.el.classList.add("block-user")
        console.log("mainContent", this.mainContent.el.className, this.mainContent.el.classList);
        // let userProfile = document.getElementsByClassName("main-content") as HTMLCollectionOf<HTMLElement>;
        // console.log("🚀 ~ file: user-profile.page.ts:101 ~ UserProfilePage ~ hideBlockedUsersProfile ~ userProfile", userProfile)

        // setTimeout(() => {
        //     this.userProfile[0].className.push('hide-profile');
        // }, 500);
    }

    async getUserData(uid) {

        this.showUserInfo = false;
        this.showUserInfoNotAvailable = false;
        this.showUserInfoLoader = true;
        // let res: any = await this.userService.getUserDataForPublicUserProfile(uid).toPromise();
        this.userService.getUserDataForPublicUserProfile(uid).subscribe((res: any) => {
            console.log("🚀 ~ file: user-profile.page.ts:218 ~ UserProfilePage ~ this.userService.getUserDataForUserProfile ~ uid:", res)
            this.showUserInfoLoader = false;
            if (res.error == true) {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'getUserData()', '', 'Error in response');
            }
            else {
                if (res.resObject.data.length == 0) {
                    this.showUserInfoNotAvailable = true;
                    this.showLoginLink = (!this.storageService.getStorage('session')) ? true : false;
                }
                else {



                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userProfilePage', 'getUserData()', '');
                    this.userService.userProfileData = res.resObject.data[0]
                    this.getUserProfileNameInitials();
                    this.showUserInfo = true;
                    this.showUserInfoNotAvailable = false;

                    if (this.userService.userProfileData.blockedUsers.includes(this.currentUserId) || this.userService.userProfileData.blockedBy.includes(this.currentUserId)) {
                        this.isUserBlocked = true
                    }
                    else {
                        console.log("🚀 ~ file: user-profile.page.ts:94 ~ UserProfilePage ~ this.userService.getUserDataForUserProfile ~ this.userService.userProfileData", this.userService.userProfileData)
                        this.certificatesData = this.userService.userProfileData.certifications
                        this.educationData = this.userService.userProfileData.education
                        this.awardsData = this.userService.userProfileData.awards
                        this.publicationsData = this.userService.userProfileData.publications
                        this.workData = this.userService.userProfileData.work
                        // this.certificatesData = res.resObject.data[0].certifications
                        // this.educationData = res.resObject.data[0].education
                        // this.awardsData = res.resObject.data[0].awards
                        // this.publicationsData = res.resObject.data[0].publications
                        // this.workData = res.resObject.data[0].work
                        this.userService.userProfileData.photo != "" ? this.getImageUrl(this.userService.userProfileData.photo) : this.userProfilePicture = "";
                        this.showUserInfo = true;
                        this.isUserBlocked = false;
                        this.sortCertificatesDataBasedOnDate();
                        this.sortAwardsDataBasedOnDate();
                        this.sortPublicationsDataBasedOnDate();
                        this.sortEducationDataBasedOnDate();
                        this.sortWorkDataBasedOnDate();

                        this.checkPrivacySettings();
                        this.checkifVideoStoryExist();

                        this.toggleOnOffSection('personal info');
                        this.toggleOnOffSection('about me');
                        this.toggleOnOffSection('certificates');
                        this.toggleOnOffSection('awards');
                        this.toggleOnOffSection('publications');
                        this.toggleOnOffSection('education');
                        this.toggleOnOffSection('work experience');
                        this.tourguidecall();


                    }
                }

            }
        }, (error => {
            this.showUserInfoLoader = false;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'getUserData()', '', error);
        }))
    }

    getUserProfileNameInitials() {
        if (this.userService.userProfileData?.full_name != '') this.profileNameInitials = this.userService.userProfileData.first_name[0] + this.userService.userProfileData.last_name[0];
        else this.profileNameInitials = this.userService.userProfileData.screen_name[0] + this.userService.userProfileData.screen_name[0];
    }

    checkPrivacySettings() {
        this.checkCurrentUser();
        this.checkCanSeeConnections();
        this.checkCanSeeAboutMe();
        this.checkCanSeeAddress();
        this.checkCanSeeAge();
        this.checkCanSeeGender();
        this.checkCanSeeHealthInterests();
        this.checkCanSeeMobileNumber();
        this.checkCanSeePatientDetails();
        this.checkCanSeeRelationshipToPatient();
        this.checkCanSeeTreatments();
        this.checkCanSendRequest()
        this.checkCanSendMessage()
        this.checkRequestSentAlready()
        this.checkUserAsConnection() ? this.isAlreadyConnected = true : this.isAlreadyConnected = false;
    }

    // async presentActionSheet() {

    //     let actionSheet;
    //     if (this.photo != "") {
    //         actionSheet = await this.replaceProfilePicture()
    //     } else {
    //         actionSheet = await this.uploadProfilePicture()
    //     }
    //     await actionSheet.present();

    //     const { role } = await actionSheet.onDidDismiss();
    // }

    async alertConfirmUploadStory(e) {
        let message = this.isVideoStoryExist ? 'Confirm replace story?' : 'Confirm publish story?';
        let text = this.isVideoStoryExist ? 'Yes, Replace' : 'Yes, Publish';
        const alert = this.alertCtrl.create({
            message: message,
            buttons: [
                {
                    text: 'No, cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: text,
                    handler: () => {
                        this.uploadVideoStory(e);
                    }
                }
            ]
        });
        (await alert).present();
    }

    async uploadProfilePicture(e) {
        this.mediaService.convertAttachedImageToBase64(e.target.files[0]).then((res) => {
            this.userProfile(res, 'forUser');
        });
        // return await this.actionSheetController.create({
        //     cssClass: 'my-custom-class', 
        //     mode: 'ios',
        //     buttons: [{
        //         text: 'Upload',
        //         icon: 'camera-outline',
        //         handler: () => {
        //             // this.mediaService.uploadProfilePicture().then((res) => {
        //             //     this.userProfile(res, 'forUser');
        //             // });

        //         }
        //     }, {
        //         text: 'Cancel',
        //         icon: 'close',
        //         role: 'cancel',
        //         handler: () => {
        //         }
        //     }]
        // });
    }

    async replaceProfilePicture() {
        return await this.actionSheetController.create({
            cssClass: 'my-custom-class',
            mode: 'ios',
            buttons: [{
                text: 'Replace',
                icon: 'repeat-outline',
                handler: () => {
                    this.mediaService.uploadProfilePicture().then((res) => {
                        this.userProfile(res, 'forUser');
                    });
                }
            }, {
                text: 'Remove',
                role: 'destructive',
                icon: 'trash-outline',
                handler: () => {
                    this.removeProfilePicture()
                }
            }, {
                text: 'Cancel',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                }
            }]
        });
    }

    removeProfilePicture() {
        this.userProfilePicture = '';
        let dataToBeUpdate = {
            photo: this.userProfilePicture
        }
        this.userService.updateUserRecord(this.currentUserId, dataToBeUpdate).subscribe(res => {
            if (!res || res == null || res == undefined) {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'removeProfilePicture()', '', 'Error in response');
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userProfilePage', 'removeProfilePicture()', '');
                // this.goBack()
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'removeProfilePicture()', '', error);
        }))
    }

    // navigateToProfilePicture(image, profileType) {
    // 	let imageProp = {
    // 		image: image,
    // 		profileType: profileType
    // 	}
    // 	this.router.navigate(['/profile-picture'], { state: imageProp });
    // }

    async userProfile(imageSelected: any, profileType) {
        let imageProp = {
            image: imageSelected,
            profileType: profileType
        }
        // let image;
        const modal = await this.modalCtrl.create({
            component: ProfilePicturePage,
            cssClass: 'my-custom-class',
            componentProps: {
                image: imageSelected,
                profileType: profileType
            }
        });

        modal.onDidDismiss().then((res) => {
            if (res.data == undefined) return;


            this.userService.userProfileData.photo = res.data.photo;
            let dataToBeUpdate = {
                photo: res.data.photo
            }
            this.userProfilePicture = res.data.displayURL

            // save profile picture image name to user record in database
            this.updateUserData(dataToBeUpdate);

            this.checkCanShowProfileAndroidUploadOptions();
            // get media URL to display profile picture
            // this.getImageUrl(dataToBeUpdate.photo);

            // this.userProfilePicture = this.userService.userProfileData.photo;

            // convert base 64 to blob
            // let blobImage = this.convertbase64ToBlob(res.data.photo)
            // console.log("🚀 ~ file: user-profile.page.ts:244 ~ UserProfilePage ~ modal.onDidDismiss ~ blobImage", blobImage)

            // get unique name for media
            // this.imageName = `${this.mediaService.getUniqueMediaName('user-image')}.jpg`;
            // console.log("🚀 ~ file: user-profile.page.ts:249 ~ UserProfilePage ~ modal.onDidDismiss ~ this.imageName", this.imageName)


            // request for media upload URL to S3 bucket
            // this.mediaService.requestMediUploadUrl(this.imageName, 'forUpload').then((uploadURLToS3) => {
            //     this.uploadURLToS3 = uploadURLToS3;
            // });
            // Upload media to s3 bucket
            // this.mediaService.postMediaToS3Bucket(this.uploadURLToS3, blobImage);
            // console.log("🚀 ~ file: user-profile.page.ts:249 ~ UserProfilePage ~ modal.onDidDismiss ~ this.uploadURLToS3", this.uploadURLToS3)



        })

        await modal.present();
    }

    updateUserData(dataToBeUpdate) {
        this.userService.updateUserRecord(this.currentUserId, dataToBeUpdate).subscribe(res => {
            console.log("🚀 ~ file: user-profile.page.ts:262 ~ UserProfilePage ~ this.userService.updateUserRecord ~ res", res)
            if (!res || res == null || res == undefined) {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'userProfile()', '', 'Error in response');
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userProfilePage', 'userProfile()', '');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'userProfile()', '', error);
        }))
    }

    getImageUrl(mediaName) {
        this.postService.getMediaUrlForDisplay(mediaName)
            .subscribe(displayUrl => {
                this.userProfilePicture = displayUrl
            });
    }



    goBack() {
        // if (this.storageService.getStorage('activeURL')) this.router.navigate(['']);
        // else this.location.back();

        this.location.back();

        // if (this.storageService.getStorage('activeURL')) this.router.navigate(['']);
        // else if (this.currentUserId == this.userId) this.location.back();
        // else this.modalCtrl.dismiss();
    }

    navigateToMyStory() {
        this.router.navigate(['/continue-signup-patient', this.userId]);
    }

    navigateToPersonalInfo() {
        this.router.navigate(['/personal-info', this.userId]);
    }

    navigateToAboutMe() {
        this.router.navigate(['/about-me', this.userId]);
    }

    navigateToAwards() {
        this.router.navigate(['/awards-and-recognition', this.userId]);
    }

    navigateToCertificates() {
        this.router.navigate(['/certificates', this.userId]);
    }

    navigateToPublications() {
        this.router.navigate(['/publications', this.userId]);
    }

    navigateToLicense() {
        this.router.navigate(['/license', this.userId]);
    }

    navigateToEducation() {
        this.router.navigate(['/education', this.userId]);
    }

    navigateToWorkExperience() {
        this.router.navigate(['/work-experience', this.userId]);
    }

    // navigateToPersonalInfo() {
    // 	this.router.navigateByUrl('/work-experience');
    // }

    async showConnections() {
        console.log("🚀 ~ file: user-profile.page.ts:497 ~ UserProfilePage ~ showConnections ~ this.userId:", this.userId)
        const connectionModal = await this.modalCtrl.create({
            component: ConnectionFriendsPage,
            cssClass: 'my-custom-class',
            componentProps: {
                uid: this.userId,
                comingFrom: 'UserProfilePage',
                healthCareConnections: false,
                specificConnections: []
            },
            backdropDismiss: true,
        });
        connectionModal.present();

    }

    async showGroups() {
        const groupModal = await this.modalCtrl.create({
            component: ConnectionGroupsPage,
            cssClass: 'my-custom-class',
            componentProps: {
                uid: this.userService.userProfileData._id,
                comingFrom: 'UserProfilePage',
                healthCareConnections: false
            },
            backdropDismiss: true,
        });
        groupModal.present();
    }

    async showCommunities() {
        const communityModal = await this.modalCtrl.create({
            component: ConnectionCommunitiesPage,
            cssClass: 'my-custom-class',
            componentProps: {
                uid: this.userService.userProfileData._id,
                comingFrom: 'UserProfilePage',
                healthCareConnections: false
            },
            backdropDismiss: true,
        });
        communityModal.present();
    }

    checkCurrentUser() {


        if (this.userService.userProfileData?._id != this.currentUserId) this.isCurrentUser = false;
        else this.isCurrentUser = true;
        console.log("🚀 ~ file: user-profile.page.ts:417 ~ UserProfilePage ~ checkCurrentUser ~ this.userService.userProfileData?._id", this.userService.userProfileData?._id, this.currentUserId)
        return this.isCurrentUser;
    }

    checkUserAsConnection() {
        return this.userService.userProfileData?.friends.some((v: any) => v == this.currentUserId);
    }

    checkCanSeeConnections() {
        if (this.userService.userProfileData?.userPrivacy?.connections == "onlyMe" && !this.checkCurrentUser()) this.canSeeConnections = false;
        else if (this.userService.userProfileData?.userPrivacy?.connections == "myConnection" && !this.checkCurrentUser()) this.canSeeConnections = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.connections == "everyone") this.canSeeConnections = true;
        else this.canSeeConnections = true;
    }

    checkCanSeeAboutMe() {
        if (this.userService.userProfileData?.userPrivacy?.aboutMe == "onlyMe" && !this.checkCurrentUser()) this.canSeeAboutMe = false;
        else if (this.userService.userProfileData?.userPrivacy?.aboutMe == "myConnection" && !this.checkCurrentUser()) this.canSeeAboutMe = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.aboutMe == "everyone") this.canSeeAboutMe = true;
        else this.canSeeAboutMe = true;
    }

    checkCanSeeAddress() {
        if (this.userService.userProfileData?.userPrivacy?.address == "onlyMe" && !this.checkCurrentUser()) this.canSeeAddress = false;
        else if (this.userService.userProfileData?.userPrivacy?.address == "myConnection" && !this.checkCurrentUser()) this.canSeeAddress = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.address == "everyone") this.canSeeAddress = true;
        else this.canSeeAddress = true;
        console.log("🚀 ~ file: user-profile.page.ts:578 ~ UserProfilePage ~ checkCanSeeAddress ~ this.canSeeAddress:", this.canSeeAddress)
    }

    checkCanSeeAge() {
        if (this.userService.userProfileData?.userPrivacy?.age == "onlyMe" && !this.checkCurrentUser()) this.canSeeAge = false;
        else if (this.userService.userProfileData?.userPrivacy?.age == "myConnection" && !this.checkCurrentUser()) this.canSeeAge = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.age == "everyone") this.canSeeAge = true;
        else this.canSeeAge = true;
    }

    checkCanSeeGender() {
        if (this.userService.userProfileData?.userPrivacy?.gender == "onlyMe" && !this.checkCurrentUser()) this.canSeeGender = false;
        else if (this.userService.userProfileData?.userPrivacy?.gender == "myConnection" && !this.checkCurrentUser()) this.canSeeGender = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.gender == "everyone") this.canSeeGender = true;
        else this.canSeeGender = true;
        console.log("🚀 ~ file: user-profile.page.ts:593 ~ UserProfilePage ~ checkCanSeeGender ~ this.canSeeGender:", this.canSeeGender)
    }

    checkCanSeeHealthInterests() {
        if (this.userService.userProfileData?.userPrivacy?.healthInterests == "onlyMe" && !this.checkCurrentUser()) this.canSeeHealthInterests = false;
        else if (this.userService.userProfileData?.userPrivacy?.healthInterests == "myConnection" && !this.checkCurrentUser()) this.canSeeHealthInterests = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.healthInterests == "everyone") this.canSeeHealthInterests = true;
        else this.canSeeHealthInterests = true;
    }

    checkCanSeeMobileNumber() {
        if (this.userService.userProfileData?.userPrivacy?.mobileNumber == "onlyMe" && !this.checkCurrentUser()) this.canSeeMobileNumber = false;
        else if (this.userService.userProfileData?.userPrivacy?.mobileNumber == "myConnection" && !this.checkCurrentUser()) this.canSeeMobileNumber = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.mobileNumber == "everyone") this.canSeeMobileNumber = true;
        else this.canSeeMobileNumber = true;
        console.log("🚀 ~ file: user-profile.page.ts:607 ~ UserProfilePage ~ checkCanSeeMobileNumber ~ this.canSeeMobileNumber:", this.canSeeMobileNumber)
    }

    checkCanSeePatientDetails() {
        if (this.userService.userProfileData?.userPrivacy?.patientDetails == "onlyMe" && !this.checkCurrentUser()) this.canSeePatientDetails = false;
        else if (this.userService.userProfileData?.userPrivacy?.patientDetails == "myConnection" && !this.checkCurrentUser()) this.canSeePatientDetails = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.patientDetails == "everyone") this.canSeePatientDetails = true;
        else this.canSeePatientDetails = true;
    }

    checkCanSeeRelationshipToPatient() {
        if (this.userService.userProfileData?.userPrivacy?.relationshipToPatient == "onlyMe" && !this.checkCurrentUser()) this.canSeeRelationshipToPatient = false;
        else if (this.userService.userProfileData?.userPrivacy?.relationshipToPatient == "myConnection" && !this.checkCurrentUser()) this.canSeeRelationshipToPatient = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.relationshipToPatient == "everyone") this.canSeeRelationshipToPatient = true;
        else this.canSeeRelationshipToPatient = true;
    }

    checkCanSeeTreatments() {
        if (this.userService.userProfileData?.userPrivacy?.treatments == "onlyMe" && !this.checkCurrentUser()) this.canSeeTreatments = false;
        else if (this.userService.userProfileData?.userPrivacy?.treatments == "myConnection" && !this.checkCurrentUser()) this.canSeeTreatments = this.checkUserAsConnection();
        else if (this.userService.userProfileData?.userPrivacy?.treatments == "everyone") this.canSeeTreatments = true;
        else this.canSeeTreatments = true;
    }

    checkCanSendRequest() {
        if (this.userService.userProfileData?.userPreferences?.allowPatientConnectionRequests && !this.checkCurrentUser() && !this.checkUserAsConnection()) {
            this.canSendRequest = true;
            this.isAlreadyConnected = false;
        }
        else if (this.userService.userProfileData?.userPreferences?.allowPatientConnectionRequests && !this.checkCurrentUser() && this.checkUserAsConnection()) this.isAlreadyConnected = true;
        else if (this.userService.currentLoggedInUser?.healthCareProfessional && !this.checkCurrentUser() && !this.checkUserAsConnection()) this.canSendRequest = true;
        else this.canSendRequest = false;

        console.log(
            "this.userService.userProfileData?.userPreferences?.allowPatientConnectionRequests -> ", this.userService.userProfileData?.userPreferences?.allowPatientConnectionRequests,
            "this.userService.userProfileData?.healthCareProfessional -> ", this.userService.userProfileData?.healthCareProfessional,
            "this.checkUserAsConnection() -> ", this.checkUserAsConnection(),
            "!this.checkCurrentUser() -> ", !this.checkCurrentUser(),
            "this.!isCurrentUser  -> ", !this.isCurrentUser,
            "this.canSendRequest  -> ", this.canSendRequest,
            "this.!isRequestSentAlredy -> ", !this.isRequestSentAlredy,
            "this.userService.currentLoggedInUser -> ", this.userService.currentLoggedInUser)
    }

    checkCanSendMessage() {
        if (this.userService.userProfileData?.userPreferences?.allowPatientChatMessages && !this.checkCurrentUser() && this.checkUserAsConnection()) this.canSendMessage = true;
        else this.canSendMessage = false;
    }

    checkSeePersonalInfo() {
        if (this.canSeeAddress == true || this.canSeeMobileNumber == true || this.canSeeGender == true) return true;
        else return false;
    }




    sendConnectionRequest() {

        if (!this.storageService.getStorage('session')) {
            this.openWelcomePageModal();
            return;
        }

        let details = {
            otherId: this.userService.userProfileData._id,
            myId: this.currentUserId
        };

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            if (!response || response == undefined || response == null || response.data == undefined || response.data.length < 1) {
                //show alert something went wrong
                return
            }
            else if (response.data[0].respondFriendRequest.includes(details.otherId)) {
                const notification = {
                    type: 'accepted-friend-request',
                    senderId: details.myId,
                    receivedId: details.otherId,
                    read: false,
                    individualRead: false,
                    active: true,
                    timestamp: new Date()
                };
                this.notificationService.send(notification).subscribe(async (res: any) => {
                    // console.log("already a request", res)
                    let alert = this.alertCtrl.create({
                        message:
                            (this.userService.userProfileData.screen_name ? this.userService.userProfileData.screen_name : this.userService.userProfileData.full_name) +
                            ' has already sent you a connection request and it has been accepted.',
                        buttons: [
                            {
                                text: 'Ok',
                                role: 'cancel',
                                handler: () => {
                                    //important
                                    this.canSendRequest = false
                                    this.isAlreadyConnected = true
                                    this.userService.userProfileData.friendsCondition = false;
                                    this.userService.userProfileData.friendRequestSentCondition = false;
                                    this.userService.userProfileData.friendRequestReceivedCondition = true;
                                }
                            }
                        ],
                        backdropDismiss: false
                    });
                    (await alert).present()

                })

            }
            else {
                const notification = {
                    type: 'friend-request',
                    senderId: details.myId,
                    receivedId: details.otherId,
                    read: false,
                    individualRead: false,
                    active: true,
                    timestamp: new Date()
                };
                this.notificationService.send(notification).subscribe((res) => {
                    // console.log("here is the notification response", res)
                    if (res == undefined || res == null || res.error == true) {
                        //show something wne twrong alert
                        return
                    }
                    else {
                        //success
                        // console.log("can send request", this.canSendRequest)
                        this.isRequestSentAlredy = true
                    }


                })
            }
        })
        //request 1 . subscribe
        //request 2 inside request 1 subscribe block

    }



    /** UNDO CONNECTION REQUEST */
    undoConnectionRequest() {
        let details = {
            otherId: this.userService.userProfileData._id,
            myId: this.currentUserId
        };

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            if (!response || response == undefined || response == null || response.data == undefined || response.data.length < 1) {
                //show alert something went wrong
                return
            }
            else if (response.data[0].friends.includes(details.otherId)) {
                let alert = this.alertCtrl.create({
                    message:
                        (this.userService.userProfileData.screen_name ? this.userService.userProfileData.screen_name : this.userService.userProfileData.full_name) +
                        ' has already accepted the connection request. The action cannot be undone now.',
                    buttons: [
                        {
                            text: 'Ok',
                            role: 'cancel',
                            handler: () => {
                                //important
                                this.canSendRequest = false
                                this.isAlreadyConnected = true
                                this.userService.userProfileData.friendsCondition = false;
                                this.userService.userProfileData.friendRequestSentCondition = false;
                                this.userService.userProfileData.friendRequestReceivedCondition = true;
                            }
                        }
                    ],
                    backdropDismiss: false
                });
                (await alert).present()
            }
            else if (response.data[0].request.filter((vendor) => vendor.id == details.otherId).length > 0) {
                const notification = {
                    type: 'undo-friend-request',
                    senderId: details.myId,
                    receivedId: details.otherId,
                    read: false,
                    individualRead: false,
                    active: true,
                    timestamp: new Date()
                };
                this.notificationService.send(notification).subscribe((sent: any) => {
                    // 				/** SERVICE REQUEST ERROR */
                    this.isRequestSentAlredy = false
                    this.canSendRequest = true
                    this.isAlreadyConnected = false
                })
            }





        })
    }

    async alertBeforeRemovingConnection() {
        let alert = this.alertCtrl.create({
            message: "Are you sure want to remove connection?",
            buttons: [
                {
                    text: 'No, Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Yes, remove',
                    role: 'confirm',
                    handler: () => { this.removeConnection() },
                },
            ],
        });
        (await alert).present();

    }

    /** REMOVE CONNECTION */
    removeConnection() {
        let details = {
            otherId: this.userService.userProfileData._id,
            myId: this.currentUserId
        };


        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            /** SERVICE REQUEST ERROR */
            if (response) {
                /** CHECK IF THE ACTION TAKEN IS VALID */
                if (response.data[0].friends.includes(details.otherId)) {
                    const notification = {
                        type: 'remove-friend-connection',
                        senderId: details.myId,
                        receivedId: details.otherId,
                        read: false,
                        individualRead: false,
                        active: true,
                        timestamp: new Date()
                    };
                    this.notificationService.send(notification).subscribe((sent: any) => {
                        if (!sent) {
                            //alert something wrong!!
                            return
                        }
                        this.isRequestSentAlredy = false
                        this.canSendRequest = true
                        this.isAlreadyConnected = false


                    })
                } else {
                    let alert = this.alertCtrl.create({
                        message:
                            'The request cannot be processed right now. Please try again later',
                        buttons: [
                            {
                                text: 'Ok',
                                role: 'cancel',
                                handler: () => {
                                    //important
                                    this.canSendRequest = false
                                    this.isAlreadyConnected = true
                                    this.userService.userProfileData.friendsCondition = false;
                                    this.userService.userProfileData.friendRequestSentCondition = false;
                                    this.userService.userProfileData.friendRequestReceivedCondition = true;
                                }
                            }
                        ],
                        backdropDismiss: false
                    });
                    (await alert).present()
                }
            }
        })

    }

    async presentUserOptionsActionSheet(buttonsArray) {
        const actionSheet = await this.actionSheetController.create({
            mode: 'ios',
            buttons: buttonsArray,
        });

        await actionSheet.present();
    }

    showOptionsBasedOnUser() {
        console.log("this.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userServicethis.userService")
        // console.log(this.userService )
        // console.log(this.userService.userProfileData.healthCareProfessional )
        // console.log(this.userService.currentLoggedInUser.healthCareProfessional )
        if (!this.storageService.getStorage('session')) {
            this.openWelcomePageModal();
            return;
        }

        let buttonsArray = [];

        if (!this.isCurrentUser && this.isAlreadyConnected) {
            buttonsArray.push({
                text: 'Remove Connection',
                icon: 'person-remove-outline',
                handler: () => {
                    this.alertBeforeRemovingConnection();
                }
            })
        }

        if (!this.isCurrentUser && this.canSendRequest) {
            // buttonsArray.push({
            //     text: this.isRequestSentAlredy ? 'Undo Request' : 'Connect',
            //     icon: 'person-add-outline',
            //     handler: () => {
            //         this.isRequestSentAlredy ? this.undoConnectionRequest() : this.sendConnectionRequest()
            //     }
            // })
        }

        // if (this.isCurrentUser && this.isVideoStoryExist && this.userProfilePicture != '') {
        //     buttonsArray.push(
        //         {
        //             text: 'remove story',
        //             role: 'destructive',
        //             icon: 'trash-outline',
        //             handler: () => {
        //                 // this.showConnections();
        //                 this.alertBeforeRemovingStory();
        //             }
        //         },
        //         {
        //             text: 'remove profile picture',
        //             role: 'destructive',
        //             icon: 'trash-outline',
        //             handler: () => {
        //                 // this.showConnections();
        //                 this.alertBeforeRemovingProfilePicture();
        //             }
        //         },
        //         {
        //             text: 'show connections',
        //             icon: 'git-network-outline',
        //             handler: () => {
        //                 // this.showConnections();
        //                 this.showConnectionsGroups('Connections');
        //             }
        //         },
        //         {
        //             text: 'show groups',
        //             icon: 'people-outline',
        //             handler: () => {
        //                 // this.showGroups();
        //                 this.showConnectionsGroups('Groups');
        //             }
        //         },
        //         {
        //             text: 'show communities',
        //             icon: '../../../../assets/images/buddha.svg',
        //             handler: () => {
        //                 this.showCommunities();
        //             }
        //         },
        //     )
        // }

        if (this.isCurrentUser && this.userProfilePicture != '') {
            buttonsArray.push(
                {
                    text: 'remove profile picture',
                    role: 'destructive',
                    icon: 'trash-outline',
                    handler: () => {
                        // this.showConnections();
                        this.alertBeforeRemovingProfilePicture();
                    }
                }
            )
        }

        if (this.isCurrentUser && this.isVideoStoryExist) {
            buttonsArray.push(
                {
                    text: 'remove story',
                    role: 'destructive',
                    icon: 'trash-outline',
                    handler: () => {
                        // this.showConnections();
                        this.alertBeforeRemovingStory();
                    }
                }
            )
        }

        if (this.isCurrentUser) {
            buttonsArray.push(
                {
                    text: 'show connections',
                    icon: 'git-network-outline',
                    handler: () => {
                        // this.showConnections();
                        this.showConnectionsGroups('Connections');
                    }
                },
                {
                    text: 'show groups',
                    icon: 'people-outline',
                    handler: () => {
                        // this.showGroups();
                        this.showConnectionsGroups('Groups');
                    }
                },
            )
            if (this.userService.userProfileData.healthCareProfessional) {
                buttonsArray.push(
                    {
                        text: 'show communities',
                        icon: '../../../../assets/images/buddha.svg',
                        handler: () => {
                            this.showCommunities();
                        }
                    },
                )
            }
        }

        if (!this.isCurrentUser && this.canSeeConnections) {
            buttonsArray.push(
                {
                    text: 'show connections',
                    icon: 'git-network-outline',
                    handler: () => {
                        // this.showConnections();
                        this.showConnectionsGroups('Connections');

                    }
                },
            )
        }

        if (!this.isCurrentUser) {
            buttonsArray.push(
                {
                    text: 'Block User',
                    role: 'destructive',
                    icon: 'ban-outline',
                    handler: () => {
                        this.blockUser();
                    }
                },
                {
                    text: 'Report User',
                    role: 'destructive',
                    icon: 'alert-circle-outline',
                    handler: () => {
                        this.reportUser();
                    }
                },
                {
                    text: 'show groups',
                    icon: 'people-outline',
                    handler: () => {
                        // this.showGroups();
                        this.showConnectionsGroups('Groups');

                    }
                },
            )
            if (this.userService.userProfileData.healthCareProfessional) {
                buttonsArray.push(
                    {
                        text: 'show communities',
                        icon: '../../../../assets/images/buddha.svg',
                        handler: () => {
                            this.showCommunities();
                        }
                    },
                )
            }
        }

        console.log("this.isCurrentUser", this.isCurrentUser, "this.isAlreadyConnected", this.isAlreadyConnected, "this.canSendRequest", this.canSendRequest)
        this.presentUserOptionsActionSheet(buttonsArray);
    }

    showConnectionsGroups(value) {
        this.router.navigate(['/connection-groups-friends', { segment: value, _id: this.userId }]);
    }

    checkRequestSentAlready() {
        this.isRequestAlreadyReceived = this.userService.userProfileData.request.some((element) => {
            return element.id == this.currentUserId;
        });
        return this.isRequestSentAlredy = this.userService.userProfileData.respondFriendRequest.includes(this.currentUserId) ? true : false;
    }

    async presentWatchStoryModal() {
        const modal = await this.modalCtrl.create({
            component: WatchStoryComponent,
            cssClass: 'my-custom-class',
            backdropDismiss: true,
            componentProps: {
                userName: this.userService.userProfileData?.screen_name ? this.userService.userProfileData?.screen_name : this.userService.userProfileData?.full_name,
                userVideoStoryFilename: ""
            }
        });
        modal.present();

        const { data, role } = await modal.onWillDismiss();
    }

    async presentRecordStoryModal() {
        const modal = await this.modalCtrl.create({
            component: RecordStoryComponent,
            cssClass: 'my-custom-class',
            backdropDismiss: true,
            componentProps: {
                // userName: this.userService.userProfileData?.screen_name ? this.userService.userProfileData?.screen_name : this.userService.userProfileData?.full_name,
                // userVideoStoryFilename: "test.mp4"
            }
        });
        modal.present();

        const { data, role } = await modal.onWillDismiss();
    }

    // startRecording() {
    //     const videoInput = this.videoInput.nativeElement;
    //     const preview = this.preview.nativeElement;

    //     if (videoInput.files.length > 0) {
    //         this.getMediaStream()
    //             .then((stream: MediaStream) => {
    //                 preview.srcObject = stream;
    //                 this.mediaRecorder = new MediaRecorder(stream);
    //                 this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
    //                 this.mediaRecorder.start();
    //                 this.recordingTime = 0;
    //                 console.log('Recording started.');
    //                 setTimeout(this.stopRecording.bind(this), 40000); // Stop recording after 40 seconds
    //             })
    //             .catch((error) => {
    //                 console.log('Error accessing media devices: ', error);
    //             });
    //     } else {
    //         console.log('No video selected.');
    //     }
    // }

    // handleDataAvailable(event) {
    //     if (event.data.size > 0) {
    //         this.chunks.push(event.data);
    //     }
    // }

    // stopRecording() {
    //     this.mediaRecorder.stop();
    //     this.mediaRecorder.onstop = () => {
    //         const blob = new Blob(this.chunks, { type: this.chunks[0].type });
    //         this.chunks = [];
    //         const videoURL = URL.createObjectURL(blob);
    //         this.preview.nativeElement.src = videoURL;
    //         console.log('Recording stopped.');
    //     };
    // }

    // private getMediaStream(): Promise<MediaStream> {
    //     return new Promise((resolve, reject) => {
    //         const videoInput = this.videoInput.nativeElement;
    //         if ('getUserMedia' in navigator.mediaDevices) {
    //             navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    //                 .then((stream: MediaStream) => resolve(stream))
    //                 .catch((error) => reject(error));
    //         } else {
    //             reject(new Error('getUserMedia is not supported in this browser.'));
    //         }
    //     });
    // }


    async recordVideo() {
        await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'user'
            },
            audio: true
        })
            .then((stream) => {
                this.localStream = stream;
                this.captureElement.nativeElement.srcObject = stream;
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.ondataavailable = (event) => {
                    if (event.data && event.data.size > 0) {
                        this.chunks.push(event.data)
                        console.log("🚀 ~ file: user-profile.page.ts:1018 ~ UserProfilePage ~ recordVideo ~ chunks:", this.chunks)
                    }
                }
                this.mediaRecorder.start();
                this.recordingTime = 0;

            })

        this.isRecording = true;

        const options = { mimeType: 'video/webm' };
        console.log("🚀 ~ file: user-profile.page.ts:1012 ~ UserProfilePage ~ recordVideo ~ this.mediaRecorder:", this.mediaRecorder)

        this.chunks = [];

    }

    stopRecord() {
        this.localStream.getTracks()[0].stop(); // audio channel off
        this.localStream.getTracks()[1].stop(); // video channel off
        this.mediaRecorder.stop()
        this.mediaRecorder.onstop = async (event) => {
            // const videoBuffer = new Blob(this.chunks, { type: 'video/webm' })
            const videoBuffer = new Blob(this.chunks, { type: this.chunks[0].type });
            console.log("🚀 ~ file: user-profile.page.ts:1023 ~ UserProfilePage ~ this.mediaRecorder.onstop= ~ videoBuffer:", videoBuffer)
            this.chunks = [];
            let videoURL = URL.createObjectURL(videoBuffer);
            this.preview.nativeElement.src = videoURL;
            console.log("🚀 ~ file: user-profile.page.ts:1044 ~ UserProfilePage ~ this.mediaRecorder.onstop= ~ videoURL:", videoURL)
            console.log('Recording stopped.');
            //store the video
            // await this.videoStoryService.storeVideo(videoBuffer);

            //reload the list
            // this.videos = this.videoStoryService.videos;
            // this.changeDetector.detectChanges();
        }
        this.mediaRecorder = null;
        this.captureElement.nativeElement.srcObject = null;
        this.isRecording = false;
    }

    async play(video) {
        const basse64data = await this.videoStoryService.getVideoUrl(video);

        // Show the player fullscreen
        await this.videoPlayer.initPlayer({
            mode: 'fullscreen',
            url: basse64data,
            playerId: 'fullscreen',
            componentTag: 'app-user-profile'
        })
    }

    toggleOnOffSection(value) {
        // switch (value) {
        //     case 'personal info':
        //         this.isPersonalInfoSectionVisible = !this.isPersonalInfoSectionVisible;
        //         break;

        //     case 'about me':
        //         this.isAboutMeSectionVisible = !this.isAboutMeSectionVisible;
        //         break;

        //     case 'certificates':
        //         this.iscertificatesSectionVisible = !this.iscertificatesSectionVisible;
        //         break;

        //     case 'awards':
        //         this.isAwardsSectionVisible = !this.isAwardsSectionVisible;
        //         break;

        //     case 'publications':
        //         this.isPublicationsSectionVisible = !this.isPublicationsSectionVisible;
        //         break;

        //     case 'education':
        //         this.isEducationSectionVisible = !this.isEducationSectionVisible;
        //         break;

        //     case 'work experience':
        //         this.isWorkExperienceSectionVisible = !this.isWorkExperienceSectionVisible;
        //         break;
        // }
    }

    sortCertificatesDataBasedOnDate() {
        this.certificatesData.sort((a, b) => {
            let date1: any = new Date(a.issueDate)
            let date2: any = new Date(b.issueDate)

            return date2 - date1;
        })
    }

    sortAwardsDataBasedOnDate() {
        this.awardsData.sort((a, b) => {
            let date1: any = new Date(a.date)
            let date2: any = new Date(b.date)

            return date2 - date1;
        })
    }

    sortPublicationsDataBasedOnDate() {
        this.publicationsData.sort((a, b) => {
            let date1: any = new Date(a.date)
            let date2: any = new Date(b.date)

            return date2 - date1;
        })
    }

    sortEducationDataBasedOnDate() {
        this.educationData.sort((a, b) => {
            let date1: any = new Date(a.from)
            let date2: any = new Date(b.from)

            return date2 - date1;
        })
    }

    sortWorkDataBasedOnDate() {
        this.workData.sort((a, b) => {
            let date1: any = new Date(a.from)
            let date2: any = new Date(b.from)

            return date2 - date1;
        })
    }


    uploadVideoStory(e) {
        console.log("🚀 ~ file: user-profile.page.ts:1208 ~ UserProfilePage ~ uploadVideoStory ~ e:", e.target.files)

        // Get the video file
        let file = e.target.files[0];

        // Convert Video file to URL
        let objectURL = URL.createObjectURL(file);
        console.log("🚀 ~ file: user-profile.page.ts:1252 ~ UserProfilePage ~ uploadVideoStory ~ objectURL:", objectURL)
        // Assign URL to video tag
        this.video.nativeElement.src = objectURL;
        // Get the video file duration
        setTimeout(async () => {
            // Check if length is less than or equal to 45sec
            if (this.video.nativeElement.duration < 46) {
                this.mediaService.processAttachedVideoForDisplayAndUpoad(file).then((res: Blob) => {
                    console.log("🚀 ~ file: user-profile.page.ts:1260 ~ UserProfilePage ~ this.mediaService.processAttachedVideoForDisplayAndUpoad ~ res:", res)
                    if (!res || res == null || res == undefined) return;
                    // Save to S3
                    this.saveVideoToS3(res)
                })
            } else {
                let videoDurationAlert = this.alertCtrl.create({
                    message: "Video File Duration should not be more than 45 seconds",
                    buttons: ['Ok']
                });

                (await videoDurationAlert).present()
            }
        }, 1000);
    }

    saveVideoToS3(videoBuffer) {
        let mediaName = this.mediaService.getUniqueMediaName('video-story') + '.mp4';
        console.log("media name", mediaName);

        this.mediaService.requestMediUploadUrl(mediaName, 'forUpload').then((requestMediaURLResponse) => {
            requestMediaURLResponse.subscribe((requestedURL) => {
                console.log("🚀 ~ file: record-story.component.ts:107 ~ RecordStoryComponent ~ requestMediaURLResponse.subscribe ~ requestedURL:", requestedURL)
                if (requestedURL) {
                    this.mediaService.postMediaToS3Bucket(requestedURL, videoBuffer).then((res) => {
                        res.subscribe((uploadres) => {
                            console.log("here is the uploadres", uploadres);
                            this.addVideoStoryToUserProfile(mediaName);
                        })
                        console.log(" here is the post response", res);

                    })
                }
            })
        })
    }

    addVideoStoryToUserProfile(mediaName) {
        let userUploadData = {
            userVideoStoryName: mediaName,
            userVideoStoryCreatedAt: new Date()
        }
        this.userService.addToProfile(userUploadData).subscribe(async (res) => {
            console.log("🚀 ~ file: user-profile.page.ts:1321 ~ UserProfilePage ~ this.userService.addToProfile ~ res:", res, this.isVideoStoryUploadSuccess)

            if (this.isVideoStoryUploadSuccess == false) {
                let successalert = this.alertCtrl.create({
                    message: "Video story has been uploaded successfully.",
                    buttons: ['Ok']
                });
                (await successalert).present();
                (await successalert).onDidDismiss().then((res) => {
                    this.isVideoStoryExist = true;
                    this.checkCanShowStoryAndroidUploadOptions();
                })
                this.isVideoStoryUploadSuccess = true;
            }
        })
    }

    async alertBeforeRemovingStory() {
        let alert = this.alertCtrl.create({
            message: "Are you sure want to remove Story?",
            buttons: [
                {
                    text: 'No, Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Yes, remove',
                    role: 'confirm',
                    handler: () => { this.removeVideoStory() },
                },
            ],
        });
        (await alert).present();

    }

    removeVideoStory() {
        let userUploadData = {
            userVideoStoryName: '',
            userVideoStoryCreatedAt: new Date()
        }
        this.userService.addToProfile(userUploadData).subscribe(async (res) => {
            console.log("🚀 ~ file: user-profile.page.ts:1321 ~ UserProfilePage ~ this.userService.addToProfile ~ res:", res, this.isVideoStoryUploadSuccess)

            if (res) {
                let removeStorySuccessAlert = this.alertCtrl.create({
                    message: "Video story has been removed successfully.",
                    buttons: ['Ok']
                });
                (await removeStorySuccessAlert).present();
                (await removeStorySuccessAlert).onDidDismiss().then((res) => {
                    this.isVideoStoryExist = false;
                })
            }
        })
    }

    checkifVideoStoryExist() {
        if (this.userService.userProfileData.userVideoStoryName != "") this.isVideoStoryExist = true;
    }

    async openWelcomePageModal() {
        if (this.isLoginModalOpen == false) {
            this.isLoginModalOpen = true;
            this.userService.welcomePageModal = await this.modalCtrl.create({
                component: WelcomePage,
                backdropDismiss: false,
                cssClass: 'signup-modal',
                componentProps: {
                    openAs: 'modal'
                }
            });
            this.userService.welcomePageModal.present();

            await this.userService.welcomePageModal.onWillDismiss()
                .then(() => {
                    this.isLoginModalOpen = false;
                })
        }
    }

    navigateToDisplayImage() {
        this.router.navigate(['/display-image', "", "userprofileimage", this.userProfilePicture]);
    }

    async profileOption() {
        let buttonsArray = [];
        if (this.userProfilePicture != '' && this.isCurrentUser) {
            buttonsArray.push({
                text: 'View Photo',
                icon: 'eye-outline',
                role: "confirm",
                handler: () => {
                    this.navigateToDisplayImage()
                }
            });
        }

        if (this.platformType != 'web') {
            buttonsArray.push({
                text: "Open Camera",
                icon: 'camera-outline',
                role: "confirm",
                handler: () => {
                    document.getElementById("openCamera").click();
                }
            });
        }
        buttonsArray.push({
            text: "Attach Photo",
            icon: 'attach',
            role: "confirm",
            handler: () => {
                document.getElementById("replacepicture").click();
            }
        });



        const actionSheet = await this.actionSheetController.create({
            mode: 'ios',
            buttons: buttonsArray,
        });

        await actionSheet.present();
    }

    async respondRequest() {
        let buttonsArray = [];
        if (!this.isCurrentUser) {
            buttonsArray.push(
                {
                    text: 'Accept',
                    icon: 'checkmark-outline',
                    handler: () => {
                        this.acceptConnectionRequest(this.userId);
                    }
                },
                {
                    text: 'Decline',
                    role: 'destructive',
                    icon: 'close-outline',
                    handler: () => {
                        this.declineConnectionRequest(this.userId);
                    }
                },
                {
                    text: 'Block User',
                    role: 'destructive',
                    icon: 'ban-outline',
                    handler: () => {
                        this.blockUser();
                    }
                },
                {
                    text: 'Report User',
                    role: 'destructive',
                    icon: 'alert-circle-outline',
                    handler: () => {
                        this.reportUser();
                    }
                }
            );
        }
        console.log("this.isCurrentUser", this.isCurrentUser, "this.isAlreadyConnected", this.isAlreadyConnected, "this.canSendRequest", this.canSendRequest)
        this.presentUserOptionsActionSheet(buttonsArray);
    }


    /** ACCEPT CONNECTION REQUEST */
    acceptConnectionRequest(receiverId) {
        let details = {
            otherId: receiverId,
            myId: this.currentUserId
        };

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            /** SERVICE REQUEST ERROR */
            if (response) {
                /** CHECK IF THE ACTION TAKEN IS VALID */
                if (response.data[0].respondFriendRequest.includes(details.otherId)) {
                    const notification = {
                        type: 'accepted-friend-request',
                        senderId: details.myId,
                        receivedId: details.otherId,
                        read: false,
                        individualRead: false,
                        active: true,
                        timestamp: new Date()
                    };
                    this.notificationService.send(notification).subscribe(async (res: any) => {
                        // console.log("already a request", res)
                        if (!response || response == undefined || response == null || response.data == undefined || response.data.length < 1) {
                            //show alert something went wrong
                            return
                        }
                        // to hide `Respond request` button
                        this.isRequestAlreadyReceived = false;
                        this.canSendRequest = false;
                    })
                } else {
                    let alert = this.alertCtrl.create({
                        message:
                            'The request cannot be processed right now. Please try again later',
                        buttons: [
                            {
                                text: 'Ok',
                                role: 'cancel',
                                handler: () => {
                                    //important
                                }
                            }
                        ],
                        backdropDismiss: false
                    });
                    (await alert).present()
                }
            }
        })
    }

    declineConnectionRequest(receiverId) {
        let details = {
            otherId: receiverId,
            myId: this.currentUserId
        };

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            /** SERVICE REQUEST ERROR */
            if (response) {
                /** CHECK IF THE ACTION TAKEN IS VALID */
                if (response.data[0].respondFriendRequest.includes(details.otherId)) {
                    const notification = {
                        type: 'decline-friend-request',
                        senderId: details.myId,
                        receivedId: details.otherId,
                        read: false,
                        individualRead: false,
                        active: true,
                        timestamp: new Date()
                    };
                    this.notificationService.send(notification).subscribe(async (res: any) => {
                        // console.log("already a request", res)
                        if (!response || response == undefined || response == null || response.data == undefined || response.data.length < 1) {
                            //show alert something went wrong
                            return
                        }
                        // to enable `connect` button instead of `Respond request`
                        this.isRequestAlreadyReceived = false;
                        //this.isCurrentUser 
                        this.canSendRequest = true;
                    })
                } else {
                    let alert = this.alertCtrl.create({
                        message:
                            'The request cannot be processed right now. Please try again later',
                        buttons: [
                            {
                                text: 'Ok',
                                role: 'cancel',
                                handler: () => {
                                    //important

                                }
                            }
                        ],
                        backdropDismiss: false
                    });
                    (await alert).present()
                }
            }
        })
    }


    tourguidecall() {
        if (this.userService.userProfileData?._id != this.currentUserId) {
            this.TourGuideService.StartUserTourType = "public";
        }
        else {
            this.TourGuideService.StartUserTourType = "loggedin";
        }

      
        this.TourGuideService.shouldStartUserTour = true;
        this.TourGuideService.StartUserTourId = this.userId;

        if (
            this.userService.currentLoggedInUser.appUserGuideShow == "done" &&
            this.userService.currentLoggedInUser.appUserGuideShowPublic != "done" &&
            this.TourGuideService.StartUserTourType == "public"
        ) {
            setTimeout(() => {
                this.TourGuideService.userProfileIntroPublic(this.TourGuideService.StartUserTourId);
            }, 1000)
        }
        // this.TourGuideService.userProfileIntro(userType , this.userId);
    }
}


