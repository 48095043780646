import { Injectable } from '@angular/core';
import { Network } from  '@capacitor/network'
import { DraftsService } from '../drafts/drafts.service';
import { AlertController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class InternetService {
  isNetWorkAvailable : boolean 
  constructor(public draftsService : DraftsService, public alertCtrl : AlertController ) { 
    this.checkNetworkStatus()
  }


  setupNetworkListener(){
    console.log("network listener added");
    
    Network.addListener('networkStatusChange', async (status)=>{

     
      console.log('network status changed', status)
      //call the below method to save all the unsaved posts
      if(status.connected == true){
          console.log("start post upload")
         
          this.isNetWorkAvailable = true
           /**
           * Also check if the user token is present and not expired : if your are in the app this logic will take care of it
           * 
           * If you are logging in after killing the app put the below method in desired places : 
           * 
           */

            let autoPublishResponse = await this.draftsService.autoPublishDraftPosts().then(res=>res).catch(err=>err)

            console.log("auto publish response", autoPublishResponse);
      }
      else{
        this.isNetWorkAvailable = false
      }
  })
  }

  checkForNetworkAndPublishDrafts(){
    console.log("check for network and publish called");
   
    if(this.isNetWorkAvailable == false) return //guard method : return of network not detected

    this.draftsService.autoPublishDraftPosts()
  }


  async checkNetworkStatus(){
   const status =  await Network.getStatus() 
  
   if (status.connected == true){
    this.isNetWorkAvailable = true
   }
   else{
    this.isNetWorkAvailable = false
   }
   
   return status
   
  }


  async presentMessageAlert() {
    console.log("present message alert added");
    
    const noInternetalert = await this.alertCtrl.create({
        message: 'Action cannot be done. Please check your internet connectivity.',
        buttons: [
            {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                    //refresh the same group wall 
                  
                }
            }
        ]
    });
    await noInternetalert.present();

}
}
