import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-watch-story',
    templateUrl: './watch-story.component.html',
    styleUrls: ['./watch-story.component.scss'],
})
export class WatchStoryComponent implements OnInit {

    userName;
    userVideoStoryFilename;
    videoDisplayUrl;
    constructor(
        public modalCtrl: ModalController,
        public postService : PostService,
        public userService : UserService,
    ) { }

    ngOnInit() {
        // this.userVideoStoryFilename;
        this.userVideoStoryFilename = this.userService.userProfileData. userVideoStoryName 

        this.postService.getVideoUrl(this.userVideoStoryFilename).subscribe(res => {
            console.log("video url from cloud front", res)
            if (res) {
                this.videoDisplayUrl = res
                // this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'displayImagePage', 'requestMediaAndSetupSlider() getVideoUrl-> video', '');
                // media.contentToDisplay = res
            }
            else {
                //this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'displayImagePage', 'requestMediaAndSetupSlider() getVideoUrl-> video', '', 'Error in response');
            }
        }
        , (error) => {
          //  media.contentToDisplay = this.errorVideoImage
           // this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'displayImagePage', 'requestMediaAndSetupSlider() getVideoUrl-> video', '', error);
        })
        console.log("🚀 ~ file: watch-story.component.ts:20 ~ WatchStoryComponent ~ ngOnInit ~ this.userVideoStoryFilename:", this.userVideoStoryFilename)
    }


    dismiss() {
        this.modalCtrl.dismiss();
        // this.location.back();
    }
}
