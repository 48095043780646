import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { NotificationService } from '../notification/notification.service';
import { LoggingService } from '../logging/logging.service';
@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	baseServerUrl: any = environment.API_URL;
	currentUserId: any;
	constructor(public http: HttpClient, public storageService: StorageService, public userService: UserService, public notificationService: NotificationService, public loggingService: LoggingService) {
		this.currentUserId = this.storageService.getStorage('currentUserId');
		this.loggingService.fetchGlobalConstants();
	}


	getFriendRequestsWhenSwitchingTabs() {
		let connectionRequestResponse = {
			error: false,
			resObject: {}
		};
		return this.http
			.get(this.baseServerUrl + '/network?action=getFriendRequestsWhenSwitchingTabs&_id=' + this.currentUserId)
			.pipe(
				map(
					(response: any) => {
						if (!response || response == undefined || response == null) {
							connectionRequestResponse.error = true;
						} else {
							connectionRequestResponse.error = false;
							connectionRequestResponse.resObject = response;
						}
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'getFriendRequestsWhenSwitchingTabs()', '');
						return connectionRequestResponse;
					}),catchError((err ) => {
					 this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','getFriendRequestsWhenSwitchingTabs()', '', err)
					connectionRequestResponse.error = true;
					throw connectionRequestResponse;	
				}))

	}



	declineConnectionRequest(details, err) {
		let declineConnectRequestResponse = {
			error: false,
			value: ''
		};
		var notification = {
			type: 'decline-friend-request',
			senderId: details.myId,
			receivedId: details.otherId,
			read: false,
			individualRead: false,
			active: true,
			timestamp: new Date()
		};

		/** GET YOUR UPDATED USER INFORMATION */
		return this.userService.getLoggedInUser().pipe(map((response: any) => {
			if (response) {
				/** CHECK IF YOU STILL NEED TO TAKE AN ACTION */
				if (response.data[0].respondFriendRequest.includes(details.otherId)) {
					this.notificationService.send(notification).subscribe((sent: any) => {
						if (!sent) {
							declineConnectRequestResponse.error = true;
							declineConnectRequestResponse.value = 'false';
							this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'declineConnectionRequest()', 'No response -> declineConnectRequestResponse.error = true');
							return declineConnectRequestResponse;
						}

						declineConnectRequestResponse.error = false;
						declineConnectRequestResponse.value = 'true';	/** SUCCESSFULLY DECLINED THE CONNECTION REQUEST */
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'declineConnectionRequest()', '');
						return declineConnectRequestResponse;
					})
				}
				else {
					/** CHECK IF ALREADY ACCEPTED BY LOOKING INTO YOUR CONNECTION ARRAY */
					if (response.data[0].friends.includes(details.otherId)) {
						declineConnectRequestResponse.error = false;
						declineConnectRequestResponse.value = 'Friends';
					}
					/** THIS SITUATION IS OBTAINED WHEN OTHER PERSON HAS DECLINED YOUR CONNECTION REQUEST */
					else {
						declineConnectRequestResponse.error = false;
						declineConnectRequestResponse.value = 'Unknown';
					}
					this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'declineConnectionRequest()', '');
					return declineConnectRequestResponse;
				}
			}
		}),catchError((err ) => {
			 this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','declineConnectionRequest()', '', err)
			declineConnectRequestResponse.error = true;
					throw declineConnectRequestResponse;		
		}))

	}

	acceptConnectionRequest(details, err) {
		let acceptConnectRequestResponse = {
			error: false,
			value: ''
		};
		/** CREATE NOTIFICATION */
		var notification = {
			type: 'accepted-friend-request',
			senderId: details.myId,
			receivedId: details.otherId,
			read: false,
			individualRead: false,
			active: true,
			timestamp: new Date()
		};

		/** GET YOUR UPDATED USER INFORMATION */
		return this.userService.getLoggedInUser().pipe(map((response: any) => {
			/** SERVICE REQUEST ERROR */
			if (response) {
				/** CHECK IF THE ACTION TAKEN IS VALID */
				if (response.data[0].respondFriendRequest.includes(details.otherId)) {

					this.notificationService.send(notification).subscribe((sent: any) => {
						// 				/** SERVICE REQUEST ERROR */
						if (sent == false) {
							acceptConnectRequestResponse.error = true;
							acceptConnectRequestResponse.value = 'false';
							this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'acceptConnectionRequest()', '');
							return acceptConnectRequestResponse;
						}
						// this.socketService.handleAcceptConnectionRequest(sent)
						acceptConnectRequestResponse.error = false;
						acceptConnectRequestResponse.value = 'true';		/** SUCCESSFULLY ACCEPTED CONNECTION REQUEST */
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'acceptConnectionRequest()', '');
						return acceptConnectRequestResponse;
					})

				}
				else {
					/** CHECK IF ALREADY ACCEPTED BY LOOKING INTO YOUR CONNECTION ARRAY */
					if (response.data[0].friends.includes(details.otherId)) {
						acceptConnectRequestResponse.error = false;
						acceptConnectRequestResponse.value = 'Friends';
					}
					/** THIS SITUATION IS OBTAINED WHEN OTHER PERSON HAS DECLINED YOUR CONNECTION REQUEST */
					else {
						acceptConnectRequestResponse.error = false;
						acceptConnectRequestResponse.value = 'Unknown';
					}
					this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'acceptConnectionRequest()', '');
					return acceptConnectRequestResponse;
				}
			}
		}),catchError((err ) => {
			this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','acceptConnectionRequest()', '', err)
			acceptConnectRequestResponse.error = true;
			throw acceptConnectRequestResponse;	
		}));
	}

	// sendConnectionRequest(details) {
	// 	let sendConnectionRequestResponse = {
	// 		error: false,
	// 		value: ''
	// 	};
	// 	const notification = {
	// 		type: 'friend-request',
	// 		senderId: details.myId,
	// 		receivedId: details.otherId,
	// 		read: false,
	// 		individualRead: false,
	// 		active: true,
	// 		timestamp: new Date()
	// 	};

	// 	/** GET YOUR UPDATED USER INFORMATION */
	// 	return this.userService.getLoggedInUser().pipe(map( (response: any) => {
	// 		/** SERVICE REQUEST ERROR */
	// 		if (response) {
	// 			/** CHECK IF THE ACTION TAKEN IS VALID */
	// 			if (response.data[0].respondFriendRequest.includes(details.otherId)) {

	// 				sendConnectionRequestResponse.error = false;
	// 				sendConnectionRequestResponse.value = 'other request exist';
	// 				return sendConnectionRequestResponse;
	// 			}
	// 			else {
	// 				 this.notificationService.send(notification).subscribe((sent: any) => {
						      
	// 					// 				/** SERVICE REQUEST ERROR */
	// 					if (!sent) {
	// 						sendConnectionRequestResponse.error = true;
	// 						sendConnectionRequestResponse.value = 'false';
	// 						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'sendConnectionRequest()', '');
	// 						return sendConnectionRequestResponse;
	// 					}
	// 					// this.socketService.handleAcceptConnectionRequest(sent)
	// 					sendConnectionRequestResponse.error = false;
	// 					sendConnectionRequestResponse.value = 'true';		/** SUCCESSFULLY ACCEPTED CONNECTION REQUEST */
	// 					this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'sendConnectionRequest()', '');
	// 					return sendConnectionRequestResponse;
	// 				},(err:any)=>{
	// 					sendConnectionRequestResponse.error = true;
	// 					sendConnectionRequestResponse.value = "error in api";
	// 					return sendConnectionRequestResponse;
	// 				})
					
	// 			}
	// 		}
	// 	}),catchError((err ) => {
	// 		this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','sendConnectionRequest()', '', err)
	// 		sendConnectionRequestResponse.error = true;
	// 		throw sendConnectionRequestResponse;	
	// 	}));
	// }

	

	undoConnectionRequest(details, err) {
		let undoConnectionRequestResponse = {
			error: false,
			value: ''
		};

		const notification = {
			type: 'undo-friend-request',
			senderId: details.myId,
			receivedId: details.otherId,
			read: false,
			individualRead: false,
			active: true,
			timestamp: new Date()
		};

		/** GET YOUR UPDATED USER INFORMATION */
		return this.userService.getLoggedInUser().pipe(map((response: any) => {
			/** SERVICE REQUEST ERROR */
			if (response) {
				/** CHECK IF THE ACTION TAKEN IS VALID */
				if (response.data[0].request.filter((vendor) => vendor.id == details.otherId).length > 0) {
					this.notificationService.send(notification).subscribe((sent: any) => {
						// 				/** SERVICE REQUEST ERROR */
						if (!sent) {
							undoConnectionRequestResponse.error = true;
							undoConnectionRequestResponse.value = 'false';
							this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'undoConnectionRequest()', '');
							return undoConnectionRequestResponse;
						}
						// this.socketService.handleAcceptConnectionRequest(sent)
						undoConnectionRequestResponse.error = false;
						undoConnectionRequestResponse.value = 'true';		/** SUCCESSFULLY ACCEPTED CONNECTION REQUEST */
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'undoConnectionRequest()', '');
						return undoConnectionRequestResponse;
					})
				} else {
					if (response.data[0].friends.includes(details.otherId)) {
						undoConnectionRequestResponse.error = false;
						undoConnectionRequestResponse.value = "Friends";
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'undoConnectionRequest()', '');
						return undoConnectionRequestResponse;
					} else {
						undoConnectionRequestResponse.error = true;
						undoConnectionRequestResponse.value = "Unknown";
						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'undoConnectionRequest()', '');
						return undoConnectionRequestResponse;
					}

				}
			}
		}),catchError((err ) => {
			this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','undoConnectionRequest()', '', err)
			undoConnectionRequestResponse.error = true;
			throw undoConnectionRequestResponse;	
		}));
	}

	removeConnection(details, err) {
		// let removeConnectionResponse = {
		// 	error: false,
		// 	value: ''
		// };
		// /** CREATE NOTIFICATION */
		// var notification = {
		// 	type: 'remove-friend-connection',
		// 	senderId: details.myId,
		// 	receivedId: details.otherId,
		// 	read: false,
		// 	individualRead: false,
		// 	active: true,
		// 	timestamp: new Date()
		// };

		

		/** GET YOUR UPDATED USER INFORMATION */
	// 	return this.userService.getLoggedInUser().pipe(map((response: any) => {
	// 		/** SERVICE REQUEST ERROR */
	// 		if (response) {
	// 			/** CHECK IF THE ACTION TAKEN IS VALID */
	// 			if (response.data[0].friends.includes(details.otherId)) {
	// 				this.notificationService.send(notification).subscribe((sent: any) => {
	// 					if (!sent) {
	// 						removeConnectionResponse.error = true;
	// 						removeConnectionResponse.value = 'false';
	// 						this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'removeConnection()', '');
	// 						return removeConnectionResponse;
	// 					}
	// 					// this.socketService.handleAcceptConnectionRequest(sent)
	// 					removeConnectionResponse.error = false;
	// 					removeConnectionResponse.value = 'true';		/** SUCCESSFULLY ACCEPTED CONNECTION REQUEST */
	// 					this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'gronetworkServiceupsService', 'removeConnection()', '');
	// 					return removeConnectionResponse;

	// 				})
	// 			} else {
	// 				removeConnectionResponse.error = false;
	// 				removeConnectionResponse.value = "true";
	// 				this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'networkService', 'removeConnection()', '');
	// 				return removeConnectionResponse;
	// 			}
	// 		}
	// 	}),catchError((err ) => {
	// 	this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error,'networkService','removeConnection()', '', err)
	// 	removeConnectionResponse.error = true;
	// 	throw removeConnectionResponse;
	// }));
	}

}
