import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  AWSSecretKey: string;
  constructor() {
    this.AWSSecretKey = environment.awsSecretKey;
  }
  
  encryption(parameter1) {    
    return CryptoJS.AES.encrypt(parameter1, this.AWSSecretKey).toString()
  }

  decryption(parameter1) {	  
    return CryptoJS.AES.decrypt(parameter1, this.AWSSecretKey).toString(CryptoJS.enc.Utf8)
  }

  decryptionAWS(parameter1) {
    return CryptoJS.AES.decrypt(parameter1, this.AWSSecretKey).toString()
  }

  hashing(parameter1) {
		return CryptoJS.SHA256(parameter1).toString(CryptoJS.enc.Hex)
	}
}
