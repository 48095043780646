import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { ShowConnectionsPage } from '../../userProfile/show-connections/show-connections.page';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { casePostCreationObject } from 'src/app/services/post/post.interface';
import { Share } from '@capacitor/share';

@Component({
    selector: 'app-share-from-wall',
    templateUrl: './share-from-wall.page.html',
    styleUrls: ['./share-from-wall.page.scss'],
})

export class ShareFromWallPage implements OnInit {


    visibilityValue: any;
    postDataFromWall: any;
    editCommentMode: boolean = false;
    currentUserInfo: any;
    AppName = environment.Appname;
    postAuthor: any;
    postAuthorId: any;
    noOfRowsForScreen: any;
    originalPostId: any;
    comments: any;
    err: any;
    modalData: any;
    plaform = sessionStorage.getItem('platform');


    clientUrl: any = environment.Client_URL;
    shareUrl : any ;
    platformType = sessionStorage.getItem('platform');



    constructor(public userService: UserService, 
        public toastController: ToastController,public postService: PostService, public modalCtrl: ModalController, public router: Router, public loggingService: LoggingService, public userAnalyticsService: UserAnalyticsService) {
        this.loggingService.fetchGlobalConstants();
        let modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        // console.log("share from wall modal data", this.modalData);


        this.initialSetupMethod();

        if (this.plaform == 'web') {
            this.noOfRowsForScreen = 10;
        }
        else {
            this.noOfRowsForScreen = 6;
        }
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ShareFromWallPage');
    }

    initialSetupMethod() {
        this.visibilityValue = "All connections";
        this.getCurrentUserInformation();
        this.postDataFromWall = this.modalData.postDataFromWall;
        console.log("this.postDataFromWall.postVisibility",this.postDataFromWall.postVisibility)
        if (this.modalData.action && this.modalData.action == "editCommentMode") {
            this.originalPostId = this.postDataFromWall._id;
            this.editCommentMode = true;
            this.comments = this.postDataFromWall.comment;
        }


        this.getPostAuthorName()
    }

    getPostAuthorName() {

        // check for shared or original post
        if (this.postDataFromWall.type == "Shared") {
            this.postAuthor = this.postDataFromWall.referencePostUserInfo[0].screen_name == "" ? this.postDataFromWall.referencePostUserInfo[0].full_name : this.postDataFromWall.referencePostUserInfo[0].screen_name;
        } else {
            this.postAuthor = this.postDataFromWall.userInfo[0].screen_name == "" ? this.postDataFromWall.userInfo[0].full_name : this.postDataFromWall.userInfo[0].screen_name;
        }

    }

    getCurrentUserInformation() {
        this.userService.getLoggedInUser().subscribe((response: any) => {
            if (response) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'shareFromWallPage', 'getUserConnections()', '');
                this.currentUserInfo = response?.data[0];
                this.shareUrl = this.clientUrl + "/post-details/"+this.postDataFromWall._id;
                console.log("this.shareUrl" ,this.shareUrl)
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'getUserConnections()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'getUserConnections()', '', error);
        }));
    }


    async sharePostActionFunction() {
        if (this.modalData.action && this.modalData.action == "editCommentMode") {
            this.postService
                .addToPost(this.originalPostId, {
                    comment: this.comments,
                    updatedDate: new Date()
                })
                .subscribe((response: any) => {
                    if (response.error == true) {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'sharePostActionFunction() -> modalData.action = editCommentMode', '', 'Error in response');
                    }
                    else {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'shareFromWallPage', 'sharePostActionFunction() -> modalData.action = editCommentMode', '');
                        this.postDataFromWall.comment = this.comments;
                        this.modalCtrl.dismiss();
                    }
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'sharePostActionFunction() -> modalData.action = editCommentMode', '', error);
                }));
        } else {
            this.originalPostId = this.postDataFromWall.referencePost
                ? this.postDataFromWall.referencePost._id
                : this.postDataFromWall._id;
            if (this.visibilityValue == "Select groups") {
                this.modalCtrl.dismiss();
                // this.app
                // 	.getRootNav()
                // 	.push("ShareGroupsPage", {
                // 		shareComment: this.comments,
                // 		originalPostId: this.originalPostId
                // 	});
                let selectGroupObj = {
                    shareComment: this.comments,
                    originalPostId: this.originalPostId,
                    groupType: 'groups'
                }
                // this.router.navigate(['/share-groups'], { state: selectGroupObj });
                this.router.navigate(['/share-groups', selectGroupObj.originalPostId, selectGroupObj.groupType, { shareComment: selectGroupObj.shareComment }]);
            } else if (this.visibilityValue == "Select communities") {
                this.modalCtrl.dismiss();
                // this.app
                // 	.getRootNav()
                // 	.push("ShareGroupsPage", {
                // 		shareComment: this.comments,
                // 		originalPostId: this.originalPostId
                // 	});
                let selectGroupObj = {
                    shareComment: this.comments,
                    originalPostId: this.originalPostId,
                    groupType: 'communitites'
                }
                // this.router.navigate(['/share-groups'], { state: selectGroupObj });
                this.router.navigate(['/share-groups', selectGroupObj.originalPostId, selectGroupObj.groupType, { shareComment: selectGroupObj.shareComment }]);
            }
            else if (this.visibilityValue == "Specific connections") {
                this.userService.currentLoggedInUser.healthCareProfessional == false
                /**
                 * IMPORTANT CHANGE DONE ON MAY 6TH 2022 BY SARTHAK BHATT : 
                 * 	Issue that was happening : The current page (ShareFromWallPage) and the next page (ShowConnectionsPage) both are modals and modals get their data from 
                 * component props. In this case, We were trying to dismiss this modal and then open the next modal. But because of the timing
                 * and DOM behavior, this modal was getting dismissed later before the next modal could be presented, hence, not updating the component props of the 
                 * next modal. The next modal (showConnectionsPage) was getting the same data as this one and the postReferenceId was not getting passed which in turn
                 * was creating a shared post record without ref post and messing up server.
                 * 
                 * Solution implemented : Promisified the current modal dismiss modal which makes sure that current modal gets dismissd first and the component props
                 * gets destroyed. After which the next modal gets presented (written in the then block of dismiss) and a new component props is being created with a 
                 * fresh data
                 * 
                 */
                this.modalCtrl.dismiss().then(async () => {
                    let showConnectionsObj = {
                        shareComment: this.comments,
                        pageComingFrom: "sharePostFromWall",
                        originalPostId: this.originalPostId
                    };

                    // console.log("share with specific block click", showConnectionsObj);

                    const captionModal = await this.modalCtrl.create({
                        component: ShowConnectionsPage,
                        cssClass: 'showConnectionsPageModal',
                        componentProps: showConnectionsObj,
                        backdropDismiss: true,
                    });
                    captionModal.present();
                })
            } else if (this.visibilityValue == "All connections") {
                let details: casePostCreationObject = {
                    thoughts: "",
                    caseTitle: "",
                    extract: "",
                    questions: "",
                    gid: "",
                    creationDate: new Date(),
                    updatedDate: new Date(),
                    disabledBy: "",
                    disabledDate: "",
                    Disable: false,
                    identify: "",
                    photos: [],
                    type: "Shared",
                    comment: this.comments,
                    postRef: this.originalPostId,
                    postVisibility: "All connections",
                    specificConnectionsVisibility: [],
                    shares: [] /** THIS PARAMETER IS FOR KEEPING THE IDS AND COUNT OF TOTAL SHARES OF ORIGINAL POST */,
                    commentId: [],
                    updates: [],
                    userExpression: [],
                    openGroupFollow: [],
                    allowOthersToShare: false,

                    userFollow: [] /** USERIDS OF PEOPLE COMMENTED AND CREATED THE POST ARE ADDED TO THIS ARRAY */,

                    pushNotificationUsers: [],
                    postOrCaseSecurityPolicy: false,
                    offlinePublishStatus: "not started"
                };

                this.postService.addPost(details).subscribe(
                    res => {
                        if (res.error == false) {
                            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'shareFromWallPage', 'sharePostActionFunction() -> else', '');
                            this.modalCtrl.dismiss();
                        }
                        else {
                            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'sharePostActionFunction() -> else', '', 'Error in response');
                        }
                    }, (error => {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'shareFromWallPage', 'sharePostActionFunction() -> else', '', error);
                    }));
            } else if (this.visibilityValue == "Select sharevia"){
                this.dismiss();
                this.share(); 
            }

        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async share() {
        if (this.platformType == 'web') {
            this.copyToClipboard();
        } else {
            await Share.share({
                title: 'Share Post',
                text: `Hi, I just came across this captivating post. Click now to uncover some remarkable  insights. `,
                url: this.shareUrl,    
                dialogTitle: 'Share with others',
            });
        }   
    }
    
    copyToClipboard() {
        const textArea = document.createElement('textarea');
        textArea.value = this.shareUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        this.presentCopyToClipboardToast()
    }

    async presentCopyToClipboardToast() {
        const copyToClipboardToast = await this.toastController.create({
            message: 'Post Link Copied to clipboard successfully!!',
            position: 'bottom',
            duration: 2000,
        });
        copyToClipboardToast.present();
    }
 
}



