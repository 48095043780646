import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { WallsService } from 'src/app/services/walls/walls.service';

@Component({
    selector: 'app-show-users',
    templateUrl: './show-users.page.html',
    styleUrls: ['./show-users.page.scss'],
})
export class ShowUsersPage implements OnInit {
    allUsers: any = []; // stores user info
    loggingConstants: any = [];
    err: any;
    userInfo: any;
    actionFromPrevpage: any;
    comingFrom: any;
    modalData: any;
    postDataFromPreviousPage: any;
    constructor(public userService: UserService, public router: Router, public modalCtrl: ModalController, public loggingService: LoggingService, public userAnalyticsService: UserAnalyticsService, public wallsService: WallsService) {
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        this.loggingService.fetchGlobalConstants();
        this.actionFromPrevpage = this.modalData.action;
        this.postDataFromPreviousPage = this.modalData.postData;
        console.log("🚀 ~ file: show-users.page.ts:29 ~ ShowUsersPage ~ constructor ~ this.postDataFromPreviousPage", this.postDataFromPreviousPage)
        this.comingFrom = this.modalData.comingFrom;

        // Get users for show users page
        let extension = "?fetchAction=usersForShowUsersPage&userFetchType="
            + this.actionFromPrevpage + "&postId=" + this.postDataFromPreviousPage._id
        this.userService.getUsersForShowUsersPage(extension).subscribe((response: any) => {
            console.log("🚀 ~ file: show-users.page.ts:36 ~ ShowUsersPage ~ this.userService.getUsersForShowUsersPage ~ response", response)
            if (response) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'showUsersPage', 'constructor', '');
                if (this.comingFrom == 'displayCommentPage' || this.actionFromPrevpage == 'postShareUsers') {
                    this.allUsers = response.data;
                    console.log("🚀 ~ if ~ this.userService.getUsersForShowUsersPage ~ this.allUsers", this.allUsers[0].postInfo.userInfo.photo)
                }
                else {
                    this.allUsers = response.data[0].users;
                    console.log("🚀 ~ else ~ this.userService.getUsersForShowUsersPage ~ this.allUsers", this.allUsers)
                }
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showUsersPage', 'constructor', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showUsersPage', 'constructor', '', error);
        }))
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ShowUsersPage');
    }

    takeToProfile(currentUser: any) {
        console.log("🚀 ~ file: show-users.page.ts:61 ~ ShowUsersPage ~ takeToProfile ~ currentUser:", currentUser)
        this.modalCtrl.dismiss();
        if (currentUser) this.router.navigate(['/user-profile', currentUser._id])
    }

    clickGroup(groupId: any) {
        let obj = {
            comingFrom: 'GroupWall',
            groupId: groupId
        }
        // this.router.navigate(['/group-home'], { state: obj });
        this.modalCtrl.dismiss();
        this.router.navigate(['/group-home', obj.groupId, obj.comingFrom]);
    }

    goBack() {
        this.modalCtrl.dismiss();
    }

}
