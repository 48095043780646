import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { chatListObject, ChatService } from '../chat/chat.service';
import { NotificationService } from '../notification/notification.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';
import { Network } from  '@capacitor/network'
import { InternetService } from '../internet/internet.service';

//import { DraftsService } from '../drafts/drafts.service';


@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket: any
    notificationSocket: any
    baseServerUrl: any = environment.API_URL
    currentSocketId: any = ''
    currentUserPlatform: any = ''
    loggedInUserId = ''
    currentUserDeviceId: any = ''
    chatWindowActive: boolean = false
    notifBadgeCount: number = 0
    chatNotifBadgeCount: number = 0
    groupWallWindowActive: boolean = false
    currentGroupWindowId: any = ""
    networkStatus : any = ""
    isNetworkAvailable : boolean = true
    constructor(
        public storageService: StorageService, 
        public alertCtrl: AlertController, 
        public router: Router, 
        public chatService: ChatService, 
        public userService: UserService, 
        public notificationService: NotificationService, 
        public ngZone: NgZone, 
        public internetService : InternetService
    ) {
        // console.log("socket constructor");

    }

    async checkForUserInternetConnection(){
        this.networkStatus = await Network.getStatus()
        return this.networkStatus
    }


    async presentUnsavedItemsInDraftsAlert() {
        const unsavedPostsAlert = await this.alertCtrl.create({
            message: '',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        //refresh the same group wall 
                        this.router.navigate(['tabs/tab1'])

                    }
                }
            ]
        });
        unsavedPostsAlert.present();

    }




    setupSocketListeners(calledBy) {
        console.log("Called By inside SOCKET:",calledBy);
        
        this.internetService.checkForNetworkAndPublishDrafts()
        console.log("Socket status: ", this.socket)
        // console.log("Socket query deviceId: ", this.socket.handshake.query.deviceId)
        // this.socket.disconnect()
        if (this.socket == undefined || this.socket.connected == false || this.socket.disconnected == true) {
            // if (this.socket == undefined || this.socket.connected == false || this.socket.disconnected == true) {

            // if(this.socket == undefined) {
            //     console.log("Socket is UNDEFINED");
                
            // }
            // if(this.socket.connected == false) {
            //     console.log("Socket CONNECTED is FALSE");

            // }
            // if(this.socket.disconnected == true) {
            //     console.log("Socket DISCONNECTED is TRUE");

            // }


            const self = this
            self.loggedInUserId = self.storageService.getStorage('currentUserId')
            // let chatPageActive = 
            console.log("socket in in again before io connect");
            // alert('above IO.connect')
            this.socket = io.connect(this.baseServerUrl, { query: { Authorization: self.storageService.getStorage('session'), userid: self.storageService.getStorage('currentUserId'), deviceId: self.currentUserDeviceId, platform: self.currentUserPlatform, chatPageActive: self.storageService.getStorage('chatPageActive') }, transports: ['websocket'] })
            // alert('below IO.connect')
                        console.log("check after io connect", this.socket);

            this.notificationSocket = io.connect(this.baseServerUrl, {query: {userId: self.storageService.getStorage('currentUserId'), deviceId: self.currentUserDeviceId, platform: self.currentUserPlatform}}, )

            this.notificationSocket.on('connect', () => {
                console.log('Connected to notification server');
            });

            this.socket.on('connect', function (socketListener) {
                // console.log("connected-socket", self.chatWindowActive);
                if (self.chatWindowActive == true) {
                    self.socket.emit('chat-page-active', { socketId: self.socket.id, userId: self.storageService.getStorage('currentUserId') })
                }
                self.currentSocketId = self.socket.id
                self.getAllUnreadNotificationCount();
            })


            //listen for new notification : 

            this.socket.on('new-notification', function (result) {
                let notificationMessage = JSON.parse(JSON.stringify(result))
                // console.log("socket notif object", notificationMessage)
                if (notificationMessage && notificationMessage != undefined) {
                    self.handleNotifications(notificationMessage)
                }
                else{
                    this.api
                }
            })


            this.socket.on('all-messages-read', function (roomId) {
                if (self.chatService.activeChatRoomId == roomId) {
                    for (const messages of self.chatService.activeWindowMessages) {
                        if (messages.senderId == self.loggedInUserId) {
                            messages.isRead = true
                        }
                    }
                }
                else {
                    return
                }
                console.log('check message read', self.chatService.activeWindowMessages);

            })


            this.socket.on('all-messages-delivered', function (userInfo) {
                // console.log("inside socket all-messages-delivered", self.chatService.activeChatUser, userInfo);

                if (self.chatService.activeChatUser._id == userInfo.receiverId) {
                    // console.log("inside socket all-messages-delivered > if");
                    for (const messages of self.chatService.activeWindowMessages) {
                        if (messages.senderId == self.loggedInUserId) {
                            // console.log("inside socket all-messages-delivered > if > if");
                            messages.messageStatus = 'delivered'
                        }
                    }
                }
                else {
                    // console.log("inside socket all-messages-delivered > else");
                    return
                }
                console.log('check message read', self.chatService.activeWindowMessages);

            })

            this.socket.on('current-message-delivered', function (infoObj) {
                if (self.chatService.activeChatRoomId == infoObj.roomId) {
                    let indexOfObject = self.chatService.activeWindowMessages.findIndex(x => x._id == infoObj.messageId);
                    self.chatService.activeWindowMessages[indexOfObject].messageStatus = 'delivered';
                }
                else {
                    return
                }
                console.log('current-message-delivered', self.chatService.activeWindowMessages);

            })


            this.socket.on('current-message-read', function (infoObj) {
                if (self.chatService.activeChatRoomId == infoObj.roomId) {
                    let indexOfObject = self.chatService.activeWindowMessages.findIndex(x => x._id == infoObj.messageId);
                    self.chatService.activeWindowMessages[indexOfObject].isRead = true;
                    self.chatService.activeWindowMessages[indexOfObject].messageStatus = 'delivered';
                }
                else {
                    return
                }
                console.log('current-message-read', self.chatService.activeWindowMessages);

            })


            this.socket.on('new-chat-message', function (eventObj) {
                console.log('listened new chat event', eventObj);
                if (self.chatService.activeChatRoomId == eventObj.roomId) { // if the user is cuurently chatting with the sender of new message
                    // console.log("active chat room if");
                    self.chatService.activeWindowMessages.push(eventObj) //update the current ongoing chat in the right window
                    let indexOfObject = self.chatService.activeUsersChatList.findIndex(x => x.roomId == eventObj.roomId);
                    self.chatService.activeUsersChatList[indexOfObject].lastMesageInfo = eventObj;
                    self.chatService.sortActiveChatListArray();

                    if (!self.chatService.atBottom) {
                        self.chatService.newMessageCount += 1;
                    }
                    self.socket.emit('current-message-read', { messageId: eventObj._id, senderId: eventObj.senderId, roomId: eventObj.roomId })
                }
                else if (self.chatService.activeUsersChatList.findIndex(x => x.roomId == eventObj.roomId) >= 0) { // if the sender of the message //exist in the active chat user list
                    // console.log("active chat user else if");
                    self.ngZone.run(() => {
                        self.chatNotifBadgeCount = self.chatNotifBadgeCount + 1
                    })
                    //  self.chatNotifBadgeCount = ++self.chatNotifBadgeCount
                    let indexOfObject = self.chatService.activeUsersChatList.findIndex(x => x.roomId == eventObj.roomId);
                    self.chatService.activeUsersChatList[indexOfObject].lastMesageInfo = eventObj;
                    self.chatService.activeUsersChatList[indexOfObject].unreadMessageCount = ++self.chatService.activeUsersChatList[indexOfObject].unreadMessageCount;
                    self.chatService.sortActiveChatListArray();
                    self.socket.emit('current-message-delivered', { messageId: eventObj._id, senderId: eventObj.senderId, roomId: eventObj.roomId })
                    console.log('check unread count', self.chatService.activeUsersChatList);
                }
                else { //first time message sender , create a record in the activeChat User list. The one that is dsiplayed on the left
                    // console.log("no user exist else");
                    self.ngZone.run(() => {
                        self.chatNotifBadgeCount = self.chatNotifBadgeCount + 1
                    })
                    // self.chatNotifBadgeCount = ++self.chatNotifBadgeCount
                    self.userService.getUserInfo(eventObj.senderId).subscribe((res: any) => {
                        let chatListObj: chatListObject = {
                            userInfo: res.data[0],
                            roomId: eventObj.roomId,
                            lastMesageInfo: eventObj,
                            unreadMessageCount: 1,
                            userStatus: '',
                            isTyping: false
                        }
                        self.chatService.activeUsersChatList.unshift(chatListObj)
                    })
                    self.socket.emit('current-message-delivered', { messageId: eventObj._id, senderId: eventObj.senderId, roomId: eventObj.roomId })
                }


            })
            this.socket.on('chat-online-offline', function (eventObj) {
                for (let i = 0; i < self.chatService.activeUsersChatList.length; i++) {
                    if (eventObj.user_id_string == self.chatService.activeUsersChatList[i].userInfo._id) {
                        self.chatService.activeUsersChatList[i].userStatus = eventObj.online_flag
                        break;
                    }
                }
                console.log('listened online offline', eventObj);

            })

            this.socket.on('user-typing-status', function (eventObj) {
                for (let i = 0; i < self.chatService.activeUsersChatList.length; i++) {
                    if (eventObj.user_id_string == self.chatService.activeUsersChatList[i].userInfo._id) {
                        self.chatService.activeUsersChatList[i].isTyping = eventObj.typingStatus
                        break;
                    }
                }
                console.log('listened online offline', eventObj);

            })

            this.socket.on('website-common',(result) => {
                let websitecommon = JSON.parse(JSON.stringify(result))
                console.log("website-common", websitecommon);
                if(websitecommon.event == "you-are-disabled"){
                    console.log("logout", websitecommon);
                    this.logout();
                }
            })

            this.socket.on('disconnect', function (reason) {
                // console.log("socket disconnect", self.storageService.getStorage('chatPageActive'));

            })
        }
        else {
            // console.log("already connected", this.currentSocketId);

        }
    }


    emitUserTypingStatus(userObject) {
        // console.log("user typing event emitted", userObject)
        this.socket.emit('user-typing-status', userObject)
    }

    emitChatPageActive() {
        // console.log("emit event page active");

        this.socket.emit('chat-page-active', { socketId: this.socket.id, userId: this.storageService.getStorage('currentUserId') })
    }

    emitChatPageInActive() {
        this.socket.emit('chat-page-inactive', { socketId: this.socket.id, userId: this.storageService.getStorage('currentUserId') })
    }



    disconnectSocket() {
        // console.log("show socket in logout method", this.socket);

        if (this.socket && this.socket.connected) {
            this.socket.emit('disconnect-user-application', { socketId: this.socket.id, userId: this.storageService.getStorage('currentUserId') })
            this.currentSocketId = ''
            // console.log("socket disconnect", this.socket)
        }
        else {
            // console.log("nothing happened on socket disconnect")
        }
    }

    //Notification section   --- start -----

    getAllUnreadNotificationCount() {
        //reset the notification count everytime the method is getting called
        this.notifBadgeCount = 0
        this.chatNotifBadgeCount = 0
        this.notificationService.getNotificationForBadgeSetup(this.storageService.getStorage('currentUserId'), 'notificationBadgeCount').subscribe((res: any) => {
            // console.log("here is the response", res)
            if (res == undefined || res == null || !res || res.data == undefined || res.data.length < 1 || !res.data) {
                return
            }
            if (res.data[0].chatNotifications != undefined && typeof (res.data[0].chatNotifications) == "number") {
                if (res.data[0].chatNotifications > 0) {
                    this.chatNotifBadgeCount = res.data[0].chatNotifications
                }
            }
            if (res.data[0].regularNotifications != undefined && typeof (res.data[0].regularNotifications == "number")) {
                if (res.data[0].regularNotifications > 0) {
                    this.notifBadgeCount = res.data[0].regularNotifications
                }
            }

        }, (err) => {

        })
    }


    /**
     * 1. GET NOTIFICVATION COUNT ON APP STARTUP
     * 2. LISTEN FOR NEW NOTIFICATION
     * 3. UPDATE BADGE COUNT ON NEW NOTIFICATION
     */

    handleNotifications(notification) {
        if (
            notification.type == 'post-created' ||
            notification.type == 'page-invite' ||
            notification.type == 'post-shared' ||
            notification.type == 'comment-created' ||
            notification.type == 'accepted-friend-request' ||
            notification.type == 'post-case-edited' ||
            notification.type == 'post-case-updated' ||
            notification.type == 'groupAdminApproval' ||
            notification.type == 'groupAdminApprovalRequestApproved' ||
            notification.type == 'groupAdminAdded' ||
            notification.type == 'groupAdminAddedMember' ||
            notification.type == 'friend-request' ||
            notification.type == 'post-reaction'
        ) {
            this.ngZone.run(() => {
                this.notifBadgeCount++ // this is used to display the badge icon
            })


        }
        /**In case of group member romoved, check if the user is still in the group while theor access was removed.
         * If yes, then reload the app and kick out the user from the group wall.
         * Also, present an alert which describes the access revoke.
         *  */
        else if (notification.type == 'groupMemberRemoved') {
            this.ngZone.run(() => {
                this.notifBadgeCount++ // this is used to display the badge icon
            })
            // console.log("memeber remiove notification test", notification);

            if (this.groupWallWindowActive && this.currentGroupWindowId == notification.groupId) { //check for type of group as well : if it is secret and private group bring them to home wall
                this.presentMessageAlert()
                //refresh teh same group
            }
        }
    }




    /**
     * USe the below alert function to alert
     */
    async presentMessageAlert() {
        const alert = await this.alertCtrl.create({
            message: '',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        //refresh the same group wall 
                        this.router.navigate(['tabs/tab1'])

                    }
                }
            ]
        });
        alert.present();

    }


    /**
     * @description: Logout user and clear localstorage and sessionstorage
     */
    logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.disconnectSocket()
        this.router.navigate(['/welcome']);
    }

    // Notification section  ---- end ------

}