import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Camera, CameraResultType } from '@capacitor/camera'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { ModalController, Platform } from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { EncryptionService } from '../encryption/encryption.service';
import { StorageService } from '../storage/storage.service';
import { ProfilePicturePage } from 'src/app/modules/media-utility/profile-picture/profile-picture.page';
import { PostService } from '../post/post.service';

import * as lodash from 'lodash';
import { FileLoaderWindowComponent } from 'src/app/components/file-loader-window/file-loader-window.component';
import { RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';
import { log } from 'console';
import { Subject } from 'rxjs';

export interface Photo {
    filepath: string;
    webviewPath: string;
}

export interface mediaObject {
    name: string,
    caption: string,
    type: string,
    imageUrl: string,
    videoUrl: string,
    pdfUrl: string,
    audioUrl: string,
    dataToBeUploaded: any,
    imagePiiEditHistory: any,
    orientation: string,
    thumbnail: string,
    uploadPercent: any,
    piiUploadStatus: boolean,
    offlineAddress: "",
    zoom: 1
    // crudAction : String, //if creating for first time give the value as 'C', else for edit give 'E'
}


@Injectable({
    providedIn: 'root'
})
export class MediaService {
    baseServerUrl: any = environment.API_URL
    uploadEditMediaArray = []
    loadingMessage: any = 'loading'
    fileLoader: any = ''
    storedAudioFileNames: string[];
    audioSrc: string;
    recording: any;
    audioFiles: any = [];

    clickedIndex: any = 0;

    constructor(public http: HttpClient, public router: Router, public actionSheetController: ActionSheetController, public storageService: StorageService, public encryptionService: EncryptionService, public modalCtrl: ModalController, public postService: PostService) { }


    public async prepareMediaDisplayArrayFromEditPost(postArray) {
        for (let file of postArray) {
            console.log("🚀 ~ file: media.service.ts:60 ~ prepareMediaDisplayArrayFromEditPost ~ postArray:", postArray)
            // console.log("FILE --> FOR LOOP-->", file);

            this.postService.getMediaUrlForDisplay(file.name).subscribe(async (res: any) => {
                console.log("🚀 ~ file: media.service.ts ~ line 63 ~ MediaService ~ this.postService.getMediaUrlForDisplay ~ res", res)
                // console.log("MEDIA URL FOR DISPLAY-->", res);

                if (file.type == "video") {
                    await (fetch(res).then(vidRes => vidRes.blob()).then(async bres => {
                        (await this.processAttachedVideoForDisplayAndUpoad(bres).then((vid: any) => {
                            const filename = this.getUniqueMediaName('video') + '.mp4'
                            let mediaObject: mediaObject = {
                                name: filename,
                                thumbnail: file.thumbnail,
                                caption: file.caption,
                                type: 'video',
                                imageUrl: '',
                                videoUrl: res,
                                pdfUrl: '',
                                audioUrl: '',
                                dataToBeUploaded: vid,
                                imagePiiEditHistory: [],
                                orientation: file.orientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1

                            }
                            this.uploadEditMediaArray.push(mediaObject)
                        })
                        )
                    }))
                }
                else if (file.type == "image") {
                    await (fetch(res).then(imgRes => imgRes.blob()).then(async bres => {
                        (await this.processAttachedImageForDisplayAndUpoad(bres).then((img: any) => {
                            const fileName = this.getUniqueMediaName('image') + '.jpg'
                            let mediaObject: mediaObject = {
                                name: fileName,
                                thumbnail: fileName,
                                caption: file.caption,
                                type: 'image',
                                imageUrl: res,
                                videoUrl: '',
                                pdfUrl: '',
                                audioUrl: '',
                                dataToBeUploaded: img,
                                imagePiiEditHistory: [res],
                                orientation: file.orientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1
                            }
                            this.uploadEditMediaArray.push(mediaObject)
                        }))
                    }))
                }
                else if (file.type == "pdf") {
                    await (fetch(res).then(pdfRes => pdfRes.blob()).then(async bres => {
                        this.processAttachedPdfForDisplayAndUpoad(bres).then((res: Blob) => {
                            let pdfUrl = 'assets/images/defaultPdfImage.png'
                            // console.log("🚀 ~ file: media.service.ts ~ line 243 ~ MediaService ~ attachMediaFromWeb ~ pdfUrl", pdfUrl)
                            let orientation;
                            // this.getImageOrientation(pdfUrl).then(orient => {
                            this.blobToBase64(res).then((base64String: string) => {
                                orientation = "P";
                                const fileName = this.getUniqueMediaName('pdf') + '.pdf'
                                let mediaObject: mediaObject = {
                                    name: fileName,
                                    thumbnail: '',
                                    caption: file.caption,
                                    type: 'pdf',
                                    imageUrl: '',
                                    videoUrl: '',
                                    pdfUrl: base64String,
                                    // pdfUrl: 'assets/images/defaultPdfImage.png',
                                    audioUrl: '',
                                    dataToBeUploaded: res,
                                    imagePiiEditHistory: [],
                                    orientation: orientation,
                                    uploadPercent: '',
                                    piiUploadStatus: false,
                                    offlineAddress: "",
                                    zoom: 1,
                                    // crudAction : 'C'
                                }
                                this.uploadEditMediaArray.push(mediaObject)
                            })
                        })
                    }))
                }
                else if (file.type == "audio") {
                    await (fetch(res).then(audRes => audRes.blob()).then(async bres => {
                        let orientation
                        this.processAttachedAudioForDisplayAndUpoad(bres).then((res: Blob) => {
                            let audioUrl = URL.createObjectURL(bres);
                            this.getImageOrientation('assets/images/audioFile.png').then((orient) => {
                                orientation = orient;
                                const fileName = this.getUniqueMediaName('audio') + '.mp3'
                                let mediaObject: mediaObject = {
                                    name: fileName,
                                    thumbnail: '',
                                    caption: file.caption,
                                    type: 'audio',
                                    imageUrl: '',
                                    videoUrl: '',
                                    pdfUrl: '',
                                    audioUrl: audioUrl,
                                    dataToBeUploaded: res,
                                    imagePiiEditHistory: [],
                                    orientation: orientation,
                                    uploadPercent: '',
                                    piiUploadStatus: false,
                                    offlineAddress: "",
                                    zoom: 1
                                    // crudAction : 'C'
                                }
                                this.uploadEditMediaArray.push(mediaObject)
                            })
                        })
                    }))
                }
            })
        }
    }

    public async attachMediaFromWeb(event, isChatMedia?, chatRoomId?) {
        console.log("🚀 ~ file: media.service.ts ~ line 104 ~ MediaService ~ attachMediaFromWeb ~ event", event)

        // console.log("upload event", event);
        let URL = window.URL || window.webkitURL


        if (isChatMedia) {
            let file = event.target.files[0];
            console.log("🚀 ~ file: media.service.ts ~ line 120 ~ MediaService ~ attachMediaFromWeb ~ file", file)
            this.uploadEditMediaArray = [];

            if (file.type == "video/mp4" || file.type == "video/quicktime") {
                // console.log("inside video tag");

                this.processAttachedVideoForDisplayAndUpoad(file).then((res: Blob) => {

                    let videoPlayUrl = URL.createObjectURL(file)
                    this.getVideoDimensionsOf(videoPlayUrl).then((videoOrientation: string) => {
                        // console.log("video res", videoOrientation)
                        const fileName = this.getUniqueMediaName('video')
                        let mediaObject: mediaObject = {
                            name: fileName + '.mp4',
                            thumbnail: fileName + '.jpg',
                            caption: '',
                            type: 'video',
                            imageUrl: '',
                            videoUrl: videoPlayUrl,
                            pdfUrl: '',
                            audioUrl: '',
                            dataToBeUploaded: res,
                            imagePiiEditHistory: [],
                            orientation: videoOrientation,
                            uploadPercent: '',
                            piiUploadStatus: false,
                            offlineAddress: "",
                            zoom: 1
                            //  crudAction:'C'
                        }
                        this.uploadEditMediaArray.push(mediaObject)
                        // this.router.navigate(['/image-pii'], { state: { isChatMedia: true, chatRoomId: chatRoomId } });
                        this.router.navigate(['/image-pii', true, { chatRoomId: chatRoomId }]);
                    })
                }, (err) => {
                    // console.log("something went wrong while reading this video.", err);

                })
            }
            else if (file.type.includes("image")) {
                if (((file.type).toLowerCase()).includes("webp")) {
                    alert(`${file.name} is invalid \r\n File format ${file.type} is not supported`);
                    return false;
                }
                this.processAttachedImageForDisplayAndUpoad(file).then((res: Blob) => {
                    let imgUrl = URL.createObjectURL(file);
                    let orientation
                    this.getImageOrientation(imgUrl).then(orient => {
                        orientation = orient;
                        const fileName = this.getUniqueMediaName('image') + '.jpg'
                        let mediaObject: mediaObject = {
                            name: fileName,
                            thumbnail: fileName,
                            caption: '',
                            type: 'image',
                            imageUrl: imgUrl,
                            videoUrl: '',
                            pdfUrl: '',
                            audioUrl: '',
                            dataToBeUploaded: res,
                            imagePiiEditHistory: [imgUrl],
                            orientation: orientation,
                            uploadPercent: '',
                            piiUploadStatus: false,
                            offlineAddress: "",
                            zoom: 1
                            // crudAction : 'C'
                        }
                        this.uploadEditMediaArray.push(mediaObject)
                        // this.router.navigate(['/image-pii'], { state: { isChatMedia: true, chatRoomId: chatRoomId } });
                        this.router.navigate(['/image-pii', true, { chatRoomId: chatRoomId }]);
                    })
                }, (err) => {
                    // console.log("something went wrong while reading this image.", err);

                })


            }
            else if (file.type.includes("pdf")) {
                this.processAttachedPdfForDisplayAndUpoad(file).then((res: Blob) => {

                    let pdfUrl = 'assets/images/defaultPdfImage.png'
                    // console.log("🚀 ~ file: media.service.ts ~ line 243 ~ MediaService ~ attachMediaFromWeb ~ pdfUrl", pdfUrl)
                    let orientation;
                    // this.getImageOrientation(pdfUrl).then(orient => {
                    this.blobToBase64(res).then((base64String: string) => {

                        orientation = "P";
                        const fileName = this.getUniqueMediaName('pdf') + '.pdf'
                        let mediaObject: mediaObject = {
                            name: fileName,
                            thumbnail: '',
                            caption: '',
                            type: 'pdf',
                            imageUrl: '',
                            videoUrl: '',
                            pdfUrl: base64String,
                            // pdfUrl: 'assets/images/defaultPdfImage.png',
                            audioUrl: '',
                            dataToBeUploaded: res,
                            imagePiiEditHistory: [],
                            orientation: orientation,
                            uploadPercent: '',
                            piiUploadStatus: false,
                            offlineAddress: "",
                            zoom: 1
                            // crudAction : 'C'
                        }
                        this.uploadEditMediaArray.push(mediaObject)
                        // this.router.navigate(['/image-pii'], { state: { isChatMedia: true, chatRoomId: chatRoomId } });
                        this.router.navigate(['/image-pii', true, { chatRoomId: chatRoomId }]);
                    })
                })
            }
            else if (file.type.includes("audio")) {
                let orientation
                this.processAttachedAudioForDisplayAndUpoad(file).then((res: Blob) => {
                    let audioUrl = URL.createObjectURL(file);
                    this.getImageOrientation('assets/images/audioFile.png').then((orient) => {
                        orientation = orient;
                        const fileName = this.getUniqueMediaName('audio') + '.mp3'
                        let mediaObject: mediaObject = {
                            name: fileName,
                            thumbnail: '',
                            caption: '',
                            type: 'audio',
                            imageUrl: '',
                            videoUrl: '',
                            pdfUrl: '',
                            audioUrl: audioUrl,
                            dataToBeUploaded: res,
                            imagePiiEditHistory: [],
                            orientation: orientation,
                            uploadPercent: '',
                            piiUploadStatus: false,
                            offlineAddress: "",
                            zoom: 1
                            // crudAction : 'C'
                        }
                        this.uploadEditMediaArray.push(mediaObject)
                        // this.router.navigate(['/image-pii'], { state: { isChatMedia: isChatMedia, chatRoomId: chatRoomId } });
                        this.router.navigate(['/image-pii', true, { chatRoomId: chatRoomId }]);
                    })
                })
            }
            else {
                alert(`${file.name} is invalid \r\n File format ${file.type} is not supported`)
            }
        } else {
            for (let file of event.target.files) {
                //   if(file.type.includes("video")){
                if (file.type == "video/mp4" || file.type == "video/quicktime") {
                    this.processAttachedVideoForDisplayAndUpoad(file).then((res: Blob) => {

                        let videoPlayUrl = URL.createObjectURL(file)
                        this.getVideoDimensionsOf(videoPlayUrl).then((videoOrientation: string) => {
                            // console.log("video res", videoOrientation)
                            const fileName = this.getUniqueMediaName('video')
                            let mediaObject: mediaObject = {
                                name: fileName + '.mp4',
                                thumbnail: fileName + '.jpg',
                                caption: '',
                                type: 'video',
                                imageUrl: '',
                                videoUrl: videoPlayUrl,
                                pdfUrl: '',
                                audioUrl: '',
                                dataToBeUploaded: res,
                                imagePiiEditHistory: [],
                                orientation: videoOrientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1
                                //  crudAction:'C'
                            }
                            this.uploadEditMediaArray.push(mediaObject)
                            // this.router.navigate(['/image-pii'], { state: { isChatMedia: false } });
                            this.router.navigate(['/image-pii', false]);
                        })
                    }, (err) => {
                        // console.log("something went wrong while reading this video.", err);

                    })
                }
                else if (file.type.includes("image")) {
                    if (((file.type).toLowerCase()).includes("webp")) {
                        alert(`${file.name} is invalid \r\n File format ${file.type} is not supported`);
                        return false;
                    }
                    this.processAttachedImageForDisplayAndUpoad(file).then((res: Blob) => {
                        let imgUrl = URL.createObjectURL(file);
                        let orientation
                        this.getImageOrientation(imgUrl).then(orient => {
                            orientation = orient;
                            const fileName = this.getUniqueMediaName('image') + '.jpg'
                            let mediaObject: mediaObject = {
                                name: fileName,
                                thumbnail: fileName,
                                caption: '',
                                type: 'image',
                                imageUrl: imgUrl,
                                videoUrl: '',
                                pdfUrl: '',
                                audioUrl: '',
                                dataToBeUploaded: res,
                                imagePiiEditHistory: [imgUrl],
                                orientation: orientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1
                                // crudAction : 'C'
                            }
                            this.uploadEditMediaArray.push(mediaObject)
                            // this.router.navigate(['/image-pii'], { state: { isChatMedia: false } });
                            this.router.navigate(['/image-pii', false]);
                        })
                    }, (err) => {
                        // console.log("something went wrong while reading this image.", err);

                    })
                }
                else if (file.type.includes("pdf")) {
                    this.processAttachedPdfForDisplayAndUpoad(file).then((res: Blob) => {

                        let pdfUrl = 'assets/images/defaultPdfImage.png'
                        // let pdfUrlbase64 = this.blobToBase64()
                        // console.log("🚀 ~ file: media.service.ts ~ line 243 ~ MediaService ~ attachMediaFromWeb ~ pdfUrl", pdfUrl)
                        let orientation;
                        this.blobToBase64(res).then((base64String: string) => {

                            // this.getImageOrientation(pdfUrl).then(orient => {
                            orientation = "P";
                            const fileName = this.getUniqueMediaName('pdf') + '.pdf'
                            let mediaObject: mediaObject = {
                                name: fileName,
                                thumbnail: '',
                                caption: '',
                                type: 'pdf',
                                imageUrl: '',
                                videoUrl: '',
                                // pdfUrl: 'assets/images/defaultPdfImage.png',
                                pdfUrl: base64String,
                                audioUrl: '',
                                dataToBeUploaded: res,
                                imagePiiEditHistory: [],
                                orientation: orientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1
                                // crudAction : 'C'
                            }
                            this.uploadEditMediaArray.push(mediaObject)
                            // this.router.navigate(['/image-pii'], { state: { isChatMedia: false } });
                            this.router.navigate(['/image-pii', false]);
                        })
                    })
                }
                else if (file.type.includes("audio")) {
                    let orientation
                    this.processAttachedAudioForDisplayAndUpoad(file).then((res: Blob) => {
                        let audioUrl = URL.createObjectURL(file);
                        this.getImageOrientation('assets/images/audioFile.png').then((orient) => {
                            orientation = orient;
                            const fileName = this.getUniqueMediaName('audio') + '.mp3'
                            let mediaObject: mediaObject = {
                                name: fileName,
                                thumbnail: '',
                                caption: '',
                                type: 'audio',
                                imageUrl: '',
                                videoUrl: '',
                                pdfUrl: '',
                                audioUrl: audioUrl,
                                dataToBeUploaded: res,
                                imagePiiEditHistory: [],
                                orientation: orientation,
                                uploadPercent: '',
                                piiUploadStatus: false,
                                offlineAddress: "",
                                zoom: 1
                                // crudAction : 'C'
                            }
                            this.uploadEditMediaArray.push(mediaObject)
                            // this.router.navigate(['/image-pii'], { state: { isChatMedia: isChatMedia } });
                            this.router.navigate(['/image-pii', false]);
                        })
                    })
                }
                else {
                    alert(`${file.name
                        } is invalid \r\n File format ${file.type} is not supported`)
                }
            }
        }
    }


    async writeMediaToDiskInOfflineMode() {
        return new Promise(async (resolve, reject) => {
            console.log("write media to disk inside promise");

            this.uploadEditMediaArray.forEach(async (element, index) => {
                switch (element.type) {
                    case 'image':
                        element.offlineAddress = element.imageUrl
                        //1. convert image to base64
                        // const imageInBase64 = await this.convertAttachedImageToBase64(element.dataToBeUploaded).then(res=>res)
                        // //2. write base64format of image to disk
                        // console.log("test the image conversion", imageInBase64);
                        // await Filesystem.writeFile({
                        //     path: 'drafts/media' +element.name +'.txt',
                        //     data: imageInBase64.toString(),
                        //     directory: Directory.Data,
                        //   }).then((res)=>{
                        //       //3. update the drafts path in the offlineAddress property of the image object
                        //       element.offlineAddress =  'drafts/media' +element.name +'.txt'
                        //       console.log("write image response", res);

                        //   }).catch((err)=>{
                        //       console.log("write file error", err);

                        //   })


                        break;

                    case 'audio':
                        element.offlineAddress = element.audioUrl
                        break;

                    case 'video':
                        element.offlineAddress = element.videoUrl
                        break;

                    case 'pdf':
                        element.offlineAddress = element.pdfUrl
                        break;

                    default:
                        break;
                }


                const mediaArrayBuffer = await element.dataToBeUploaded.arrayBuffer()
                console.log("show me media array buffer");

                element.dataToBeUploaded = Array.from(new Uint8Array(mediaArrayBuffer))
                // element.dataToBeUploaded = mediaArrayBuffer  

                if (index == this.uploadEditMediaArray.length - 1) {

                    resolve(this.uploadEditMediaArray)

                    this.uploadEditMediaArray = []

                }

            });


        }).catch((err) => {
            console.log("error in writeMediaToDiskInOfflineMode", err);

        })
    }

    public attachProfilePicture(file) {
        // console.log("🚀 ~ file: media.service.ts ~ line 280 ~ MediaService ~ attachProfilePicture ~ file", file)
        // this.convertAttachedImageToBase64(file);

    }





    processAttachedVideoForDisplayAndUpoad(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: 'video/mp4' });
                // console.log("reader result for video", reader.result);
                resolve(blob)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(file)
        })
    }




    processAttachedImageForDisplayAndUpoad(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: 'image/jpg' });
                // console.log("reader result for image", reader.result);
                resolve(blob)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(file)
        })
    }

    processAttachedPdfForDisplayAndUpoad(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: 'application/pdf' });
                // console.log("reader result for image", reader.result);
                resolve(blob)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(file)
        })
    }

    processAttachedAudioForDisplayAndUpoad(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: 'audio/mpeg' });
                // console.log("reader result for image", reader.result);
                resolve(blob)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(file)
        })
    }

    async convertAttachedImageToBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = function () {
                // console.log("reader result", reader.result);
                resolve(reader.result)
            }
            reader.onerror = reject

            reader.readAsDataURL(file)
        })


    }





    public async presentMediaActionSheetForCasePostPage() {
        const actionSheet = await this.actionSheetController.create({
            buttons: [{
                text: 'Capture Image',
                icon: 'camera-outline',
                handler: () => {
                    this.triggerMediaFunctions('capture-image')
                }
            }
            ]
        })
        await actionSheet.present()
    }


    public async triggerMediaFunctions(action) {
        switch (action) {
            case 'capture-image':
                this.captureImage()
                break;

            case 'attach':
                this.attachImagesFromGallery()
                break;
            default:
                break;
        }
    }
    public async captureImage() {

        const image = await Camera.getPhoto({
            quality: 100,
            allowEditing: false,
            correctOrientation: true,
            resultType: CameraResultType.Base64
        })

        let orientation
        this.getImageOrientation(`data: image / jpeg; base64, ${image.base64String} `).then(res => {
            orientation = res;
            const fileName = this.getUniqueMediaName('image') + '.jpg'
            //update local array
            let mediaObject: mediaObject = {

                name: fileName,
                thumbnail: fileName,
                caption: '',
                type: 'image',
                imageUrl: `data: image / jpeg; base64, ${image.base64String} `,
                dataToBeUploaded: `data: image / jpeg; base64, ${image.base64String} `,
                pdfUrl: '',
                videoUrl: '',
                audioUrl: '',
                imagePiiEditHistory: [],
                orientation: orientation,
                uploadPercent: '',
                piiUploadStatus: false,
                offlineAddress: "",
                zoom: 1
            }
            this.uploadEditMediaArray.push(mediaObject)
            this.router.navigate(['/image-pii']);
            // console.log("show media array", this.uploadEditMediaArray);
        })



        return
    }


    public async attachImagesFromGallery() {
        Filesystem.readFile({ path: '', directory: Directory.Data }).then(res => {
            // console.log("read file data", res);

        })
    }

    public checkForPiiInImageApi(filename) {
        return this.http.post(this.baseServerUrl + '/pii-check', { filename: filename, action: 'imagepii' }).pipe(catchError((err) => {
            console.log("error in pii check api", err);
            throw err
        }));
    }




    getUniqueMediaName(typeOfMedia) {
        let mediaName: String = ''
        mediaName = this.storageService.getStorage('currentUserId') + '-' + typeOfMedia + '-' + new Date().getTime()

        return mediaName
    }

    async openFileLoader() {
        this.fileLoader = await this.modalCtrl.create({
            component: FileLoaderWindowComponent,
            cssClass: 'file-loader-modal',
            backdropDismiss: false,
        });
        this.fileLoader.present();
    }


    writeFileToTempDisk() {
        return new Promise((reject, resolve) => {
            this.uploadEditMediaArray.forEach(element => {

            });
        })
    }

    /**
     * 
     * @param typeOfMedia 
     * @returns 
     */


    public processUploadMediaToS3(typeOfMedia) {
        return new Promise(async (resolve, reject) => {
            // this.openFileLoader();
            for (let index = 0; index < this.uploadEditMediaArray.length; index++) {
                this.loadingMessage = 'Uploading ' + (index + 1) + ' of' + this.uploadEditMediaArray.length;

                (await this.requestMediUploadUrl(this.uploadEditMediaArray[index].name, typeOfMedia)).subscribe(async (res) => {
                    let toBeUploaded: any;
                    (await this.postMediaToS3Bucket(res, this.uploadEditMediaArray[index].dataToBeUploaded)).subscribe(res => {
                        if (res.type == 1 && this.uploadEditMediaArray[index] != undefined) {
                            let percent = (res?.loaded / res?.total);
                            this.uploadEditMediaArray[index].uploadPercent = lodash.round(percent, 1);
                        }
                        if (this.checkIfUploadCompleted(this.uploadEditMediaArray)) { //only resolve when all the media items get uploaded to s3
                            setTimeout(() => {
                                delete this.uploadEditMediaArray[index].imageUrl
                                delete this.uploadEditMediaArray[index].videoUrl
                                delete this.uploadEditMediaArray[index].pdfUrl
                                delete this.uploadEditMediaArray[index].dataToBeUploaded
                                delete this.uploadEditMediaArray[index].imagePiiEditHistory
                                delete this.uploadEditMediaArray[index].uploadPercent
                                delete this.uploadEditMediaArray[index].piiUploadStatus
                                this.dismissModal();
                                resolve(this.uploadEditMediaArray)
                            }, 1000);
                        }
                    }, (err) => {
                        // console.log("this is error post media", err);

                        reject(err)
                    })
                }, (err) => {
                    // console.log("this is error request media upload url", err);
                    reject(err)
                })
            }
        })
    }


    public processUploadMediaToS3FromOutbox(mediaArray) {
        return new Promise(async (resolve, reject) => {
            // this.openFileLoader();
            console.log("step 1 process upload media");

            for (let index = 0; index < mediaArray.length; index++) {
                //this.loadingMessage = 'Uploading ' + (index + 1) + ' of' + this.uploadEditMediaArray.length;

                (await this.requestMediUploadUrl(mediaArray[index].name, 'forUpload')).subscribe(async (res) => {
                    let toBeUploaded: any;
                    console.log("step 2 request media upload url");
                    (await this.postMediaToS3Bucket(res, mediaArray[index].dataToBeUploaded)).subscribe(res => {
                        console.log("step 3 post media to s3 bucket");
                        if (res.type == 1 && mediaArray[index] != undefined) {
                            console.log("step 3.1 inside post to s3 response");

                            let percent = (res?.loaded / res?.total);
                            mediaArray[index].uploadPercent = lodash.round(percent, 1); //round off the uplaod percent value to one dec point ex= 0.6565785 --> 0.6
                        }
                        if (this.checkIfUploadCompleted(mediaArray)) { //return true only  when all the media items get uploaded to s3
                            setTimeout(() => {
                                delete mediaArray[index].imageUrl
                                delete mediaArray[index].videoUrl
                                delete mediaArray[index].pdfUrl
                                delete mediaArray[index].dataToBeUploaded
                                delete mediaArray[index].imagePiiEditHistory
                                delete mediaArray[index].uploadPercent
                                delete mediaArray[index].piiUploadStatus
                                console.log(" step 4 before resolve in upload media");

                                resolve(mediaArray)

                            }, 1000);
                        }
                    }, (err) => {
                        console.log("this is error post media", err);

                        reject(err)
                    })
                }, (err) => {
                    console.log("this is error request media upload url", err);
                    reject(err)
                })
            }
        })
    }


    public checkIfUploadCompleted(mediaArray) {
        let counter = 0;
        for (let i = 0; i < mediaArray.length; i++) {
            //Once file has upload percent to 1, that means file is uploaded and increase the counter value
            if (mediaArray[i].uploadPercent == 1) ++counter;
        }
        //Once the counter value reaches to media array length that mean all the files are uploaded and return true
        return counter == mediaArray.length;
    }


    // public checkIfOutboxMediaUploadComplete() {
    //     let counter = 0;
    //     for (let i = 0; i < this.uploadEditMediaArray.length; i++) {
    //         //Once file has upload percent to 1, that means file is uploaded and increase the counter value
    //         if (this.uploadEditMediaArray[i].uploadPercent == 1) ++counter;
    //     }
    //     //Once the counter value reaches to media array length that mean all the files are uploaded and return true
    //     return counter == this.uploadEditMediaArray.length;
    // }

    public dismissModal() {
        this.fileLoader.dismiss()
        //this.modalCtrl.dismiss();
    }


    public async requestMediUploadUrl(mediaName, action) {
        let mediaMime = sessionStorage.getItem('mediaMime');
        return this.http.get(this.baseServerUrl + '/media?action=' + action + '&mediaName=' + mediaName + '&mediaMime='+mediaMime).pipe(map((res: any) => {
            // console.log("request media upload url", res);
            return res.data
        }))
    }

    public async postMediaToS3Bucket(preSignedUrl, mediaObject) {
        console.log("in this upload", preSignedUrl, mediaObject);

        return this.http.put(preSignedUrl, mediaObject, { reportProgress: true, observe: "events" }).pipe(map((res: any) => {
            console.log("🚀 ~ file: media.service.ts:855 ~ returnthis.http.put ~ res", res)
            // console.log("put response", res);
            return res
        }),
            catchError((err) =>

                this.catchErrorFunction('postMediaToS3Bucket', err)

            )
        )
    }

    catchErrorFunction(methodName, err) {
        //logging here
        // console.log("error in method " + err);
        console.log("show me the error", err);

        return err
    }


    public async uploadProfilePicture() {
        const image = await Camera.getPhoto({
            quality: 100,
            allowEditing: false,
            correctOrientation: true,
            resultType: CameraResultType.Base64
        })

        return image.base64String;
    }

    /**
     * @description: detect orientation of image, Function promisified to make synchronous for execution of 'onload()' method
     * @param imgURL: Image URL
     * @returns: "L" for landscape and "P" for Portrait/Square
     */
    getImageOrientation(imgURL) {
        // console.log("image orientation called", imgURL)
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = (res) => {
                // console.log("verify image res", res);
                if (!res || res == null || res == undefined) reject();
                // console.log("video test", res.currentTarget["width"]);

                resolve(res.currentTarget["width"] > res.currentTarget["height"] ? 'L' : 'P');
            }
            // img.onerror=reject
            img.src = imgURL;
        })
    }


    /**
  Returns the dimensions of a video asynchrounsly.
  @param {String} url Url of the video to get dimensions from.
  @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
  */
    getVideoDimensionsOf(url) {
        return new Promise(resolve => {
            // create the video element
            const video = document.createElement('video');

            // place a listener on it
            video.addEventListener("loadedmetadata", function () {
                // retrieve dimensions
                const height = this.videoHeight;
                const width = this.videoWidth;
                // console.log("video configurations", height, width);
                // send back result
                resolve(width > height ? 'L' : 'P');
            }, false);

            // start download meta-datas
            video.src = url;
        });
    }

    addAudioToMediaArray(name, src, dataToUpload, isChatMedia, chatRoomId) {
        let orientation
        this.getImageOrientation('assets/images/audioFile.png').then((res) => {
            console.log("🚀 ~ file: media.service.ts ~ line 653 ~ MediaService ~ this.getImageOrientation ~ res", res)
            orientation = res;
            let mediaObject: mediaObject = {
                name: name,
                thumbnail: '',
                caption: '',
                type: 'audio',
                imageUrl: '',
                videoUrl: '',
                pdfUrl: '',
                audioUrl: src,
                dataToBeUploaded: dataToUpload,
                imagePiiEditHistory: [],
                orientation: orientation,
                uploadPercent: '',
                piiUploadStatus: false,
                offlineAddress: "",
                zoom: 1
                // crudAction : 'C'
            }
            this.uploadEditMediaArray.push(mediaObject)
            // this.router.navigate(['/image-pii'], { state: { isChatMedia: isChatMedia, chatRoomId: chatRoomId } });
            this.router.navigate(['/image-pii', isChatMedia, { chatRoomId: chatRoomId }]);
        });
    }


    private gettrigerAudioActions = new Subject<any>();
    gettrigerAudioActions_trigge$ = this.gettrigerAudioActions.asObservable();

    callgettrigerAudioActions(data) {
        this.gettrigerAudioActions.next(data);
    }

    blobToBase64(blob) {

        return new Promise((resolve, reject) => {
            // let pdfvalue =  (<any>window).URL.createObjectURL(blob)
            // resolve(pdfvalue);

            const reader = new FileReader;
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);

        });
    }
}
