
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import ApiInterceptor from './services/api-interceptor/api-interceptor.service';
import * as Raven from 'raven-js';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        Raven.captureException(err);
    }
}


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [

        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,

    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor, multi: true
        }],
        schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule { }
