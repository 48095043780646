import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';

@Injectable({
    providedIn: 'root'
})
export class VideoStoryService {

    public videos = [];
    private VIDEOS_KEY: string = 'videos';

    constructor() { }

    async loadVides() {
        const videoList = await localStorage.getItem(this.VIDEOS_KEY);
        this.videos = JSON.parse(videoList) || [];
        return this.videos;
    }

    async storeVideo(blob) {
        const fileName = new Date().getTime() + '.mp4';
        const base64Data = await this.convertBlobToBase64(blob) as string;

        const savedFile = await Filesystem.writeFile({
            path: fileName,
            directory: Directory.Data,
            data: blob,
        })

        this.videos.unshift(savedFile.uri);
        console.log('my array now: ', this.videos);

        return localStorage.setItem(this.VIDEOS_KEY, JSON.stringify(this.videos))
    }

    // Healper function
    private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    })

    async getVideoUrl(fullPath) {
        const path = fullPath.substr(fullPath.lastIndexOf('/') + 1);
        const file = await Filesystem.readFile({
            path: path,
            directory: Directory.Data
        });

        return `data:video/mp4;base64,${file.data}`;
    }
}
