import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { MediaService } from 'src/app/services/media/media.service';
import { log } from 'console';
@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.page.html',
    styleUrls: ['./profile-picture.page.scss'],
})
export class ProfilePicturePage implements OnInit {

    croppedImage = null;
    myImage = null;
    data: any;
    currentUserId: any;
    modalData: any;
    cropperFor: any;
    imageName: string;
    uploadURLToS3: any;
    displayURL: string;
    image;
    profileType;

    constructor(
        public router: Router,
        public userService: UserService,
        public storageService: StorageService,
        public location: Location,
        public modalController: ModalController,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService,
        public mediaService: MediaService
    ) {
        this.loggingService.fetchGlobalConstants();
        // const modalElement = document.querySelector('ion-modal');
        // this.modalData = modalElement.componentProps;
        this.currentUserId = this.storageService.getStorage('currentUserId');
        // this.cropperFor = this.modalData.profileType;
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ProfilePicturePage');
        // this.getImage(this.modalData.image);
        this.cropperFor = this.profileType;
        this.getImage(this.image);
    }

    getImage(imageData) {
        // let imageData = this.data.profilePicture;
        this.myImage = imageData;
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = await this.convertbase64ToBlob(event.base64).then((res) => res);
        this.imageName = `${this.mediaService.getUniqueMediaName('user-image')}.jpg`;
        this.displayURL = this.createDisplayURLFromBlob(this.croppedImage);

        // request for media upload URL to S3 bucket
        this.mediaService.requestMediUploadUrl(this.imageName, 'forUpload').then((uploadURLToS3) => {
            uploadURLToS3.subscribe((res) => {
                this.uploadURLToS3 = res;
            });
        });
    }

    async convertbase64ToBlob(image) {
        return new Promise((resolve, reject) => {

            fetch(image).then(ret => {
                ret.blob().then(blob => {
                    resolve(new Blob([blob], { type: 'image/jpg' }));
                })
            })
        });


        // let binaryData = Buffer.from(image)

        // return new Blob([binaryData], { type: 'image/jpg' });
    }

    createDisplayURLFromBlob(blob) {
        return URL.createObjectURL(blob);
    }

    removeProfilePicture() {
        this.croppedImage = "";
    }

    async saveProfilePicture() {
        this.mediaService.postMediaToS3Bucket(this.uploadURLToS3, this.croppedImage).then((res) => {
            res.subscribe((res) => {
                console.log("🚀 ~ file: profile-picture.page.ts:76 ~ ProfilePicturePage ~ this.mediaService.postMediaToS3Bucket ~ res", res)
            })
        })
        this.dismissModal(this.imageName);
    }

    dismissModal(photo?) {
        if (photo) this.modalController.dismiss({ photo: photo, displayURL: this.displayURL });
        else this.modalController.dismiss();
    }

}
