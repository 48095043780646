import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { SocketService } from '../socket/socket.service';
import { FCM } from '@capacitor-community/fcm';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(public socketService: SocketService) { }
 
  public initializePushNotifService(): Promise<string> {

    return new Promise((resolve, reject) => {

      this.registerPush(resolve, reject);

    });

  }
 
  private registerPush(resolve: (token: string) => void, reject: (error: any) => void) {

    PushNotifications.requestPermissions().then((permission) => {

      if (permission.receive === 'granted') {

        PushNotifications.register();

      } else {

        reject('Notification permission not granted');

      }

    }).catch(reject);
 
    // Listen when the token is registered

    PushNotifications.addListener('registration', (token: Token) => {

      FCM.getToken().then((result) => {

        this.socketService.currentUserDeviceId = result.token;

        resolve(result.token); // Resolve the promise with the token

      }).catch(reject);

    });
 
    // Handle registration errors

    PushNotifications.addListener('registrationError', (error: any) => {

      reject('Error on registration: ' + JSON.stringify(error));

    });

  }

}

 
// export class FcmService {

//   constructor(public socketService : SocketService) { }

//   public async initializePushNotifService(){
//      await this.registerPush()
//   }

//   private async registerPush(){
//     PushNotifications.requestPermissions().then((permission)=>{
//      // alert("permission from user "+ permission)
//       if(permission.receive == 'granted'){
//       //  alert("permission granted "+ permission.receive)
//         PushNotifications.register()
//       }
//       else{
//        // alert("Either notifications permission is not granted or something went wrong " + permission)
//       }
//     })


//     //SETUP NOTIFICATIONS LISTENERS :: start ::

//     //listen when token is registered
//     PushNotifications.addListener('registration', (token: Token) => {
//       FCM.getToken().then((result) => {
//         console.log("Token in FCM Service: ", result.token); // This is token for IOS
//         // alert("FCM Token: "+result.token)
//         if (result.token != '') {
//           alert('Inside IF Result.token:'+result.token)
//           this.socketService.currentUserDeviceId = result.token

//         }

//       }).catch((err) => console.log('i am Error', err));
//     });

//     PushNotifications.addListener('registrationError', (error: any) => {
//     //  alert('Error on registration: ' + JSON.stringify(error));
//     });

//   }


  



  

// }
