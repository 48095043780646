import { Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserLogin } from '../../interfaces/user-login';
import { Observable, Subject, from, of, BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EncryptionService } from '../encryption/encryption.service';
import { StorageService } from '../storage/storage.service';
import { MaintenancePage } from '../../modules/welcome/maintenance/maintenance.page';
import { UserService } from '../user/user.service';
import { LoggingService } from '../logging/logging.service';
import { getContext } from 'raven-js';


export interface userInSession {
    email?: string,
    platform?: string,
    userId?: string,
    admin?: boolean,
    loginId?: string
}






export interface loggedInUserType {
    platform?: string,
    activated?: boolean,
    healthCareProfessional?: boolean,
    userId?: string

}


@Injectable({
    providedIn: 'root'
})


export class AuthenticationService {
    baseServerUrl: any = environment.API_URL
    platformType: any = '';
    version: any;
    apiAuthKey: any = environment.userAuthenticationApi;
    user: any;
    maintenanceAndVersionResponse: any;
    currentVerionOnThisDevice: any = environment.Version;
    AppName: any = environment.Appname;
    public userInSession: Subject<userInSession>
    constructor(
        private http: HttpClient,
        public platform: Platform,
        public encryptionService: EncryptionService,
        public storage: StorageService,
        public userService: UserService,
        public loggingService: LoggingService
    ) {
        this.platformType = sessionStorage.getItem('platform');
        this.loggingService.fetchGlobalConstants();
    }



    /**
     * 
     * @param logObject 
     * @returns Server response as Observable
     */
    userSignIn(logObject: UserLogin) {
        // let response: Subject<any>

        //const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.post(this.baseServerUrl + '/authentication', JSON.stringify(logObject)).pipe(tap((data) => {
            this.user = data;
            this.storage.setStorage("userEmail", this.user.userDetails.email);
            this.storage.setStorage("session", this.user.accessToken);
            this.storage.setStorage("loginId", this.user.userDetails.loginId);
            //	this.storage.setStorage("userObject", this.user.userDetails)
            this.storage.setStorage("currentUserId", this.user.userDetails._id)
            this.storage.setStorage("chatPageActive", 'false');
            this.storage.setStorage("signupProcessCompleted", this.user.signupProcessCompleted)
            this.storage.setStorage(
                'CurrentLoglevel',
                JSON.stringify({
                    logLevel: this.user.logLevel,
                    username: this.user,
                    _id: this.user.userDetails._id,
                    email: this.user.userDetails.email
                })
            );
            console.log("api login call res", this.user
            );

            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'userSignIn()', '');
        }), catchError((err) => {
            console.log("🚀 ~ file: authentication.service.ts:93 ~ AuthenticationService ~ returnthis.http.post ~ err", err)
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'userSignIn()', '', err)
            throw err;
        }
        ));
    }




    authenticate() {
        console.log("authenticate called");

        let credentials = {
            strategy: 'jwt',
            accessToken: this.storage.getStorage('session')
        }
        return this.http.post(this.baseServerUrl + '/authentication', JSON.stringify(credentials)).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'authenticate()', '');
            return res;
        }), catchError((err) => {
            return this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'authenticate()', '', err)

        })
        );

    }

    public checkUserStatus() {
        console.log("checkUserStatus called");

        let userStatusResponse = {
            status: false, //true if user login session is still valid
            error: false
        }
        return this.userService.getLoggedInUser().pipe(map((response: any) => {
            console.log("🚀 ~ file: authentication.service.ts ~ line 126 ~ AuthenticationService ~ returnthis.userService.getLoggedInUser ~ response", response)
            if (response.data[0]) {

                if (this.AppName != 'Albatross' && response.data[0].signupProcessCompleted == 'true') {
                    userStatusResponse.status = true;
                }
                // for Healable we are setting adminApproval field to true by default. 
                //  It can only be false in case of Albatross.
                //   Hence not checking for AppName == Albatroos and just checking for adminApproval field only.

                else if (this.AppName == 'Albatross') {
                    if (response.data[0].adminApproval == 'false' || response.data[0].adminApproval == "pending") {
                        // this.logout();
                        userStatusResponse.status = false;
                    }

                    else {
                        // this.logout();
                        userStatusResponse.status = true;
                    }
                }
                else {
                    // this.logout();
                    userStatusResponse.status = false;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'checkUserStatus()', '');
                return userStatusResponse;
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'checkUserStatus()', '');
                return userStatusResponse;
            }
        }), catchError((err) => {
            console.log("🚀 ~ file: authentication.service.ts ~ line 156 ~ AuthenticationService ~ returnthis.userService.getLoggedInUser ~ err", err)
            userStatusResponse.error = true;
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'checkUserStatus()', '', err)
            throw userStatusResponse;
        })
        );
    }


    /** ---------------- */

    /** :::::::::::::::::::::::::MAINTENANCE BLOCK START HU-6 Sarthak Bhatt 08/05/21 ::::::::::: */
    /**
    * ONLY WORKS FOR WEB APPLICATION
    * check for the maintenance flag in the db and based on it navigates to the maintenance page.
    * 
    * @returns Response Object : { maintenanceRequired : boolean , error: boolean, errorMessage: string, resObject: object}
    */
    checkForMaintenance() { // CHECK FOR MAINTENANCE ONLY FOR WEB
        let responseObject: any = {
            maintenanceRequired: false,
            error: false,
            errorMessage: "",
            resObject: {}
        }
        if (this.checkPlatform() == "web") {
            return this.http.get(this.baseServerUrl + '/maintenance').pipe(map((res: any) => {
                console.log("🚀 ~ file: authentication.service.ts:191 ~ AuthenticationService ~ returnthis.http.get ~ res:", res)
                //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
                if (!res || res == undefined || res == null || !res.data || res.data == undefined) {
                    responseObject.error = true
                    responseObject.errorMessage = "no or bad response from the backend. "
                }
                //STORE VERSION API RESPONSE IN A CONSTANT ATTACH IT IN THE RESPONSE OBJECT
                const maintResponse = responseObject.resObject = res.data[0]


                //Check if maintenance flag in the db response is "Y":yes, then set the value of maintenanceRequired flag in the response object to true.
                if (maintResponse.maintenanceFlag == "Y") {
                    responseObject.maintenanceRequired = true
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'checkForMaintenance()', 'Platform is web');
                return responseObject // RETURN STATEMENT OF MAP OPERATOR
            }), catchError((err) => {
                responseObject.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'checkForMaintenance()', '', err)
                throw responseObject;
            }))
        }
        else {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'checkForMaintenance()', 'Platform is mobile');
            return from([responseObject])
        }

    }

    /** :::::::::::::::::::::::::MAINTENANCE BLOCK END   HU-6 Sarthak Bhatt 08/05/21::::::::::: */

    /** ---------------- */

    /** :::::::::::::::::::::::::::::::::::APP VERSION BLOCK START HU-6 Sarthak Bhatt 08/05/21::::::::::::::::*/
    /**
     * ONLY WORKS IN IOS OR ANDROID DEVICES
     * check for newer version of the app and compares it with the current installed version of application.
     * based on it, it returns a reponse object.
     * 
     * @returns Response Object : { forceVersionUpgrade : boolean, newVersionAvailable: boolean , error: boolean, errorMessage: string, resObject: object}
     */
    getAppVersion() {  // Only for ios or android
        //RESPONSE OBJECT THAT WILL BE RETURNED
        let responseObject: any = {
            forceVersionUpgrade: false,
            newVersionAvailable: false,
            error: false,
            errorMessage: "",
            resObject: {}
        }

        if (this.checkPlatform() == "ios" || this.checkPlatform() == "android") {
            return this.http.get(this.baseServerUrl + '/version').pipe(map((res: any) => { //OBSERVABLE MAP OPERATOR
                //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
                if (!res || res == undefined || res == null || !res.data || res.data == undefined) {
                    responseObject.error = true
                    responseObject.errorMessage = "no or bad response from the backend. "
                }
                //STORE VERSION API RESPONSE IN A CONSTANT
                const verResponse = res.data[0]
                //ATTACH API REPONSE IN THE REPONSE OBJECT AS WELL.
                responseObject.resObject = verResponse
                //CHECK IF THE NEW VERSION IS AVAILABALE FOR THIS PARTICULAR PLATFORM : checkIfNewVersionIsAvailable() => THIS METHOD RETURNS TRUE IF THERE IS NEWER VERSION AVAILABLE, STORE THE OUTPUT IN THE REPONSEOBJECT.newVersionAvailable FLAG
                responseObject.newVersionAvailable = this.checkIfNewVersionIsAvailable(verResponse) // THIS METHOD RETURNS TRUE IF NEW VERSION IS AVAILABLE FOR THE DEVICE OR ELSE RETURNS FALSE
                //CHECK IF THE NEW VERSION IS AVAILABLE AND THE EXPIRY OF OLD VERSION IS ALREADY A PAST DATE. THIS IS BEING DONE IN THE TERNARY OPERATOR BELOW AND THE OUTPUT IS DIRECTLY BEEN UPDATED IN THE RESPONSE OBJECT
                responseObject.forceVersionUpgrade = ((this.checkIfNewVersionIsAvailable(verResponse) == true) && (verResponse.previousVersionExpiryDate) < new Date().toISOString()) ? true : false
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'version()', '');
                return responseObject // This return is of map operator. no need to typ
            }), catchError(err => {
                responseObject.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'version()', '', err)
                throw responseObject;
            }))
        }
        else {
            // this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'version()', '');
            return from([responseObject])
        }
    }

    getAppStoreLink() {
        return this.http.get(this.baseServerUrl + '/version').pipe(map((res: any) => res))
    }

    //HU-6 Sarthak Bhatt 08/05/21
    checkIfNewVersionIsAvailable(versionFromDB) {

        if (!versionFromDB || versionFromDB == null || versionFromDB == undefined) {
            return false
        }

        if (this.checkPlatform() == "ios" && versionFromDB.IOSVersion > this.currentVerionOnThisDevice) {
            return true
        }
        else if (this.checkPlatform() == "android" && versionFromDB.AndroidVersion > this.currentVerionOnThisDevice) {
            return true
        }
        else {
            return false
        }
    }

    //HU-6 Sarthak Bhatt 08/05/21

    checkPlatform() {
        // console.log("🚀 ~ file: authentication.service.ts:308 ~ AuthenticationService ~ checkPlatform ~ this.platform.platforms():", this.platform.platforms())

        // if (this.platform.platforms().some(v => v == "ios")) {
        //     return ("ios")
        // }
        // else if (this.platform.platforms().some(v => v == "android")) {
        //     return ("android")
        // }
        // else {
        //     return ("web")
        // }


        if (this.platform.platforms().includes('ios') && this.platform.platforms().includes('mobileweb')) return ("ioswebbrowser")
        else if (this.platform.platforms().includes('android') && this.platform.platforms().includes('mobileweb')) return ("androidwebbrowser")
        else if (this.platform.platforms().includes('ios')) return ("ios")
        else if (this.platform.platforms().includes('android')) return ("android")
        else return ("web")
    }


    public check(credentials) {
        let responseObject = {
            error: false,
            errorMessage: "",
            resObject: {}
        }
        return this.http
            .post(this.baseServerUrl + '/authentication', JSON.stringify(credentials))
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        responseObject.error = true;
                    }
                    else {
                        responseObject.error = false;
                        responseObject.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'authenticationService', 'check()', '');
                    return responseObject;
                }), catchError((err) => {
                    responseObject.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'authenticationService', 'check()', '', err)
                    throw responseObject;
                })
            );
    }

    checkForGlobalEmergencyAlert() {
        return this.http.get(this.baseServerUrl + '/globalEmergencyAlert').pipe(
            map((res: any) => {
                console.log("🚀 ~ file: authentication.service.ts:338 ~ AuthenticationService ~ map ~ res:", res)
                return res.data[0];
            }),
            catchError((err) => {
                console.log("🚀 ~ file: authentication.service.ts:341 ~ AuthenticationService ~ catchError ~ err:", err)
                throw new Error(err);
            }))
    }

}

/** :::::::::::::::::::::::::::::::::::APP VERSION BLOCK END HU-6 Sarthak Bhatt 08/05/21::::::::::::::::*/


// /**
//  * @description
//  * @returns '{
//  * maintenanceRequired: boolean,
//  * forceVersionUpgrade:boolean,
//  * newVersionAvailable:boolean,
//  * error: boolean
//  * }'
//  *
//  *
//  */
// checkForMaintenanceAndVersionUpdate() : Observable<any>{
//    //RESPONSE OBJECT THAT WILL BE RETURNED
//    let responseObject :any =  {
//     maintenanceRequired : false,
//     forceVersionUpgrade : false,
//     newVersionAvailable : false,
//     error: false
//   }

//   // CALL MAINTENANCE API
// return this.http.get(this.baseServerUrl + '/maintenance').pipe(map((res:any)=> {

//   //HANDLE NON-RESPONSE OR UNDEFINED ERROR CONDITION
//     if(!res || res ==  undefined || res == null || !res.data || res.data == undefined){
//       responseObject.error = true
//       throw 'no or undefined response error from maintenance'
//     }

//     const maintenanceResponse = res.data[0] //STORE MAINTENANCE API RESPONSE IN A CONSTANT

//     //CHECK IF THE PLATFORM IS IOS OR ANDROID AND MAINTENANCE IS NOT ON.
//     //IF MAINTENANCE IS ON THEN DO NOT CALL THE VERSION API AND UPDATE THE REPONSE OBJECT WITH
//     //MAINTENANCEREQUIRED = TRUE : THIS PART IS HANDLED IN ELSE
//     if(maintenanceResponse == "N" && this.checkPlatform() != "web"){
//       //CALL THE VERSION API AND STORE THE REPONSE IN A CONSTANT
//         this.getAppVersion().subscribe((versionResponse : any)=>{
//         const verResponse = versionResponse.data[0]
//         //CHECK IF THE NEW VERSION IS AVAILABALE FOR THIS PARTICULAR PLATFORM : checkIfNewVersionIsAvailable() => THIS METHOD RETURNS TRUE IF THERE IS NEWER VERSION AVAILABLE, STORE THE OUTPUT IN THE REPONSEOBJECT.newVersionAvailable FLAG
//         responseObject.newVersionAvailable = this.checkIfNewVersionIsAvailable(verResponse) // THIS METHOD RETURNS TRUE IF NEW VERSION IS AVAILABLE FOR THE DEVICE OR ELSE RETURNS FALSE

//         //CHECK IF THE NEW VERSION IS AVAILABLE AND THE EXPIRY OF OLD VERSION IS ALREADY A PAST DATE. THIS IS BEING DONE IN THE TERNARY OPERATOR BELOW AND THE OUTPUT IS DIRECTLY BEEN UPDATED IN THE RESPONSE OBJECT
//         responseObject.forceVersionUpgrade = ((this.checkIfNewVersionIsAvailable(verResponse) == true) && (verResponse.previousVersionExpiryDate) < new Date().toISOString()) ? true : false
//      },err => { //ERROR HANDLED FOR VERSION API, IN CASE OF FAILURE RETURN ALL THE FLAG IN RESPONSE OBJECT AS FALSE, SO THE REGULAR NAVIGATION CAN TAKE PLACE
//       responseObject.maintenanceRequired = false
//       responseObject.forceVersionUpgrade = false
//       responseObject.newVersionAvailable = false
//      })
//     }
//     //THIS IS THE ELSE OF MAINTENANCE CHECK, WHICH MEANS THE MAINTENANCE IS ON AND SET THE responseObject.maintenanceRequired FLAG TO TRUE
//     else{
//       responseObject.maintenanceRequired = true
//       responseObject.forceVersionUpgrade = false
//       responseObject.newVersionAvailable = false
//     }
//   // return res.responseObject
//   return responseObject // ALERT ::::: THIS IS THE RETURN STATEMENT OF THE MAP OPERATOR!!!!!!

//   }
//   ))

// }





// getPost(){
// 	try {
// 		// DEBUG
// 	} catch (error) {
// ERROR
// 	}
// 	finally(){
// INFO
// 	}
// }

// getContextFromBackEnd(){
// HttpClient.subscribe()=>{}

// }