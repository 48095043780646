import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { Location } from '@angular/common';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';


@Component({
    selector: 'app-about',
    templateUrl: './about.page.html',
    styleUrls: ['./about.page.scss'],
})
export class AboutPage implements OnInit {
    // comingFromPageName: any;
    @Input('comingFrom') comingFromPageName;
    pageName: any;
    AppName: any;
    pageFlag: any;
    loggingConstants: any = [];
    err: any;
    text: any;
    constructor(
        private http: HttpClient,
        public router: Router,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService,
        public location: Location,
        public route: ActivatedRoute,
        public modalCtrl: ModalController,
    ) {
        this.loggingService.fetchGlobalConstants();
    }

    ngOnInit() {

        console.log("Modal Values=================" , this.comingFromPageName)
        console.log("Modal Values=================" , this.pageFlag)
        this.userAnalyticsService.trackPagesHandler('AboutPage');
        this.AppName = environment.Appname;
        // let dataFromPreviousPage = this.router.getCurrentNavigation().extras.state;
        // this.comingFromPageName = dataFromPreviousPage.comingFrom;
        // this.pageFlag = dataFromPreviousPage.pageFlag;
        // this.comingFromPageName = this.route.snapshot.params['comingFrom'];
        // this.pageFlag = this.route.snapshot.params['pageFlag'];
        console.log("🚀 ~ file: about.page.ts:39 ~ AboutPage ~ ngOnInit ~ this.comingFromPageName:", this.comingFromPageName, this.pageFlag)
        if (this.comingFromPageName == 'signup' || this.comingFromPageName == 'settings') {
            this.pageName = 'Usage Policy';
            console.log("inside if", this.pageName);

        }
        else if (this.comingFromPageName == 'welcome') {
            this.pageName = 'About Us';
        }

        if (this.AppName != 'Albatross') {
            if (this.pageFlag == true) {
                this.http.get('assets/text/about-us.txt', { responseType: 'text' as 'json' }).subscribe((data) => {
                    console.log("🚀 ~ file: about.page.ts:53 ~ AboutPage ~ this.http.get ~ data:", data)
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'aboutPage', 'ngOnInit() -> about-us.txt', '');
                    this.text = data;
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'aboutPage', 'ngOnInit() -> about-us.txt', '', error);
                }));

            }
        } else if (this.AppName == 'Albatross') {
            if (this.pageFlag == 'true') {
                this.http.get('assets/text/AboutusAlbatross.txt', { responseType: 'text' as 'json' }).subscribe((data) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'aboutPage', 'ngOnInit() -> AboutusAlbatross.txt', '');
                    this.text = data;
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'aboutPage', 'ngOnInit() -> AboutusAlbatross.txt', '', error);
                }));
            } else {
                this.http.get('assets/text/UsagepolicyAlbatross.txt', { responseType: 'text' as 'json' }).subscribe((data) => {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'aboutPage', 'ngOnInit() -> UsagepolicyAlbatross.txt', '');
                    this.text = data;
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'aboutPage', 'ngOnInit() -> UsagepolicyAlbatross.txt', '', error);
                }));
            }
        }
    }

    goBack() {
        // this.location.back();
        this.modalCtrl.dismiss();
    }

}
