import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import introJs from 'intro.js';
import { UserService } from 'src/app/services/user/user.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AlertController, ModalController, IonContent } from '@ionic/angular';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TourGuideService {

    /*
    Ticket no - HL-199 new feature 
    Developer - Swapnali Gurav 
    
     * intro.js = https://introjs.com/docs/getting-started/install
     * version - 6.0.0
     * cmd = npm install intro.js@6.0.0
     * 
     * when tour guide get play ?
     * 1.signup new user 
     *  - for hcp after mandatory fileds filled 
     *  - for normal user after registration process
     * 2.login user on device firsttime 
     * 3.From setting-> App guide
     * 
     * App flow 
     * for sign up ->
     *  set shouldStartHomeTour=true pointer value to indicate start tour 
     *   for the hcp -> after mandatory profile fields completing ie. on the WorkExperiencePage or from the WallPage 
     *   for the general user -> on wallPage 
     * 
     * intro code flow 
     * first created json with start indicator step 
     * when intro.start() it will skip first step and moved to next step 
     * click on the back button of every 2 step will move to prev page back 
     * 
     * use of skip step = since intro() plugin not provide pause()/skip()/back-next button click events we mannaully add the 
     * skip step so that it will move to next step and while click on back it will be on prev page , to remove the disable back button effect 
     */


    StartUserTourType: any;
    StartUserTourId: any;
    shouldStartUserTour: boolean = false;//start user page tour after finishing user page loading 

    UIViewLoaded: boolean = false;

    communitiesJoinedArray: boolean = false;

    baseServerUrl: any = environment.API_URL;

    messagesArray = [];

    constructor(public userService: UserService,
        public storageService: StorageService,
        private popoverController: PopoverController,
        public alertController: AlertController,
        private http: HttpClient,
        public router: Router,) {

    }



    async willStartGuide() {

        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            message: "Let's start with App Guide !!!",
            buttons: [
                {
                    text: 'Cancel',
                    role: "cancel",
                    handler: () => {
                    }
                },
                {
                    text: 'Start',
                    handler: () => {
                        this.homepageTour("first");
                    }
                }
            ],
        });

        await alert.present();
        this.updateDBUserguide();//HL-551
    }

    homepageTour(startwith) {


        const homepageIntro = introJs();
        let stepsArray = [];

        if (this.userService.currentLoggedInUser.healthCareProfessional) {//for hcp only
            stepsArray.push(
                {
                    "element": "#createCaseBtn",
                    "intro": this.getMessage("home-step-1"),
                    "id": "home-step-1",
                    "category": "home"
                },
            )
        }

        stepsArray.push(
            {
                "element": "#createPostBtn",
                "intro": this.getMessage("home-step-2"),
                "id": "home-step-2",
                "category": "home"   
            }
        )

        //HL-570 - if share icon present then only this step will be shown
        var postshareicon = document.getElementById('postshareicon0');
        if (postshareicon) {
            stepsArray.push(
                {
                    "element": "#postshareicon0",
                    "intro": this.getMessage("home-step-3"),
                    "id": "home-step-3",
                    "category": "home"
                },
            )
        }
        let app = this;
        homepageIntro.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            showBullets: false,
            steps: stepsArray,
            doneLabel: "Next",
        });
        var elementId = document.getElementById("createPostBtn");
        elementId.scrollIntoView();
        homepageIntro.start();
        if (startwith == "last") {
            homepageIntro.goToStepNumber(stepsArray.length);
        }

        homepageIntro.onbeforechange(function () {
            let index = homepageIntro._currentStep;
            var elementId = document.getElementById(homepageIntro._introItems[index].element.id);
            if (elementId) elementId.scrollIntoView();
        });

        var isskip = "no";
        homepageIntro.onskip(function () {
            isskip = "yes;"
        });

        homepageIntro.oncomplete(function () {
            setTimeout(() => {
                if (isskip == "no") {

                    app.router.navigate(['/tabs/tab3']);
                    setTimeout(() => {
                        app.grpWallIntro("first");
                    }, 500)
                }
            }, 500)
        });

        homepageIntro.onexit(function () {
        });
        app.updateDBUserguide();
    }


    // 2.Gruop Page tour
    grpWallIntro(startWith) {
        const grpWallIntro = introJs();
        let stepsArray = [];

        //grp page 
        stepsArray.push(
            {
                element: 'startwithgrp',
                title: "Groups",
                intro: ""
            },
            {
                "element": (window.innerWidth >= 970) ? 'app-group-wall #GroupsHeader' : '#tab-button-tab3',
                "intro": this.getMessage("grp-step-1"),
                "id": "grp-step-1",
                "category": "Group"
            },
            {
                "element": '#MyGroupsbtn',
                "intro": this.getMessage("grp-step-2"),
                "id": "grp-step-2",
                "category": "Group"
            },
            {
                "element": '#DiscoverGroupsbtn',
                "intro": this.getMessage("grp-step-3"),
                "id": "grp-step-3",
                "category": "Group"
            }
        )

        if (window.innerWidth >= 970) {//for web view
            if (this.userService.currentLoggedInUser.healthCareProfessional) {//for hcp only
                stepsArray.push(
                    {
                        "element": '#webCreategrpHCP',
                        "intro": this.getMessage("grp-step-4"),
                        "id": "grp-step-4",
                        "category": "Group"
                    },
                )
            }
            stepsArray.push(
                {
                    "element": '#webcreategrp',
                    "intro": this.getMessage("grp-step-5"),
                    "id": "grp-step-5",
                    "category": "Group"
                },
            );
        }

        if (window.innerWidth < 970) {
            stepsArray.push(
                {
                    element: '#mobilecreateicon',
                    intro: "Click on the plus button to get more options !",
                }
            )
            stepsArray.push(
                {
                    element: 'openMobileCreateOptionClick',
                    intro: "Click on the plus button to get more options !",
                }
            )
            if (this.userService.currentLoggedInUser.healthCareProfessional) {//for hcp only

                stepsArray.push(
                    {
                        "element": '#mobileCreategrpHCP',
                        "intro": this.getMessage("grp-step-4"),
                        "id": "grp-step-4",
                        "category": "Group"
                    },
                )
            }
            stepsArray.push(
                {
                    "element": '#mobilecreategrp',
                    "intro": this.getMessage("grp-step-5"),
                    "id": "grp-step-5",
                    "category": "Group"
                },
            )
        }

        grpWallIntro.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            showBullets: false,
            // doneLabel : maindonelable,
            doneLabel: "Next",
            steps: stepsArray
        });
        if (startWith == "last") {
            if (window.innerWidth < 970) {
                var elementcreategrpbtn = document.getElementById("mobilecreateicon");
                elementcreategrpbtn.click();
            }
            setTimeout(() => {
                grpWallIntro.start();
                grpWallIntro.goToStepNumber(stepsArray.length);
            }, 200)
        } else {
            grpWallIntro.start();

            if (grpWallIntro._currentStep == 0) {
                grpWallIntro.nextStep();
            }
        }

        let app = this;

        grpWallIntro.onafterchange(function (targetElement) {

            let index = grpWallIntro._currentStep
            if (stepsArray[index].element === "openMobileCreateOptionClick" && grpWallIntro._direction == "forward") {

                var elementcreategrpbtn = document.getElementById("mobilecreateicon");
                elementcreategrpbtn.click();
                setTimeout(() => {
                    grpWallIntro.start();
                    grpWallIntro.goToStepNumber(grpWallIntro._currentStep + 1);
                }, 300)
            }
            if (stepsArray[index].element === "openMobileCreateOptionClick" && grpWallIntro._direction == "backward") {
                app.popoverController.dismiss();
                setTimeout(() => {
                    grpWallIntro.start();
                    grpWallIntro.goToStepNumber(grpWallIntro._currentStep - 1);
                }, 100)
            }



            if (stepsArray[index].element === "startwithgrp" && grpWallIntro._direction == "backward") {
                grpWallIntro.exit();
                app.router.navigate(['/tabs/tab1']);
                setTimeout(() => {
                    app.homepageTour("last");
                }, 1000)
            }
        });

        var isskip = "no";
        grpWallIntro.onskip(function () {
            isskip = "yes;"
            app.popoverController.dismiss();
        });


        grpWallIntro.oncomplete(function () {
            setTimeout(() => {
                if (isskip == "no") {
                    app.popoverController.dismiss();
                    if (app.userService.currentLoggedInUser.healthCareProfessional) {
                        app.router.navigate(['/tabs/tab6']);//comunity page
                        setTimeout(() => {
                            app.communityIntro("first");
                        }, 100)
                    } else {
                        app.router.navigate(['/tabs/tab5']);//settingpage
                        setTimeout(() => {
                            app.settingIntro("first");
                        }, 700)
                    }
                }
            }, 500)
        });

        grpWallIntro.onexit(function () {
            app.popoverController.dismiss();
        });


    }


    //3.Communitypage tour
    communityIntro(startwith) {
        // dynamic ui should load before starting tour 
        var interval = setInterval(() => {
            if (this.UIViewLoaded) {
                clearInterval(interval);
                const communityIntro = introJs();
                let stepsArray = [];
                stepsArray.push(
                    {
                        element: 'startcommunities',
                        Title: "Communities",
                        intro: ""
                    },
                    {
                        "element": (window.innerWidth >= 970) ? 'app-communities #CommunitiesHeader' : '#tab-button-tab6',
                        "intro": this.getMessage("cmnt-step-1"),
                        "id": "cmnt-step-1",
                        "category": "Community"
                    }
                )
                if (this.communitiesJoinedArray) {
                    stepsArray.push(
                        {
                            "element": '#Joinedcommunities0',
                            "intro": this.getMessage("cmnt-step-2"),
                            "id": "cmnt-step-2",
                            "category": "Community"
                        },
                    )
                }
                communityIntro.setOptions({
                    skipLabel: "X",
                    scrollToElement: true,
                    scrollTo: 'element',
                    doneLabel: "Next",
                    showBullets: false,
                    steps: stepsArray
                });
                communityIntro.refresh();
                communityIntro.start();
                if (startwith == "last") {
                    communityIntro.goToStepNumber(stepsArray.length);
                } else {


                    if (communityIntro._currentStep == 0) {
                        console.log("communityIntro INTRO ....._currentStep1", communityIntro._currentStep)
                        // setTimeout(()=>{
                        // grpWallIntro.start();
                        communityIntro.nextStep();
                        // grpWallIntro.goToStepNumber(1);
                        //  },500)

                    }
                }

                let app = this;
                // communityIntro.onbeforechange(function () {

                //   let index = communityIntro._currentStep
                //   // console.log(communityIntro._currentStep)
                //   // console.log(communityIntro._introItems[index].element.id) 
                //   var elementId =   document.getElementById(communityIntro._introItems[index].element.id);
                //   elementId.scrollIntoView();                   
                // });

                communityIntro.onafterchange(function () {
                    // alert("end of introduction // not skipped or close");
                    let index = communityIntro._currentStep

                    if (stepsArray[index].element === "startcommunities" && communityIntro._direction == "backward") {
                        communityIntro.exit();
                        app.router.navigate(['/tabs/tab3']);
                        setTimeout(() => {
                            app.grpWallIntro("last");
                        }, 500)
                    }
                });

                var isskip = "no";
                communityIntro.onskip(function () {
                    isskip = "yes;"
                });
                communityIntro.oncomplete(function () {
                    setTimeout(() => {
                        if (isskip == "no") {
                            app.router.navigate(['/tabs/tab5']);//settingpage
                            setTimeout(() => {
                                app.settingIntro("first");
                            }, 700)
                        }
                    }, 700)
                });
                communityIntro.onexit(function () {
                });
            }
        }, 1000);

    }

    settingIntro(startwith) {

        const settingIntro = introJs();
        let stepsArray = [];

        stepsArray.push(
            {
                element: 'startSetting',
                title: "Setting",
                intro: ""
            },
        )
        stepsArray.push(
            {
                "element": '#Outbox',
                "intro": this.getMessage("setting-step-1"),
                "id": "setting-step-1",
                "category": "Setting"
            },
        )
        if (this.userService.currentLoggedInUser.healthCareProfessional) {//for hcp only
            stepsArray.push(
                {
                    "element": '#InviteHealthcareProviders',
                    "intro": this.getMessage("setting-step-2"),
                    "id": "setting-step-2",
                    "category": "Setting"
                },
            )
        }

        settingIntro.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            showBullets: false,
            doneLabel: "Next",
            steps: stepsArray
        });
        settingIntro.start();
        if (startwith == "last") {
            settingIntro.goToStepNumber(stepsArray.length);
        } else {
            if (settingIntro._currentStep == 0) {
                setTimeout(() => {
                    settingIntro.nextStep();
                }, 10)

            }
        }
        let app = this;
        settingIntro.onbeforechange(function () {

            let index = settingIntro._currentStep
            // console.log(settingIntro._currentStep)8
            // console.log(settingIntro._introItems[index].element.id) 
            if (stepsArray[index].element != "startSetting") {
                var elementId = document.getElementById(settingIntro._introItems[index].element.id);
                elementId.scrollIntoView();
            }

        });

        settingIntro.onafterchange(function () {
            // alert("end of settingIntro // not skipped or close");
            let index = settingIntro._currentStep;

            if (stepsArray[index].element === "startSetting" && settingIntro._direction == "backward") {
                settingIntro.exit();
                if (app.userService.currentLoggedInUser.healthCareProfessional) {
                    app.router.navigate(['/tabs/tab6']);//comunity page
                    setTimeout(() => {
                        app.communityIntro("last");
                    }, 100)
                } else {
                    app.router.navigate(['/tabs/tab3']);//settingpage
                    setTimeout(() => {
                        app.grpWallIntro("last");
                    }, 500)
                }
            }
        });
        var isskip = "no";
        settingIntro.onskip(function () {
            isskip = "yes;"
        });

        settingIntro.oncomplete(function () {
            setTimeout(() => {
                if (isskip == "no") {
                    app.router.navigate(['/tabs/tab2']);//chat page
                    setTimeout(() => {
                        app.chatIntro("first");
                    }, 500)
                }
            }, 500)
        });
        settingIntro.onexit(function () {
        });
    }



    // chat page intro
    chatIntro(startwith) {

        const chatIntro = introJs();
        let stepsArray = [];
        stepsArray.push(
            {
                element: 'startChat',
                title: "Chat",
                intro: "",
            },
            {
                element: '#chatintrobtn',
                "intro": this.getMessage("chat-step-1"),
                "id": "chat-step-1",
                "category": "Chat"
            },
        )

        chatIntro.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            doneLabel: "Next",
            showBullets: false,
            steps: stepsArray
        });
        chatIntro.start();
        if (startwith == "last") {
            chatIntro.goToStepNumber(stepsArray.length);
        } else {
            if (chatIntro._currentStep == 0) {
                // setTimeout(()=>{
                // grpWallIntro.start();
                chatIntro.nextStep();
                // grpWallIntro.goToStepNumber(1);
                //  },500)

            }

        }

        let app = this;
        chatIntro.onafterchange(function () {
            let index = chatIntro._currentStep
            if (stepsArray[index].element === "startChat" && chatIntro._direction == "backward") {
                chatIntro.exit();
                app.router.navigate(['/tabs/tab5']);//settingpage
                setTimeout(() => {
                    app.settingIntro("last");
                }, 500)
            }
        });

        chatIntro.onbeforechange(function () {
            let index = chatIntro._currentStep
            // console.log(chatIntro._currentStep)8
            // console.log(chatIntro._introItems[index].element.id) 
            // if(stepsArray[index].element != "startChat")
            //   {   
            //     var elementId =   document.getElementById(chatIntro._introItems[index].element.id);
            //     elementId.scrollIntoView(); 
            //   }

        });
        var isskip = "no";
        chatIntro.onskip(function () {
            isskip = "yes;"
        });


        chatIntro.oncomplete(function () {
            setTimeout(() => {
                if (isskip == "no") {
                    app.router.navigate(['/user-profile', app.userService.currentLoggedInUser._id]);
                    app.shouldStartUserTour = false
                    var interval = setInterval(() => {
                        if (app.shouldStartUserTour) {
                            clearInterval(interval);
                            app.userProfileIntro(app.StartUserTourType, app.userService.currentLoggedInUser._id);
                        }
                    }, 500);
                }
            }, 500);
        });
        chatIntro.onexit(function () {
        });
    }

    userProfileIntro(usertype, userId) {

        const userProfileIntro = introJs();
        let stepsArray = [];
        var addStoryBtn = document.getElementById("addStoryBtn");
        stepsArray.push(
            {
                element: 'startUserProfile',
                title: "User Profile",
                intro: "",
            },
        )
        if (addStoryBtn) {
            stepsArray.push(
                {
                   "element": '#addStoryBtn',
                   "intro": this.getMessage("profile-step-1"),
                   "id": "profile-step-1",
                   "category": "Profile"
                },
            )
        }
        var WatchStoryBtn = document.getElementById("WatchStoryBtn");
        if (WatchStoryBtn) {
            stepsArray.push(
                {
                    element: '#WatchStoryBtn',
                    "intro": this.getMessage("profile-step-2"),
                    "id": "profile-step-2",
                    "category": "Profile"                },
            )
        }
        stepsArray.push(
            {
                "element": '#shareProfileBtnTour' + userId,
                "intro":  this.getMessage("profile-step-3"),
                "id": "profile-step-3",
                "category": "Profile"
            },
        )
        userProfileIntro.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            showBullets: false,
            steps: stepsArray
        });
        userProfileIntro.start();

        if (userProfileIntro._currentStep == 0) {
            // setTimeout(()=>{
            // grpWallIntro.start();
            userProfileIntro.nextStep();
            // grpWallIntro.goToStepNumber(1);
            //  },500)

        }

        let app = this;
        userProfileIntro.onafterchange(function () {
            let index = userProfileIntro._currentStep
            if (stepsArray[index].element === "startUserProfile" && userProfileIntro._direction == "backward") {
                userProfileIntro.exit();
                app.router.navigate(['/tabs/tab2']);//settingpage
                setTimeout(() => {
                    app.chatIntro("last");
                }, 500)
            }
        });
        var isskip = "no";
        userProfileIntro.onskip(function () {
            isskip = "yes;"
        });


        userProfileIntro.oncomplete(function () {
            setTimeout(() => {
                if (isskip == "no") {
                    app.finishGuide();
                }
            }, 500);
        });
        userProfileIntro.onexit(function () {
        });
    }


    userProfileIntroPublic(userId) {
        const userProfileIntropublic = introJs();
        let stepsArray = [];
        var connectBtn = document.getElementById('connectBtnTour' + userId);
        console.log('#connectBtnTour' + userId)
        if (connectBtn) {
            stepsArray.push(
                {
                    "element": '#connectBtnTour' + userId,
                    "intro": this.getMessage("profile-step-4"),
                    "id": "profile-step-4",
                    "category": "Profile"
                },
            )
        }
        var WatchStoryBtn = document.getElementById("WatchStoryBtn");
        if (WatchStoryBtn) {
            stepsArray.push(
                {
                    "element": '#WatchStoryBtn',
                    "intro":this.getMessage("profile-step-2"),
                    "id": "profile-step-2",
                    "category": "Profile"
                },
            )
        }
        stepsArray.push(
            {
                element: '#shareProfileBtnTour' + userId,
               "intro": this.getMessage("profile-step-3"),
               "id": "profile-step-3",
               "category": "Profile"
            },
        )
        userProfileIntropublic.setOptions({
            skipLabel: "X",
            scrollToElement: true,
            scrollTo: 'element',
            showBullets: false,
            steps: stepsArray
        });
        userProfileIntropublic.start();
        sessionStorage.setItem("tourguidePublicUser", "yes");

        userProfileIntropublic.oncomplete(function () {
            sessionStorage.setItem("tourguidePublicUser", "yes");
        });
        userProfileIntropublic.onexit(function () {
            userProfileIntropublic.exit();
            sessionStorage.setItem("tourguidePublicUser", "yes");
        });
        this.updateDBUserguidePublic()
    }


    updateDBUserguide() {

        let details = {
            appUserGuideShow: "done"
        };

        this.userService.addToProfileUserGuideUpdate(details).subscribe((success) => {
            if (success) {
                this.userService.userProfileData.appUserGuideShow = "done";
                this.userService.currentLoggedInUser.appUserGuideShow = "done";

            } else {
                console.log("Error.........")
            }
        }, (error => {
            console.log("Error.........")
        }));

        // this.navigationService.navigateToUserProfile();
    }

    updateDBUserguidePublic() {

        let details = {
            appUserGuideShowPublic: "done"
        };

        this.userService.addToProfileUserGuideUpdate(details).subscribe((success) => {
            if (success) {
                this.userService.userProfileData.appUserGuideShowPublic = "done";
                this.userService.currentLoggedInUser.appUserGuideShowPublic = "done";
                
            } else {
                console.log("Error.........")
            }
        }, (error => {
            console.log("Error.........")
        }));

        // this.navigationService.navigateToUserProfile();
    }

    callAllMessages() {
        //get all messages from db
        return this.http.get(this.baseServerUrl + '/userGuideMessage').pipe(map(
            // this.http.get("./app-guide-messages.json").pipe(map(
            (response: any) => {
                console.log("response.data[0].messages",response.data[0].messages)
                this.messagesArray = response.data[0].messages
            }, catchError((err) => {
                throw err;
            })
        ));
    }

    getMessage(id){
        let index =  this.messagesArray.findIndex(
            (x) => x.id == id
        );
        var msg ;
        if(index != -1){
            msg =  this.messagesArray[index].intro;
        }
        else{
            msg = "";
        }
        return msg;
    }

      
  async finishGuide() {

    const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        message: "App Guide Completed!!!",
        buttons: [ 
            {
                text: 'Home',
                handler: () => {
                    this.router.navigate(['/tabs/tab1']);
                }
            }
        ],
    });

    await alert.present();
}


}
