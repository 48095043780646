import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EmptyScreenService } from 'src/app/services/empty-screen/empty-screen.service';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-connection-communities',
    templateUrl: './connection-communities.page.html',
    styleUrls: ['./connection-communities.page.scss'],
})
export class ConnectionCommunitiesPage implements OnInit {
    modalData: any;
    connection_id: any;
    currentUserId: any;
    isCurrentUser: boolean;

    constructor(
        public groupService: GroupsService,
        public modalCtrl: ModalController,
        public userService: UserService,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService,
        public storageService: StorageService,
        public emptyScreenService: EmptyScreenService
    ) {
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        this.connection_id = this.modalData.uid;
        console.log("this.modalData", this.modalData)
    }

    ngOnInit() {
        this.groupService.getUserConnectionsCommunities(this.connection_id);
        this.currentUserId = this.storageService.getStorage('currentUserId');
    }

    checkCurrentUser() {
        if (this.userService.userProfileData?._id != this.currentUserId) this.isCurrentUser = false;
        else this.isCurrentUser = true;
        console.log("🚀 ~ file: user-profile.page.ts:417 ~ UserProfilePage ~ checkCurrentUser ~ this.userService.userProfileData?._id", this.userService.userProfileData?._id, this.currentUserId)

        return this.isCurrentUser;
    }

    goBack() {
        this.modalCtrl.dismiss();
    }

}
