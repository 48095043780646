import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';


@Component({
    selector: 'app-search-health-interests',
    templateUrl: './search-health-interests.page.html',
    styleUrls: ['./search-health-interests.page.scss'],
})
export class SearchHealthInterestsPage implements OnInit {
    healthInterests: any[];
    userDetails: any;
    selectedInterest: any = [];
    interestName: any = [];
    interestFromPreviousPage;
    constructor(
        public userService: UserService,
        public modalCtrl: ModalController,
        public alertCtrl: AlertController,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService
    ) {
        this.loggingService.fetchGlobalConstants();
        // let data = document.querySelector('ion-modal').componentProps;
        
    }
    
    ngOnInit() {
        this.selectedInterest = [...this.interestFromPreviousPage];
        this.userAnalyticsService.trackPagesHandler('SearchHealthInterestsPage');
        this.showhealthInterests();
    }

    showhealthInterests() {
        this.healthInterests = [];
        this.userService.gethealthInterests().subscribe((res) => {
            console.log("🚀 ~ file: search-health-interests.page.ts:46 ~ SearchHealthInterestsPage ~ this.userService.gethealthInterests ~ res", res)
            if (res) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchHealthInterestsPage', 'showhealthInterests()', '');
                this.healthInterests = res.data
                this.checkForSelectedInterest();
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchHealthInterestsPage', 'showhealthInterests()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchHealthInterestsPage', 'showhealthInterests()', '', error);
        }));
    }

    checkForSelectedInterest() {
        for (let i = 0; i < this.healthInterests.length; i++) {
            for (let j = 0; j < this.selectedInterest.length; j++) {
                if (this.healthInterests[i].name == this.selectedInterest[j]) {
                    this.healthInterests[i].checked = true;
                }
            }
        }
    }

    /**
     * Search for a health interest
     * @param e : Search input
     * @returns 
     */
    searchInterests(e) {
        let searchValue = e.target.value;
        if (!searchValue) {
            this.showhealthInterests()
            return
        }
        this.healthInterests = this.healthInterests.filter(function (val) {
            return (
                val.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            )
        })
    }


    saveHealthInterests() {
        if(this.selectedInterest.length == 0){
            this.alertFunction();
        }else{
            this.modalCtrl.dismiss({
                selectedInterest: this.selectedInterest
            });
        }
    }
    async alertFunction() {
        const alert = await this.alertCtrl.create({
            message: 'At least select one health interest.',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        // this.pageLoading.dismiss();
                    }
                }
            ]
        });
        alert.present();
    }

    checkUncheckInterests(value) {
        if (value.checked == true) {
            if (this.selectedInterest.some(obj => obj === value.name)) {
                // Dont push value into selectedInterest Array
            }
            else {
                this.selectedInterest.push(value.name);
            }

        } else if (value.checked == false) {
            this.selectedInterest = this.selectedInterest.filter(function (e) {
                return e != value.name;
            });
        } else {
            //error
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
