import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfilePicturePage } from 'src/app/modules/media-utility/profile-picture/profile-picture.page';
import { UserProfilePage } from 'src/app/modules/userProfile/user-profile/user-profile.page';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { MediaService } from 'src/app/services/media/media.service';
import { PostService } from 'src/app/services/post/post.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-upload-profile-picture-options',
    templateUrl: './upload-profile-picture-options.component.html',
    styleUrls: ['./upload-profile-picture-options.component.scss'],
})
export class UploadProfilePictureOptionsComponent implements OnInit {
    currentUserId: any;
    isCurrentUser: boolean;
    platformType = sessionStorage.getItem('platform');
    // userProfilePicture: any = '';


    constructor(
        public userService: UserService,
        public storageService: StorageService,
        public postService: PostService,
        public loggingService: LoggingService,
        public mediaService: MediaService,
        public modalCtrl: ModalController,
    ) { }

    ngOnInit() {
        this.currentUserId = this.storageService.getStorage('currentUserId');
        this.checkCurrentUser();
        console.log("this.isCurrentUser", this.isCurrentUser);
        console.log("this.platformType", this.platformType);
        this.getProfilePicture();
    }

    getProfilePicture() {
        this.userService.currentLoggedInUser.photo != "" ? this.getImageUrl(this.userService.currentLoggedInUser.photo) : this.userService.userProfilePicture = "";
    }

    checkCurrentUser() {
        if (this.userService.currentLoggedInUser?._id != this.currentUserId) this.isCurrentUser = false;
        else this.isCurrentUser = true;

        return this.isCurrentUser;
    }

    getImageUrl(mediaName) {
        this.postService.getMediaUrlForDisplay(mediaName)
            .subscribe(displayUrl => {
                this.userService.userProfilePicture = displayUrl
            });
    }

    removeProfilePicture() {
        this.userService.userProfilePicture = '';
        let dataToBeUpdate = {
            photo: this.userService.userProfilePicture
        }
        this.userService.updateUserRecord(this.currentUserId, dataToBeUpdate).subscribe(res => {
            if (!res || res == null || res == undefined) {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'removeProfilePicture()', '', 'Error in response');
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userProfilePage', 'removeProfilePicture()', '');
                // this.goBack()
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'removeProfilePicture()', '', error);
        }))
    }

    async uploadProfilePicture(e) {
        this.mediaService.convertAttachedImageToBase64(e.target.files[0]).then((res) => {
            this.userProfile(res, 'forUser');
        });
    }

    async userProfile(imageSelected: any, profileType) {
        let imageProp = {
            image: imageSelected,
            profileType: profileType
        }
        // let image;
        const modal = await this.modalCtrl.create({
            component: ProfilePicturePage,
            cssClass: 'my-custom-class',
            componentProps: {
                image: imageSelected,
                profileType: profileType
            }
        });

        modal.onDidDismiss().then((res) => {
            if (res.data == undefined) return;


            this.userService.currentLoggedInUser.photo = res.data.photo;
            let dataToBeUpdate = {
                photo: res.data.photo
            }
            this.userService.userProfilePicture = res.data.displayURL

            // save profile picture image name to user record in database
            this.updateUserData(dataToBeUpdate);

        })

        await modal.present();
    }

    updateUserData(dataToBeUpdate) {
        this.userService.updateUserRecord(this.currentUserId, dataToBeUpdate).subscribe(res => {
            console.log("🚀 ~ file: user-profile.page.ts:262 ~ UserProfilePage ~ this.userService.updateUserRecord ~ res", res)
            if (!res || res == null || res == undefined) {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'userProfile()', '', 'Error in response');
            }
            else {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'userProfilePage', 'userProfile()', '');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'userProfilePage', 'userProfile()', '', error);
        }))
    }
}
