import { PostService } from 'src/app/services/post/post.service';
import { UserService } from 'src/app/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Subject, from } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { ShareFromWallPage } from 'src/app/modules/home/share-from-wall/share-from-wall.page';
import { ModalController } from '@ionic/angular';
import { ShowUsersPage } from 'src/app/modules/home/show-users/show-users.page';
import { Router } from '@angular/router';
import { LoggingService } from '../logging/logging.service';
import { InternetService } from '../internet/internet.service';
import { UserProfilePage } from 'src/app/modules/userProfile/user-profile/user-profile.page';


@Injectable({
    providedIn: 'root'
})
export class WallsService {
    baseServerUrl: any = environment.API_URL;
    disableShareButtonOnWall: boolean = false;
    currentUserId: any;
    modalRef: any = {};
    constructor(
        public http: HttpClient,
        public storageService: StorageService,
        public userService: UserService,
        public postService: PostService,
        public modalCtrl: ModalController,
        public router: Router,
        public loggingService: LoggingService,
        public internetService: InternetService) {
        this.loggingService.fetchGlobalConstants;
        this.currentUserId = this.storageService.getStorage('currentUserId');
    }

    getGroupPublicInfo(details) {
        let getWallInformationResponse = {
            error: false,
            resObject: {}
        }

        return this.http.get(this.baseServerUrl + details).pipe(map(
            (response: any) => {

                if (!response || response == undefined || response == null) {
                    getWallInformationResponse.error = true;
                }
                else {
                    getWallInformationResponse.error = false;
                    getWallInformationResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'getWallInformation()', '');
                return getWallInformationResponse;
            }), catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'wallsService', 'getWallInformation()', '', err)
                getWallInformationResponse.error = true;
                throw getWallInformationResponse;
            }));
    }

    getWallInformation(details) {
        let getWallInformationResponse = {
            error: false,
            resObject: {}
        }
        details += '&userID=' + this.storageService.getStorage('currentUserId');
        return this.http.get(this.baseServerUrl + details).pipe(map(
            (response: any) => {

                if (!response || response == undefined || response == null) {
                    getWallInformationResponse.error = true;
                }
                else {
                    getWallInformationResponse.error = false;
                    getWallInformationResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'getWallInformation()', '');
                return getWallInformationResponse;
            }), catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'wallsService', 'getWallInformation()', '', err)
                getWallInformationResponse.error = true;
                throw getWallInformationResponse;
            }));
    }


    async sharePost(post) {

        // check for internet connectivity first
        if (this.internetService.isNetWorkAvailable == false) {
            this.internetService.presentMessageAlert()
            // this.internetService.presentMessageAlert()
            return
        }

        this.disableShareButtonOnWall = true;
        let obj = { postDataFromWall: post };
        let captionModal = await this.modalCtrl.create({
            component: ShareFromWallPage,
            cssClass: 'shareFromWallModal',
            componentProps: obj,
            backdropDismiss: true,
        });
        (await captionModal).present();
        this.disableShareButtonOnWall = false;
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'sharePost()', 'ShareFromWall modal opened');
    }

    async openUserList(post, action) {
        let obj = { action: action, postData: post, comingFrom: 'post' }
        if(this.modalRef['showUsersPageModal']){
            return false;
        }else{
            this.modalRef['showUsersPageModal'] = true;
        }
        let showUsersPageModal = await this.modalCtrl.create({
            component: ShowUsersPage,
            cssClass: 'my-custom-class',
            componentProps: obj,
            backdropDismiss: false,
        });
        showUsersPageModal.onDidDismiss().then((value) => {
            this.modalRef['showUsersPageModal'] = false;
        });
        (await showUsersPageModal).present();
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'openUserList()', 'ShowUsersPage modal opened');
    }



    checkLikeConditions(post, currentUser) {
        let expressionArray = post.postLikes.concat(post.postDislikes);
        if (expressionArray.length > 0) {
            var abc = false;

            for (var j = 0; j < expressionArray.length; j++) {
                if (expressionArray[j].userId == currentUser._id) {
                    (post.like.condition = true),
                        (post.like.expressionFlag = expressionArray[j].expressionFlag),
                        (abc = true);
                }

                if (j == expressionArray.length - 1 && abc == false) post.like.condition = false;
            }
        } else post.like.condition = false;
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'checkLikeConditions()', '');
        post.like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.likeCount);
        post.like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.dislikeCount);
    }

    /** CONVERT NUMBER TO MAXIMUM THREE DIGITS */
    convertLongNumberToThreeDigitsMax(number) {
        let returnValue;

        if (Math.abs(number) > 999999) returnValue = parseInt((number / 100000).toString()) + 'M';
        else if (Math.abs(number) > 999) returnValue = parseInt((number / 1000).toString()) + 'K';
        else returnValue = number;
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'wallsService', 'convertLongNumberToThreeDigitsMax()', '');
        return returnValue;
    }

    checkFollowConditions(post, currentUser) {
        for (var j = 0; j < post.userFollow.length; j++) {
            if (post.userFollow[j] == currentUser._id) {
                post.follow = true;
                break;
            }

            if (j == post.userFollow.length - 1) post.follow = false;
        }
    }

    navigateToUserProfile(userInfo) {
        console.log("🚀 ~ file: walls.service.ts:144 ~ WallsService ~ navigateToUserProfile ~ userInfo:", userInfo)
        this.router.navigate(['/user-profile', userInfo._id]);
        // if (this.currentUserId == userInfo._id) this.router.navigate(['/user-profile', userInfo._id]);
        // else this.presentUserProfileModal(userInfo._id);
    }

    async presentUserProfileModal(uid) {

        const userProfileModal = await this.modalCtrl.create({
            component: UserProfilePage,
            backdropDismiss: false,
            cssClass: 'my-custom-class',
            componentProps: {
                uid: uid
            }
        });
        userProfileModal.present();

        // const { data, role } = await userProfileModal.onWillDismiss();
    }


        ///start this is used for triggering wall page information
        private refreshWallPage = new Subject<any>();
        refreshWallPage_trigge$ = this.refreshWallPage.asObservable();
    
        callrefreshWallPage(data) {
            this.refreshWallPage.next(data);
        }
        ///end this is used for triggering wall page information
    
}
