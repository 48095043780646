import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
    selector: 'app-get-app',
    templateUrl: './get-app.component.html',
    styleUrls: ['./get-app.component.scss'],
})
export class GetAppComponent implements OnInit {
    appStoreLinkRes: any = '';
    platform: any = '';
    @ViewChild('appStoreBtnIos') appStoreBtnIosElm: ElementRef;
    @ViewChild('appStoreBtnAndroid') appStoreBtnAndroidElm: ElementRef;


    constructor(
        public modalCtrl: ModalController,
        public authService: AuthenticationService
    ) {
        this.getAppStoreLink();
        this.platform = this.authService.checkPlatform();
        console.log("🚀 ~ file: get-app.component.ts:22 ~ GetAppComponent ~ ngOnInit ~ this.platform:", this.platform)
        console.log("🚀 ~ file: get-app.component.ts:22 ~ GetAppComponent ~ ngOnInit ~ typeof this.platform:", typeof this.platform)
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.assignDisplayClass();
    }

    assignDisplayClass() {
        if (this.platform == 'ioswebbrowser') this.appStoreBtnAndroidElm.nativeElement.style.display = 'none'
        else if (this.platform == 'androidwebbrowser') this.appStoreBtnIosElm.nativeElement.style.display = 'none'
    }

    getAppStoreLink() {
        this.authService.getAppStoreLink().subscribe(res => {
            console.log("🚀 ~ file: get-app.component.ts:27 ~ GetAppComponent ~ this.authService.getAppStoreLink ~ res:", res.data[0])
            this.appStoreLinkRes = res.data[0];
        })
    }

    takeToStore() {
        if (this.platform == 'ioswebbrowser') window.open(this.appStoreLinkRes.AppStoreAppURL);
        else if (this.platform == 'androidwebbrowser') window.open(this.appStoreLinkRes.PlayStoreAppURL);
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

}
