import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';
import { globalConstants } from 'src/assets/config/global-constants';
import { Subject } from 'rxjs';
import { TourGuideService } from 'src/app/services/tour-guide/tour-guide.service';


@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    baseServerUrl: any = environment.API_URL;
    globalConstants: any = globalConstants;
    myCommunities: any;
    myConnectionsCommunities: any;
    constructor(
        public http: HttpClient,
        public storageService: StorageService,
        public TourGuideService: TourGuideService,
        public loggingService: LoggingService) {
        this.loggingService.fetchGlobalConstants();
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     console.log("🚀 ~ file: groups.service.ts:22 ~ GroupsService ~ this.http.get ~ res", res)
        //     this.globalConstants = res;
        // });
    }

    getGroupsForShareGroupsPage(skip, healthcareProfessional) {
        let groupsResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getGroupsForShareGroupsPage&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&healthCareProfessional=' +
                healthcareProfessional +
                '&$limit=' +
                20
                // this.globalConstants.groupsLimitValue
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        groupsResponse.error = true;
                    }
                    else {
                        groupsResponse.error = false;
                        groupsResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getGroupsForShareGroupsPage()', '');
                    return groupsResponse;
                }), catchError((err) => {
                    groupsResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getGroupsForShareGroupsPage()', '', err)
                    throw groupsResponse;
                }));
    }

    public getGroupsForInfiniteScroll(skip) {
        console.log("🚀 ~ file: groups.service.ts:62 ~ GroupsService ~ getGroupsForInfiniteScroll ~ skip", skip)
        let myGroupsResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getGroupsForInfiniteScroll&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&$limit=' +
                20
                // this.globalConstants.groupsLimitValue
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        myGroupsResponse.error = true;
                    }
                    else {
                        myGroupsResponse.error = false;
                        myGroupsResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getGroupsForInfiniteScroll()', '');
                    return myGroupsResponse;
                }), catchError((err) => {
                    myGroupsResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getGroupsForInfiniteScroll()', '', err)
                    throw myGroupsResponse
                }));


    }

    public getGroupsForDiscoverGroupsPage(skip, healthcareProfessional) {
        let discoverGroupsResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getGroupsForDiscoverGroupsPage&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&healthCareProfessional=' +
                healthcareProfessional +
                '&$limit=' +
                20
                // this.globalConstants.groupsLimitValue
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        discoverGroupsResponse.error = true;
                    }
                    else {
                        discoverGroupsResponse.error = false;
                        discoverGroupsResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getGroupsForDiscoverGroupsPage()', '');
                    return discoverGroupsResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getGroupsForDiscoverGroupsPage()', '', err)
                    discoverGroupsResponse.error = true;
                    throw discoverGroupsResponse;
                }));
    }


    public getGroupMembersForInfiniteScroll(id, skip, searchOperation, searchQuery) {
        let groupMembersResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getGroupMembersForInfiniteScroll&_id=' +
                id +
                '&$skip=' +
                skip +
                '&$limit=' +
                20 +
                // this.globalConstants.groupsLimitValue +
                '&searchOperation=' +
                searchOperation +
                '&searchQueryForMembers=' +
                searchQuery
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        groupMembersResponse.error = true;
                    }
                    else {
                        groupMembersResponse.error = false;
                        groupMembersResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getGroupMembersForInfiniteScroll()', '');
                    return groupMembersResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getGroupMembersForInfiniteScroll()', '', err)
                    groupMembersResponse.error = true;
                    throw groupMembersResponse;
                }));
    }

    public getPendingApprovalMembers(id) {
        let pendingApprovalMembersResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(this.baseServerUrl + '/groups?action=getPendingApprovalMembers&_id=' + id)
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        pendingApprovalMembersResponse.error = true;
                    }
                    else {
                        pendingApprovalMembersResponse.error = false;
                        pendingApprovalMembersResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getPendingApprovalMembers()', '');
                    return pendingApprovalMembersResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getPendingApprovalMembers()', '', err)
                    pendingApprovalMembersResponse.error = true;
                    throw pendingApprovalMembersResponse;
                }));
    }

    public updateSpecificGroupMembers(id, details) {
        let updateSpecificGroupMembersResponse = {
            error: false,
            resObject: {}
        }

        return this.http
            .patch(this.baseServerUrl + '/groups/' + id, JSON.stringify(details))
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        updateSpecificGroupMembersResponse.error = true;
                    }
                    else {
                        updateSpecificGroupMembersResponse.error = false;
                        updateSpecificGroupMembersResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'updateSpecificGroupMembers()', '');
                    return updateSpecificGroupMembersResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'updateSpecificGroupMembers()', '', err)
                    updateSpecificGroupMembersResponse.error = true;
                    throw updateSpecificGroupMembersResponse;
                }));
    }


    public addGroup(details) {
        let addGroupsResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .post(this.baseServerUrl + '/groups', JSON.stringify(details))
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        addGroupsResponse.error = true;
                    }
                    else {
                        addGroupsResponse.error = false;
                        addGroupsResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'addGroup()', '');
                    return addGroupsResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'addGroup()', '', err)
                    addGroupsResponse.error = true;
                    throw addGroupsResponse;
                }));
    }

    public editGroup(details) {
        let editGroupsResponse = {
            error: false,
            resObject: {}
        }

        return this.http
            .patch(this.baseServerUrl + '/groups/' + details._id, JSON.stringify(details))
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        editGroupsResponse.error = true;
                    }
                    else {
                        editGroupsResponse.error = false;
                        editGroupsResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'editGroup()', '');
                    return editGroupsResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'editGroup()', '', err)
                    editGroupsResponse.error = true;
                    throw editGroupsResponse;
                }));
    }

    public getPatientTypeInGroup(id) {
        let getPatientTypeInResponse = {
            error: false,
            resObject: {}
        }

        return this.http
            .get(this.baseServerUrl + '/groups?action=getPatientTypeInGroup&_id=' + id)
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        getPatientTypeInResponse.error = true;
                    }
                    else {
                        getPatientTypeInResponse.error = false;
                        getPatientTypeInResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getPatientTypeInGroup()', '');
                    return getPatientTypeInResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getPatientTypeInGroup()', '', err)
                    getPatientTypeInResponse.error = true;
                    throw getPatientTypeInResponse;
                }));
    }

    public getCommunitiesForExplore(skip) {
        // console.log("🚀 ~ file: groups.service.ts:332 ~ GroupsService ~ getCommunitiesForExplore ~ this.globalConstants.groupsLimitValue", this.globalConstants.groupsLimitValue)
        let getCommunitiesForExploreResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getCommunitiesForExploreCommunitiesPage&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&$limit=' +
                20
                // this.globalConstants.groupsLimitValue
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        getCommunitiesForExploreResponse.error = true;
                    }
                    else {
                        getCommunitiesForExploreResponse.error = false;
                        getCommunitiesForExploreResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getCommunitiesForExplore()', '');
                    return getCommunitiesForExploreResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getCommunitiesForExplore()', '', err)
                    getCommunitiesForExploreResponse.error = true;
                    throw getCommunitiesForExploreResponse;
                }));
    }

    public getUserCommunities(skip, user_id) {
        // console.log("this.globalConstants.groupsLimitValue", this.globalConstants.groupsLimitValue);
        let getUserCommunitiesResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/groups?action=getUserCommunities&_id=' +
                user_id +
                '&$skip=' +
                skip +
                '&$limit=' +
                20
            )
            .pipe(map(
                (response: Response) => {
                    if (!response || response == undefined || response == null) {
                        getUserCommunitiesResponse.error = true;
                    }
                    else {
                        getUserCommunitiesResponse.error = false;
                        getUserCommunitiesResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'groupsService', 'getUserCommunities()', '');
                    return getUserCommunitiesResponse;
                }), catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'groupsService', 'getUserCommunities()', '', err)
                    getUserCommunitiesResponse.error = true;
                    throw getUserCommunitiesResponse;
                }));
    }

    public getupdatedUserCommunities() {
        this.getUserCommunities(0, this.storageService.getStorage('currentUserId')).subscribe((res: any) => {
            this.myCommunities = res.resObject.data;
            this.TourGuideService.communitiesJoinedArray  = (this.myCommunities.length > 0) ? true : false;
            // console.log("this.myCommunities", this.myCommunities);
        })
    }

    public getUserConnectionsCommunities(user_connections_id) {

        this.getUserCommunities(0, user_connections_id).subscribe((res: any) => {
            this.myConnectionsCommunities = res.resObject.data;
            console.log("this.myConnectionsCommunities", this.myConnectionsCommunities);
        })
    }


       ///start this is used for triggering refresh 
       private refreshCommunitylist = new Subject<any>();
       refreshCommunitylist_trigge$ = this.refreshCommunitylist.asObservable();
   
       callrefreshCommunitylistTrigger(data) {
           this.refreshCommunitylist.next(data);
       }
       ///end this is used for triggering refresh 

          
    ///start this is used for triggering refresh My grp list
    private refreshMyGrpList = new Subject<any>();
    refreshMyGrpList_trigge$ = this.refreshMyGrpList.asObservable();

    callrefreshMyGrpListTrigger(data) {
        this.refreshMyGrpList.next(data);
    }
    ///end this is used for triggering refresh My grp list
   
}
