import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { InternetService } from 'src/app/services/internet/internet.service';
import { log } from 'console';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})


export class AuthGuard implements CanActivate {
    constructor(
        public storageService: StorageService,
        public authService: AuthenticationService,
        public router: Router,
        public internetService: InternetService,
        public loadingController: LoadingController
    ) {






    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.checkAuthenticatedUser().then(res => res)

    }
    // canActivate(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    //   return this.checkAuthenticatedUser().then(res => res)


    // }

    async presentLoader() {
        const verificationLoading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: "Loading",
        });
        await verificationLoading.present();
    }


    dismiss() {
        this.loadingController.dismiss()
    }

    // checkForInternet() {
    //     this.internetService.checkNetworkStatus();
    //     if (this.internetService.isNetWorkAvailable == true) {

    //     } else {
    //         this.checkIfTokenExist();
    //     }
    // }

    checkIfTokenExist() {
        if (!this.storageService.getStorage("session") ||
            this.storageService.getStorage("session") == undefined ||
            this.storageService.getStorage("session") == null ||
            this.storageService.getStorage("session") == ""
        ) {
            console.log("storage heck");

            this.router.navigate(['/welcome']);
            return false;
        }
        else {
            return true;
        }
    }

    async checkAuthenticatedUser() {
        return new Promise<boolean>(async (resolve, reject) => {
            // if (!this.storageService.getStorage("session") ||
            //   this.storageService.getStorage("session") == undefined ||
            //   this.storageService.getStorage("session") == null ||
            //   this.storageService.getStorage("session") == ""
            // ) {
            //   console.log("storage heck");

            //   this.router.navigate(['/welcome']);
            //   resolve(false)
            // }
            //  else {
            //   const verificationLoading = await this.loadingController.create({
            //     cssClass: 'my-custom-class',
            //     message: "Loading",
            // });
            //await verificationLoading.present();


            await this.internetService.checkNetworkStatus();
            if (this.internetService.isNetWorkAvailable == true) {
                await (this.authService.authenticate()).subscribe((isAuthorised: any) => {
                    if (!isAuthorised ||
                        isAuthorised == null ||
                        isAuthorised == undefined
                    ) {
                        //  verificationLoading.dismiss()
                        console.log("inside route guard non auth", isAuthorised);

                        this.router.navigate(['/welcome']);
                        resolve(false)

                    }
                    else {
                        console.log("inside else of route guard");

                        if (isAuthorised.userDetails.signupProcessCompleted == "true") {
                            //  verificationLoading.dismiss()
                            console.log("inside else of route guard and signup process completed");
                            resolve(true)
                        }
                        // else if(isAuthorised.userDetails.signupProcessCompleted == "pending"){
                        //   console.log("inside else if of route guard and signup process completed");
                        //   this.router.navigate(['/continue-signup-patient'])
                        //   resolve(false)
                        // }
                        else {
                            //  verificationLoading.dismiss()
                            console.log("inside else of route guard");
                            this.router.navigate(['/welcome']);
                            resolve(false)
                        }
                    }
                }, (err) => {
                    //  verificationLoading.dismiss()
                    this.router.navigate(['/welcome']);
                    resolve(false)
                    // this.loggingService.LogMessage(this.loggingConstants.log_error, 'Page: Root ->Method: authenticateUser > authenticate error ->', JSON.stringify(err));
                })
            } else {
                resolve(this.checkIfTokenExist())
            }



            //  }
        })








    }
}






