import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IonCheckbox, ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { PostService } from 'src/app/services/post/post.service';
import { SearchItemsService } from 'src/app/services/searchItems/search-items.service';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { HttpClient } from '@angular/common/http';
import { casePostCreationObject } from 'src/app/services/post/post.interface';
import { globalConstants } from 'src/assets/config/global-constants';
import { EmptyScreenService } from 'src/app/services/empty-screen/empty-screen.service';
@Component({
    selector: 'app-show-connections',
    templateUrl: './show-connections.page.html',
    styleUrls: ['./show-connections.page.scss'],
})
export class ShowConnectionsPage implements OnInit {
    selectedUserArray: any = [];
    displayArray: any = [];
    specificConnectionsFromCreatePage: any = [];
    skipValue: any;
    infiniteFlag: boolean = false;
    currentUserInfo: any;
    sharingCommentByUser: any;
    originalPostId: any;
    specificConnectionIds: any = [];
    actionButtonText: any;
    searchQueryForConnections: any = "";
    searchOperationFlag: boolean = false;
    connectionAndSearchResults: any = [];
    modalData: any;
    globalConstants: any = globalConstants;
    constructor(
        public userService: UserService,
        public modalCtrl: ModalController,
        public postService: PostService,
        public searchItems: SearchItemsService,
        private zone: NgZone,
        public router: Router,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService,
        public http: HttpClient,
        public emptyScreenService: EmptyScreenService
    ) {
        this.loggingService.fetchGlobalConstants();
        let modalMetaData = document.querySelector('ion-modal');
        this.modalData = modalMetaData.componentProps;
        // console.log("show modal data here", this.modalData);

        this.selectedUserArray = [];
        this.initialSetupMethod();
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     this.globalConstants = res;
        // });

    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ShowConnectionsPage');
    }

    ngOnDestroy() {
        this.modalData = '';
    }


    loadAllSelectedConnections() {
        for (var i = 0; i < this.specificConnectionsFromCreatePage.length; i++) {
            this.specificConnectionsFromCreatePage[i].addedToArray = true;
            this.selectedUserArray.push(this.specificConnectionsFromCreatePage[i]);
        }
    }

    initialSetupMethod() {
        this.getCurrentUserInformation();

        this.specificConnectionsFromCreatePage = this.modalData.specificConnections;
        this.sharingCommentByUser = this.modalData.shareComment;
        this.originalPostId = this.modalData.originalPostId;

        this.skipValue = 0;

        if (this.modalData.comingFrom == "PostCaseCreationPage") {
            this.actionButtonText = "Confirm";
            this.selectedUserArray = [];
            this.loadAllSelectedConnections();
        } else {
            this.actionButtonText = "Share";
        }

        this.getConnectionForInfiniteLoading(
            this.skipValue,
            this.searchOperationFlag,
            false
        );
    }


    getCurrentUserInformation() {
        this.userService.getLoggedInUser().subscribe((response: any) => {
            if (response) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'showConnectionsPage', 'getCurrentUserInformation()', '');
                this.currentUserInfo = response?.data[0];
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'getCurrentUserInformation()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'getCurrentUserInformation()', '', error);
        }));
    }

    searchUsers() {
        this.searchQueryForConnections = '';
    }

    searchForConnections(e) {

        if (this.searchQueryForConnections != '' && this.searchQueryForConnections != null) {
            this.searchOperationFlag = true;
            this.skipValue = 0;
            this.connectionAndSearchResults = [];
            this.searchItems
                .searchConnectionsForInfiniteScrollPages(
                    this.skipValue,
                    this.searchQueryForConnections,
                    this.searchOperationFlag,
                    false
                )
                .subscribe((response: any) => {
                    if (response.error == true) {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'searchForConnections()', '', 'Error in response');
                    }
                    else {
                        if (response && response.resObject.data.length > 0) {
                            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'showConnectionsPage', 'searchForConnections()', '');
                            this.checkForSelectedMembers(response.resObject.data);
                        }
                    }
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'searchForConnections()', '', error);
                }));
            return
        }
        else {
            this.skipValue = 0;
            this.searchOperationFlag = false;
            this.connectionAndSearchResults = [];
            this.getConnectionForInfiniteLoading(
                this.skipValue,
                this.searchOperationFlag,
                false
            );
        }

        // 
        // 	if (
        // 		this.searchQueryForConnections != "" &&
        // 		this.searchQueryForConnections != null
        // 	) {
        //     // console.log("INSIDE IF");

        // 		this.searchOperationFlag = true;
        // 		// this.zone.run(() => {
        // 			this.skipValue = 0;
        // 			this.connectionAndSearchResults = [];
        // 			this.searchItems
        // 				.searchConnectionsForInfiniteScrollPages(
        // 					this.skipValue,
        // 					this.searchQueryForConnections,
        // 					this.searchOperationFlag,
        // 					false
        // 				)
        // 				.subscribe((response : any) => {
        //           // console.log("RESPONSE-->", response);

        // 					if (response && response.resObject.data.length > 0) {
        // 						this.checkForSelectedMembers(response.resObject.data);
        // 					}
        // 				});
        // 		// });
        // 	} else {
        // 		this.skipValue = 0;
        // 		this.searchOperationFlag = false;
        // 		this.connectionAndSearchResults = [];
        // 		this.getConnectionForInfiniteLoading(
        // 			this.skipValue,
        // 			this.searchOperationFlag,
        // 			false
        // 		);
        // 	}
    }

    getConnectionForInfiniteLoading(skip, searchOperation, healthCareExclusive) {
        this.searchItems
            .getConnectionsForInfiniteScrollPages(
                skip,
                searchOperation,
                healthCareExclusive
            )
            .subscribe((response: any) => {
                if (response.error == false) {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'showConnectionsPage', 'getConnectionForInfiniteLoading()', '');
                    if (response.resObject.data.length > 0) {
                        this.infiniteFlag = true;
                        this.checkForSelectedMembers(response.resObject.data);
                    }
                    else {
                        this.infiniteFlag = false;
                    }
                }
                else {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'getConnectionForInfiniteLoading()', '', 'Error in response');
                }
            }, (error => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'getConnectionForInfiniteLoading()', '', error);
            }));
    }

    doInfinite(infiniteScroll) {
        setTimeout(() => {
            if (this.connectionAndSearchResults.length > 0) {
                this.skipValue = this.skipValue + this.globalConstants.connectionsSkipValue;
                this.getConnectionForInfiniteLoading(
                    this.skipValue,
                    this.searchOperationFlag,
                    false
                );
            }
            infiniteScroll.target.complete();
        }, 1500);
    }

    removeConnection(member) {
        for (var i = 0; i < this.connectionAndSearchResults.length; i++) {
            if (member._id == this.connectionAndSearchResults[i]._id) {
                this.connectionAndSearchResults[i].checked = false;
                this.connectionAndSearchResults[i].isAddedToArray = false;
                break;
            }
        }
        this.selectedUserArray = this.selectedUserArray.filter(function (e) {
            // filter selected array and retrun without member in it
            return e._id !== member._id;
        });
    }

    cancelUserSelection() {
        // this.navCtrl.pop();
    }

    async checkForSelectedMembers(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].checked != true) {
                for (var j = 0; j < this.selectedUserArray.length; j++) {
                    if (data[i]._id == this.selectedUserArray[j]._id) {
                        data[i].checked = true;
                        data[i].addedToArray = true;
                    }
                }
                await this.connectionAndSearchResults.push(data[i]);
            }
        }
    }

    checkUncheckUser(connection) {
        if (connection.checked == true) {
            this.displayArray.push(connection)
        }
        else {
            this.selectedUserArray = this.selectedUserArray.filter(function (e) {
                return e._id !== connection._id;
            });
            this.displayArray = this.displayArray.filter(function (e) {
                return e._id !== connection._id;
            });
        }


        // connection.checked = !connection.checked;
        // if(connection.checked == true){
        // 	this.selectedUserArray.push(connection);
        // }
        // else{
        // 	this.selectedUserArray = this.selectedUserArray.filter(function (e) {
        // 		return e._id != connection._id;
        // 	})
        // }
    }

    save() {
        if (this.actionButtonText == "Confirm") {
            this.modalCtrl.dismiss({
                updatedArray: this.displayArray
            });
        } else this.sharePost();
    }

    filterIdsFromArray() {
        this.specificConnectionIds = [];
        for (var i = 0; i < this.displayArray.length; i++) {
            this.specificConnectionIds.push(this.displayArray[i]._id);
        }
    }

    async sharePost() {
        this.filterIdsFromArray();

        let details: casePostCreationObject = {
            thoughts: "",
            caseTitle: "",
            extract: "",
            questions: "",
            gid: "",
            creationDate: new Date(),
            updatedDate: new Date(),
            disabledBy: "",
            disabledDate: "",
            Disable: false,
            identify: "",
            photos: [],
            type: "Shared",
            comment: this.sharingCommentByUser,
            postRef: this.originalPostId,
            postVisibility: "Specific connections",
            specificConnectionsVisibility: this.specificConnectionIds,
            shares: [] /** THIS PARAMETER IS FOR KEEPING THE IDS AND COUNT OF TOTAL SHARES OF ORIGINAL POST */,
            commentId: [],
            updates: [],
            userExpression: [],
            openGroupFollow: [],
            allowOthersToShare: false,
            userFollow: [] /** USERIDS OF PEOPLE COMMENTED AND CREATED THE POST ARE ADDED TO THIS ARRAY */,
            pushNotificationUsers: [],
            postOrCaseSecurityPolicy: false,
            offlinePublishStatus: "not started"
        };

        (await this.postService.addPost(details)).subscribe(
            (res: any) => {
                if (res.error == false) {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'showConnectionsPage', 'sharePost()', '');
                    this.modalCtrl.dismiss();
                }
                else {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'sharePost()', '', 'Error in response');
                }
            }, (error => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'showConnectionsPage', 'sharePost()', '', error);
            })
        );
    }

    goBack() {
        this.modalCtrl.dismiss();
    }

}
