import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ChatMessage } from 'src/app/interfaces/ai-chat';
import { LoggingService } from 'src/app/services/logging/logging.service';
import * as marked from 'marked';
import { StorageService } from 'src/app/services/storage/storage.service';


@Injectable({
    providedIn: 'root'
})
export class AIChatService {
    private apiUrl = environment.AI_URL
    baseAIServerUrl: any = environment.AI_API_URL
    aiActiveChatRoomId: any = ''
    activeAiChatRooms: any = [];
    messages: ChatMessage[] = [{
        sender: 'AI',
        message: 'Hello! How can I help you today?',
        og_text: '',
        showCopyIcon: false,
        showTime: false,
        time: '',
        showLike: false,
        senderId: '',
        receiverId: 'NeuroAI',
        Deleted: false,
        createdAt: '',
        messageType: '',
        chatType: '',
        chatRoomId: this.aiActiveChatRoomId
    }];
    nextPageDatetime: any = '';
    hasMoreChatRooms: any = false;
    selectedChatListIndex: number | null = null; // Store selected item index

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        public loggingService: LoggingService,
    ) { }

    sendMessage(chatMessage: ChatMessage): Observable<{ evaluation: string, piiDetected: any, detectedPii: any, token?: string, retryLimit?: number, chatRoomId?: string }> {

        return this.http.post<{ evaluation: string, piiDetected: any, detectedPii: any, token?: string }>(this.apiUrl, chatMessage,
        ).pipe(
            tap(response => {
                console.log('Response:', response);
                if (response.token) {
                    this.storageService.setStorage('token', response.token);
                    console.log('New Token Stored:', response.token);
                }
            })
        );
    }

    // sendMessage(transcript: string): Observable<any> {
    //   return this.http.post(this.apiUrl, { transcript });
    // }

    ///start this is used for triggering refreshAIChatPage
    private refreshAIChatPage = new Subject<any>();
    refreshAIChatPage_trigge$ = this.refreshAIChatPage.asObservable();

    callrefreshAIChatPage(data) {
        this.refreshAIChatPage.next(data);
    }
    ///end this is used for triggering refreshAIChatPage

    createClearInputEvent() {
        // Create and dispatch the custom event
        const event = new CustomEvent('chatRoomChanged', {});
        window.dispatchEvent(event); // Dispatch globally
    }

    createscrollToBottomEvent() {
        // Create and dispatch the custom event
        const event = new CustomEvent('scrollToBottom', {});
        window.dispatchEvent(event); // Dispatch globally
    }

    getMessagesForAIActiveWindow(roomId, nextdatetime) {
        return this.http.get(this.baseAIServerUrl + '/api/chats?chatRoomId=' + roomId + '&datetime=' + nextdatetime).pipe(tap(res => {
            this.createClearInputEvent();
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getMessagesForActiveWindow()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getMessagesForActiveWindow()', '', err)
        }))
    }

    getChatRoomList(nextdatetime) {
        return this.http.get(this.baseAIServerUrl + '/api/chatRooms?datetime=' + nextdatetime).pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getMessagesForActiveWindow()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getMessagesForActiveWindow()', '', err)
        }))
    }

    getUpdatedChatRoomList() {
        return this.http.get(this.baseAIServerUrl + '/api/updateChatRooms').pipe(tap(res => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'chatService', 'getMessagesForActiveWindow()', '');
            return res;
        }), catchError((err) => {
            throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'chatService', 'getMessagesForActiveWindow()', '', err)
        }))
    }

    getChat() {
        this.getMessagesForAIActiveWindow(this.aiActiveChatRoomId, "").subscribe(async (res: any) => {
            if (res) {
                this.messages = []
                console.log("🚀 ~ AiChatWindowComponent ~ getChat ~ res:", res)
                let chats = res.chats

                for (let i = 0; i < chats.length; i++) {
                    const message = chats[i];
                    message.content = await this.parseMarkdown(message.content)
                    this.messages.push(message)
                }

                this.createscrollToBottomEvent();
            }
        })
    }

    getChatList(date) {
        this.getChatRoomList(date).subscribe((res: any) => {
            if (res) {
                console.log("🚀 ~ ChatPage ~ this.aiChatService.getChatRoomList ~ res:", res);
                this.activeAiChatRooms = res.chatRooms;
                if (res.hasMore == true) {
                    this.hasMoreChatRooms = res.hasMore;
                    this.nextPageDatetime = res.nextPageDatetime;
                }
                console.log("🚀 ~ AIChatService ~ this.getChatRoomList ~ this.activeAiChatRooms:", this.activeAiChatRooms)
            }
        })
    }

    getMoreChatList() {
        this.getChatRoomList(this.nextPageDatetime).subscribe((res: any) => {
            if (res) {
                console.log("🚀 ~ ChatPage ~ this.aiChatService.getChatRoomList ~ res:", res);

                this.hasMoreChatRooms = res.hasMore;
                this.nextPageDatetime = res.nextPageDatetime;


                let newchatrooms = res.chatRooms;
                for (let i = 0; i < newchatrooms.length; i++) {
                    const room = newchatrooms[i];
                    this.activeAiChatRooms.push(room);
                }

                console.log("🚀 ~ AIChatService ~ this.getChatRoomList ~ this.activeAiChatRooms:", this.activeAiChatRooms)
            }
        })
    }

    reDefineMessageObj() {
        console.log("🚀 ~ AIChatService ~ reDefineMessageObj ~ reDefineMessageObj()")

        this.messages = [{
            sender: 'AI',
            message: 'Hello! How can I help you today?',
            og_text: '',
            showCopyIcon: false,
            showTime: false,
            time: '',
            showLike: false,
            senderId: '',
            receiverId: 'NeuroAI',
            Deleted: false,
            createdAt: '',
            messageType: '',
            chatType: '',
            chatRoomId: ''
        }];

        this.aiActiveChatRoomId = '';
    }

    parseMarkdown(text: string) {
        return marked.parse(text);
    }
}




// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class ChatService {

//   private apiUrl = 'http://localhost:3000/api/evaluate';  // Backend API URL
//  // private apiUrl = 'https://healable-widget-back-770d9e4a5737.herokuapp.com/api/chat';  // Backend API URL

//   constructor(private http: HttpClient) { }

//   sendMessage(message: string): Observable<any> {
//     return this.http.post(this.apiUrl, { message });
//   }
// }
