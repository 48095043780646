import { Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmptyScreenService {

    emptyScreenMessages: any = {};
    baseServerUrl: any = environment.API_URL;

    constructor(
        public loggingService: LoggingService,
        public http: HttpClient,
    ) {
        this.fetchAllTheEmptyMessages().subscribe((res: any) => {
            console.log("🚀 ~ file: empty-screen.service.ts:41 ~ EmptyScreenService ~ this.fetchAllTheEmptyMessages ~ res:", res)
        })
    }

    fetchAllTheEmptyMessages() {
        console.log("fetchAllTheEmptyMessages called");
        return this.http.get(this.baseServerUrl + '/emptyScreenMessage').pipe(tap((res: any) => {
            console.log("🚀 ~ file: empty-screen.service.ts:43 ~ EmptyScreenService ~ returnthis.http.get ~ res:", res)
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'EmptyScreenService', 'FetchAllTheEmptyMessages()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'EmptyScreenService', 'FetchAllTheEmptyMessages()', '', err);
            }))
    }
}
