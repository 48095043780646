import { Component, NgZone } from '@angular/core';
import { environment } from '../../src/environments/environment'
import { AuthenticationService } from './services/authentication/authentication.service';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as Raven from 'raven-js';
import { FcmService } from './services/fcm/fcm.service';
import { SocketService } from './services/socket/socket.service';
import { PostService } from 'src/app/services/post/post.service';

import { Location, PlatformLocation } from '@angular/common';
import { MediaService } from './services/media/media.service';
import { StorageService } from './services/storage/storage.service';
import { UserService } from './services/user/user.service';
import { LoggingService } from './services/logging/logging.service';
import { HttpClient } from '@angular/common/http';
import { InternetService } from './services/internet/internet.service';
import { globalConstants } from "src/assets/config/global-constants";
import { GetAppComponent } from './components/get-app/get-app.component';
import { TourGuideService } from './services/tour-guide/tour-guide.service';
import { EmptyScreenService } from './services/empty-screen/empty-screen.service';
// import { INotificationPayload } from 'cordova-plugin-fcm-with-dependecy-updated';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
// import { register } from 'swiper/element/bundle';
import { log } from 'console';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PushNotifications, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { ChatService } from './services/chat/chat.service';
import { OauthService } from './services/oauth/oauth.service';

// register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})

export class AppComponent {
    platformType: any = '';
    version: any;
    public maintenanceResponse: any;
    public urlParams: any = [];
    loggingConstants: any;
    public pushtoPageValue: any;
    public urlpassword: any
    public versionResponseFromAPI: any
    authenticateResponse: any;
    public hasNotificationPermission: boolean;
    public notificationToken: string;
    appActiveTimeOut: any;
    globalConstants: any = globalConstants;
    logOutTime: any = 259200000;
    globalEmergencyAlertAvailable: boolean = true;
    globalLoader: boolean = false;
    //public pushPayload: INotificationPayload;
    constructor(
        public authService: AuthenticationService,
        public zone: NgZone,
        public platform: Platform,
        public router: Router,
        public fcm: FcmService,
        public socketService: SocketService,
        public location: Location,
        public mediaService: MediaService,
        public internetService: InternetService,
        public storageService: StorageService,
        public pltLocation: PlatformLocation,
        public modalCtrl: ModalController,
        public loadingController: LoadingController,
        public userService: UserService,
        public loggingService: LoggingService,
        public alertCtrl: AlertController,
        public http: HttpClient,
        public postService: PostService,
        public toastController: ToastController,
        public tourGuideService: TourGuideService,
        public emptyScreenService: EmptyScreenService,
        public chatService: ChatService,
        public oauthService: OauthService
    ) {

        this.appLinkDetect();
        this.userService.globalLoader.subscribe((v) => {
            this.globalLoader = v;
        });
        this.loggingService.fetchGlobalConstants();
        // this.testforloop().then((res)=>{
        //     console.log("res of test loop", res);

        // })



        Raven.config(environment.sentry, { ignoreErrors: ['Non-Error exception captured', 'ngOriginalError', 'wrapped(vendor)'] }).install()
        // this.router.navigate(['/welcome'])

        //add network listner : checks for internet connectivity of the app.
        internetService.checkNetworkStatus()
        internetService.setupNetworkListener()

        this.platform.ready().then((platformRes) => {
            // console.log("platfrom ready method called", platformRes)
            //   alert('platfrom ready method called')
            if (platformRes != 'dom') {
                //this.setupFCM();
                fcm.initializePushNotifService().then(res => {
                    this.functionToCallAfterFCM()
                })


                //CHECK FOR APP VERSION: ONLY VERSION FOR IOS OR ANDROID DEVICES
                this.authService.getAppVersion().subscribe(res => {
                    //HANDLE NAVIGATION BASED ON THE REPONSE
                    // console.log("app version response", res);

                }, err => {
                    // console.log("error in version call", err);
                })
                // 	//HU-

                // never logout automatically
                // this.platform.pause.subscribe(() => {
                //     setTimeout(() => {
                //         this.timeOutLogout();
                //     }, this.logOutTime);
                //     this.socketService.disconnectSocket()
                // })

                this.platform.resume.subscribe(() => {

                    // this.socketService.setupSocketListeners()
                })

            }
            else {
                this.functionToCallAfterFCM()

            }
            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                console.log('Push notification received in foreground:', notification);
                // Optional: handle notification display or logic here
            });

            // Handle notification click (when app is in background or closed)
            PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
                this.handleNotificationRouting(notification.notification.data);
            });

            // this.socketService.setupSocketListeners()



        })


        this.platform.backButton.subscribeWithPriority(10, () => {
            // console.log('Back press handler!',this.router.url);
            if (this.location.isCurrentPathEqualTo('/create-case-or-post')) {

                //show alert
                // this.mediaService.uploadEditMediaArray = [];
                this.postService.backfromCreatePostOrCase();

            } else {

                // Navigate to back page
                console.log('Navigate to back page');
                this.modalState();
                // this.location.back();
            }
        });

        this.pltLocation.onPopState(async () => {
            let modal = await this.modalCtrl.getTop();
            if (modal) {
                // modal.dismiss();
            }
            if (this.router.url == '/create-case-or-post') {
                this.mediaService.uploadEditMediaArray = []
            }
        })

        this.oauthService.loadGoogleScript();
    }

    async modalState() {

        let modal = await this.modalCtrl.getTop();
        console.log(modal)
        var input = document.getElementById("recordAudio");
        console.log(input)

        if (modal) {
            if (input != null) {
                this.mediaService.callgettrigerAudioActions("backfromAudioRecording")
            }
            else {
                modal.dismiss();
            }

        }
        else {
            if (this.router.url == '/create-case-or-post') {
                this.mediaService.uploadEditMediaArray = []
            }
            else {
                if ((this.router.url).includes("tabs/tab1")) {
                    navigator['app'].exitApp();
                } else {
                    this.location.back();
                }
            }
        }
    }

    // async testforloop(){
    //     return new Promise(async (resolve, reject)=>{
    //         for (let index = 0; index < 10; index++) {
    //             setTimeout(() => {
    //                 console.log("index = " , index);     
    //             }, 1000);

    //         }
    //         console.log("first for loop is done");

    //         for (let j = 11; j < 20; j++) {
    //             console.log("j = " , j);     
    //         }

    //         console.log("second for loop is done");

    //         resolve(true)
    //     })

    // }






    // async setupFCM() {
    // 	await this.platform.ready();
    // 	console.log('FCM setup started');

    // 	if (!this.platform.is('cordova')) {
    // 		return;
    // 	}
    // 	console.log('In cordova platform');

    // 	console.log('Subscribing to token updates');


    // 	// this.fcm.onTokenRefresh().subscribe((newToken) => {
    // 	// 	this.notificationToken = newToken;
    // 	// 	console.log('onTokenRefresh received event with: ', newToken);
    // 	// });


    // 	//Subscribe to a new Notification
    // 	// console.log('Subscribing to new notifications');
    // 	// this.fcm.onNotification().subscribe((payload) => {
    // 	// 	this.pushPayload = payload;
    // 	// 	console.log('onNotification received event with: ', payload);
    // 	// });


    // 	// Request push notification permission on iOS, alerting the user if he/she/they have not yet accepted or denied. 
    // 	//For Android, it'll always return true.
    // 	this.hasNotificationPermission = await this.fcm.requestPushPermission();
    // 	console.log('requestPushPermission result: ', this.hasNotificationPermission);

    // 	//Gets device's current registration id.
    // 	this.notificationToken = await this.fcm.getToken();


    // 	// this.pushPayload = await this.fcm.getInitialPushPayload();
    // 	// console.log('getInitialPushPayload result: ', this.pushPayload);
    // }

    private handleNotificationRouting(data: any) {
        // console.log("Inside h/andling notifcaiton rotuingn")
        if (data.route && data.actionOnId) {

            console.log("Notif data: ", data.route, " ", data.actionOnId)
            const route = data.route;
            //   const navigationExtras = {
            //     state: {
            //       roomId: data.actionOnId
            //     }
            //   };

            // Navigate to the specified route
            if (data.route === '/group-home') {
                this.router.navigate([route, data.actionOnId, 'GroupWall']);
            }
            else if (data.route === '/active-chat-window') {
                //call for trigger
                this.chatService.callgetchatUserTrigger(data.actionOnId);
                this.router.navigate(['/tabs/tab2']);
            }
            else {
                this.router.navigate([route, data.actionOnId]);
            }

        } else {
            console.log('No route specified in notification data; handling as usual.');
        }
    }

    async ngOnInit() {

        // this.tourGuideService.callAllMessages().subscribe((res) => {
        //     console.log("🚀 ~ file: app.component.ts:244 ~ AppComponent ~ this.tourGuideService.callAllMessages ~ res:", res)
        // })

        // this.checkForPlatform();
        // const internetStatusValue = await this.internetService.checkNetworkStatus();
        // if (internetStatusValue.connected == false) {
        //     this.checkUserNetworkStatusAndProceed_noNetworkFound();
        // }
        // else {
        //     this.checkUserNetworkStatusAndProceed_networkFound();
        // }


        // if (sessionStorage.getItem('new-user') == 'true' && (sessionStorage.getItem('platform') == 'ioswebbrowser' || sessionStorage.getItem('platform') == 'androidwebbrowser')) this.presentAppStoreAlert();

        // this.emptyScreenService.fetchAllTheEmptyMessages().subscribe(async (res: any) => {
        //     console.log("🚀 ~ file: empty-screen.service.ts:41 ~ EmptyScreenService ~ this.fetchAllTheEmptyMessages ~ res:", res)
        //     this.emptyScreenService.emptyScreenMessages = await res.data[0].messages;
        // })
    }

    async functionToCallAfterFCM() {
        this.tourGuideService.callAllMessages().subscribe((res) => {
            console.log("🚀 ~ file: app.component.ts:244 ~ AppComponent ~ this.tourGuideService.callAllMessages ~ res:", res)
        })

        this.checkForPlatform();
        const internetStatusValue = await this.internetService.checkNetworkStatus();
        if (internetStatusValue.connected == false) {
            this.checkUserNetworkStatusAndProceed_noNetworkFound();
        }
        else {
            this.checkUserNetworkStatusAndProceed_networkFound();
        }


        if (sessionStorage.getItem('new-user') == 'true' && (sessionStorage.getItem('platform') == 'ioswebbrowser' || sessionStorage.getItem('platform') == 'androidwebbrowser')) this.presentAppStoreAlert();

        this.emptyScreenService.fetchAllTheEmptyMessages().subscribe(async (res: any) => {
            console.log("🚀 ~ file: empty-screen.service.ts:41 ~ EmptyScreenService ~ this.fetchAllTheEmptyMessages ~ res:", res)
            this.emptyScreenService.emptyScreenMessages = await res.data[0].messages;
        })
    }

    /***
     * verify the platform type on app load first 
     * and save platform type to session 
     */
    async checkForPlatform() {
        let platform = this.authService.checkPlatform();
        console.log("platformplatformplatformplatform", platform)
        if (platform == 'ios') {
            this.socketService.currentUserPlatform = "IOS"
        }
        else if (platform == 'android') {
            this.socketService.currentUserPlatform = "ANDROID"
        }
        else {
            this.socketService.currentUserPlatform = "web"
        }
        sessionStorage.setItem('platform', platform)
    }

    /**
     * Check user network status
     * If network found - check platform
     * check maintenance for web and version updates for phones
     */
    checkUserNetworkStatusAndProceed_networkFound() {
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     this.globalConstants = res;
        // });

        let platform = this.authService.checkPlatform();
        this.setNewUserInSessionStorage();


        if (platform == 'ios' || platform == 'android') {
            this.authService.getAppVersion().subscribe(res => {
                //HANDLE NAVIGATION BASED ON THE REPONSE
                if (res.newVersionAvailable == true) {
                    this.router.navigate(['/version'], { state: res }); // no need to pass data in URL as in phone app can not be refreshed
                }
                else {
                    this.authenticateUser();
                }
            }, err => {
                // console.log("error in version call", err);
            })
            // this.authenticateUser();
        }
        else {
            //CHECK FOR MAINTENANCE : ONLY WORKS IN WEB APP
            this.authService.checkForMaintenance().subscribe(res => {
                console.log("🚀 ~ file: app.component.ts:247 ~ AppComponent ~ this.authService.checkForMaintenance ~ res:", res)
                //HANDLE NAVIGATION BASED ON RESPONSE
                if (res.maintenanceRequired == true) {
                    // this.router.navigate(['/maintenance'], { state: res });
                    this.router.navigate(['/maintenance', res.resObject.maintenanceMsg]);
                }
                else {
                    this.authenticateUser();
                }
            }, err => {
                // console.log("error in maintenance call", err);
            })
        }

        if (sessionStorage.getItem('new-user') != 'false') this.checkIfGlobalAlertAvailable();
    }

    setNewUserInSessionStorage() {
        if (sessionStorage.getItem('new-user') == null && sessionStorage.getItem('new-user') != 'false') {
            sessionStorage.setItem('new-user', 'true');
        }
    }

    checkIfGlobalAlertAvailable() {
        this.authService.checkForGlobalEmergencyAlert().subscribe((res: any) => {
            console.log("🚀 ~ file: app.component.ts:283 ~ AppComponent ~ this.authService.checkForGlobalEmergencyAlert ~ res:", res)

            if (res.active == true) this.presentGlobalAlertToast(res);
        })
    }

    async presentGlobalAlert(DbResponse) {
        const globalAlert = await this.alertCtrl.create({
            header: `${DbResponse.title}`,
            message: `${DbResponse.message}`,
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        sessionStorage.setItem('new-user', 'false');
                    }
                }
            ]
        });
        globalAlert.present();

    }

    async presentAppStoreAlert() {
        const appStoreModal = await this.modalCtrl.create({
            component: GetAppComponent,
            backdropDismiss: true,
            cssClass: 'verification-modal',
        });
        appStoreModal.present();

        const { data, role } = await appStoreModal.onWillDismiss();

        sessionStorage.setItem('new-user', 'false');
    }

    async presentGlobalAlertToast(DbResponse) {
        const toast = await this.toastController.create({
            header: `${DbResponse.title}`,
            message: `${DbResponse.message}`,
            position: 'top',
            color: 'light',
            duration: 60000,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        sessionStorage.setItem('new-user', 'false');
                    },
                }
            ]
        });
        toast.present();
    }


    /**
     * Check user network status
     * If network not found - Check user token in storage
     * If token found - Navigate user to home wall
     * If token not found - do nothing
     */
    checkUserNetworkStatusAndProceed_noNetworkFound() {
        console.log("user no network found method");

        if (this.storageService.getStorage('session')) {
            this.router.navigate(['']);
        }
        else {
            this.router.navigate(['/welcome']);
        }
    }

    authenticateUser() {
        this.storageService.setStorage('chatPageActive', 'false')
        // this.socketService.setupSocketListeners('authenticate user');

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            console.log("🚀 ~ file: app.component.ts:423 ~ AppComponent ~ this.userService.getLoggedInUser ~ response:", response)
            if (response) {
                if (response.data[0].Disable == true) {
                    this.router.navigate(['/request-activate-account']);
                    return;
                } else {
                    if (this.storageService.getStorage("session")) {
                        this.socketService.setupSocketListeners('authenticate user');
                        this.userService.getUserInfoForGlobalUserObject();
                    }
                }
            }
        });
    }


    /**
     * Check user status - If a user had already logged in, then navigate user to wall page, orelse navigate to welcome page
     */
    // authenticateUser() {
    // console.log("authenticate user called");

    // Check if user local token is still valid, if not navigate to welcome page
    //    this.authService.authenticate().subscribe((isAuthorised) => {
    // console.log("authenticate user called with response");
    // console.log("🚀 ~ file: app.component.ts:284 ~ AppComponent ~ this.authService.authenticate ~ isAuthorised:", isAuthorised)
    // if (!isAuthorised) {
    //     this.router.navigate(['/welcome']);
    // }
    //  else {
    //   this.authenticateResponse = isAuthorised
    // this.loggingService.LogMessage(this.loggingConstants.log_debug, 'Page: Root -> Method: authenticateUser -> authenticate response ->'+ JSON.stringify(this.authenticateResponse), '');
    //   if (this.authenticateResponse.activated == true) {
    // Check user's signup status, if complete go to wall page else go to welcome page
    //  this.authService.checkUserStatus().subscribe((response: any) => {
    //console.log("🚀 ~ file: app.component.ts ~ line 288 ~ AppComponent ~ this.authService.checkUserStatus ~ response", response)
    // this.loggingService.LogMessage(this.loggingConstants.log_debug, 'Page: Root -> Method: authenticateUser -> checkUserStatus response ->'+JSON.stringify(response), '');
    // if (response.status == true) {

    // this.router.navigate(['']);
    // }
    // else {
    //     this.router.navigate(['/welcome']);
    // }
    // }, (err) => {
    //     this.router.navigate(['/welcome']);
    // })
    // }
    //  }
    // }, (err) => {
    //     // this.loggingService.LogMessage(this.loggingConstants.log_error, 'Page: Root ->Method: authenticateUser > authenticate error ->', JSON.stringify(err));
    // })
    // }

    async presentAutoLoginLoading() {
        const verificationLoading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: "loading",
        });
        await verificationLoading.present();

        const { role, data } = await verificationLoading.onDidDismiss();
        console.log('Loading dismissed!');
        // this.loadingController.dismiss();
    }

    timeOutLogout() {
        console.log("timeOutLogout called");

        this.userService.getLoggedInUser().subscribe(async (response: any) => {
            if (response) {
                if (response.data[0].signupProcessCompleted == 'true') {
                    console.log("timeOutLogout if called");

                    const alert = await this.alertCtrl.create({
                        header: 'You have been logged out due to an extended period of inactivity.',
                        buttons: ['OK']
                    });
                    alert.present();
                    this.logout();
                } else
                    console.log("timeOutLogout else called");

                this.logout();
            }
        });
    }

    logout() {
        console.log("logout called");
        localStorage.clear();
        sessionStorage.clear();
        this.socketService.disconnectSocket();
        this.router.navigate(['/welcome']);
    }

    appLinkDetect() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                // console.log("external link click "+event.url)
                if ((event.url).includes("user-profile")) {
                    var urldetails = event.url.split("/user-profile/");
                    this.router.navigate(['/user-profile', urldetails[1]]);
                }

                if ((event.url).includes("post-details")) {
                    var urldetails = event.url.split("/post-details/");
                    this.router.navigate(['/post-details', urldetails[1]]);
                }

                if ((event.url).includes("group-home")) {
                    var urldetails = event.url.split("/group-home/");
                    // console.log(urldetails[1])
                    // console.log(urldetails[1].split("/"))
                    var urlgrp = urldetails[1].split("/")
                    this.router.navigate(['/group-home', urlgrp[0], urlgrp[1]]);
                }

            });
        });
    }

}
