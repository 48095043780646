import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { environment } from 'src/environments/environment';
import { EncryptionService } from "../encryption/encryption.service";
import { catchError } from "rxjs/operators";
import { LoggingService } from "../logging/logging.service";
import { ModalController } from "@ionic/angular";
import { WelcomePage } from "src/app/modules/welcome/welcome/welcome.page";
import { Router } from "@angular/router";
import { globalConstants } from "src/assets/config/global-constants";


// import { InternetService } from "../internet/internet.service";


/**
     * TO-DOS:
     * ERROR HANDLING CAN BE IMPLEMENTED DIRECTLY IN ORDER TO CATCH ALL THE API RELATED ERRORS
     * REFRESH TOKEN HANDLING CAN BE IMPLEMENTED ONCE BACKEND CAN BE CONFIGURED TO SEND REFRESH TOKEN EVERY 5 MINS.
     * AWS S3 CALLS CAN BE IMPLEMENTED WITH KEYS
     *
     */
@Injectable()
export default class ApiInterceptor implements HttpInterceptor {
    // StorageService: StorageService
    // encryptionService : EncryptionService
    returnValue: any = '';
    loggingConstraints: any = globalConstants;
    isNetworkOffline: boolean = true
    isLoginModalOpen: boolean = false;
    constructor(
        private encryptionService: EncryptionService,
        private storageService: StorageService,
        public loggingService: LoggingService,
        public http: HttpClient,
        public modalCtrl: ModalController,
        public router: Router,
    ) {
        // this.loggingService.fetchGlobalConstants();
    }
    intercept(request: HttpRequest<any>, next: HttpHandler) {

        // if(this.internetService.isNetWorkAvailable == false){
        // 	console.log("inside interceptor no network condition");

        // 	this.internetService.presentMessageAlert()
        // 	this.returnValue = ""

        // }
        //If request url is of login or signup then we don’t have to add Authorization headers or anything. 
        //So we have returned original request in this case.
        if (request.url.includes("authentication") || request.url.includes("version") || request.url.includes("loginAction:'resendVerifySignup'") || request.url.includes("specialization") || request.url.includes("health-interests")) {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            let url = `${request.url}`;
            let newAuthRequest = request.clone({ headers, url });
            this.returnValue = newAuthRequest;
            // INFO
            // return next.handle(newAuthRequest);
        }
        else if (
            request.url.includes("maintenance") ||
            request.url.includes("/userdetails?email") ||
            request.url.includes("/forgotpassword") ||
            request.url.includes("/systemOptions") ||
            request.url.includes("/analytics") ||
            request.url.includes("/emptyScreenMessage") ||
            request.url.includes("/userGuideMessage") ||
            (
                request.url.includes("/public") &&
                !request.url.includes("/publications")
            ) ||
            request.url.includes("/video-cloud-front-sign") ||
            request.url.includes("/globalEmergencyAlert")
        ) {
            let apiAuth = this.encryptionService.encryption(environment.userAuthenticationApi)
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': apiAuth
            });
            let url = `${request.url}`;
            let newMaintenanceRequest = request.clone({ headers, url });
            this.returnValue = newMaintenanceRequest;
            // return next.handle(newMaintenanceRequest);
        }
        else if (request.url.includes("amazonaws.com")) {
            let headers

            if (request.url.includes("video")) {
                headers = new HttpHeaders({
                    'Content-Type': 'application/octet-stream',
                });
            }
            else if (request.url.includes("audio")) {
                headers = new HttpHeaders({
                    'Content-Type': 'audio/mpeg',
                });
            }
            else {
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                });
            }
            let url = `${request.url}`;
            let newAwsRequest = request.clone({ headers, url });
            this.returnValue = newAwsRequest;
            // return next.handle(newAwsRequest);
        }
        else {

            // if (!this.storageService.getStorage('session') && !request.url.includes("user-profile") && !request.url.includes("newnotifications")) {
            //     this.openWelcomePageModal();
            // }
            let headerObjects = {
                'Authorization': this.storageService.getStorage('session'),
                'Content-Type': 'application/json'
            };

            const storedToken = this.storageService.getStorage('token') || '';
            console.log('Stored Token:', storedToken);
            if(storedToken){
                headerObjects['token'] = storedToken;
            }
            let headers = new HttpHeaders(headerObjects);
            //const url = `${request.url}&api_key=xyZapiKeyExample`; THIS CAN BE USED WHEN SENDING ANY 
            //API KEY AUTHENTICATION IN THE REQUEST, SUCH AS: AWS S3 OR ANY API PROTECTED BACKEDN SERVICE
            let url = `${request.url}`;
            /**
             * request.clone(options) method clone HttpRequest object and modifying headers, 
             * url will affect newRequest object. By returning next.handle(newRequest), 
             * HttpHandler will now make request with modifications for all api calls 
             * other than login and signup.
             */
            let newRequest = request.clone({ headers, url });
            this.returnValue = newRequest;
            // return next.handle(newRequest);

            /**
             * We can use rxjs pipe and catchError operator to handle errrors.
             */
        }
        return next.handle(this.returnValue).pipe(catchError((err: any) => {
            console.log("🚀 ~ file: api-interceptor.service.ts:125 ~ ApiInterceptor ~ returnnext.handle ~ this.returnValue:", this.returnValue)
            console.log("interceptor error block", err);

            this.logErrorToSentry(err);
            throw err;
        }))
    }

    // async openWelcomePageModal() {
    //     if (this.isLoginModalOpen == false) {
    //         this.isLoginModalOpen = true;
    //         const welcomePageModal = await this.modalCtrl.create({
    //             component: WelcomePage,
    //             backdropDismiss: false,
    //             cssClass: 'signup-modal',
    //             componentProps: {
    //                 openAs: 'modal'
    //             }
    //         });
    //         welcomePageModal.present();

    //         // const { data, role } = 
    //         await welcomePageModal.onWillDismiss()
    //             .then(() => {
    //                 this.isLoginModalOpen = false;
    //             })
    //     }
    // }

    logErrorToSentry(err) {
        if (err.status === 401) {
            this.loggingService.httpErrorHandler(this.loggingConstraints.log_error, 'apiInterceptorService', 'intercept()', '', err);
        }
    }

    fetchGlobalConstants() {
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     this.loggingConstraints = res;
        // });
    }
}


