import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { ReportPostUser } from 'src/app/interfaces/report-post-user';
import { BlockUser } from 'src/app/interfaces/block-user';
import { UserService } from 'src/app/services/user/user.service';
@Component({
    selector: 'app-report',
    templateUrl: './report.page.html',
    styleUrls: ['./report.page.scss'],
})
export class ReportPage implements OnInit {
    postReportReasons: any = [];
    userReportReasons: any = [];
    commentReportReasons: any = [];
    reportedPost: any;
    reportedUser: any;
    reportedCommentData: any;
    currentUserId: any;
    postUser: any;
    reason: any = '';
    custom: any = '';
    loggingConstants: any = [];
    reportedComment: any = null;
    err: any;
    modalData: any;
    action: any = ''
    comment: any = '';
    showBlockUserAlert: boolean = true;
    submitAttempt: boolean = false;
    constructor(public postService: PostService, public userService: UserService, public alertCtrl: AlertController, public modalCtrl: ModalController, public loggingService: LoggingService, public userAnalyticsService: UserAnalyticsService) {
        this.loggingService.fetchGlobalConstants();
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        console.log("🚀 ~ file: report.page.ts:31 ~ ReportPage ~ constructor ~ this.modalData:", this.modalData)

        if (this.modalData.hasOwnProperty('userReport')) {
            console.log("user property is true");
            this.action = 'reportUser'
            this.reportedUser = this.modalData.userReport
            this.currentUserId = this.modalData.currentUser
            this.getUserReportReasons();
        }
        else if (this.modalData.hasOwnProperty('postReport') && !this.modalData.hasOwnProperty('commentReport')) {
            this.action = 'reportPost'
            this.reportedPost = this.modalData.postReport;
            this.reportedComment = this.modalData.commentReport
            this.postUser = this.modalData.postReport.userInfo[0].full_name;
            this.getPostReportReasons();
        }
        else if (this.modalData.hasOwnProperty('postReport') && this.modalData.hasOwnProperty('commentReport')) {
            this.action = 'reportComment'
            this.reportedPost = this.modalData.postReport;
            this.reportedComment = this.modalData.commentReport;
            this.comment = this.modalData.commentInfo;//HL-444
            this.postUser = this.modalData.postReport.userInfo[0].full_name;
            // this.filterReportCommentData(this.reportedComment);
            this.getCommentReportReasons();
        }



    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ReportPage');
    }

    getPostReportReasons() {
        this.postService.getPostReportReasons().subscribe((res: any) => {
            if (res.error == false) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'constructor()', '');
                this.postReportReasons = res.resObject.data;
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', error);
        }));
    }

    getUserReportReasons() {
        this.postService.getUserReportReasons().subscribe((res: any) => {
            if (res.error == false) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'constructor()', '');
                this.userReportReasons = res.resObject.data;
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', error);
        }));
    }

    getCommentReportReasons() {
        this.postService.getCommentReportReasons().subscribe((res: any) => {
            if (res.error == false) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'constructor()', '');
                this.commentReportReasons = res.resObject.data;
            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'constructor()', '', error);
        }));
    }

    filterReportCommentData(commentId) {
        for (let i = 0; i < this.reportedPost.comments.length; i++) {
            const comment = this.reportedPost.comments[i];
            if (comment._id == this.reportedComment) {
                this.comment = comment;
                console.log("🚀 ~ file: report.page.ts:78 ~ ReportPage ~ filterReportCommentData ~ this.comment:", this.comment)
            }
        }
    }

    async report() {
        if (this.reason == '' && this.custom == '') {
            this.submitAttempt = true;
            return;
        }

        switch (this.action) {
            case 'reportUser':
                this.reportUser()
                break;

            case 'reportPost':
                this.reportPost()
                break;

            case 'reportComment':
                this.reportPost()
                break;

            default:
                break;
        }
    }

    async reportUser() {
        let content: ReportPostUser = {
            postID: null,
            reportedUserID: this.reportedUser._id,
            reportingDate: new Date(),
            reason: this.reason,
            other: this.custom,
            action: 'unresolved',
            commentID: null,
            commentAction: null,
            read: 'false'
        };
        this.postService.reportPost(content).subscribe(async (res: any) => {
            if (res.error == false) {
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'report() -> reportedUser', '');
                if (this.showBlockUserAlert) {
                    let alert = this.alertCtrl.create({
                        message: "We will review this user within 24 hours and remove them within that timeframe, if inappropriate. For now, you can choose to block the user and not see any of their content on your wall.",
                        buttons: [
                            {
                                text: 'Report Only',
                                role: 'cancel',
                                handler: () => {
                                    //this.dismiss();;
                                    this.modalCtrl.dismiss({ userBlocked: false })
                                },
                            },
                            {
                                text: 'Block User',
                                role: 'confirm',
                                handler: () => {
                                    let blockUserObject: BlockUser = {
                                        blockedBy: this.currentUserId,
                                        blockedUser: this.reportedUser._id,
                                        blockedComment: '',
                                        blockedPost: '',
                                        action: 'block'
                                    }
                                    this.userService.blockUser(blockUserObject).subscribe(async (res) => {
                                        console.log("user blocked successfully", res);
                                        let successalert = this.alertCtrl.create({
                                            message: "User blocked. You can unblock this user from the 'Blocked Users' option in the settings tab. ",
                                            buttons: ['Ok']
                                        });
                                        (await successalert).present()
                                        this.modalCtrl.dismiss({ userBlocked: true })
                                    })

                                },
                            },
                        ],
                    });
                    (await alert).present();
                } else {
                    this.modalCtrl.dismiss();
                }

            }
            else {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report() -> reportedComment == null', '', 'Error in response');
            }
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report() -> reportedComment == null', '', error);
        }));
    }




    async reportPost() {
        if ((this.reason != '' && this.reason != undefined && this.reason != null) || this.custom != '') {
            if (this.reportedPost && this.reportedComment == null) {
                let content: ReportPostUser = {
                    postID: this.reportedPost._id,
                    reportedUserID: null,
                    reportingDate: new Date(),
                    reason: this.reason,
                    other: this.custom,
                    action: 'unresolved',
                    commentID: null,
                    commentAction: null,
                    read: 'false'
                };

                this.postService.reportPost(content).subscribe(async (res: any) => {
                    if (res.error == false) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'report() -> reportedComment == null', '');
                        let alert = this.alertCtrl.create({
                            message: "We will review this post within 24 hours and remove it within that timeframe, if inappropriate.  ",
                            buttons: ['OK']
                        });
                        (await alert).present();
                        this.dismiss();
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report() -> reportedComment == null', '', 'Error in response');
                    }
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report() -> reportedComment == null', '', error);
                }));
            } else if (this.reportedPost && this.reportedComment) {
                let content: ReportPostUser = {
                    postID: this.reportedPost._id,
                    reportedUserID: '',
                    reportingDate: new Date(),
                    reason: this.reason,
                    other: this.custom,
                    action: 'unresolved',
                    commentID: this.reportedComment,
                    commentAction: 'unresolved',
                    read: 'false'
                };

                this.postService.reportPost(content).subscribe(async (res: any) => {
                    if (res.error == false) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'reportPage', 'report()', '');
                        let alert = this.alertCtrl.create({
                            message: "We will review this comment within 24 hours and remove it within that timeframe, if inappropriate. For now, you can choose to hide this comment for you from the options.",
                            buttons: ['OK']
                        });
                        (await alert).present();
                        this.dismiss();
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report()', '', 'Error in response');
                    }
                }, (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'reportPage', 'report()', '', error);
                }));
            }
        } else {
            let alert = this.alertCtrl.create({
                message: 'Please select any reason or type a message',
                buttons: ['OK']
            });
            (await alert).present();
        }
    }

    checkIfReasonSelected() {
        this.submitAttempt = true;
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
