import { Injectable } from '@angular/core';
import { EncryptionService } from '../encryption/encryption.service';
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    AWSSecretKey: string;
    CurrentUser: string;
    userEmail: string;
    CurrentLoglevel: string;
    refresherVariableForPhysicianSchedulePage: boolean;
    setupListenerOnce: boolean;
    loggingConstants: any = [];
    localStorageObj: any = {
        'CurrentUser': '',
        'userEmail': '',
        'currentUserId': '',
        'CurrentLoglevel': '',
        'notificationData': '',
        'session': '',
        'chatPageActive': '',
        'userObject': '',
        'resendCodeCount': '',
        'loginAttemptCount': '',
        'loginId': '',
        'signupProcessCompleted': '',
        'activeURL': '',
        'activeURLType': '',
        'aiChatRestrictionCount':'',
        'aiChatUserQnsCount':'',
        'token':'',
    }
    constructor(public encryptionProvider: EncryptionService) { }

    /**
     * Set storage with hashed key and encrypted value
     * @param key : set storage 
     * @param value : value of respective key
     * @returns 
     */
    public setStorage(key, value) {

        if (this.localStorageObj.hasOwnProperty(key)) {
            let hashedKey = this.encryptionProvider.hashing(key);
            let encryptedValue = this.encryptionProvider.encryption(value);
            localStorage.setItem(hashedKey, encryptedValue);
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Get from storage using hashed key. 
     * @param key : get from storage using this key. 
     */
    public getStorage(key) {

        let hashedKey = this.encryptionProvider.hashing(key);
        let response = localStorage.getItem(hashedKey)

        if (response != null || response != undefined) {
            let decryptedValue;
            decryptedValue = this.encryptionProvider.decryption(response);
            return decryptedValue;
        }
        else {
            return false;
        }


    }


    /**
* Method : Promisified getStorage
* Description :  Get from storage using hashed key. 
* This method is identical to another method 'getStorage' 
* Any changes made to this method should be applied to other method also
* @param {any} indicates error occured
* @return {Observable<error>}
*/
    async promisifiedGetStorage(key) {
        let hashedKey = this.encryptionProvider.hashing(key);
        return await new Promise((resolve, reject) => {
            localStorage.get(hashedKey).then(
                (response) => {
                    if (response) {
                        let decryptedValue;
                        decryptedValue = this.encryptionProvider.decryption(response);
                        resolve(decryptedValue);
                    }
                    else {
                        resolve(false)
                    }

                }, (error) => {
                    resolve(false);
                })
        })
    }

    /**
     * Clear all storage items except specified keys
     * @param except : array of keys to preserve in storage
     * @returns boolean indicating success
     */
    public clearAll(except?: string[]) {
        console.log("🚀 ~ StorageService ~ clearAll ~ clearAll:")
        try {
            if (!except || except.length === 0) {
                localStorage.clear();
                return true;
            }

            // Store values for excepted keys
            const preserved = {};
            except.forEach(key => {
                if (this.localStorageObj.hasOwnProperty(key)) {
                    const hashedKey = this.encryptionProvider.hashing(key);
                    const value = localStorage.getItem(hashedKey);
                    if (value) {
                        preserved[hashedKey] = value;
                    }
                }
            });

            // Clear all storage
            localStorage.clear();

            // Restore preserved values
            Object.keys(preserved).forEach(hashedKey => {
                localStorage.setItem(hashedKey, preserved[hashedKey]);
            });

            return true;
        } catch (error) {
            return false;
        }
    }

}
