import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
@Component({
    selector: 'app-connection-groups',
    templateUrl: './connection-groups.page.html',
    styleUrls: ['./connection-groups.page.scss'],
})
export class ConnectionGroupsPage implements OnInit {
    modalData: any;
    userGroups: any;

    constructor(public modalCtrl: ModalController, public userService: UserService, public loggingService: LoggingService, public userAnalyticsService: UserAnalyticsService) {
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        this.getUserConnections(this.modalData.uid);
        this.loggingService.fetchGlobalConstants();
    }

    getUserConnections(uid) {
        this.userService.getUserGroupsAndConnections(uid).subscribe((res: any) => {
            this.userGroups = res.resObject.data[0].userGroups;
            this.removeOpenGroupsFromDisplay();
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'ConnectionGroupsPage', 'getUserConnections()', '');
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'ConnectionGroupsPage', 'getUserConnections()', '', error);
        }))
    }

    removeOpenGroupsFromDisplay() {
        for (let i = 0; i < this.userGroups.length; i++) {
            const group = this.userGroups[i];
            if (group.groupType == "Open") this.userGroups.splice(i, 1);
        }
        console.log("🚀 ~ file: connection-groups.page.ts:34 ~ ConnectionGroupsPage ~ removeOpenGroupsFromDisplay ~ this.userGroups:", this.userGroups)
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ConnectionGroupsPage');
    }

    goBack() {
        this.modalCtrl.dismiss();
    }
}
