import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthOnlyGuard implements CanActivate {
    constructor(public authService: AuthenticationService, public router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("🚀 ~ file: auth-only.guard.ts:15 ~ AuthOnlyGuard ~ route:", route)
        if (route.params.promoCode) return true;
        else return this.checkAuthenticatedUser().then(res => res)
    }



    async checkAuthenticatedUser() {
        return new Promise<boolean>(async (resolve, reject) => {
            await (this.authService.authenticate()).subscribe((isAuthorised: any) => {
                if (!isAuthorised ||
                    isAuthorised == null ||
                    isAuthorised == undefined
                ) {
                    //  verificationLoading.dismiss()


                    this.router.navigate(['/welcome']);
                    resolve(false)

                }
                else {
                    console.log("inside else of route guard");
                    resolve(true)
                }
            }, (err) => {
                //  verificationLoading.dismiss()
                this.router.navigate(['/welcome']);
                resolve(false)
                // this.loggingService.LogMessage(this.loggingConstants.log_error, 'Page: Root ->Method: authenticateUser > authenticate error ->', JSON.stringify(err));
            })


            //  }
        })








    }

}
