export const environment = {
    production: true,
    API_URL: 'https://healable-notif-back-40009c7662fe.herokuapp.com',
    Client_URL: 'https://healable-sysfront.herokuapp.com',
    environmentName: 'Development Environment',
    ionicEnvName: 'Healable-systest',
    awsAccessKey: 'AKIAJ56VAPWNAS2CXZWQ',
    userAuthenticationApi: '503d8395-e1ad-45a5-8d75-2ad6515800cd',
    awsSecretKey: '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',
    bucketName: 'healable-systest',
    Appname: 'Healable',
    Version: 2.7,
    sentry: 'https://7296ba226d424f3eaa127a787164d4b4@o130337.ingest.sentry.io/287690',
    messagesToBeFetchedFromTwilioForHealableChat: 14,
    messagesToBeFetchedFromTwilioForVideoChat: 25

};
// export const environment = {
//     production: false,
//     API_URL: 'https://healable-systest.herokuapp.com',
//     Client_URL: 'https://healable-sysfront.herokuapp.com',
//     environmentName: 'Development Environment',
//     ionicEnvName: 'Healable-systest',
//     awsAccessKey: 'AKIAJ56VAPWNAS2CXZWQ',
//     userAuthenticationApi: '503d8395-e1ad-45a5-8d75-2ad6515800cd',
//     awsSecretKey: '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',
//     bucketName: 'healable-systest',
//     Appname: 'Healable',
//     Version: 1.2,
//     sentry: 'https://7296ba226d424f3eaa127a787164d4b4@o130337.ingest.sentry.io/287690',
//     messagesToBeFetchedFromTwilioForHealableChat: 14,
//     messagesToBeFetchedFromTwilioForVideoChat: 25

// };
