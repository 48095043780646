import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { catchError, map, tap } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';
import { globalConstants } from 'src/assets/config/global-constants';
@Injectable({
    providedIn: 'root'
})
export class SearchItemsService {
    baseServerUrl: any = environment.API_URL;
    globalSearchGroupResult = [];
    globalSearchPeopleResult = [];
    globalSearchCommunityResult = [];
    globalConstants: any = globalConstants;
    getAllResultsClicked: boolean = false;
    constructor(public http: HttpClient, public storageService: StorageService, public loggingService: LoggingService) {
        this.loggingService.fetchGlobalConstants();
        // this.http.get('assets/json/global-constants.json').subscribe((res: any) => {
        //     this.globalConstants = res;
        // });
    }

    public searchConnectionsForInfiniteScrollPages(
        skip,
        searchQuery,
        searchOperation,
        healthCareExclusive
    ) {
        let searchConnectionResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/usersearch?action=getConnectionsForInfiniteScrollPages&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&healthCareExclusive=' +
                healthCareExclusive +
                '&searchQuery=' +
                searchQuery +
                '&$limit=' +
                this.globalConstants.searchLimitValue +
                '&searchOperation=' +
                searchOperation
            )
            .pipe(
                map(
                    (response: any) => {
                        if (!response || response == undefined || response == null) {
                            searchConnectionResponse.error = true;
                        }
                        else {
                            searchConnectionResponse.error = false;
                            searchConnectionResponse.resObject = response;
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'searchConnectionsForInfiniteScrollPages()', '');
                        return searchConnectionResponse;
                    }), catchError((err) => {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'searchConnectionsForInfiniteScrollPages()', '', err)
                        searchConnectionResponse.error = true;
                        throw searchConnectionResponse;
                    })
            )
    }

    public getConnectionsForInfiniteScrollPages(skip, searchOperation, healthCareExclusive) {
        let getSearchConnectionResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/usersearch?action=getConnectionsForInfiniteScrollPages&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&$skip=' +
                skip +
                '&healthCareExclusive=' +
                healthCareExclusive +
                '&$limit=' +
                this.globalConstants.connectionsLimitValue +
                '&searchOperation=' +
                searchOperation
            )
            .pipe(map((response: any) => {
                if (!response || response == undefined || response == null) {
                    getSearchConnectionResponse.error = true;
                }
                else {
                    getSearchConnectionResponse.error = false;
                    getSearchConnectionResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'getConnectionsForInfiniteScrollPages()', '');
                return getSearchConnectionResponse;
            }),
                catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'getConnectionsForInfiniteScrollPages()', '', err)
                    getSearchConnectionResponse.error = true;
                    throw getSearchConnectionResponse;
                }));
    }


    public searchConnectionsForEditGroupAddMembers(
        skip,
        groupId,
        searchQuery,
        searchOperation,
        healthCareExclusive
    ) {
        let searchConnectionsForEditGroupAddMembersResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/usersearch?action=getConnectionsForEditGroupAddMembers&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&groupId=' +
                groupId +
                '&$skip=' +
                skip +
                '&healthCareExclusive=' +
                healthCareExclusive +
                '&$limit=' +
                this.globalConstants.connectionsLimitValue +
                '&searchQuery=' +
                searchQuery +
                '&searchOperation=' +
                searchOperation
            )
            .pipe(map((response: any) => {
                if (!response || response == undefined || response == null) {
                    searchConnectionsForEditGroupAddMembersResponse.error = true;
                }
                else {
                    searchConnectionsForEditGroupAddMembersResponse.error = false;
                    searchConnectionsForEditGroupAddMembersResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'searchConnectionsForEditGroupAddMembers()', '');
                return searchConnectionsForEditGroupAddMembersResponse;
            }),
                catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'searchConnectionsForEditGroupAddMembers()', '', err)
                    searchConnectionsForEditGroupAddMembersResponse.error = true;
                    throw searchConnectionsForEditGroupAddMembersResponse;
                }));

    }


    public getConnectionsForEditGroupAddMembers(skip, groupId, searchOperation, healthCareExclusive) {
        let getConnectionsForEditGroupAddMembersResponse = {
            error: false,
            resObject: {}
        }
        return this.http
            .get(
                this.baseServerUrl +
                '/usersearch?action=getConnectionsForEditGroupAddMembers&_id=' +
                this.storageService.getStorage('currentUserId') +
                '&groupId=' +
                groupId +
                '&$skip=' +
                skip +
                '&healthCareExclusive=' +
                healthCareExclusive +
                '&$limit=' +
                this.globalConstants.connectionsLimitValue +
                '&searchOperation=' +
                searchOperation
            )
            .pipe(map((response: any) => {
                if (!response || response == undefined || response == null) {
                    getConnectionsForEditGroupAddMembersResponse.error = true;
                }
                else {
                    getConnectionsForEditGroupAddMembersResponse.error = false;
                    getConnectionsForEditGroupAddMembersResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'getConnectionsForEditGroupAddMembers()', '');
                return getConnectionsForEditGroupAddMembersResponse;
            }),
                catchError((err) => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'getConnectionsForEditGroupAddMembers()', '', err)
                    getConnectionsForEditGroupAddMembersResponse.error = true;
                    throw getConnectionsForEditGroupAddMembersResponse;
                }));

    }

    globalSearchVariable: any;
    globalSearchResult: any = [];
    showSearchResults: boolean = true;
    performSearch(e, healthCareProfessional) {
        this.showSearchResults = false;
        this.globalSearchVariable = e.srcElement.value;
        if (this.globalSearchVariable != '') {
            // Special characters in the search string have to be encoded by using encodeURIComponent.
            this.showSearchResults = true;
            let details = {
                character: encodeURIComponent(this.globalSearchVariable),
                skipValue: 0,
                limitValue: 8,
                searchType: 'showWallList'
            };
            this.performGlobalSearch(details, healthCareProfessional).subscribe((response: any) => {
                console.log("🚀 ~ file: search-items.service.ts:214 ~ SearchItemsService ~ this.performGlobalSearch ~ response:", response)

                if (response.resObject.userResult) response.resObject.userResult = response.resObject.userResult.slice(0, 4);
                if (response.resObject.groupResult) response.resObject.groupResult = response.resObject.groupResult.slice(0, 4);
                if (response.resObject.userResult) this.globalSearchResult = response.resObject.userResult;
                if (response.resObject.groupResult) this.globalSearchResult = response.resObject.groupResult;
                if (response.resObject.userResult && response.resObject.groupResult) this.globalSearchResult = response.resObject.userResult.concat(response.resObject.groupResult);
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'performSearch()', '');
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'performSearch()', '', err)
                });
        }
        else {
            this.showSearchResults = false;
        }
    }

    performGlobalSearch(details, healthCareProfessionalValue) {
        let globalSearchResponse = {
            error: false,
            resObject: {}
        }

        let extension =
            '/usersearch?action=performGlobalSearch&searchType=' +
            details.searchType +
            '&regex=' +
            details.character +
            '&_id=' +
            this.storageService.getStorage('currentUserId') +
            '&skipValue=' +
            details.skipValue +
            '&limitValue=' +
            details.limitValue +
            '&healthCareProfessional=' +
            healthCareProfessionalValue;

        return this.http.get(this.baseServerUrl + extension).pipe(map((response: any) => {
            if (!response || response == undefined || response == null) {
                globalSearchResponse.error = true;
            }
            else {
                globalSearchResponse.error = false;
                globalSearchResponse.resObject = response;
            }
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'performGlobalSearch()', '');
            return globalSearchResponse;
        }),
            catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'performGlobalSearch()', '', err)
                globalSearchResponse.error = true;
                throw globalSearchResponse;
            }));
    }

    searchResultFound: boolean = true;
    globalSearchResults: any = [];
    skipValue: any = 0;
    fetchResultsForSearch(healthCareProfessional) {
        this.skipValue = 0;
        if (this.globalSearchVariable != '') {
            let details = {
                character: encodeURIComponent(this.globalSearchVariable),
                skipValue: this.skipValue,
                limitValue: this.globalConstants.searchLimitValue,
                searchType: 'showAllResultsOnGlobalSearchPage'
            };

            this.performGlobalSearch(details, healthCareProfessional).subscribe((response: any) => {
                this.searchResultFound = true;
                this.globalSearchResults = response.resObject;
                this.globalSearchGroupResult = [];
                this.globalSearchPeopleResult = [];
                this.globalSearchCommunityResult = [];
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'searchItemsService', 'fetchResultsForSearch()', '');
                if (this.globalSearchResults.userResult && this.globalSearchResults.userResult.length > 0) {
                    // this.globalSearchPeopleResult = this.globalSearchResults.userResult;
                    this.globalSearchPeopleResult = this.globalSearchResults.userResult.slice(0, 4);
                }


                if (this.globalSearchResults.groupResult && this.globalSearchResults.groupResult.length > 0) {
                    for (const group of this.globalSearchResults.groupResult) {
                        if (group.groupType == 'Open') {
                            this.globalSearchCommunityResult.push(group);
                        } else {
                            this.globalSearchGroupResult.push(group);
                        }
                    }
                    console.log("🚀 ~ file: search-items.service.ts:295 ~ SearchItemsService ~ this.performGlobalSearch ~ this.globalSearchCommunityResult", this.globalSearchCommunityResult)

                    this.globalSearchGroupResult = this.globalSearchResults.groupResult.slice(0, 4);
                }
                if (this.globalSearchPeopleResult.length == 0 && this.globalSearchGroupResult.length == 0) {
                    this.searchResultFound = false;
                }

                console.log("🚀 ~ file: search-items.service.ts:301 ~ SearchItemsService ~ this.performGlobalSearch ~ this.globalSearchCommunityResult:", this.globalSearchCommunityResult)
                console.log("🚀 ~ file: search-items.service.ts:304 ~ SearchItemsService ~ this.performGlobalSearch ~ this.globalSearchGroupResult:", this.globalSearchGroupResult)
                console.log("🚀 ~ file: search-items.service.ts:313 ~ SearchItemsService ~ this.performGlobalSearch ~ this.globalSearchPeopleResult:", this.globalSearchPeopleResult)
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'searchItemsService', 'fetchResultsForSearch()', '', err)
                });
        }
    }
}
