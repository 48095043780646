import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { catchError, map, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { Subject, Observable } from 'rxjs';

import { LoggingService } from '../logging/logging.service';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    notificationValue: any;
    baseServerUrl: any = environment.API_URL;
    constructor(
        public http: HttpClient,
        public storageService: StorageService,
        public userService: UserService,
        public loggingService: LoggingService
    ) {
        this.loggingService.fetchGlobalConstants();
    }

    receiveLazy(details) {
        let receiveLazyResponse = {
            error: false,
            resObject: {}
        };
        return this.http.get(this.baseServerUrl + details).pipe(map((response: any) => {
            if (!response || response == undefined || response == null) {
                receiveLazyResponse.error = true;
            } else {
                receiveLazyResponse.error = false;
                receiveLazyResponse.resObject = response;
            }
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'notificationService', 'receiveLazy()', '');
            return receiveLazyResponse;
        }), catchError((err) => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'notificationService', 'receiveLazy()', '', err)
            receiveLazyResponse.error = true;
            throw receiveLazyResponse;
        }));
    }

    /**
     * 
     * @param receiverId to get notification for
     * @param action 
     * @returns 
     */
    getNotificationForBadgeSetup(receiverId, action) {
        return this.http.get(this.baseServerUrl + '/newnotifications?currentUserId=' + receiverId + '&action=' + action).pipe(tap((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'notificationService', 'getNotificationForBadgeSetup()', '');
            // console.log("here is the notification api response", res);

            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'notificationService()', 'getNotificationForBadgeSetup()', '', err);
            }))
    }

    readNotification(details) {
        let readNotificationResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .patch(this.baseServerUrl + details, JSON.stringify({ read: true }))
            .pipe(map((response: any) => {
                if (!response || response == undefined || response == null) {
                    readNotificationResponse.error = true;
                } else {
                    readNotificationResponse.error = false;
                    readNotificationResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'notificationService', 'readNotification()', '');
                return readNotificationResponse;
            }), catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'notificationService', 'readNotification()', '', err)
                readNotificationResponse.error = true;
                throw readNotificationResponse;
            }));
    }



    send(details) {
        let sendResponse = {
            error: false,
            resObject: {}
        };
        return this.http.post(this.baseServerUrl + '/notifications', JSON.stringify(details))
            .pipe(
                map(
                    (response: any) => {

                        if (!response || response == undefined || response == null) {
                            sendResponse.error = true;
                        } else {
                            sendResponse.error = false;
                            sendResponse.resObject = response;
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'notificationService', 'send()', '');
                        return sendResponse;
                    }), catchError((err) => {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'notificationService', 'send()', '', err)
                        sendResponse.error = true;
                        throw sendResponse;
                    })
            );

    }


    ///start this is used for triggering refresh notification page
    private refreshnotification = new Subject<any>();
    refreshnotification_trigge$ = this.refreshnotification.asObservable();
    notificationrigerhit = false;
    callNOtificatoinTrigger(data) {
        this.refreshnotification.next(data);
    }


    ///end this is used for triggering refresh notification page

}
