import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { EncryptionService } from '../encryption/encryption.service';
import { ActionSheetController, AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { ShareFromWallPage } from 'src/app/modules/home/share-from-wall/share-from-wall.page';
import { Router } from '@angular/router';
import { ReportPage } from 'src/app/modules/home/report/report.page';
import { LoggingService } from '../logging/logging.service';
import { BlockUser } from '../../interfaces/block-user'
import { UserService } from '../user/user.service';
import { PostDeleteReasonComponent } from 'src/app/components/post-delete-reason/post-delete-reason.component';
import { CommentDeleteReasonComponent } from 'src/app/components/comment-delete-reason/comment-delete-reason.component';
import { Location } from '@angular/common';
import { MediaService } from 'src/app/services/media/media.service';
import { GroupsService } from '../groups/groups.service';
import { NotificationService } from '../notification/notification.service';
import { WallsService } from 'src/app/services/walls/walls.service';
import { TourGuideService } from 'src/app/services/tour-guide/tour-guide.service';


@Injectable({
    providedIn: 'root'
})
export class PostService {
    baseServerUrl: any = environment.API_URL;
    getPostMatchDateForWall: any;
    myPostWall: any = [];

    myHomeWallPost: any = []; // for tabed home page
    myPostsWallPost: any = [];  // for my ppost listing page
    groupsWallPost: any = []; // for group or community post listing page



    infiniteFlag: boolean = false;
    currentUserId: any = '';
    currentlySelectedPost: any = ''
    editPostObject: any
    constructor(
        public http: HttpClient,
        public storageService: StorageService,
        public encryptionService: EncryptionService,
        public alertController: AlertController,
        public toastCtrl: ToastController,
        public actionSheetController: ActionSheetController,
        public modalCtrl: ModalController,
        public router: Router,
        public location: Location,
        public mediaService: MediaService,
        public loggingService: LoggingService,
        public userService: UserService,
        public groupsService: GroupsService,
        public platform: Platform,
        public notificationService: NotificationService,
        public wallsService: WallsService,
        public TourGuideService: TourGuideService,
    ) {
        this.currentUserId = this.storageService.getStorage('currentUserId');
        this.loggingService.fetchGlobalConstants();
        console.log("🚀 ~ file: post.service.ts:63 ~ currentUserId:", this.currentUserId)
    }

    public getPostForHomeWall(details) {
        if (this.myHomeWallPost.length == 0) this.myHomeWallPost = [];

        let postResponse = {
            error: false,
            resObject: {}
        };
        details += '&userID=' + this.storageService.getStorage('currentUserId');
        return this.http.get(this.baseServerUrl + details).pipe(
            map(
                (response: any) => {
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;

                        if (response.latestTimeStamp && response.latestTimeStamp != '') {
                            this.getPostMatchDateForWall = response.latestTimeStamp;
                        }

                        if (response.data.length == 0) this.infiniteFlag = true;
                        else {
                            var previousIndex = this.myHomeWallPost.length;
                            this.myHomeWallPost = this.myHomeWallPost.concat(response.data);
                            this.processPosts(previousIndex, this.myHomeWallPost, this.currentUserId);
                            // console.log("🚀 ~ file: post.service.ts ~ line 62 ~ PostService ~ getPostForHomeWall ~ this.myHomeWallPost", this.myHomeWallPost)
                        }
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getPostForHomeWall()', '');
                    return postResponse;
                }),
            catchError((error) => {
                postResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getPostForHomeWall()', '', error)
                throw postResponse;
            })
        );
    }

    public getPostlist(details) {

        let postResponse = {
            error: false,
            resObject: {}
        };
        details += '&userID=' + this.storageService.getStorage('currentUserId');
        return this.http.get(this.baseServerUrl + details).pipe(
            map(
                (response: any) => {
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getPostlist()', '');
                    return postResponse;
                }),
            catchError((error) => {
                postResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getPostlist()', '', error)
                throw postResponse;
            })
        );
    }

    /**
   * @description: Get all the details related to the requested post.
   * @param singlePostId : unique post id requested by user
   * @returns : post details
   */
    public getSinglePostDetails(singlePostId) {
        let getSinglePostDetailsResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .get(
                this.baseServerUrl +
                '/homewallpost?action=singlePostFetch&primaryAction=postsFetch&singlePostId=' +
                singlePostId
            )
            .pipe(
                map(
                    (response: any) => {
                        if (!response || response == undefined || response == null) {
                            getSinglePostDetailsResponse.error = true;
                        } else {
                            getSinglePostDetailsResponse.error = false;
                            getSinglePostDetailsResponse.resObject = response.data[0];
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getSinglePostDetails()', '');
                        return getSinglePostDetailsResponse;
                    }),
                catchError((error) => {
                    getSinglePostDetailsResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getSinglePostDetails()', '', error);
                    throw getSinglePostDetailsResponse;
                })
            );
    }
    /**
   * @description: Get all the details related to the requested post in case of share post url.
   * @param singlePostId : unique post id requested by user
   * @returns : post details
   */
    public getSingleSharedPostDetails(singlePostId) {
        let getSinglePostDetailsResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .get(
                this.baseServerUrl +
                '/public?content=post&singlePostId=' +
                singlePostId
            )
            .pipe(
                map(
                    (response: any) => {
                        if (!response || response == undefined || response == null) {
                            getSinglePostDetailsResponse.error = true;
                        } else {
                            getSinglePostDetailsResponse.error = false;
                            getSinglePostDetailsResponse.resObject = response[0];
                        }
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getSinglePostDetails()', '');
                        return getSinglePostDetailsResponse;
                    }),
                catchError((error) => {
                    getSinglePostDetailsResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getSinglePostDetails()', '', error);
                    throw getSinglePostDetailsResponse;
                })
            );
    }

    ///start this is used for triggering refresh shared profile inof page
    private refreshSharedPOST = new Subject<any>();
    refreshSharedPOST_trigge$ = this.refreshSharedPOST.asObservable();

    callrefreshSharedPOSTTrigger(data) {
        this.refreshSharedPOST.next(data);
    }
    ///end this is used for triggering refresh shared profile inof page


    /**
   * @description: Get all the comments related to the requested post.
   * @param singlePostId : unique post id requested by user
   * @param timestamp : latest timestamp if
   * @returns : all comments
   */
    public getCommentsForPost(singlePostId, timestamp) {
        let commentResponse = {
            error: false,
            resObject: {}
        };
        return this.http.get(this.baseServerUrl + `/comments?postID=${singlePostId}&timestamp=${timestamp}`).pipe(
            map(
                (response: any) => {
                    if (!response || response == undefined || response == null) {
                        commentResponse.error = true;
                    } else {
                        commentResponse.error = false;
                        commentResponse.resObject = response.data;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getCommentsForPost()', '');
                    return commentResponse;
                }),
            catchError((error) => {
                commentResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getCommentsForPost()', '', error);
                throw commentResponse;
            })
        );
    }

    /**
   * @description: Create new post/case
   * @returns : post response
   */
    public addPost(details) {
        details.userFollow.push(this.storageService.getStorage('currentUserId'));
        details.pushNotificationUsers.push(this.storageService.getStorage('currentUserId'));
        let postResponse = {
            error: false,
            resObject: {}
        };
        return this.http.post(this.baseServerUrl + '/posts', JSON.stringify(details)).pipe(
            map(
                (response: any) => {
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;
                        this.callrefreshpostfeedTrigger("");
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addPost()', '');
                    return postResponse;
                }),
            catchError((err) => {
                postResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addPost()', '', err);
                throw postResponse;
            })
        );
    }

    /**
     * patch particular post 
     * When user tries to share a particular post from wall
     */
    public addToPost(postID, details) {
        let addToPostResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .patch(this.baseServerUrl + '/posts/' + postID, JSON.stringify(details))
            .pipe(
                map(
                    (response: any) => {
                        if (!response || response == undefined || response == null) {
                            addToPostResponse.error = true;
                        } else {
                            addToPostResponse.error = false;
                            addToPostResponse.resObject = response;
                        }
                        this.callrefreshpostfeedTrigger("");
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addToPost()', '');
                        return addToPostResponse;
                    }),
                catchError((err) => {
                    addToPostResponse.error = true;
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addToPost()', '', err);
                    throw addToPostResponse;
                })
            );
    }


    public getPostImagesFromAWSUrl(imageUrl) {
        return this.http.get(imageUrl).pipe(
            map((res: any) => {
                if (!res || res == null || res == undefined || res == '') {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_debug, 'postService', 'getPostImagesFromAWSUrl()', 'No response');
                    return false;
                } else {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getPostImagesFromAWSUrl()', '');
                    return res
                }
            }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getPostImagesFromAWSUrl()', '', err);
            })
        );
    }

    public getPdfFromAWSUrl(imageUrl) {
        return this.http.get(imageUrl, { responseType: 'arraybuffer' }).pipe(
            map((res: any) => {
                if (!res || res == null || res == undefined || res == '') {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_debug, 'postService', 'getPdfFromAWSUrl()', 'No response');
                    return false;
                } else {
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getPdfFromAWSUrl()', '');
                    return res;
                }
            }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getPdfFromAWSUrl()', '', err);
            })
        );
    }

    /**
     * 
     * @param objectId : id of post or comment for which the media is being requested
     * @param typeOfObject : it will be either 'post' or 'comment'
     * @param action : get the media url for display : getForDisplay
     * @returns 
     */
    public getMediaUrls(objectId, typeOfObject, action) {
        return this.http.get(this.baseServerUrl + '/media?objectId=' + objectId + '&typeOfObject=' + typeOfObject + '&action=' + action).pipe(map((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getMediaUrls()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getMediaUrls()', '', err);
            }))
    }

    public getVideoUrl(videoFileName) {
        return this.http.get(this.baseServerUrl + '/video-cloud-front-sign?fileName=' + videoFileName).pipe(tap((res: any) => {
         
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getVideoUrl()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getVideoUrl()', '', err);
            }))
    }

    public getAudioUrl(audioFileName) {
        return this.http.get(this.baseServerUrl + '/video-cloud-front-sign?fileName=' + audioFileName).pipe(tap((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getAudioUrl()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getAudioUrl()', '', err);
            }))
    }

    public getMediaUrlForDisplay(fileName) {
        return this.http.get(this.baseServerUrl + '/video-cloud-front-sign?fileName=' + fileName).pipe(tap((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getMediaUrlForDisplay()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getMediaUrlForDisplay()', '', err);
            }))
    }

    public actionPostTest(details) {
        let actionPostTestResponse = {
            error: false,
            resObject: {}
        };

        return this.http.get(this.baseServerUrl + '/posts?editAction=' + JSON.stringify(details)).pipe(
            map((response) => {
                if (!response || response == undefined || response == null) {
                    actionPostTestResponse.error = true;
                } else {
                    actionPostTestResponse.error = false;
                    actionPostTestResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'actionPostTest()', '');
                return actionPostTestResponse;
            }),
            catchError((err) => {
                actionPostTestResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'actionPostTest()', '', err);
                throw actionPostTestResponse;
            })
        );
    }

    followPost(post, currentUserId) {
        var contentAccessBlock = post.groupInfo
            ? post.groupInfo.groupType == 'Public' && post.groupInfo.joinCondition == false
            : false;

        if (contentAccessBlock == true) {
            this.actionCannotBeDoneAlert();
        } else {
            let editAction = {
                action: 'followPost',
                postId: post._id,
                currentUserId: currentUserId
            };

            this.actionPostTest(editAction).subscribe((response) => {
                post.follow = true;
                post.userFollow.push(currentUserId);
                post.userFollow = post.userFollow.filter((x, i, a) => a.indexOf(x) == i);
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'followPost()', '');
            }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'followPost()', '', err);
                });
        }
    }

    unfollowPost(post, currentUser, err) {
        let editAction = {
            action: 'unfollowPost',
            postId: post._id,
            currentUserId: currentUser
        };

        // let self = this;
        this.actionPostTest(editAction).subscribe((response) => {
            post.follow = false;
            post.userFollow.splice(post.userFollow.indexOf(currentUser));
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'unfollowPost()', '');
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'unfollowPost()', '', err);
            });
    }

    async actionCannotBeDoneAlert() {
        const alert = await this.alertController.create({
            message:
                'This feature is only available to group members. Please join this group first to use this feature. ',
            buttons: ['OK']
        });

        await alert.present();
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'actionCannotBeDoneAlert()', '');
    }

    // /** LIKE POST */
    likePost(post, currentUserId, walltype, err) {
        let editAction = {
            action: 'likePost',
            postId: post._id,
            currentUserId: currentUserId,
            implementAction: '',
            postAuthorId: post.postAuthorId
        };

        if (post.like.condition == true && post.like.expressionFlag == 'like')
            editAction.implementAction = 'undoAction';

        // let self = this;
        this.actionPostTest(editAction).subscribe((response) => {
            if (post.like.condition == true) {
                if (post.like.expressionFlag == 'like') {
                    post.like.condition = false;
                    post.like.likeCount--;
                } else {
                    post.like.dislikeCount--;
                    post.like.likeCount++;
                    post.like.condition = true;
                    post.like.expressionFlag = 'like';
                    post.like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.dislikeCount);
                }
            } else {
                post.like.condition = true;
                post.like.expressionFlag = 'like';
                post.like.likeCount++;
            }
            post.like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.likeCount);
            //not using following function call because post itself retrn the value update
            //this.refreshLikeThumb(post);

            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'likePost()', '');
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'likePost()', '', err);
            });
    }

    refreshLikeThumb(post) {

        setTimeout(() => {
            let indexmyHomeWallPost = this.myHomeWallPost.findIndex(x => x._id == post._id)
            if (indexmyHomeWallPost != -1) {
                if (this.myHomeWallPost[indexmyHomeWallPost].like.condition == true) {
                    if (this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag == 'like') {
                        this.myHomeWallPost[indexmyHomeWallPost].like.condition = false;
                        this.myHomeWallPost[indexmyHomeWallPost].like.likeCount--;
                    } else {
                        this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount--;
                        this.myHomeWallPost[indexmyHomeWallPost].like.likeCount++;

                        this.myHomeWallPost[indexmyHomeWallPost].like.condition = true;
                        this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag = 'like';
                        this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount);
                    }
                } else {
                    this.myHomeWallPost[indexmyHomeWallPost].like.condition = true;
                    this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag = 'like';
                    this.myHomeWallPost[indexmyHomeWallPost].like.likeCount++;
                }
                this.myHomeWallPost[indexmyHomeWallPost].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myHomeWallPost[indexmyHomeWallPost].like.likeCount);
            }


            let indexmyPostWall = this.myPostWall.findIndex(x => x._id == post._id)
            if (indexmyPostWall != -1) {
                if (this.myPostWall[indexmyPostWall].like.condition == true) {
                    if (this.myPostWall[indexmyPostWall].like.expressionFlag == 'like') {
                        this.myPostWall[indexmyPostWall].like.condition = false;
                        this.myPostWall[indexmyPostWall].like.likeCount--;
                    } else {
                        this.myPostWall[indexmyPostWall].like.dislikeCount--;
                        this.myPostWall[indexmyPostWall].like.likeCount++;

                        this.myPostWall[indexmyPostWall].like.condition = true;
                        this.myPostWall[indexmyPostWall].like.expressionFlag = 'like';
                        this.myPostWall[indexmyPostWall].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myPostWall[indexmyPostWall].like.dislikeCount);
                    }
                } else {
                    this.myPostWall[indexmyPostWall].like.condition = true;
                    this.myPostWall[indexmyPostWall].like.expressionFlag = 'like';
                    this.myPostWall[indexmyPostWall].like.likeCount++;
                }
                this.myPostWall[indexmyPostWall].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myPostWall[indexmyPostWall].like.likeCount);
            }




            let indexgroupsWallPost = this.groupsWallPost.findIndex(x => x._id == post._id)
            if (indexgroupsWallPost != -1) {

                if (this.groupsWallPost[indexgroupsWallPost].like.condition == true) {
                    if (this.groupsWallPost[indexgroupsWallPost].like.expressionFlag == 'like') {
                        this.groupsWallPost[indexgroupsWallPost].like.condition = false;
                        this.groupsWallPost[indexgroupsWallPost].like.likeCount--;
                    } else {
                        this.groupsWallPost[indexgroupsWallPost].like.dislikeCount--;
                        this.groupsWallPost[indexgroupsWallPost].like.likeCount++;

                        this.groupsWallPost[indexgroupsWallPost].like.condition = true;
                        this.groupsWallPost[indexgroupsWallPost].like.expressionFlag = 'like';
                        this.groupsWallPost[indexgroupsWallPost].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.groupsWallPost[indexgroupsWallPost].like.dislikeCount);
                    }
                } else {
                    this.groupsWallPost[indexgroupsWallPost].like.condition = true;
                    this.groupsWallPost[indexgroupsWallPost].like.expressionFlag = 'like';
                    this.groupsWallPost[indexgroupsWallPost].like.likeCount++;
                }
                this.groupsWallPost[indexgroupsWallPost].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.groupsWallPost[indexgroupsWallPost].like.likeCount);

            }
        }, 1200);
    }

    // /** DISLIKE POST */
    dislikePost(post, currentUser, walltype, err) {
        let editAction = {
            action: 'dislikePost',
            postId: post._id,
            currentUserId: currentUser,
            implementAction: '',
            postAuthorId: post.postAuthorId
        };

        if (post.like.condition == true && post.like.expressionFlag == 'dislike')
            editAction.implementAction = 'undoAction';

        // let self = this;
        this.actionPostTest(editAction).subscribe((response) => {
            if (post.like.condition == true) {
                if (post.like.expressionFlag == 'dislike') {
                    post.like.condition = false;
                    post.like.dislikeCount--;
                } else {
                    post.like.dislikeCount++;
                    post.like.likeCount--;
                    post.like.condition = true;
                    post.like.expressionFlag = 'dislike';
                    post.like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.likeCount);
                }
            } else {
                post.like.condition = true;
                post.like.expressionFlag = 'dislike';
                post.like.dislikeCount++;
            }
            post.like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.dislikeCount);
            //not using following function call because post itself retrn the value update
            // this.refreshDisLikeThumb(post);

            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'dislikePost()', '');
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'dislikePost()', '', err);
            });
    }

    refreshDisLikeThumb(post) {

        setTimeout(() => {
            let indexmyHomeWallPost = this.myHomeWallPost.findIndex(x => x._id == post._id)
            if (indexmyHomeWallPost != -1) {
                if (this.myHomeWallPost[indexmyHomeWallPost].like.condition == true) {
                    if (this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag == 'dislike') {
                        this.myHomeWallPost[indexmyHomeWallPost].like.condition = false;
                        this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount--;
                    } else {
                        this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount++;
                        this.myHomeWallPost[indexmyHomeWallPost].like.likeCount--;
                        this.myHomeWallPost[indexmyHomeWallPost].like.condition = true;
                        this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag = 'dislike';
                        this.myHomeWallPost[indexmyHomeWallPost].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myHomeWallPost[indexmyHomeWallPost].like.likeCount);
                    }
                } else {
                    this.myHomeWallPost[indexmyHomeWallPost].like.condition = true;
                    this.myHomeWallPost[indexmyHomeWallPost].like.expressionFlag = 'dislike';
                    this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount++;
                }
                this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myHomeWallPost[indexmyHomeWallPost].like.dislikeCount);
            }


            let indexmyPostWall = this.myPostWall.findIndex(x => x._id == post._id)
            if (indexmyPostWall != -1) {
                if (this.myPostWall[indexmyPostWall].like.condition == true) {
                    if (this.myPostWall[indexmyPostWall].like.expressionFlag == 'dislike') {
                        this.myPostWall[indexmyPostWall].like.condition = false;
                        this.myPostWall[indexmyPostWall].like.dislikeCount--;
                    } else {
                        this.myPostWall[indexmyPostWall].like.dislikeCount++;
                        this.myPostWall[indexmyPostWall].like.likeCount--;
                        this.myPostWall[indexmyPostWall].like.condition = true;
                        this.myPostWall[indexmyPostWall].like.expressionFlag = 'dislike';
                        this.myPostWall[indexmyPostWall].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myPostWall[indexmyPostWall].like.likeCount);
                    }
                } else {
                    this.myPostWall[indexmyPostWall].like.condition = true;
                    this.myPostWall[indexmyPostWall].like.expressionFlag = 'dislike';
                    this.myPostWall[indexmyPostWall].like.dislikeCount++;
                }
                this.myPostWall[indexmyPostWall].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.myPostWall[indexmyPostWall].like.dislikeCount);
            }

            let indexgroupsWallPost = this.groupsWallPost.findIndex(x => x._id == post._id)
            if (indexgroupsWallPost != -1) {
                if (this.groupsWallPost[indexgroupsWallPost].like.condition == true) {
                    if (this.groupsWallPost[indexgroupsWallPost].like.expressionFlag == 'dislike') {
                        this.groupsWallPost[indexgroupsWallPost].like.condition = false;
                        this.groupsWallPost[indexgroupsWallPost].like.dislikeCount--;
                    } else {
                        this.groupsWallPost[indexgroupsWallPost].like.dislikeCount++;
                        this.groupsWallPost[indexgroupsWallPost].like.likeCount--;
                        this.groupsWallPost[indexgroupsWallPost].like.condition = true;
                        this.groupsWallPost[indexgroupsWallPost].like.expressionFlag = 'dislike';
                        this.groupsWallPost[indexgroupsWallPost].like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.groupsWallPost[indexgroupsWallPost].like.likeCount);
                    }
                } else {
                    this.groupsWallPost[indexgroupsWallPost].like.condition = true;
                    this.groupsWallPost[indexgroupsWallPost].like.expressionFlag = 'dislike';
                    this.groupsWallPost[indexgroupsWallPost].like.dislikeCount++;
                }
                this.groupsWallPost[indexgroupsWallPost].like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(this.groupsWallPost[indexgroupsWallPost].like.dislikeCount);

            }
        }, 1200);
    }



    // /** CONVERT NUMBER TO MAXIMUM THREE DIGITS */
    convertLongNumberToThreeDigitsMax(number) {
        let returnValue;

        if (Math.abs(number) > 999999) returnValue = parseInt((number / 100000).toString()) + 'M';
        else if (Math.abs(number) > 999) returnValue = parseInt((number / 1000).toString()) + 'K';
        else returnValue = number;
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'convertLongNumberToThreeDigitsMax()', '');
        return returnValue;
    }

    processPosts(previousIndex, postlist, currentUser) {
        for (var i = previousIndex; i < postlist.length; i++) {
            let post = postlist[i];
            this.doAllPostSetup(post, currentUser);
        }
    }

    doAllPostSetup(post, currentUser) {
        this.checkFollowConditions(post, currentUser);
        this.checkLikeConditions(post, currentUser);
        // this.checkCommentPhotos(post);
    }

    checkFollowConditions(post, currentUser) {
        for (var j = 0; j < post.userFollow.length; j++) {
            if (post.userFollow[j] == currentUser) {
                post.follow = true;
                break;
            }

            if (j == post.userFollow.length - 1) post.follow = false;
        }
    }

    checkLikeConditions(post, currentUser) {
        let expressionArray = post.postLikes.concat(post.postDislikes);
        if (expressionArray.length > 0) {
            var abc = false;

            for (var j = 0; j < expressionArray.length; j++) {
                if (expressionArray[j].userId == currentUser) {
                    (post.like.condition = true),
                        (post.like.expressionFlag = expressionArray[j].expressionFlag),
                        (abc = true);
                }

                if (j == expressionArray.length - 1 && abc == false) post.like.condition = false;
            }
        } else post.like.condition = false;

        post.like.likeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.likeCount);
        post.like.dislikeCountDisplay = this.convertLongNumberToThreeDigitsMax(post.like.dislikeCount);
    }



    // postSettings(post, currentUser, comingFrom) {
    //     console.log("🚀 ~ file: post.service.ts ~ line 493 ~ PostService ~ postSettings ~ post", post)
    //     let contentAccessBlock = post.groupInfo
    //         ? post.groupInfo.groupType == 'Public' && post.groupInfo.joinCondition == false
    //         : false;

    //     if (contentAccessBlock == true) {
    //         this.actionCannotBeDoneAlert();
    //     } else {
    //         if (post.type == 'Shared') {
    //             if (post.postAuthorId == currentUser) {
    //                 if (post.referencePost.postAuthorId == currentUser) {
    //                     if (post.referencePost.identify == 'case')
    //                         this.actionSheetForPostSetting(post, 'sharedLayoutForCaseAuthor', currentUser, comingFrom);
    //                     else if (post.referencePost.identify == 'post')
    //                         this.actionSheetForPostSetting(post, 'sharedLayoutForPostAuthor', currentUser, comingFrom);
    //                 } else if (post.referencePost.postAuthorId != currentUser)
    //                     this.actionSheetForPostSetting(post, 'sharedLayoutForNonPostAuthor', currentUser, comingFrom);
    //             } else {
    //                 if (post.referencePost.postAuthorId == currentUser) {
    //                     if (post.referencePost.identify == 'case')
    //                         this.actionSheetForPostSetting(
    //                             post,
    //                             'sharedLayoutReferenceForCaseAuthorButNotSharedCaseAuthor',
    //                             currentUser,
    //                             comingFrom
    //                         );
    //                     else if (post.referencePost.identify == 'post')
    //                         this.actionSheetForPostSetting(
    //                             post,
    //                             'sharedLayoutReferenceForPostAuthorButNotSharedPostAuthor',
    //                             currentUser,
    //                             comingFrom
    //                         );
    //                 } else this.actionSheetForPostSetting(post, 'onlyReport', currentUser, comingFrom);
    //             }
    //         } else if (post.type == 'Original') {
    //             if (post.postAuthorId == currentUser) {
    //                 if (post.identify == 'case')
    //                     this.actionSheetForPostSetting(post, 'originalCaseAuthor', currentUser, comingFrom);
    //                 else if (post.identify == 'post')
    //                     this.actionSheetForPostSetting(post, 'originalPostAuthor', currentUser, comingFrom);
    //             } else this.actionSheetForPostSetting(post, 'onlyReport', currentUser, comingFrom);
    //         }
    //     }
    // }


    postSettings(post, currentUser, comingFrom) {
        console.log("🚀 ~ file: post.service.ts ~ line 493 ~ PostService ~ postSettings ~ post", post)
        let contentAccessBlock = post.groupInfo
            ? post.groupInfo.groupType == 'Public' && post.groupInfo.joinCondition == false
            : false;

        if (contentAccessBlock == true) {
            this.actionCannotBeDoneAlert();
        } else {
            // console.log(post.groupInfo)
            var isloggedinuserAdmin = 'no', actionby;
            if ((post.groupInfo).length > 0) {
                for (let i = 0; i < (post.groupInfo).length; i++) {
                    console.log("list of admins", post.groupInfo[i].groupAdmins)
                    if (post.groupInfo[i].groupAdmins.includes(currentUser)) {
                        console.log("this is ADMIN login")
                        isloggedinuserAdmin = "yes";
                        break;
                    }
                    else {
                        console.log("this is NORMAL login")
                    }
                }

            }
            console.log("isloggedinuserAdmin", isloggedinuserAdmin)

            if (post.type == 'Original') {
                if (post.postAuthorId == currentUser) {
                    if (post.identify == 'case') this.actionSheetForPostSetting(post, 'Original-Case-Author', currentUser, comingFrom, isloggedinuserAdmin);
                    else this.actionSheetForPostSetting(post, 'Original-Post-Author', currentUser, comingFrom, isloggedinuserAdmin);
                } else {
                    this.actionSheetForPostSetting(post, 'Other', currentUser, comingFrom, isloggedinuserAdmin);
                }
            } else if (post.type == 'Shared') {
                if (post.postAuthorId == currentUser) {
                    this.actionSheetForPostSetting(post, 'Shared-Author', currentUser, comingFrom, isloggedinuserAdmin);
                } else {
                    this.actionSheetForPostSetting(post, 'Other', currentUser, comingFrom, isloggedinuserAdmin);
                }
            } else {
                console.error('Invalid post type.');
            }
        }
    }

    async actionSheetForPostSetting(post, type, currentUser, comingFrom, isloggedinuserAdmin) {
        console.log("🚀 ~ file: post.service.ts:555 ~ PostService ~ actionSheetForPostSetting ~ post:", type, post)
        let buttons = [];
        var deletingvbyuser = ((isloggedinuserAdmin == "yes" && post.postAuthorId != currentUser) ? "Group-Admin" : type)
        switch (type) {

            // Type: Original
            // Identify: Case
            // User role: Author
            case 'Original-Case-Author':
                buttons = [
                    {
                        text: 'Edit case',
                        icon: 'create-outline',
                        handler: () => { this.navigateToCaseOrPost('case', post, comingFrom); }
                    },
                    {
                        text: 'Add and Edit case update',
                        icon: 'add-circle-outline',
                        handler: () => { this.navigateToCaseUpdate(post); }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        icon: 'trash-outline',
                        handler: () => { this.deletePostAlertController(post, currentUser, deletingvbyuser); }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        icon: 'close-circle-outline',
                        handler: () => { }
                    }
                ];
                break;

            // Type: Original
            // Identify: Post
            // User role: Author
            case 'Original-Post-Author':
                buttons = [
                    {
                        text: 'Edit post',
                        icon: 'create-outline',
                        handler: () => { this.navigateToCaseOrPost('post', post, comingFrom); }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        icon: 'trash-outline',
                        handler: () => { this.deletePostAlertController(post, currentUser, deletingvbyuser); }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        icon: 'close-circle-outline',
                        handler: () => { }
                    }
                ];
                break;

            // Type: Shared
            // Identify: Post and Case
            // User role: Author
            case 'Shared-Author':
                buttons = [
                    {
                        text: 'Edit shared comment',
                        icon: 'create-outline',
                        handler: () => { this.editSharedComments(post); }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        icon: 'trash-outline',
                        handler: () => { this.deletePostAlertController(post, currentUser, deletingvbyuser); }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        icon: 'close-circle-outline',
                        handler: () => { }
                    }
                ];
                break;

            // Type: Original and Shared
            // Identify: Post and Case
            // User role: User
            case 'Other':
                buttons = [
                    {
                        text: 'Report',
                        role: 'destructive',
                        icon: 'alert-circle-outline',
                        handler: async () => {
                            const captionModal = await this.modalCtrl.create({
                                component: ReportPage,
                                cssClass: 'my-custom-class',
                                componentProps: {
                                    postReport: post
                                },
                                backdropDismiss: true,
                            });
                            captionModal.present();
                        }
                    },
                    {
                        text: 'Not Interested ',
                        role: 'destructive',
                        icon: 'ban-outline',
                        handler: () => {
                            this.alertBeforeclickingNotInterested(post);
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        icon: 'close-circle-outline',
                        handler: () => { }
                    }
                ];
                break;
        }

        if (post.follow == true && post.postAuthorId != currentUser)
            buttons.unshift({
                text: 'Unfollow',
                icon: 'remove-circle-outline',
                handler: () => {
                    this.unfollowPost(post, currentUser, '');
                }
            });



        if (isloggedinuserAdmin == "yes" && post.postAuthorId != currentUser)
            buttons.push({
                text: 'Delete',
                role: 'destructive',
                icon: 'trash-outline',
                handler: () => { this.deletePostAlertController(post, currentUser, deletingvbyuser); }
            });


        if (post.isCurrentUserIsGroupAdmin) {
            buttons.unshift(
                {
                    text: (post.pinnedPost) ? 'Remove Pin' : 'Pin',
                    role: (post.pinnedPost) ? 'destructive' : 'constructive',
                    icon: 'pin-outline',
                    handler: () => {

                        let details = {
                            editAction: (post.pinnedPost) ? 'unPinPost' : 'pinPost',
                            post_id: post._id,
                            group_id: post.gid,
                        };
                        this.pinPost(details).subscribe(
                            async (res) => {
                                if (res && res.resObject && res.resObject['data'] && res.resObject['data']['status']) {
                                    if (res.resObject['data']['status'] == "Success") {
                                        if (res.resObject['data']['type'] == "postPinned") {
                                            post.pinnedPost = true;
                                        }
                                        if (res.resObject['data']['type'] == "postUnPinned") {
                                            post.pinnedPost = false;
                                        }
                                    } else if (res.resObject['status'] == 'error') {
                                        await this.serverResponseAlert(res.resObject);
                                    }
                                }
                            },
                            (err) => {
                                console.log(err)
                            }
                        );
                    }
                },
            );
        }


        let actionSheet = this.actionSheetController.create({
            mode: 'ios',
            buttons: buttons
        });
        (await actionSheet).present();
    }

    notInterestedInPost(post) {
        let blockUserObject: BlockUser = {
            blockedBy: this.storageService.getStorage('currentUserId'),
            // blockedBy: this.currentUserId,
            blockedUser: '',
            blockedComment: '',
            blockedPost: post._id,
            action: 'block-post'
        }
        console.log("🚀 ~ file: post.service.ts:1054 ~ notInterestedInPost ~ blockUserObject:", blockUserObject)
        this.userService.blockUser(blockUserObject).subscribe((res) => {
            console.log("post blocked successfully", res);
            this.removeSpecificPostObjectInTheDisplay(post._id);
        })
    }

    async alertBeforeclickingNotInterested(post) {
        let alert = this.alertController.create({
            message: "Are you sure want remove this post from suggestion?",
            buttons: [
                {
                    text: 'No, Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Yes, remove',
                    role: 'confirm',
                    handler: () => { this.notInterestedInPost(post) },
                },
            ],
        });
        (await alert).present();

    }

    // async actionSheetForPostSetting(post, type, currentUser, comingFrom) {
    //     console.log("🚀 ~ file: post.service.ts:555 ~ PostService ~ actionSheetForPostSetting ~ post:", type, post)
    //     let buttons = [];
    //     switch (type) {

    //         // Type: Original
    //         // Identify: Case
    //         // User role: Author
    //         case 'Original-Case-Author':
    //             buttons = [
    //                 {
    //                     text: 'Edit case',
    //                     icon: 'create-outline',
    //                     handler: () => { this.navigateToCaseOrPost('case', post, comingFrom); }
    //                 },
    //                 {
    //                     text: 'Add case update',
    //                     icon: 'add-circle-outline',
    //                     handler: () => { this.navigateToCaseUpdate(post); }
    //                 },
    //                 {
    //                     text: 'Delete',
    //                     role: 'destructive',
    //                     icon: 'trash-outline',
    //                     handler: () => { this.deletePostAlertController(post, currentUser); }
    //                 },
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     icon: 'close-circle-outline',
    //                     handler: () => { }
    //                 }
    //             ];
    //             break;

    //         // Type: Original
    //         // Identify: Post
    //         // User role: Author
    //         case 'Original-Post-Author':
    //             buttons = [
    //                 {
    //                     text: 'Edit post',
    //                     icon: 'create-outline',
    //                     handler: () => { this.navigateToCaseOrPost('post', post, comingFrom); }
    //                 },
    //                 {
    //                     text: 'Delete',
    //                     role: 'destructive',
    //                     icon: 'trash-outline',
    //                     handler: () => { this.deletePostAlertController(post, currentUser); }
    //                 },
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     icon: 'close-circle-outline',
    //                     handler: () => { }
    //                 }
    //             ];
    //             break;

    //         // Type: Shared
    //         // Identify: Post and Case
    //         // User role: Author
    //         case 'Shared-Author':
    //             buttons = [
    //                 {
    //                     text: 'Edit shared comment',
    //                     icon: 'create-outline',
    //                     handler: () => { this.editSharedComments(post); }
    //                 },
    //                 {
    //                     text: 'Delete',
    //                     role: 'destructive',
    //                     icon: 'trash-outline',
    //                     handler: () => { this.deletePostAlertController(post, currentUser); }
    //                 },
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     icon: 'close-circle-outline',
    //                     handler: () => { }
    //                 }
    //             ];
    //             break;

    //         // Type: Original and Shared
    //         // Identify: Post and Case
    //         // User role: User
    //         case 'Other':
    //             buttons = [
    //                 {
    //                     text: 'Report',
    //                     role: 'destructive',
    //                     icon: 'alert-circle-outline',
    //                     handler: async () => {
    //                         const captionModal = await this.modalCtrl.create({
    //                             component: ReportPage,
    //                             cssClass: 'my-custom-class',
    //                             componentProps: {
    //                                 postReport: post
    //                             },
    //                             backdropDismiss: true,
    //                         });
    //                         captionModal.present();
    //                     }

    //                 },
    //                 {
    //                     text: 'Not Interested ',
    //                     role: 'destructive',
    //                     icon: 'ban-outline',
    //                     handler: () => {
    //                         let blockUserObject: BlockUser = {
    //                             blockedBy: this.currentUserId,
    //                             blockedUser: '',
    //                             blockedComment: '',
    //                             blockedPost: post._id,
    //                             action: 'block-post'
    //                         }
    //                         this.userService.blockUser(blockUserObject).subscribe((res) => {
    //                             console.log("post blocked successfully", res);
    //                             this.removeSpecificPostObjectInTheDisplay(post._id);
    //                         })
    //                     }
    //                 },
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     icon: 'close-circle-outline',
    //                     handler: () => { }
    //                 }
    //             ];
    //             break;
    //     }


    //     // async actionSheetForPostSetting(post, type, currentUser, comingFrom) {
    //     //     console.log("🚀 ~ file: post.service.ts:555 ~ PostService ~ actionSheetForPostSetting ~ post:", type, post)
    //     //     let buttons = [];
    //     //     switch (type) {
    //     //         /** THIS IS ORIGINAL CASE CREATOR */
    //     //         case 'originalCaseAuthor':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Delete',
    //     //                     role: 'destructive',
    //     //                     icon: 'trash-outline',
    //     //                     handler: () => {
    //     //                         this.deletePostAlertController(post, currentUser);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Edit case',
    //     //                     icon: 'create-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseOrPost('case', post, comingFrom);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Add case update',
    //     //                     icon: 'add-circle-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseUpdate(post);
    //     //                         console.log("🚀 ~ file: post.service.ts ~ line 562 ~ PostService ~ actionSheetForPostSetting ~ post", post)

    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS ORIGINAL POST CREATOR */
    //     //         case 'originalPostAuthor':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Delete',
    //     //                     role: 'destructive',
    //     //                     icon: 'trash-outline',
    //     //                     handler: () => {
    //     //                         this.deletePostAlertController(post, currentUser);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Edit post',
    //     //                     icon: 'create-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseOrPost('post', post, comingFrom);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS ORIGINAL POST CREATOR ACCESSING THIS
    //     //         * FROM ITS SHARED INSTANCE CREATED BY SOMEONE ELSE */
    //     //         case 'sharedLayoutReferenceForPostAuthorButNotSharedPostAuthor':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Edit post',
    //     //                     icon: 'create-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseOrPost('post', post, comingFrom);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Report',
    //     //                     role: 'destructive',
    //     //                     icon: 'alert-circle-outline',
    //     //                     handler: () => {
    //     //                         // let captionModal = this.modalCtrl.create('ReportPage', { postReport: post });
    //     //                         // captionModal.present();
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Not Interested ',
    //     //                     role: 'destructive',
    //     //                     icon: 'ban-outline',
    //     //                     handler: () => {
    //     //                         let blockUserObject: BlockUser = {
    //     //                             blockedBy: this.currentUserId,
    //     //                             blockedUser: '',
    //     //                             blockedComment: '',
    //     //                             blockedPost: post._id,
    //     //                             action: 'block-post'
    //     //                         }
    //     //                         this.userService.blockUser(blockUserObject).subscribe((res) => {
    //     //                             console.log("post blocked successfully", res);
    //     //                             this.removeSpecificPostObjectInTheDisplay(post._id);
    //     //                         })
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS ORIGINAL CASE CREATOR ACCESSING THIS
    //     //         * FROM ITS SHARED INSTANCE CREATED BY SOMEONE ELSE */
    //     //         case 'sharedLayoutReferenceForCaseAuthorButNotSharedCaseAuthor':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Add case update',
    //     //                     icon: 'create-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseUpdate(post);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Edit case',
    //     //                     icon: 'create-outline',
    //     //                     handler: () => {
    //     //                         this.navigateToCaseOrPost('case', post, comingFrom);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Report',
    //     //                     role: 'destructive',
    //     //                     icon: 'alert-circle-outline',
    //     //                     handler: () => {
    //     //                         // let captionModal = this.modalCtrl.create('ReportPage', { postReport: post });
    //     //                         // captionModal.present();
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Not Interested ',
    //     //                     role: 'destructive',
    //     //                     icon: 'ban-outline',
    //     //                     handler: () => {
    //     //                         let blockUserObject: BlockUser = {
    //     //                             blockedBy: this.currentUserId,
    //     //                             blockedUser: '',
    //     //                             blockedComment: '',
    //     //                             blockedPost: post._id,
    //     //                             action: 'block-post'
    //     //                         }
    //     //                         this.userService.blockUser(blockUserObject).subscribe((res) => {
    //     //                             console.log("post blocked successfully", res);
    //     //                             this.removeSpecificPostObjectInTheDisplay(post._id);
    //     //                         })
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS SHARED POST/CASE CREATOR BUT IS NOT THE ORIGINAL POST CREATOR */
    //     //         case 'sharedLayoutForNonPostAuthor':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Report',
    //     //                     role: 'destructive',
    //     //                     icon: 'alert-circle-outline',
    //     //                     handler: () => {
    //     //                         // let captionModal = this.modalCtrl.create('ReportPage', { postReport: post });
    //     //                         // captionModal.present();
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Not Interested ',
    //     //                     role: 'destructive',
    //     //                     icon: 'ban-outline',
    //     //                     handler: () => {
    //     //                         let blockUserObject: BlockUser = {
    //     //                             blockedBy: this.currentUserId,
    //     //                             blockedUser: '',
    //     //                             blockedComment: '',
    //     //                             blockedPost: post._id,
    //     //                             action: 'block-post'
    //     //                         }
    //     //                         this.userService.blockUser(blockUserObject).subscribe((res) => {
    //     //                             console.log("post blocked successfully", res);
    //     //                             this.removeSpecificPostObjectInTheDisplay(post._id);
    //     //                         })
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Edit shared comment',
    //     //                     icon: 'create-outline',
    //     //                     handler: async () => {
    //     //                         this.editSharedComments(post);
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS NEITHER THE SHARED POST NOR THE ORIGINAL POST CREATOR */
    //     //         case 'onlyReport':
    //     //             buttons = [
    //     //                 {
    //     //                     text: 'Report',
    //     //                     role: 'destructive',
    //     //                     icon: 'alert-circle-outline',
    //     //                     handler: async () => {


    //     //                         const captionModal = await this.modalCtrl.create({
    //     //                             component: ReportPage,
    //     //                             cssClass: 'my-custom-class',
    //     //                             componentProps: {
    //     //                                 postReport: post
    //     //                             },
    //     //                             backdropDismiss: true,
    //     //                         });
    //     //                         captionModal.present();
    //     //                     }

    //     //                 },
    //     //                 {
    //     //                     text: 'Not Interested ',
    //     //                     role: 'destructive',
    //     //                     icon: 'ban-outline',
    //     //                     handler: () => {
    //     //                         let blockUserObject: BlockUser = {
    //     //                             blockedBy: this.currentUserId,
    //     //                             blockedUser: '',
    //     //                             blockedComment: '',
    //     //                             blockedPost: post._id,
    //     //                             action: 'block-post'
    //     //                         }
    //     //                         this.userService.blockUser(blockUserObject).subscribe((res) => {
    //     //                             console.log("post blocked successfully", res);
    //     //                             this.removeSpecificPostObjectInTheDisplay(post._id);
    //     //                         })
    //     //                     }
    //     //                 },
    //     //                 {
    //     //                     text: 'Cancel',
    //     //                     role: 'cancel',
    //     //                     icon: 'close-circle-outline',
    //     //                     handler: () => { }
    //     //                 }
    //     //             ];
    //     //             break;

    //     //         /** THIS IS SHARED POST CREATOR AND ALSO THE ORIGINAL POST CREATOR */
    //     //         case 'sharedLayoutForPostAuthor':
    //     //             console.log("🚀 ~ file: post.service.ts:820 ~ PostService ~ actionSheetForPostSetting ~ post:", post)

    //     //             if (post.referencePost?.Disable == true) {
    //     //                 buttons = [
    //     //                     {
    //     //                         text: 'Delete',
    //     //                         role: 'destructive',
    //     //                         icon: 'trash-outline',
    //     //                         handler: () => {
    //     //                             this.deletePostAlertController(post, currentUser);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit shared comment',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.editSharedComments(post);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Cancel',
    //     //                         role: 'cancel',
    //     //                         icon: 'close-circle-outline',
    //     //                         handler: () => { }
    //     //                     }
    //     //                 ];
    //     //             } else {
    //     //                 buttons = [
    //     //                     {
    //     //                         text: 'Delete',
    //     //                         role: 'destructive',
    //     //                         icon: 'trash-outline',
    //     //                         handler: () => {
    //     //                             this.deletePostAlertController(post, currentUser);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit shared comment',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.editSharedComments(post);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit post',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.navigateToCaseOrPost('post', post, comingFrom);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Cancel',
    //     //                         role: 'cancel',
    //     //                         icon: 'close-circle-outline',
    //     //                         handler: () => { }
    //     //                     }
    //     //                 ];
    //     //             }

    //     //             break;

    //     //         /** THIS IS SHARED CASE CREATOR AND ALSO THE ORIGINAL CASE CREATOR */
    //     //         case 'sharedLayoutForCaseAuthor':
    //     //             if (post.referencePost?.Disable == true) {
    //     //                 buttons = [
    //     //                     {
    //     //                         text: 'Delete',
    //     //                         role: 'destructive',
    //     //                         icon: 'trash-outline',
    //     //                         handler: () => {
    //     //                             this.deletePostAlertController(post, currentUser);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit shared comment',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.editSharedComments(post);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Cancel',
    //     //                         role: 'cancel',
    //     //                         icon: 'close-circle-outline',
    //     //                         handler: () => { }
    //     //                     }
    //     //                 ];
    //     //             }
    //     //             else {
    //     //                 buttons = [
    //     //                     {
    //     //                         text: 'Delete',
    //     //                         role: 'destructive',
    //     //                         icon: 'trash-outline',
    //     //                         handler: () => {
    //     //                             this.deletePostAlertController(post, currentUser);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit shared comment',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.editSharedComments(post);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Edit case',
    //     //                         icon: 'create-outline',
    //     //                         handler: () => {
    //     //                             this.navigateToCaseOrPost('case', post, comingFrom);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Add case update',
    //     //                         icon: 'add-circle-outline',
    //     //                         handler: () => {
    //     //                             this.navigateToCaseUpdate(post);
    //     //                         }
    //     //                     },
    //     //                     {
    //     //                         text: 'Cancel',
    //     //                         role: 'cancel',
    //     //                         icon: 'close-circle-outline',
    //     //                         handler: () => { }
    //     //                     }
    //     //                 ];
    //     //             }
    //     //             break;
    //     //     }

    //     if (post.follow == true && post.postAuthorId != currentUser)
    //         buttons.unshift({
    //             text: 'Unfollow',
    //             icon: 'remove-circle-outline',
    //             handler: () => {
    //                 this.unfollowPost(post, currentUser, '');
    //             }
    //         });

    //     // let self = this;

    //     // if (
    //     //     post.pushNotificationUsers.filter(function (e) {
    //     //         return e === currentUser;
    //     //     }).length > 0
    //     // )
    //     //     buttons.unshift({
    //     //         text: 'Turn off Push Notification',
    //     //         icon: 'notifications-off-outline',
    //     //         handler: () => {
    //     //             // this.turnOffPushNotifications(post, currentUser);
    //     //         }
    //     //     });
    //     // else
    //     //     buttons.unshift({
    //     //         text: 'Turn on Push Notification',
    //     //         icon: 'notifications-outline',
    //     //         handler: () => {
    //     //             // this.turnOnPushNotifications(post, currentUser);
    //     //         }
    //     //     });

    //     let actionSheet = this.actionSheetController.create({
    //         mode: 'ios',
    //         buttons: buttons
    //     });
    //     (await actionSheet).present();
    // }

    async deletePostAlertController(post: any, currentUser, disabledBy) {
        console.log("post deleted ...........................................", disabledBy)
        // console.log("post deleted ..........................................." , currentUser)
        const alert = this.actionSheetController.create({
            mode: 'ios',
            header: 'Delete Post',
            subHeader: 'Are you sure you want to delete?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Yes',
                    handler: () => {
                        if (disabledBy === "Group-Admin") {
                            this.openDeleteReason(post, currentUser, disabledBy);
                        } else {
                            this.deletePost(post, currentUser, disabledBy, "");
                        }
                    }
                }
            ]
        });
        (await alert).present();
    }

    checkIfPostCanBeViewed(postID) {
        let postResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .get(
                this.baseServerUrl +
                '/homewallpost?primaryAction=checkIfPostCanBeViewed&postID=' +
                postID +
                '&userID=' +
                this.storageService.getStorage('currentUserId')
            )
            .pipe(
                map((response) => {
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'checkIfPostCanBeViewed()', '');
                    return postResponse;
                }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'checkIfPostCanBeViewed()', '', err);
                })
            );
    }

    addComment(commentDetails) {
        let commentResponse = {
            error: false,
            resObject: {}
        };

        return this.http.post(this.baseServerUrl + '/comments', JSON.stringify(commentDetails)).pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    commentResponse.error = true;
                } else {
                    commentResponse.error = false;
                    commentResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addComment()', '');
                return commentResponse;
            }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addComment()', '', err);
            })
        );
    }

    addToComment(commentId, details) {
        let commentResponse = {
            error: false,
            resObject: {}
        };

        return this.http.patch(this.baseServerUrl + '/comments/' + commentId, JSON.stringify(details)).pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    commentResponse.error = true;
                } else {
                    commentResponse.error = false;
                    commentResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addToComment()', '');
                return commentResponse;
            }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addToComment()', '', err);
            })
        );
    }

    async openDeleteReason(post, currentUser, disabledBy) {
        const captionModal = await this.modalCtrl.create({
            component: PostDeleteReasonComponent,
            cssClass: 'my-custom-class',
            componentProps: {
                post: post,
                currentUser: currentUser,
                disabledBy: disabledBy
            },
            backdropDismiss: true,
        });
        captionModal.present();
    }

    deletePost(post, currentUser, disabledBy, reason) {
        console.log("admin post delete reason", reason)
        this
            .addToPost(post._id, {
                Disable: true,
                // disabledBy : disabledBy,
                disabledDate: new Date(),
                disabledByUserID: currentUser,
                disableReason: reason
            })
            .subscribe((response: any) => {
                if (response == false || response == null || response == undefined) {
                    alert("Post cannot be deleted at thi spoint. Please try again later.")
                    return
                }
                post.Disable = true;
                this.removeSpecificPostObjectInTheDisplay(post._id)
                this.modalCtrl.dismiss();
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'deletePost()', '');
            }),
            catchError((err) => {
                alert("Post cannot be deleted at thi spoint. Please try again later.")
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'deletePost()', '', err);
            });
    }

    async editSharedComments(post) {
        let obj = { postDataFromWall: post, action: 'editCommentMode' }
        let captionModal = await this.modalCtrl.create({
            component: ShareFromWallPage,
            cssClass: 'shareFromWallModal',
            componentProps: obj,
            backdropDismiss: true,
        });
        (await captionModal).present();
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'editSharedComments()', '');
    }

    navigateToCaseOrPost(data, post, comingFrom) {

        this.userService.globalLoader.next(true); // to show the global loader
        this.checkIfPostCanBeEdit(post._id).subscribe((res) => {
            let canEditPost = res.resObject[0];
            this.userService.globalLoader.next(false); // to hide the global loader
            if (
                canEditPost.postEdit.value == true
            ) {
                console.log("show coming from", comingFrom);

                let obj = {
                    comingFrom: data,
                    action: 'Edit',
                    pageComingFrom: "",
                    casePost: post
                };
                console.log("🚀 ~ file: post.service.ts:1071 ~ PostService ~ navigateToCaseOrPost ~ obj:", obj)
                if (comingFrom == "GroupWall") {
                    obj.pageComingFrom = "GroupWall"
                }
                else {
                    obj.pageComingFrom = "HomeWall"
                }
                console.log("🚀 ~ file: post.service.ts:1057 ~ PostService ~ navigateToCaseOrPost ~ data, post:", data, post)

                console.log("🚀 ~ file: post.service.ts:1063 ~ PostService ~ navigateToCaseOrPost ~ obj:", obj)
                // this.router.navigate(['/create-case-or-post'], { state: obj });
                this.router.navigate(['/create-case-or-post', obj.comingFrom, obj.action, obj.pageComingFrom, { postId: post._id }]);
                this.editPostObject = post
                this.router.navigate(['/edit-case-or-post', obj.comingFrom, obj.action, obj.pageComingFrom, post._id])
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'navigateToCaseOrPost()', '');

            } else {
                this.commonAlert("You don't have permission to edit this post");
            }
        });
    }


    navigateToCaseUpdate(post) {
        console.log("🚀 ~ file: post.service.ts:1070 ~ PostService ~ navigateToCaseUpdate ~ post:", post)
        this.router.navigate(['/case-update'], { state: post });
        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'navigateToCaseUpdate()', '');
    }


    public getPostReportReasons() {
        let getPostReportResponse = {
            error: false,
            resObject: {}
        };

        return this.http.get(this.baseServerUrl + '/reportreasons').pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    getPostReportResponse.error = true;
                } else {
                    getPostReportResponse.error = false;
                    getPostReportResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getReportReasons()', '');
                return getPostReportResponse;
            }),
            catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getReportReasons()', '', err);
                getPostReportResponse.error = true;
                throw getPostReportResponse;
            })
        );
    }

    public getCommentReportReasons() {
        let getCommentReportResponse = {
            error: false,
            resObject: {}
        };

        return this.http.get(this.baseServerUrl + '/reportreasonscomments').pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    getCommentReportResponse.error = true;
                } else {
                    getCommentReportResponse.error = false;
                    getCommentReportResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getReportReasons()', '');
                return getCommentReportResponse;
            }),
            catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getReportReasons()', '', err);
                getCommentReportResponse.error = true;
                throw getCommentReportResponse;
            })
        );
    }

    public getUserReportReasons() {
        let getUserReportResponse = {
            error: false,
            resObject: {}
        };

        return this.http.get(this.baseServerUrl + '/reportreasonsusers').pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    getUserReportResponse.error = true;
                } else {
                    getUserReportResponse.error = false;
                    getUserReportResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getReportReasons()', '');
                return getUserReportResponse;
            }),
            catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getReportReasons()', '', err);
                getUserReportResponse.error = true;
                throw getUserReportResponse;
            })
        );
    }



    // report post
    public reportPost(details) {

        let reportPostResponse = {
            error: false,
            resObject: {}
        };

        return this.http.post(this.baseServerUrl + '/reported-post', JSON.stringify(details)).pipe(
            map((response: any) => {
                if (!response || response == undefined || response == null) {
                    reportPostResponse.error = true;
                } else {
                    reportPostResponse.error = false;
                    reportPostResponse.resObject = response;
                }
                this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'reportPost()', '');
                return reportPostResponse;
            }),
            catchError((err) => {
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'reportPost()', '', err);
                reportPostResponse.error = true;
                throw reportPostResponse;
            })
        );
    }


    public checkForPii(sentence) {
        return this.http.post(this.baseServerUrl + '/pii-check', { sentence: sentence, action: 'textpii' }).pipe(tap((res: any) => {
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'checkForPii()', '');
            return res;
        }),
            catchError((err) => {
                throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'checkForPii()', '', err);
            }))
    }


    updateSpecificPostObjectInTheDisplay(updatedPostObject) {
        //update home wall page if post displayed
        let indexmyHomeWallPost = this.myHomeWallPost.findIndex(x => x._id == updatedPostObject._id)
        this.myHomeWallPost[indexmyHomeWallPost] = updatedPostObject;

        //update mypost wall page if post displayed
        let indexmyPostWall = this.myPostWall.findIndex(x => x._id == updatedPostObject._id)
        this.myPostWall[indexmyPostWall] = updatedPostObject;

        //update home wall page if post displayed
        let indexgroupsWallPost = this.groupsWallPost.findIndex(x => x._id == updatedPostObject._id)
        this.groupsWallPost[indexgroupsWallPost] = updatedPostObject;



    }

    removeSpecificPostObjectInTheDisplay(postId) {
        //update home wall page if post displayed
        let indexmyHomeWallPost = this.myHomeWallPost.findIndex(x => x._id == postId)
        this.myHomeWallPost.splice(indexmyHomeWallPost, 1);
        console.log("🚀 ~ file: post.service.ts:2002 ~ removeSpecificPostObjectInTheDisplay ~ this.myHomeWallPost:", this.myHomeWallPost)

        //update mypost wall page if post displayed
        let indexmyPostWall = this.myPostWall.findIndex(x => x._id == postId)
        this.myPostWall.splice(indexmyPostWall, 1);
        console.log("🚀 ~ file: post.service.ts:2002 ~ removeSpecificPostObjectInTheDisplay ~ this.myPostWall:", this.myPostWall)

        //update group wall page if post displayed
        let indexgroupsWallPost = this.groupsWallPost.findIndex(x => x._id == postId)
        this.groupsWallPost.splice(indexgroupsWallPost, 1);
        console.log("🚀 ~ file: post.service.ts:2003 ~ removeSpecificPostObjectInTheDisplay ~ this.groupsWallPost:", this.groupsWallPost)
    }


    public getUpdates(details, timestamp) {
        let caseUpdateResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .get(
                this.baseServerUrl +
                '/case-update?postID=' + details + '&timestamp=' + timestamp
            )
            .pipe(
                map((response) => {
                    if (!response || response == undefined || response == null) {
                        caseUpdateResponse.error = true;
                    } else {
                        caseUpdateResponse.error = false;
                        caseUpdateResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'getUpdates()', '');
                    return caseUpdateResponse;
                }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'getUpdates()', '', err);
                })
            );


    }

    public addToCaseUpdate(caseUpdateId, details) {
        let addToCaseUpdateResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .patch(this.baseServerUrl + '/case-update/' + caseUpdateId, JSON.stringify(details))
            .pipe(
                map((response) => {
                    if (!response || response == undefined || response == null) {
                        addToCaseUpdateResponse.error = true;
                    } else {
                        addToCaseUpdateResponse.error = false;
                        addToCaseUpdateResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addToCaseUpdate()', '');
                    return addToCaseUpdateResponse;
                }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addToCaseUpdate()', '', err);
                })
            );
    }

    public addUpdate(details) {
        let addUpdateResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .post(this.baseServerUrl + '/case-update', JSON.stringify(details))
            .pipe(
                map((response) => {
                    if (!response || response == undefined || response == null) {
                        addUpdateResponse.error = true;
                    } else {
                        addUpdateResponse.error = false;
                        addUpdateResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addUpdate()', '');
                    return addUpdateResponse;
                }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addUpdate()', '', err);
                })
            );
    }

    // commentSettings(comment: any) {
    //     if (comment.userInfo.email && comment.userInfo.email == this.userDetails.email) {
    //         let actionSheet = this.actionSheetCtrl.create({
    //             buttons: [
    //                 {
    //                     text: 'Delete',
    //                     role: 'destructive',
    //                     icon: 'ios-trash-outline',
    //                     handler: () => {
    //                         this.presentConfirm(comment);
    //                     }
    //                 },
    //                 {
    //                     text: 'Edit',
    //                     icon: 'create',
    //                     handler: () => {
    //                         this.editComment(comment);
    //                     }
    //                 },
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     icon: 'ios-close-circle-outline',
    //                     handler: () => { }
    //                 }
    //             ]
    //         });
    //         actionSheet.present();
    //     } else {
    //         let actionSheet = this.actionSheetCtrl.create({
    //             buttons: [
    //                 {
    //                     text: 'Report',
    //                     role: 'destructive',
    //                     icon: !this.platform.is('ios') ? 'ios-alert-outline' : null,
    //                     handler: () => {
    //                         let captionModal = this.modalCtrl.create('ReportPage', {
    //                             postReport: this.postSelected || this.postSelected.referencePost._id,
    //                             commentReport: comment._id
    //                         });
    //                         captionModal.present();
    //                     }
    //                 }
    //             ]
    //         });
    //         actionSheet.present();
    //     }
    // }

    actionSheetForCommentOptions(post, comment) {
        console.log("🚀 ~ file: post.service.ts:1254 ~ PostService ~ actionSheetForCommentOptions ~ comment", comment)
        console.log("🚀 ~ file: post.service.ts:1254 ~ PostService ~ actionSheetForCommentOptions ~ post", post)
        // if ('post-author') this.commentOptionsBasedOnRole('post-author');
        // else if ('comment-author') this.commentOptionsBasedOnRole('comment-author');
        // else { } this.commentOptionsBasedOnRole('app-user');
    }


    // async commentOptionsBasedOnRole(role) {
    //     let actionSheet;
    //     switch (role) {
    //         case 'post-author':

    //             actionSheet = this.actionSheetCtrl.create({
    //                 buttons: [
    //                     {
    //                         // Hide For Me(Block)
    //                         text: 'Delete',
    //                         role: 'destructive',
    //                         icon: 'ban-outline',
    //                         handler: () => {
    //                             // this.confirmBeforeDelete(comment);
    //                         }
    //                     },
    //                     {
    //                         // Delete
    //                         text: 'Delete',
    //                         role: 'destructive',
    //                         icon: 'trash-outline',
    //                         handler: () => {
    //                             // this.confirmBeforeDelete(comment);
    //                         }
    //                     },
    //                     {
    //                         // Report
    //                         text: 'Report',
    //                         role: 'destructive',
    //                         icon: 'ios-alert-outline',
    //                         handler: () => {
    //                         }
    //                     },
    //                     {
    //                         // Cancel
    //                         text: 'Cancel',
    //                         role: 'cancel',
    //                         icon: 'close-circle-outline',
    //                         handler: () => { }
    //                     }
    //                 ]
    //             });
    //             (await actionSheet).present();

    //             break;

    //         case 'comment-author':

    //             actionSheet = this.actionSheetCtrl.create({
    //                 buttons: [
    //                     {
    //                         // Delete
    //                         text: 'Delete',
    //                         role: 'destructive',
    //                         icon: 'trash-outline',
    //                         handler: () => {
    //                             // this.confirmBeforeDelete(comment);
    //                         }
    //                     },
    //                     {
    //                         // Edit
    //                         text: 'Edit',
    //                         icon: 'create',
    //                         handler: () => {
    //                             // this.editComment(comment);
    //                         }
    //                     },
    //                     {
    //                         // Cancel
    //                         text: 'Cancel',
    //                         role: 'cancel',
    //                         icon: 'close-circle-outline',
    //                         handler: () => { }
    //                     }
    //                 ]
    //             });
    //             (await actionSheet).present();

    //             break;

    //         default:

    //             actionSheet = this.actionSheetCtrl.create({
    //                 buttons: [
    //                     {
    //                         // Report
    //                         text: 'Report',
    //                         role: 'destructive',
    //                         icon: 'ios-alert-outline',
    //                         handler: () => {
    //                         }
    //                     },
    //                     {
    //                         // Cancel
    //                         text: 'Cancel',
    //                         role: 'cancel',
    //                         icon: 'close-circle-outline',
    //                         handler: () => { }
    //                     }
    //                 ]
    //             });
    //             (await actionSheet).present();
    //             break;
    //     }
    // }


    async alertBeforeclickingDeleteComment(comment, postID) {
        let alert = this.alertController.create({
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    text: 'No, Cancel',
                    role: 'cancel',
                    handler: () => {
                        //this.dismiss();;

                    },
                },
                {
                    text: 'Yes, delete',
                    role: 'confirm',
                    handler: () => {
                        this.askdeleteComment(comment, postID);
                    },
                },
            ],
        });
        (await alert).present();

    }


    askdeleteComment(comment, postID) {
        if (comment.userInfo._id === this.currentUserId) {   // comment is creaded by original post author
            this.deleteComment(comment, "", postID);
        } else {
            //comment is created by another user , original post author want to delete comment with reason
            this.openCommentDeleteReason(comment, postID)
        }
    }

    async openCommentDeleteReason(comment, postID) {
        const captionModal = await this.modalCtrl.create({
            component: CommentDeleteReasonComponent,
            cssClass: 'my-custom-class',
            componentProps: {
                comment: comment,
                postID: postID
            },
            backdropDismiss: true,
        });
        captionModal.present();
    }

    deleteComment(comment, reason, postID) {


        var deleteFlag = {
            Disable: true,
            DisabledDate: new Date(),
            DisabledByUserID: comment.userInfo._id,
            DisabledReason: reason,
        };

        this.addToComment(comment._id, deleteFlag).subscribe((response) => {
            console.log("🚀 ~ file: post.service.ts:1397 ~ PostService ~ this.deleteComment ~ response", response)
            if (!response.error) {
                this.modalCtrl.dismiss();
                this.callrefreshcommentlistTrigger(comment._id)
                this.refreshcommentfromfeed(postID, comment._id)
                this.callrefreshpostfeedTrigger("");
            }
        });
    }

    private refreshcommentlist = new Subject<any>();
    refreshcommentlist_trigge$ = this.refreshcommentlist.asObservable();

    callrefreshcommentlistTrigger(data) {
        this.refreshcommentlist.next(data);
    }



    removeCommentMedia(comment) {
        var removeCommentMedia = {
            comments: comment
        }

        this.addToComment(comment._id, removeCommentMedia).subscribe((response) => {
            console.log("🚀 ~ file: post.service.ts:1418 ~ PostService ~ this.removeCommentMedia ~ response", response)
        });
    }




    // // START OF ALERT BOX FOR DELETE COMMENT FUNCTIONALITY
    // confirmBeforeDelete(comment: any) {
    //     /** Edited by Aakanksha on 02-18-2019 - HEAL-705v2
    //     * ADDED ERROR HANDLING CONDITION WHERE YOU CAN IF YOU CAN REACH THE SERVER
    //     * IF THE VALUE IS FALSE, THEN STOP THE METHOD
    //     */
    //     if (this.userService.yourOnlineStatus == false) {
    //         this.userService.errorHandlingToast(
    //             this.err.ERROR_HANDLING_COMMON_MESSAGE,
    //             this.err.ERROR_HANDLING_COMMMON_TOAST_DURATION,
    //             'displaycomment page - presentConfirm',
    //             null
    //         );
    //         return null;
    //     }
    //     // HEAL-705v2 - Aakanksha
    //     const alert = this.alertCtrl.create({
    //         title: 'Delete Comment',
    //         message: 'Are you sure you want to delete this comment?',
    //         buttons: [
    //             {
    //                 text: 'No',
    //                 role: 'cancel',
    //                 handler: () => { }
    //             },
    //             {
    //                 text: 'Yes',
    //                 handler: () => {
    //                     this.deleteComment(comment);
    //                 }
    //             }
    //         ]
    //     });
    //     alert.present();
    // }
    // // END OF ALERT BOX FOR DELETE COMMENT FUNCTIONALITY

    // editComment(comment) {
    //     let alert = this.alertCtrl.create({
    //         title: 'Editing comment ...',
    //         inputs: [
    //             {
    //                 name: 'editedComment',
    //                 value: comment.comments
    //             }
    //         ],
    //         buttons: [
    //             {
    //                 text: 'Cancel',
    //                 role: 'cancel',
    //                 handler: (data) => { }
    //             },
    //             {
    //                 text: 'Save',
    //                 handler: (data) => {
    //                     if (data.editedComment != '') {
    //                         if (data.editedComment.trim().length > 0) {
    //                             this.editCommentId = comment._id;
    //                             this.sendEditedComment(data.editedComment);
    //                         }
    //                     }
    //                 }
    //             }
    //         ]
    //     });
    //     alert.present();
    // }

    // // START OF PATCH COMMENT FOR EDIT ACTION METHOD CALLED
    // sendEditedComment(comment) {
    //     if (this.userService.yourOnlineStatus == false) {
    //         this.userService.errorHandlingToast(
    //             this.err.ERROR_HANDLING_COMMON_MESSAGE,
    //             this.err.ERROR_HANDLING_COMMMON_TOAST_DURATION,
    //             'displaycomment page - sendEditedComment',
    //             null
    //         );
    //         return null;
    //     }

    //     let self = this;
    //     var editedComment = {
    //         comments: comment
    //     };
    //     this.postService.addToComment(this.editCommentId, editedComment).subscribe((response) => {
    //         if (response == false) {
    //             this.userService.errorHandlingToast(
    //                 this.err.ERROR_HANDLING_COMMON_MESSAGE,
    //                 this.err.ERROR_HANDLING_COMMMON_TOAST_DURATION,
    //                 'displaycomment page - sendEditedComment - Error 1',
    //                 response
    //             );
    //             return null;
    //         }

    //         if (response) {
    //             this.myComments[this.myComments.findIndex((o) => o._id === self.editCommentId)].comments = comment;
    //             if (this.postSelected.comments) {
    //                 let index = this.postSelected.comments.findIndex((o) => o._id === self.editCommentId);
    //                 if (index > -1) this.postSelected.comments[index].comments = comment;
    //             }
    //         }
    //     });
    // }


    ///start this is used for triggering refresh post feed
    private refreshpostfeed = new Subject<any>();
    refreshrefreshpostfeed_trigge$ = this.refreshpostfeed.asObservable();

    callrefreshpostfeedTrigger(data) {
        this.refreshpostfeed.next(data);
    }
    ///end this is used for triggering refresh post feed


    async backfromCreatePostOrCase() {
        const alert = await this.alertController.create({
            message: 'Discard changes?',
            buttons: [
                {
                    text: 'Yes',
                    handler: () => {
                        this.mediaService.uploadEditMediaArray = [];
                        this.editPostObject = "";
                        this.location.back();
                        // this.router.navigate(['/tabs/tab1']);
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        // this.pageLoading.dismiss();
                    }
                }
            ]
        });
        alert.present();
    }

    refreshcommentfromfeed(postid, commentid) {
        // refresh comments list from home page , group page , my post page after deleting comment 
        let indexmyHomeWallPost = this.myHomeWallPost.findIndex(x => x._id == postid)
        if (indexmyHomeWallPost != -1) {
            let indexmyHomeWallPostcommnet = this.myHomeWallPost[indexmyHomeWallPost].comments.findIndex(x => x._id == commentid)
            if (indexmyHomeWallPostcommnet != -1) {
                this.myHomeWallPost[indexmyHomeWallPost].comments.splice(indexmyHomeWallPostcommnet, 1);
            }
        }

        let indexmyPostWall = this.myPostWall.findIndex(x => x._id == postid)
        if (indexmyPostWall != -1) {
            let indexmyPostWallcommnet = this.myPostWall[indexmyPostWall].comments.findIndex(x => x._id == commentid)
            if (indexmyPostWallcommnet != -1) {
                this.myPostWall[indexmyPostWall].comments.splice(indexmyPostWallcommnet, 1);
            }
        }

        let indexgroupsWallPost = this.groupsWallPost.findIndex(x => x._id == postid)
        if (indexgroupsWallPost != -1) {
            let indexgroupsWallPostcommnet = this.groupsWallPost[indexgroupsWallPost].comments.findIndex(x => x._id == commentid)
            if (indexgroupsWallPostcommnet != -1) {
                this.groupsWallPost[indexgroupsWallPost].comments.splice(indexgroupsWallPostcommnet, 1);
            }
        }

    }

    public pinPost(details) {

        let postResponse = {
            error: false,
            resObject: {}
        };
        console.log(details);


        let _return = this.http.post(this.baseServerUrl + '/groups', JSON.stringify(details)).pipe(
            map(
                (response: any) => {
                    console.log(response);
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'addPost()', '');
                    return postResponse;
                }),
            catchError((err) => {
                console.log(err);
                postResponse.error = true;
                this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'addPost()', '', err);
                throw postResponse;
            })
        );
        console.log(_return);
        return _return;

    }

    async serverResponseAlert(obj) {


        (await this.toastCtrl
            .create({
                position: 'top',
                color: 'danger',
                header: "Alert!",
                message: obj.message,
                duration: 3000
            }))
            .present();

        // const alert = await this.alertController.create({
        //     header: "Alert!",
        //     message: obj.message,
        //     buttons: ['OK']
        // });
        // await alert.present();
    }

    refreshList() {
        //refresh every list in case of login /signup
        this.callrefreshpostfeedTrigger("");
        this.wallsService.callrefreshWallPage("")
        this.groupsService.callrefreshMyGrpListTrigger("");
        this.groupsService.callrefreshCommunitylistTrigger("");
        this.notificationService.callNOtificatoinTrigger("");
        this.userService.callrefreshSharedprofileurlINfoTrigger("");
        this.TourGuideService.callAllMessages().subscribe((res) => {
            console.log("🚀 ~ file: app.component.ts:244 ~ AppComponent ~ this.tourGuideService.callAllMessages ~ res:", res)
        })

    }

    checkIfPostCanBeEdit(postID) {

        let postResponse = {
            error: false,
            resObject: {}
        };
        return this.http
            .get(
                this.baseServerUrl +
                '/homewallpost?primaryAction=checkIfPostCanBeEdit&postID=' +
                postID +
                '&userID=' +
                this.storageService.getStorage('currentUserId')
            )
            .pipe(
                map((response) => {
                    if (!response || response == undefined || response == null) {
                        postResponse.error = true;
                    } else {
                        postResponse.error = false;
                        postResponse.resObject = response;
                    }
                    this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'postService', 'checkIfPostCanBeViewed()', '');
                    return postResponse;
                }),
                catchError((err) => {
                    throw this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'postService', 'checkIfPostCanBeViewed()', '', err);
                })
            );

    }

    async commonAlert(text, OKCB?) {
        const alert = await this.alertController.create({
            message: text,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        if (typeof OKCB === 'function') {
                            OKCB(); // Call the callback function if it exists
                        }
                    }
                }
            ]
        });
        alert.present();
    }
}
