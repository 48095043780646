import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';


@Component({
  selector: 'app-post-delete-reason',
  templateUrl: './post-delete-reason.component.html',
  styleUrls: ['./post-delete-reason.component.scss'],
})

export class PostDeleteReasonComponent implements OnInit {
  deletePostReasonForm: FormGroup;
  submitAttempt : boolean = false;
  post;
  currentUser ;
  disabledBy ;
  constructor(public formBuilder: FormBuilder,
              public postService: PostService,
              public modalController: ModalController) { 
    this.deletePostReasonForm = this.formBuilder.group({ 
      reason: ['', Validators.required]
    });
  }

  ngOnInit() {}

  closemodal(){
    this.modalController.dismiss();
  }

  submitReason(){
    this.submitAttempt = true;
    if (this.deletePostReasonForm.status == 'VALID') {

      // console.log(this.post._id, this.currentUser , this.disabledBy  ,this.deletePostReasonForm.value.reason)
      this.postService.deletePost(this.post, this.currentUser , this.disabledBy  ,this.deletePostReasonForm.value.reason)
    }
  }

}
