import { environment } from 'src/environments/environment';
import { SearchHealthInterestsPage } from './../../signup/search-health-interests/search-health-interests.page';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { ModalController } from '@ionic/angular';
import { AboutPage } from '../about/about.page';
import { UserService } from 'src/app/services/user/user.service'
import { StorageService } from 'src/app/services/storage/storage.service';
import { AIChatService } from 'src/app/ai/public/service/aichat.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.page.html',
    styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {

    AppName: string
    openAs;
    platform: string;
    constructor(public router: Router,
        public storageService: StorageService,
        public user: UserService,
        public userAnalyticsProvider: UserAnalyticsService,
        public modalController: ModalController,
        public aiChatService: AIChatService
    ) { }

    // Track visited pages in Sentry
    ngOnInit() {
        this.userAnalyticsProvider.trackPagesHandler('WelcomePage');
        this.AppName = environment.Appname;
        if (this.openAs != 'modal') this.clearStorage();

        this.platform = sessionStorage.getItem('platform');
    }



    // Navigate to login page
    navigateToLoginPage() {
        this.router.navigate(['/login']);
    }

    navigateToAdminApproval() {
        this.router.navigate(['/adminapproval']);
    }

    navigateToPendingSignup() {
        this.router.navigate(['/pending-signup']);
    }

    navigateToSearchHealthInterests() {
        this.router.navigate(['/search-health-interests']);
        // this.presentSearchHealthInterestsModal();
    }

    navigateToSignupPhysician() {
        this.router.navigate(['/signup-physician']);
    }

    navigateToSignupPatient() {
        this.router.navigate(['/continue-signup-patient']);
    }

    navigateToWall() {
        this.router.navigate(['/wall']);
    }

    navigateToCaseUpdate() {
        this.router.navigate(['/case-update']);
    }

    navigateToChatWindow() {
        this.router.navigate(['/chat-window']);
    }

    async presentSearchHealthInterestsModal() {
        const modal = await this.modalController.create({
            component: SearchHealthInterestsPage,
            cssClass: 'small-modal'
        });
        return await modal.present();
    }


    about() {
        let obj = { comingFrom: 'welcome', pageFlag: true };
        // this.router.navigateByUrl('/about', { state: obj })
        // this.router.navigate(['/about', obj.comingFrom, { pageFlag: obj.pageFlag }]);
        this.user.openAboutPrivacyInfo(obj.comingFrom, obj.pageFlag);
    }

    ionViewDidLoad() { }

    ionViewWillEnter() {
        // this.http.get('assets/json/global-constant.json').map((res) => res.json()).subscribe((data) => {
        //   this.loggingConstants = data;
        //   this.loggingService.LogMessage(this.loggingConstants.log_info, 'Welcome page ', null);
        // });.
    }

    dismiss() {
        this.modalController.dismiss();
    }

    clearStorage() {
        this.storageService.clearAll(['token']);
        // sessionStorage.clear();
    }
}
