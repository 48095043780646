import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthOnlyGuard } from './guards/auth-only/auth-only.guard';
import { AuthGuard } from './guards/auth/auth.guard';


const routes: Routes = [
    { path: '', loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule), canActivate: [AuthGuard] },
    // { path: '**', loadChildren: () => import('./modules/welcome/maintenance/maintenance.module').then((m) => m.MaintenancePageModule) },
    { path: 'about/:comingFrom', loadChildren: () => import('./modules/welcome/about/about.module').then((m) => m.AboutPageModule) },
    { path: 'about-group', loadChildren: () => import('./modules/groups/about-group/about-group.module').then((m) => m.AboutGroupPageModule), canActivate: [AuthGuard] },
    { path: 'about-me/:id', loadChildren: () => import('./modules/signup/about-me/about-me.module').then((m) => m.AboutMePageModule), canActivate: [AuthGuard] },
    { path: 'active-chat-window', loadChildren: () => import('./modules/chat/active-chat-window/active-chat-window.module').then(m => m.ActiveChatWindowPageModule), canActivate: [AuthGuard] },
    { path: 'add-group-members', loadChildren: () => import('./modules/groups/add-group-members/add-group-members.module').then((m) => m.AddGroupMembersPageModule), canActivate: [AuthGuard] },
    { path: 'adminapproval', loadChildren: () => import('./modules/signup/adminapproval/adminapproval.module').then((m) => m.AdminapprovalPageModule), canActivate: [AuthGuard] },
    { path: 'audio-recorder', loadChildren: () => import('./modules/media-utility/audio-recorder/audio-recorder.module').then(m => m.AudioRecorderPageModule), canActivate: [AuthGuard] },
    { path: 'awards-and-recognition/:id', loadChildren: () => import('./modules/signup/awards-and-recognition/awards-and-recognition.module').then((m) => m.AwardsAndRecognitionPageModule), canActivate: [AuthGuard] },
    { path: 'blocked-users', loadChildren: () => import('./modules/settings/blocked-users/blocked-users.module').then(m => m.BlockedUsersPageModule), canActivate: [AuthGuard] },
    { path: 'continue-signup-physician', loadChildren: () => import('./modules/signup/continue-signup-physician/continue-signup-physician.module').then((m) => m.ContinueSignupPhysicianPageModule), canActivate: [AuthGuard] },
    { path: 'continue-signup-patient', loadChildren: () => import('./modules/signup/continue-signup-patient/continue-signup-patient.module').then((m) => m.ContinueSignupPatientPageModule), canActivate: [AuthOnlyGuard] },
    { path: 'certificates/:id', loadChildren: () => import('./modules/signup/certificates/certificates.module').then((m) => m.CertificatesPageModule), canActivate: [AuthGuard] },
    { path: 'create-case-or-post/:comingFrom/:action/:pageComingFrom', loadChildren: () => import('./modules/case-post/create-case-or-post/create-case-or-post.module').then((m) => m.CreateCaseOrPostPageModule), canActivate: [AuthGuard] },
    { path: 'create-case-or-post', loadChildren: () => import('./modules/case-post/create-case-or-post/create-case-or-post.module').then((m) => m.CreateCaseOrPostPageModule), canActivate: [AuthGuard] },
    { path: 'case-update', loadChildren: () => import('./modules/case-post/case-update/case-update.module').then((m) => m.CaseUpdatePageModule), canActivate: [AuthGuard] },
    { path: 'create-group', loadChildren: () => import('./modules/groups/create-group/create-group.module').then((m) => m.CreateGroupPageModule), canActivate: [AuthGuard] },
    { path: 'chat', loadChildren: () => import('./modules/chat/chat/chat.module').then(m => m.ChatPageModule), canActivate: [AuthGuard] },
    { path: 'chat-connections', loadChildren: () => import('./modules/chat/chat-connections/chat-connections.module').then(m => m.ChatConnectionsPageModule), canActivate: [AuthGuard] },
    { path: 'connection-request', loadChildren: () => import('./modules/settings/connection-request/connection-request.module').then(m => m.ConnectionRequestPageModule), canActivate: [AuthGuard] },
    { path: 'connection-groups', loadChildren: () => import('./modules/groups/connection-groups/connection-groups.module').then(m => m.ConnectionGroupsPageModule), canActivate: [AuthGuard] },
    { path: 'connection-friends', loadChildren: () => import('./modules/groups/connection-friends/connection-friends.module').then(m => m.ConnectionFriendsPageModule), canActivate: [AuthGuard] },
    { path: 'communities', loadChildren: () => import('./modules/communities/communities.module').then(m => m.CommunitiesPageModule), canActivate: [AuthGuard] },
    { path: 'connection-communities', loadChildren: () => import('./modules/groups/connection-communities/connection-communities.module').then(m => m.ConnectionCommunitiesPageModule), canActivate: [AuthGuard] },
    { path: 'display-image/:id/:imagetype/:imageurls', loadChildren: () => import('./modules/media-utility/display-image/display-image.module').then((m) => m.DisplayImagePageModule), canActivate: [AuthGuard] },
    { path: 'drafts', loadChildren: () => import('./modules/case-post/drafts/drafts.module').then(m => m.DraftsPageModule), canActivate: [AuthGuard] },
    { path: 'edit-case-or-post/:comingFrom/:action/:pageComingFrom/:postId', loadChildren: () => import('./modules/case-post/create-case-or-post/create-case-or-post.module').then((m) => m.CreateCaseOrPostPageModule), canActivate: [AuthGuard] },
    { path: 'education/:id', loadChildren: () => import('./modules/signup/education/education.module').then((m) => m.EducationPageModule), canActivate: [AuthGuard] },
    { path: 'forgot-password/:comingFrom/:mode', loadChildren: () => import('./modules/login/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule), canActivate: [AuthGuard] },
    { path: 'group-wall', loadChildren: () => import('./modules/groups/group-wall/group-wall.module').then((m) => m.GroupWallPageModule), canActivate: [AuthGuard] },
    { path: 'group-home/:gid/:comingFrom', loadChildren: () => import('./modules/groups/group-home/group-home.module').then((m) => m.GroupHomePageModule) },
    { path: 'group-home', loadChildren: () => import('./modules/groups/group-home/group-home.module').then((m) => m.GroupHomePageModule), canActivate: [AuthGuard] },
    { path: 'groups-info', loadChildren: () => import('./modules/groups/groups-info/groups-info.module').then((m) => m.GroupsInfoPageModule), canActivate: [AuthGuard] },
    { path: 'global-search/:name', loadChildren: () => import('./modules/search/global-search/global-search.module').then(m => m.GlobalSearchPageModule), canActivate: [AuthGuard] },
    { path: 'image-pii/:isChatMedia', loadChildren: () => import('./modules/media-utility/image-pii/image-pii.module').then((m) => m.ImagePiiPageModule), canActivate: [AuthGuard] },
    { path: 'image-pii', loadChildren: () => import('./modules/media-utility/image-pii/image-pii.module').then((m) => m.ImagePiiPageModule), canActivate: [AuthGuard] },
    { path: 'invite-user', loadChildren: () => import('./modules/settings/invite-user/invite-user.module').then(m => m.InviteUserPageModule), canActivate: [AuthGuard] },
    { path: 'login', loadChildren: () => import('./modules/login/login/login.module').then((m) => m.LoginPageModule), canActivate: [AuthGuard] },
    { path: 'license/:id', loadChildren: () => import('./modules/signup/license/license.module').then((m) => m.LicensePageModule) },
    { path: 'maintenance/:msg', loadChildren: () => import('./modules/welcome/maintenance/maintenance.module').then((m) => m.MaintenancePageModule) },
    { path: 'my-posts', loadChildren: () => import('./modules/settings/my-posts/my-posts.module').then(m => m.MyPostsPageModule), canActivate: [AuthGuard] },
    { path: 'notification', loadChildren: () => import('./modules/notification/notification/notification.module').then(m => m.NotificationPageModule), canActivate: [AuthGuard] },
    { path: 'offline-home', loadChildren: () => import('./modules/home/offline-home/offline-home.module').then(m => m.OfflineHomePageModule), canActivate: [AuthGuard] },
    { path: 'pending-signup', loadChildren: () => import('./modules/signup/pending-signup/pending-signup.module').then((m) => m.PendingSignupPageModule), canActivate: [AuthGuard] },
    { path: 'publications/:id', loadChildren: () => import('./modules/signup/publications/publications.module').then((m) => m.PublicationsPageModule), canActivate: [AuthGuard] },
    { path: 'post-details/:id', loadChildren: () => import('./modules/case-post/post-details/post-details.module').then((m) => m.PostDetailsPageModule) },
    // { path: 'post-details/:id', loadChildren: () => import('./modules/case-post/post-details/post-details.module').then((m) => m.PostDetailsPageModule), canActivate: [AuthGuard] },
    { path: 'profile-picture', loadChildren: () => import('./modules/media-utility/profile-picture/profile-picture.module').then((m) => m.ProfilePicturePageModule), canActivate: [AuthGuard] },
    { path: 'privacy', loadChildren: () => import('./modules/settings/privacy/privacy.module').then(m => m.PrivacyPageModule), canActivate: [AuthGuard] },
    { path: 'personal-info/:id', loadChildren: () => import('./modules/signup/personal-info/personal-info.module').then(m => m.PersonalInfoPageModule), canActivate: [AuthGuard] },
    { path: 'reset-password', loadChildren: () => import('./modules/settings/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule), canActivate: [AuthGuard] },
    { path: 'report', loadChildren: () => import('./modules/home/report/report.module').then(m => m.ReportPageModule), canActivate: [AuthGuard] },
    { path: 'reported-post', loadChildren: () => import('./modules/home/reported-post/reported-post.module').then(m => m.ReportedPostPageModule), canActivate: [AuthGuard] },
    { path: 'signup', loadChildren: () => import('./modules/signup/signup/signup.module').then((m) => m.SignupPageModule), canActivate: [AuthGuard] },
    { path: 'search-health-interests', loadChildren: () => import('./modules/signup/search-health-interests/search-health-interests.module').then((m) => m.SearchHealthInterestsPageModule), canActivate: [AuthGuard] },
    { path: 'signup-physician', loadChildren: () => import('./modules/signup/signup-physician/signup-physician.module').then((m) => m.SignupPhysicianPageModule), canActivate: [AuthGuard] },
    { path: 'settings', loadChildren: () => import('./modules/settings/settings/settings.module').then((m) => m.SettingsPageModule), canActivate: [AuthGuard] },
    { path: 'share-post', loadChildren: () => import('./modules/case-post/share-post/share-post.module').then((m) => m.SharePostPageModule), canActivate: [AuthGuard] },
    { path: 'show-users', loadChildren: () => import('./modules/home/show-users/show-users.module').then((m) => m.ShowUsersPageModule), canActivate: [AuthGuard] },
    { path: 'show-connections', loadChildren: () => import('./modules/userProfile/show-connections/show-connections.module').then((m) => m.ShowConnectionsPageModule), canActivate: [AuthGuard] },
    { path: 'share-groups/:originalPostId/:groupType', loadChildren: () => import('./modules/groups/share-groups/share-groups.module').then((m) => m.ShareGroupsPageModule), canActivate: [AuthGuard] },
    { path: 'share-from-wall', loadChildren: () => import('./modules/home/share-from-wall/share-from-wall.module').then((m) => m.ShareFromWallPageModule), canActivate: [AuthGuard] },
    { path: 'support', loadChildren: () => import('./modules/settings/support/support.module').then(m => m.SupportPageModule), canActivate: [AuthGuard] },
    { path: 'specialization', loadChildren: () => import('./modules/signup/specialization/specialization.module').then(m => m.SpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'sub-specialization', loadChildren: () => import('./modules/signup/sub-specialization/sub-specialization.module').then(m => m.SubSpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'select-country-code-list', loadChildren: () => import('./modules/signup/select-country-code-list/select-country-code-list.module').then(m => m.SelectCountryCodeListPageModule), canActivate: [AuthGuard] },
    { path: 'search-communities', loadChildren: () => import('./modules/signup/search-communities/search-communities.module').then(m => m.SearchCommunitiesPageModule), canActivate: [AuthGuard] },
    { path: 'user-profile/:id', loadChildren: () => import('./modules/userProfile/user-profile/user-profile.module').then((m) => m.UserProfilePageModule) },
    { path: 'user-preferences', loadChildren: () => import('./modules/settings/user-preferences/user-preferences.module').then(m => m.UserPreferencesPageModule), canActivate: [AuthGuard] },
    { path: 'version', loadChildren: () => import('./modules/welcome/version/version.module').then((m) => m.VersionPageModule) },
    { path: 'welcome', loadChildren: () => import('./modules/welcome/welcome/welcome.module').then((m) => m.WelcomePageModule) },
    { path: 'wall', loadChildren: () => import('./modules/home/wall/wall.module').then((m) => m.WallPageModule), canActivate: [AuthGuard] },
    { path: 'work-experience/:id', loadChildren: () => import('./modules/signup/work-experience/work-experience.module').then((m) => m.WorkExperiencePageModule), canActivate: [AuthGuard] },
    { path: 'connection-groups-friends', loadChildren: () => import('./modules/connection-groups-friends/connection-groups-friends.module').then(m => m.ConnectionGroupsFriendsPageModule), canActivate: [AuthGuard] },
    { path: 'invite-physician', loadChildren: () => import('./modules/settings/invite-physician/invite-physician.module').then(m => m.InvitePhysicianPageModule), canActivate: [AuthGuard] },
    { path: 'nurse-specialization', loadChildren: () => import('./modules/signup/nurse-specialization/nurse-specialization.module').then(m => m.NurseSpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'dentist-specialization', loadChildren: () => import('./modules/signup/dentist-specialization/dentist-specialization.module').then(m => m.DentistSpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'therapist-specialization', loadChildren: () => import('./modules/signup/therapist-specialization/therapist-specialization.module').then(m => m.TherapistSpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'psychologist-specialization', loadChildren: () => import('./modules/signup/psychologist-specialization/psychologist-specialization.module').then(m => m.PsychologistSpecializationPageModule), canActivate: [AuthGuard] },
    { path: 'security', loadChildren: () => import('./modules/settings/security/security.module').then(m => m.SecurityPageModule), canActivate: [AuthGuard] },
    { path: 'request-activate-account', loadChildren: () => import('./modules/userProfile/request-activate-account/request-activate-account.module').then(m => m.RequestActivateAccountPageModule), canActivate: [AuthGuard] },
    { path: 'upgrade-to-hcp', loadChildren: () => import('./modules/userProfile/upgrade-to-hcp/upgrade-to-hcp.module').then(m => m.UpgradeToHCPPageModule) },
    {
        path: 'edit-comment',
        loadChildren: () => import('./modules/case-post/edit-comment/edit-comment.module').then(m => m.EditCommentPageModule)
    },


];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
