import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { log } from 'console';
import { SelectCountryCodeListPageRoutingModule } from 'src/app/modules/signup/select-country-code-list/select-country-code-list-routing.module';
import { MediaService } from 'src/app/services/media/media.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-record-story',
    templateUrl: './record-story.component.html',
    styleUrls: ['./record-story.component.scss'],
})
export class RecordStoryComponent implements OnInit {
    @ViewChild('video') captureElement: ElementRef;
    @ViewChild('preview', { static: false }) preview: ElementRef;
    chunks: Blob[] = [];
    recordingTime: number = 45000;
    stream: MediaStream;
    localStream: MediaStream;
    mediaRecorder: MediaRecorder;
    isRecording: boolean = false;
    videoBuffer: Blob;
    isRecordingCompleted: boolean = false;
    isRecordingNull: boolean = true;
    constructor(
        public modalCtrl: ModalController,
        public alertController: AlertController,
        public mediaService: MediaService,
        public userService: UserService
    ) { }

    ngOnInit() {
        this.presentVideoStoryAlert();
    }

    async recordVideo() {
        await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'user'
            },
            audio: true
        }).then((stream) => {
            this.isRecordingNull = false;
            this.localStream = stream;
            this.captureElement.nativeElement.srcObject = stream;
            this.mediaRecorder = new MediaRecorder(stream);
            console.log("🚀 ~ file: record-story.component.ts:47 ~ RecordStoryComponent ~ recordVideo ~ this.mediaRecorder:", this.mediaRecorder)
            this.mediaRecorder.ondataavailable = (event) => {
                console.log("🚀 ~ file: record-story.component.ts:49 ~ RecordStoryComponent ~ recordVideo ~ event:", event)
                if (event.data && event.data.size > 0) {
                    this.chunks.push(event.data)
                }
            }
            this.mediaRecorder.start();

            setTimeout(() => {
                this.stopRecord();
            }, this.recordingTime);
        }).catch((err) => {
            alert("something went wrong please and try again");
            this.dismiss();
        })
        console.log("🚀 ~ file: record-story.component.ts:63 ~ RecordStoryComponent ~ recordVideo ~ navigator:", navigator, navigator.mediaDevices)

        this.isRecording = true;

        const options = { mimeType: 'video/webm' };

        this.chunks = [];

    }

    stopRecord() {
        this.localStream.getTracks()[0].stop(); // audio channel off
        this.localStream.getTracks()[1].stop(); // video channel off
        this.mediaRecorder?.stop()
        this.mediaRecorder.onstop = async (event) => {
            // this.videoBuffer = new Blob(this.chunks, { type: this.chunks[0].type });
            this.videoBuffer = new Blob(this.chunks, { type: 'video/mp4' });
            this.chunks = [];
            let videoURL = URL.createObjectURL(this.videoBuffer);
            this.preview.nativeElement.src = videoURL;
            this.isRecordingCompleted = true;
        }
        this.mediaRecorder = null;
        this.captureElement.nativeElement.srcObject = null;
        this.isRecording = false;
    }

    async presentVideoStoryAlert() {
        const videoStoryAlert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            message: `Video story will be ${this.recordingTime / 1000} seconds long`,
            buttons: [{
                text: 'OK',
                handler: () => {
                    this.recordVideo();
                }
            }],

        });

        await videoStoryAlert.present();

        const { role } = await videoStoryAlert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    // save() {
    //     this.saveVideoStoryToS3();
    //     console.log("🚀 ~ file: record-story.component.ts:99 ~ RecordStoryComponent ~ save ~ this.mediaName:", this.mediaName)
    // }


    saveVideoStoryToS3() {

        let mediaName = this.mediaService.getUniqueMediaName('video-story');
        console.log("media name", mediaName);

        this.mediaService.requestMediUploadUrl(mediaName, 'forUpload').then((requestMediaURLResponse) => {
            requestMediaURLResponse.subscribe((requestedURL) => {
                console.log("🚀 ~ file: record-story.component.ts:107 ~ RecordStoryComponent ~ requestMediaURLResponse.subscribe ~ requestedURL:", requestedURL)
                if (requestedURL) {
                    this.mediaService.postMediaToS3Bucket(requestedURL, this.videoBuffer).then((res) => {
                        res.subscribe((uploadres) => {
                            console.log("here is the uploadres", uploadres);
                            let userUploadData = {
                                userVideoStoryName: mediaName,
                                userVideoStoryCreatedAt: new Date()
                            }
                            this.userService.addToProfile(userUploadData).subscribe((res) => {
                                // alert("Video story has been uploaded successfully.")
                            })
                        })
                        console.log(" here is the post response", res);

                    })
                }
            })
        })
    }

    dismiss() {
        if (this.isRecording == true) this.stopRecord();
        this.modalCtrl.dismiss();
        // this.location.back();
    }

    confirm() {
        this.saveVideoStoryToS3();
        this.modalCtrl.dismiss();
        // this.location.back();
    }

}
