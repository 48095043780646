import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { WallsService } from 'src/app/services/walls/walls.service';
@Component({
    selector: 'app-connection-friends',
    templateUrl: './connection-friends.page.html',
    styleUrls: ['./connection-friends.page.scss'],
})
export class ConnectionFriendsPage implements OnInit {
    modalData: any;
    userFriends: any;

    constructor(
        public modalCtrl: ModalController,
        public userService: UserService,
        public loggingService: LoggingService,
        public userAnalyticsService: UserAnalyticsService,
        public wallsService: WallsService
    ) {
        this.loggingService.fetchGlobalConstants();
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        this.getUserConnections(this.modalData.uid);
        console.log("🚀 ~ file: connection-friends.page.ts:27 ~ ConnectionFriendsPage ~ this.modalData.uid:", this.modalData.uid)
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('ConnectionFriendsPage');
    }

    getUserConnections(uid) {
        this.userService.getUserGroupsAndConnections(uid).subscribe((res: any) => {
            console.log("🚀 ~ file: connection-friends.page.ts:35 ~ ConnectionFriendsPage ~ this.userService.getUserGroupsAndConnections ~ res:", uid, res.resObject.data[0])
            this.userFriends = res.resObject.data[0].userFriends;
            this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'ConnectionFriendsPage', 'getUserConnections()', '');
        }, (error => {
            this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'ConnectionFriendsPage', 'getUserConnections()', '', error);
        }))
    }

    goBack() {
        this.modalCtrl.dismiss();
    }
}
